import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  5: {
    unit: 'Feelings',
    id: 'FG10-F-P11-E5',
    exerciseKey: '/img/FriendsPlus/Page11/E5/Key/answerKey.png',
    audio: '',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '5',
        title: `
          In pairs, do the quiz below. How many did you get right?
        `,
        color: '#860052',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 3px', borderColor: 'gray' },
        limitSelect: 1,
        listWords: ['a <br/> b <br/> c', 'a <br/> b <br/> c', 'a <br/> b <br/> c', 'a <br/> b <br/> c'],
        answers: ['0-0', '1-8', '2-8', '3-4'],
        initialValue: [],
      },
      Layout: `
          <div style='position: relative;'>
              <div><img src='/img/FriendsPlus/Page11/E5/1.png' style="width:600px;"/></div>
                <div style='position: absolute; top: 170px; left: 430px;'><input id='0' type='Circle' /> </div>
                <div style='position: absolute; top: 330px; left: 430px;'><input id='1' type='Circle' /> </div>
                <div style='position: absolute; top: 490px; left: 430px;'><input id='2' type='Circle' /> </div>
                <div style='position: absolute; top: 658px; left: 430px;'><input id='3' type='Circle' /> </div>
              </div>
          </div>
      `,
    },
  },
  6: {
    unit: 'Feelings',
    id: 'FG10-F-P11-E6',
    exerciseKey: '/img/FriendsPlus/Page11/E6/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 180,
    titleQuestion: [
      {
        num: '6',
        title: `
          Read the tweets and complete the hashtags with adjectives from exercise 2.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style='width:850px;color:rgb(146, 39, 143)'><b>Adjectives to describe feelings</b>  
         &emsp; anxious &emsp; ashamed &emsp; bored &emsp; confused &emsp; cross &emsp; delighted &emsp; disappointed &emsp; embarrassed &emsp; envious &emsp; excited &emsp; frightened &emsp; proud &emsp; relieved &emsp; shocked &emsp; suspicious &emsp; upset
        </div>

          <div style="display: flex; gap:10px;">
            <div><b>1</b></div>
            <div>
              <div><b>Stella P</b>&emsp;<span style="color:gray;">@StellaP02</span></div>
              <div>Jules has got that new phone that I really want … <i style="color:rgb(154, 201, 243);">envious</i></div>
            </div>
          </div>
          <div style="display: flex; gap:10px;">
            <div><b>2</b></div>
            <div>
              <div><b>Amy Price </b><span style="color:gray;">@Priceyone</span></div>
              <div>There’s nothing to do here. #</div>
            </div>
          </div>
          <div style="display: flex; gap:10px;">
            <div><b>3</b></div>
            <div>
              <div><b>Student Voice </b>&emsp;<span style="color:gray;">@1234student</span></div>
              <div>It’s our end-of-term party tonight!!  #</div>
            </div>
          </div>
          <div style="display: flex; gap:10px;">
            <div><b>4</b></div>
            <div>
              <div><b>MaryGG </b>&emsp;<span style="color:gray;">@MaryGG</span></div>
              <div>Our English teacher is away so we haven’t got a test :D #</div>
            </div>
          </div>
          <div style="display: flex; gap:10px;">
            <div><b>5</b></div>
            <div>
              <div><b>Music Mad </b>&emsp;<span style="color:gray;">@mad4music</span></div>
              <div>My new MP3 player doesn’t work.  #</div>
            </div>
          </div>
          <div style="display: flex; gap:10px;">
            <div><b>6</b></div>
            <div>
              <div><b>6 Dan B</b>&emsp;<span style="color:gray;"> @DanB25</span></div>
              <div>Oops! Dropped a plate of food in the school canteen. #</div>
            </div>
          </div>
        `,
        answer: ['bored', 'excited / relieved', 'delighted / relieved', 'upset / disappointed', 'embarrassed'],
      },
    ],
  },
  7: {
    unit: 'Feelings',
    id: 'FG10-F-P11-E7',
    exerciseKey: '/img/FriendsPlus/Page11/E7/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD1_06.mp3',
    video: '',
    component: T6,
    inputSize: 140,
    maxLength: 12,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 18,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '7',
        title: `
          <audioimage name="1.06"></audioimage>
          <b> Listen to four speakers. Match one
            adjective from the list below with each speaker
            (1–4). There are four extra adjectives.
          </b>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div style="color: rgb(146,39,143)">
            <span>anxious  cross  confused </span><br>
            <span>disappointed excited embarrassed </span><br>
            <span>proud  suspicious</span>
          </div>

        <div style="margin-top: 10px;">
          <b>Answers:</b>
        </div>
    
        <div>
          <div>
            <b>1</b> <span style="margin-right: 10px;">#</span>
            <b>2</b> <span style="margin-right: 10px;">#</span>
          </div>
          <div>
            <b>3</b> <span style="margin-right: 10px;">#</span>
            <b>4</b> <span style="margin-right: 10px;">#</span>
          </div>
        </div>
        `,
        answer: ['excited', 'disappointed', 'confused', 'embarrassed'],
      },
    ],
  },
  8: {
    unit: 'Feelings',
    id: 'FG10-F-P11-E8',
    exerciseKey: '/img/FriendsPlus/Page11/E8/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD1_06.mp3',
    video: '',
    component: T6,
    inputSize: 180,
    maxLength: 20,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 18,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '8',
        title: `
          <audioimage name="1.06"></audioimage>
          <b> Read the <span style="color:rgb(146,39,143) ;">Recycle!</span> box. Then listen again and
            complete the sentences (a–d) with speakers 1–4
            and the correct present tense form of the verbs in
            brackets.
          </b>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
         <div>
          <img src="/img/FriendsPlus/Page11/E8/E8.jpg" style="width:700px">
        </div>  
        <div style="margin-top: 10px;">
          <b>Answers:</b>
        </div>

        <div style="margin-top: 10px;">
          <div>
            <b>a</b> 
            <span>&nbsp Speaker# #</span>
            <span> (not know) that a friend’s exam result is bad.</span>
          </div>    
          <div>
            <b>b</b> 
            <span>&nbsp Speaker# #</span>
            <span> (not understand) why there aren’t any buses.</span>
          </div> 
          <div>
            <b>c</b> 
            <span>&nbsp Speaker# #</span>
            <span> (have) a ticket for a really good concert.</span>
          </div> 
          <div>
            <b>d</b> 
            <span>&nbsp Speaker# #</span>
            <span> (make) a birthday cake for a friend who won’t like it.</span>
          </div> 
        </div>
        
        `,
        answer: ['4', 'doesn’t know', '3', 'doesn’t understand', '1', 'has', '2', 'is making'],
      },
    ],
  },
  9: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U1-P16-E6',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '9',
        title: `
          <b style="color: rgb(2, 146, 97);">SPEAKING</b> Work in pairs. Ask and answer about the
following situations. Sometimes you might need 
more than one adjective.
        `,
      },
    ],
    recorder: true,
    questionImage: [
      [
        // Column1
        { url: '/img/FriendsPlus/Page11/E9/E9.jpg' },
      ],
    ],
  },
};

export default json;
