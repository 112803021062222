import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Feelings',
    id: 'FG10-F-P14-E1',
    exerciseKey: '',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
        <span style="color: rgb(2, 146, 97);">SPEAKING</span> Work in pairs. Say what you did last night.
        `,
        color: '#860052',
      },
    ],

    recorder: true,
    questionImage: [],
  },

  2: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U1-P14-E2',
    video: '',
    audio: '/Audios/Friends Global Grade 10 SB_CD1_11.mp3',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page14/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `
          <audioimage name="1.11"></audioimage> Read and listen to the video chat. 
        `,
        color: '#860052',
      },
    ],
    recorder: true,
    textareaStyle: { width: 800 },
    questions: [
      {
        title: `

          <div style="display:flex; gap:30px">
            <div>
              <div style="display: flex;">
                <div style="font-weight: bold; width: 100px;">Sam</div>
                <div>Hi, Emma! Did you go out last night?</div>
              </div>
              <div style="display: flex;">
                <div style="font-weight: bold; width: 100px;">Emma</div>
                <div>Yes, I went to the cinema.</div>
              </div>
              <div style="display: flex;">
                <div style="font-weight: bold; width: 100px;">Sam</div>
                <div>Oh, really? Who did you go with?</div>
              </div>
              <div style="display: flex;">
                <div style="font-weight: bold; width: 100px;">Emma</div>
                <div>My sister.</div>
              </div>
              <div style="display: flex;">
                <div style="font-weight: bold; width: 100px;">Sam</div>
                <div>What did you see?</div>
              </div>
              <div style="display: flex;">
                <div style="font-weight: bold; width: 100px;">Emma</div>
                <div>The new Jennifer Lawrence film.</div>
              </div>
              <div style="display: flex;">
                <div style="font-weight: bold; width: 100px;">Sam</div>
                <div>Did you enjoy it?</div>
              </div>
            </div>
          	<div> <img src='img/FriendsPlus/Page14/E2/1.png' alt="" style="width: 330px;"/> </div>
          </div>

            
            <div style="display: flex;">
              <div style="font-weight: bold; width: 100px;">Emma</div>
              <div style="margin-left:28px;">No, it wasn’t great. And I couldn’t see the screen very well. The man in front <br/>of me was really tall, and he didn’t stop talking to his girlfriend!</div>
            </div>
            <div style="display: flex;">
              <div style="font-weight: bold; width: 100px;">Sam</div>
              <div>I hate that!</div>
            </div>
            <div style="display: flex;">
              <div style="font-weight: bold; width: 100px;">Emma</div>
              <div>And that’s not all. I lost my mobile! I think I dropped it in the cinema.</div>
            </div>
            <br/>
          <div><b>Why did Emma not enjoy her evening at the cinema?</b></div>
         <textarea id="0" rows="3"></textarea>
        `,
        answer: [`The film wasn't great. She couldn't see the screen very well because the man in front of her was really tall and he didn't stop talking to his girlfriend. She also lost her mobile.`],
      },
    ],
  },

  3: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U1-P14-E3',
    video: '',
    audio: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: `
        Read the <span style="color:rgb(245, 147, 37);">Learn this!</span> box. Complete the rules. 
        `,
        color: '#860052',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
    },
    exerciseKey: 'img/FriendsPlus/Page14/E3/Key/answerKey.png',
    inputSize: 100,
    checkDuplicated: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					  <div style=" width: 750px; background-image: url('img/FriendsPlus/Page14/E3/2.png');  background-repeat: no-repeat;
  background-size: 750px 450px;">
              <div style='padding:25px 15px 25px 55px;'>
                    <div><b style='color:rgb(244, 143, 32);'>LEARN THIS!</b><b> Past simple (negative and interrogative)</b></div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> a </b></div>
                      <div>We form the negative form of the past simple with <b><sup>1</sup>#</b> and the infinitive without <i>to</i>. 
                      <br/><i style='color:rgb(124, 162, 211)'>I didn't go out last night.</i></div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> b </b></div>
                      <div>We form the interrogative form of the past simple with <b><sup>2</sup>#</b> and the infinitive without <i>to</i>. 
                      <br/><i style='color:rgb(124, 162, 211)'>Did Harry text you? Yes, he did. / No, he didn't.</i></div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> c </b></div>
                      <div>We do not use <i>did / didn't</i> with the verbs <b><sup>3</sup>#/#</b> or <b><sup>4</sup>#/#</b> .
                      <br/><i style='color:rgb(124, 162, 211)'>Was Joe late for school? Yes, he was.<br/>Could you read when you were three? No, I couldn't.</i></div>
                    </div>
                </div>
            </div>
        `,
        answer: [
          `did not /didn't `,
          `did`,
          `was / were `, `was / were `,
          `could / couldn't`, `could / couldn't`,
        ],
      },
    ],
  },

  4: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U1-P14-E4',
    audio: '/Audios/Friends Global Grade 10 SB_CD1_12.mp3',
    video: '',
    component: T6,
    isHiddenCheck: true,
    textAlign: 'center',
    stylesTextInput: {
      backgroundColor: 'transparent',
      borderBottom: 'dotted 1px',
      padding: 0,
      fontSize: 22,
    },
    exerciseKey: 'img/FriendsPlus/Page14/E4/Key/answerKey.png',
    inputSize: 200,
    titleQuestion: [
      {
        num: '4',
        title:
          '<audioimage name="1.12"></audioimage> Complete the second part of the dialogue. Use the past simple affirmative, negative or interrogative form of the verbs in brackets. Then listen and check.',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Sam</b></div> <div style="flex: 9">You <sup><b>1</b></sup> # (not leave) your mobile at the cinema. You <sup><b>2</b></sup> # (lend) it to me, remember? I <sup><b>3</b></sup> # (not give) it back to you</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Emma</b></div> <div style="flex: 9">Yes, of course! Can you bring it to school tomorrow?</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Sam</b></div> <div style="flex: 9">I’m really sorry, but I <sup><b>4</b></sup> # (leave) it on the bus yesterday evening.</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Emma</b></div> <div style="flex: 9">h no! What <sup><b>5</b></sup> # (you / do)? <sup><b>6</b></sup> # (you / ring) the bus company?</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Sam</b></div> <div style="flex: 9">Yes, I did, but they <sup><b>7</b></sup> # (not can) find it. It <sup><b>8</b></sup> # (not be) on the bus. Don’t worry. I <sup><b>9</b></sup> # (phone) your number …</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Emma</b></div> <div style="flex: 9"> <sup><b>10</b></sup> # (anyone / answer)?</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Sam</b></div> <div style="flex: 9">Yes! Lucy, from our class.</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Emma</b></div> <div style="flex: 9">Why <sup><b>11</b></sup> # (she / have) my phone? <sup><b>12</b></sup> # (she / be) on the bus with you?</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Sam</b></div> <div style="flex: 9">Yes. She <sup><b>13</b></sup> # (pick) it up by mistake. She’s bringing it to school tomorrow!</div></div>
        </div>
        `,
        answer: [
          "didn't leave ",
          'lent ',
          "didn't give ",
          'left',
          'did you do ',
          'Did you ring ',
          "couldn't ",
          "wasn't",
          'phoned ',
          'Did anyone answer ',
          'did she have',
          'Was she ',
          'picked',
        ],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U1-P14-E5',
    audio: '',
    video: '',
    component: T6,
    isHiddenCheck: true,
    textAlign: 'center',
    stylesTextInput: {
      padding: 0,
    },
    exerciseKey: 'img/FriendsPlus/Page14/E5/Key/answerKey.png',
    inputSize: 220,
    titleQuestion: [
      {
        num: '5',
        title: `
          Complete the <i>yes / no</i> questions about your weekend. Use the past simple interrogative form of the verbs below. Then complete the follow-up questions.
        `,
      },
    ],
    questionImage: [],
    
    questions: [
      {
        title: `
              <div style="color: rgb(246, 140, 31); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px">
                <div>do (x2)</div>
                <div>go</div>
                <div>play</div>
                <div>see</div>
                <div><s>watch</s></div>
              </div>
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "><span style="color: rgb(0, 68, 98)">Did you watch</span> TV? What <span style="color: rgb(0, 68, 98)">did you watch</span>?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> # out on Friday or Saturday evening? Where # ?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> # anyone on Saturday or Sunday? Who # ?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> # any homework? When # ?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> # computer games? Which # ?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> # any sport? What # ?</div></div>
              </div>
        `,
        answer: [
          'Did you go',
          'did you go',
          'Did you see',
          'did you see',
          'Did you do',
          'did you do it',
          'Did you play',
          'games did you play',
          'Did you do',
          'sport did you do',
        ],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U1-P14-E6',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '6',
        title: `
          <b style="color: rgb(2, 146, 97);">SPEAKING</b> Work in pairs. Take turns to ask and answer the questions in exercise 5.
        `,
      },
    ],
    recorder: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page14/E6/1.jpg' },
      ],
    ],
  },
};

export default json;
