import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Grammar Builder and  Reference",
    id: "SB10-GBAR-P106-E1",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title:
          "Complete the sentences with the present simple affirmative form of the verbs below.",
        color: "black",
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: "center",
      borderBottom: "none",
      backgroundColor: "transparent",
    },
    inputSize: 110,
    maxLength: 7,
    exerciseKey: "img/FriendsPlus/Page6/E1/Key/answerKey.png",
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style=' position: relative; '>
            <div><img src='img/FriendsPlus/Page6/2.jpg' style='width: 1025px;' /></div>
            <div style=" position: absolute; top: 196px; left: 55px;">#</div>
            <div style=" position: absolute; top: 349px; left: 97px;">#</div>
            <div style=" position: absolute; top: 110px; left: 375px;">#</div>
            <div style=" position: absolute; top: 110px; left: 516px;">#</div>
            <div style=" position: absolute; top: 321px; left: 415px;">#</div>
            <div style=" position: absolute; top: 321px; left: 564px;">#</div>
            <div style=" position: absolute; top: 49px; left: 841px;">#</div>
            <div style=" position: absolute; top: 129px; left: 841px;">#</div>
            <div style=" position: absolute; top: 353px; left: 741px;">#</div>
            <div style=" position: absolute; top: 353px; left: 849px;">#</div>
					</div>
				`,
        answer: [
          "Hannah",
          "Tony",
          "Joanne",
          "Matt",
          "Melanie",
          "John",
          "Michael",
          "Luke",
          "Sally",
          "Nico",
        ],
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "SB7-S-P6-E2",
    audio: "Audios/1-02 Friends Plus 7.mp3",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title:
          '<audioimage name="1.02"></audioimage> Read the text again and match the words in <span style="color: #00aeef;">blue</span> in the text with eight opposite words from the box. Then listen and check.',
        color: "#0067b4",
      },
    ],
    exerciseKey: "img/FriendsPlus/Page6/E2/Key/answerKey.png",
    stylesTextInput: {
      // fontSize: 18,
      // height: 24,
      // borderBottom: "none",
      backgroundColor: "transparent",
    },
    textAlign: "center",
    inputSize: 190,
    maxLength: 13,
    titleImage: "",
    questionImage: [],
    hintBox: [
      {
        src: [
          "aunt",
          "<s>brother</s>",
          "child",
          "cousin",
          "daughter",
          "granddaughter",
          "grandmother",
          "mum",
          "niece",
          "partner",
          "twin",
          "wife",
        ],
        borderColor: "#468dcb",
        width: 525,
      },
    ],
    questions: [
      {
        title: `
          <div><img src='img/FriendsPlus/Page6/2.jpg' style='width: 1025px;' /></div>
          <div>brother - <i>sister</i></div>
          <hintbox id='0'></hintbox>
          <div>uncle - #</div>
          <div>husband - #</div>
          <div>son - #</div>
          <div>nephew - #</div>
          <div>dad - #</div>
          <div>grandfather - #</div>
          <div>grandson - #</div>
        `,
        answer: [
          "aunt",
          "wife",
          "daughter",
          "niece",
          "mum",
          "grandmother",
          "granddaughter",
        ],
      },
    ],
  },
  3: {
    unit: "Starter",
    id: "SB7-S-P6-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page6/E3/Key/answerKey.png",
    inputSize: 240,

    titleQuestion: [
      {
        num: "3",
        title: "Write names for 1–6.",
        color: "#0067b4",
      },
    ],
    questions: [
      {
        title: `
          <div style="background-color: rgb(207, 232, 212); border-radius: 25px; padding: 20px;">
            <div><img src='img/FriendsPlus/Page6/E3/1.png' /></div>
            <div>Hannah’s dad Michael</div>
            <div><b>1</b> Hannah’s mum #</div>
            <div><b>2</b> Melanie’s husband #</div>
            <div><b>3</b> Michael and Sally’s son #</div>
            <div><b>4</b> John’s sister #</div>
            <div><b>5</b> Nico’s grandfather #</div>
            <div><b>6</b> Nico’s aunts and #</div>
          </div>
        `,
        answer: ["Sally", "Matt", "Luke", "Joanne", "Tony", "Lucy and Hannah"],
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "SB7-S-P6-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page6/E3/Key/answerKey.png",
    inputSize: 240,

    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Ask and answer the questions. Use the Key Phrases to help you. Then change the words in <span style='color: #00aeef;'>blue</span> to invent new questions.",
        color: "#0067b4",
        prefix: "default",
        // prefix: { icons: ['far fa-comment', 'fas fa-pencil-alt'], text: 'CULTURAL' },
      },
    ],
    questions: [
      {
        title: `
          <div><b>1</b> Have you got any <span style='color: rgb(0, 174, 239);'>brothers</span> and <span style='color: rgb(0, 174, 239);'>sisters</span>?</div>
          <div><b>2</b> What’s your <span style='color: rgb(0, 174, 239);'>mum</span>’s name?</div>
          <div><b>3</b> How old is your <span style='color: rgb(0, 174, 239);'>dad</span>?</div>
          <div><b>4</b> Where’s your <span style='color: rgb(0, 174, 239);'>grandfather</span> from?</div>
          <div><b>5</b> Have you got a <span style='color: rgb(0, 174, 239);'>cousin</span>? </div>
          How old is he / she?
        `,
        answer: [],
      },
    ],
  },
};

export default json;
