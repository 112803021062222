import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P110-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title:
          "<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>2B</span>Past continuous</strong> <br /> <strong>1</strong> Complete the sentences. Use the past continuous form of the verbs in brackets.<leftarrow name='2.1, 2.2, 2.3, 2.4'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 150,
    exerciseKey: 'img/FriendsPlus/Page110/E1/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              At eight o’clock yesterday evening, Josh <input />
              (text) his girlfriend.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              'Why <input /> 
              they <input />
              (laugh) at me?'
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              You <input />
              (not pay) attention while I <input />
              (speak), were you?
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              Dave <input />
              (walk) down the street, <input />
              (eat) a sandwich.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              Emma <input />
              (watch) TV and Lisa <input />
              (read) a magazine.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              At midnight, Wendy <input />\
              still <input />
              (do) her homework.
            </div>
          </div>
				`,
        answer: [
          'was texting',
          'were',
          'laughing',
          `weren't paying`,
          'was speaking',
          'was walking',
          'eating',
          'was watching',
          'was reading',
          'was',
          'doing',
        ],
      },
    ],
  },
  2: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P110-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title:
          "Complete the text with the past continuous form of the verbs below. <leftarrow name='2.1, 2.2, 2.3'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 140,
    exerciseKey: 'img/FriendsPlus/Page110/E2/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
         <div style="display: flex; column-gap: 20px; flex-wrap: wrap; color: rgb(246 140 31)">
            <span>get</span>
            <span>make</span>
            <span>put</span>
            <span>shine</span>
            <span>sing</span>
            <span>talk</span>
          </div>
          <div style="width: 700px;  background-color: rgb(241 235 216); box-shadow: 7px 7px 5px rgb(203 202 202); padding: 20px; font-size: 22px; border-radius: 5px; font-style: oblique">
            <span>
              It was a beautiful morning. The sun <sup>1</sup> <input /> 
              and the birds <sup>2</sup> <input />.
              We <sup>3</sup> <input />
              ready to go to the beach. Dad <sup>4</sup> <input />
              our bags in the car and Mum and Tom <sup>5</sup> <input /> 
              sandwiches in the kitchen <br />
              I <sup>6</sup> <input />
              to my friend on the phone
            </span>
          </div>
				`,
        answer: ['was shining', 'were singing', 'were getting', 'was putting', 'were making', 'was talking'],
      },
    ],
  },
  3: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P110-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title:
          "<span style='color:rgb(246 140 31)'></span>Complete the dialogue with the past continuous form of the verbs in brackets. <leftarrow name='2.1, 2.2, 2.3'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 180,
    exerciseKey: 'img/FriendsPlus/Page110/E3/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="display: flex; width: 800px; column-gap: 20px ">
            <div style="width: 200px;">
              <strong>Policeman</strong>
            </div>
            <div style="width: 600px">
              What <sup>1</sup> <input />
               (you / do) at 9 o’clock yesterday evening ?
            </div>
          </div>
          <div style="display: flex; width: 800px; column-gap: 20px ">
            <div style="width: 200px;">
              <strong>Woman</strong>
            </div>
            <div style="width: 600px">
              I <sup>2</sup> <input />
              (watch) TV in the living room.
            </div>
          </div>

          <div style="display: flex; width: 800px; column-gap: 20px ">
            <div style="width: 200px;">
              <strong>Policeman</strong>
            </div>
            <div style="width: 600px">
              What <sup>3</sup> <input />
              (you / watch)?
            </div>
          </div>
          <div style="display: flex; width: 800px; column-gap: 20px ">
            <div style="width: 200px;">
              <strong>Woman</strong>
            </div>
            <div style="width: 600px">
              Er … I can’t remember. I  <sup>4</sup> <input />
              (not pay) attention. I was sleepy.
            </div>
          </div>
          
          <div style="display: flex; width: 800px; column-gap: 20px ">
            <div style="width: 200px;">
              <strong>Policeman</strong>
            </div>
            <div style="width: 600px">
              What <sup>5</sup> <input />
              (your husband / do) at that time?
            </div>
          </div>
          <div style="display: flex; width: 800px; column-gap: 20px ">
            <div style="width: 200px;">
              <strong>Woman</strong>
            </div>
            <div style="width: 600px">
              He <sup>6</sup> <input />
              (make) chicken pie in the kitchen.
            </div>
          </div>

          <div style="display: flex; width: 800px; column-gap: 20px ">
            <div style="width: 200px;">
              <strong>Policeman</strong>
            </div>
            <div style="width: 600px">
              How do you know?
            </div>
          </div>
          <div style="display: flex; width: 800px; column-gap: 20px ">
            <div style="width: 200px;">
              <strong>Woman</strong>
            </div>
            <div style="width: 600px">
              Because our dog <sup>7</sup> <input />
              (go crazy). He loves the smell of chicken!
            </div>
          </div>
        `,
        answer: [
          'were you doing',
          'was watching',
          'were watching',
          `wasn't paying`,
          'was your husband doing',
          'was making',
          'was going',
        ],
      },
    ],
  },
  4: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P110-E4',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title:
          "What were you doing last Saturday at these times? Write sentences. <leftarrow name='2.1, 2.2, 2.3'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 500,
    exerciseKey: 'img/FriendsPlus/Page110/E4/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
        <div style="display: flex; column-gap: 20px; justify-content: space-between; width: 500px">
          <div>
            <div>
              <strong>1</strong>
              <span style="margin-left: 20px">7 a.m</span>
            </div>
            <div>
              <strong>2</strong>
              <span style="margin-left: 20px">10 a.m</span>
            </div>
            <div>
              <strong>3</strong>
              <span style="margin-left: 20px">1 p.m</span>
            </div>
          </div>
         <div>
            <div>
              <strong>4</strong>
              <span style="margin-left: 20px">6 p.m</span>
            </div>
            <div>
              <strong>5</strong>
              <span style="margin-left: 20px">9 p.m</span>
            </div>
            <div>
              <strong>6</strong>
              <span style="margin-left: 20px">11.30 p.m</span>
            </div>
          </div>
        </div>

        <div style="margin-top: 20px">
          <div style="color: rgb(4 84 121); font-style: oblique;">At 7 a.m., I was having a shower</div>
          <div>
            <strong style="margin-right: 15px">1</strong>
            <input />
          </div>
          <div>
            <strong style="margin-right: 15px">2</strong>
            <input />
          </div>
          <div>
            <strong style="margin-right: 15px">3</strong>
            <input />
          </div>
          <div>
            <strong style="margin-right: 15px">4</strong>
            <input />
          </div>
          <div>
            <strong style="margin-right: 15px">5</strong>
            <input />
          </div>
          <div>
            <strong style="margin-right: 15px">6</strong>
            <input />
          </div>
        </div>
        `,
        answer: [],
      },
    ],
  },
  5: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P110-E5',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title:
          "<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>2D</span>Contrast: past simple and past continuous</strong> <br /> <strong>5</strong> Complete the sentences with the past simple or past continuous form of the verbs in brackets. <leftarrow name='2.5, 2.6'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 200,
    exerciseKey: 'img/FriendsPlus/Page110/E5/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              When Molly <input />
              (drop) her phone on the floor, <input />
              (break).
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              When my parents <input />
              (get) home, they <input />
              (find) a mouse in the kitchen.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              Martin <input />
              (not hear) the phone because he <input />
              (have) a shower.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              At 8 p.m. last night, I <input />
              (sit) at my desk but I <input />
               (not work).
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              When I <input />
              (see) Ben and Tia, they  <input />
              (sit) on a bench, <input />
              (laugh) and <input />
              (chat).
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              It was a warm afternoon, but the sun <input />
              (not shine).
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>7</strong>
            <div>
              While we <input />
              (take) my grandmother to hospital, our car <input />
              (break down).
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>8</strong>
            <div>
              I <input />
              (lose) my watch while we <input />
              (play) volleyball in the park.
            </div>
          </div>
				`,
        answer: [
          'dropped',
          ' broke',
          'got',
          'found',
          `didn't hear`,
          ' was having',
          'was sitting',
          `wasn't working`,
          'saw',
          'were sitting',
          'laughing',
          'chatting',
          `wasn't shining`,
          'were taking',
          'broke down',
          'lost',
          'were playing',
        ],
      },
    ],
  },
  6: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P110-E6',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '6',
        title:
          "Complete the second sentence so that it means the same as the first. <leftarrow name='2.5, 2.6'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 400,
    exerciseKey: 'img/FriendsPlus/Page110/E6/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              <div>While we were having dinner, my dad arrived home.</div>
              <div>
                We <input />
                when my dad <input />.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              <div>I was trying to sleep when the phone rang</div>
              <div>
                The <input />
                while I <input />.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div>She was climbing up some rocks when she fell.</div>
              <div>
                As she <input />
                she <input />.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              <div>While you were shopping, I tidied your room.</div>
              <div>
                You <input />
                when <input />.
              </div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <div>The boat hit some rocks as it was sailing towards the shore.</div>
              <div>
                The boat was <input />
                when it <input />.
              </div>
            </div>
          </div>
          
				`,
        answer: [
          'We were having dinner',
          'arrived home.',
          'The phone rang',
          'was trying to sleep.',
          'As she was climbing up some rocks',
          'fell.',
          'You were shopping',
          'I tidied your room.',
          'The boat was sailing towards the shore',
          'hit some rocks.',
        ],
      },
    ],
  },
  7: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P110-E7',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '7',
        title:
          "Complete the text with the past simple or past continuous form of the verbs in brackets <leftarrow name='2.1, 2.2, 2.3'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 200,
    exerciseKey: 'img/FriendsPlus/Page110/E7/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="width: 800px;  background-color: rgb(241 235 216); box-shadow: 7px 7px 5px rgb(203 202 202); padding: 20px; font-size: 22px; border-radius: 5px; font-weight: 600">
            <span>
              It was past midnight when Helen <sup>1</sup> <input /> 
              (arrive) home. She <sup>2</sup> <input />.
              (close) the door quietly because her parents <sup>3</sup> <input />
              (sleep) upstairs. As she <sup>4</sup> <input />
              (take off) her coat, she <sup>5</sup> <input />
              (see) a letter on the floor with her name on it. She <sup>6</sup> <input />
              (open) the letter and <sup>7</sup> <input />
              (read) it. Then she <sup>8</sup> <input />
              (put) it in her pocket <sup>9</sup> <input />
              (pick up) her coat and <sup>10</sup> <input />
              (go) out again. It <sup>11</sup> <input />
              (be) a cold night but it <sup>12</sup> <input />
              (not rain). A few people <sup>13</sup> <input />
              (walk) towards the High Street, so Helen <sup>14</sup> <input />
              (cross) the road and <sup>15</sup> <input />
              (follow) them. She <sup>16</sup> <input />
              (not know) where they <sup>17</sup> <input />
              (go) – but she <sup>18</sup> <input />
              (not want) to be alone.
            </span>
          </div>
				`,
        answer: [
          'arrived ',
          'closed',
          'were sleeping',
          'took off',
          ' saw',
          'opened ',
          'read',
          'put',
          'picked up',
          'went',
          'was',
          `wasn't raining`,
          'were walking',
          'crossed',
          'followed',
          `didn't know`,
          'were going',
          `didn't want`,
        ],
      },
    ],
  },
};

export default json;
