import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 6',
    id: 'SB10-U6-P74-E1',
    exerciseKey: '/img/FriendsPlus/Page74/E1/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 600,
    maxLength: 50,
    hideBtnFooter: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
      marginLeft: -12,
    },
    textareaStyle: { width: 200 },
    titleQuestion: [
      {
        num: '1',
        title: `
           Read the text. Where is Howells’s hard drive? How much is it worth? <br/>Did he find it?
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
            <img style='margin-left:100px' src="/img/FriendsPlus/Page74/E1/1.jpg" ' >
            <div  style='line-height:49px ' >
              <b>1</b> #<br>
              <b>2</b> #<br>
              <b>3</b> #<br>
            </div>
          </div>

        `,
        answer: [
          `Howell's hard drive is in an enormous rubbish dump`,
          'It is worth $7.5 million.',
          `No, he did't find it`,
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 6',
    id: 'SB10-U6-P74-E2',
    exerciseKey: '/img/FriendsPlus/Page74/E2/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 230,
    maxLength: 30,
    isHiddenCheck: true,
    checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
    },
    textareaStyle: { width: 600 },
    titleQuestion: [
      {
        num: '2',
        title: `
              Look at the examples of the past perfect highlighted
              in the text in exercise 1. <br/>Find all the other examples.
              Complete rule a in the <span style="color: rgb(246,140,32);">Learn this!</span> box.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div style='display: flex;'><img src="/img/FriendsPlus/Page74/E2/1.jpg" alt=""/><img src="/img/FriendsPlus/Page74/E2/E2.png" alt=""/></div>
                
          <div style="margin-top: 20px;">
          <div><b>Learn this:</b>&emsp;1-<input width="100px"/>, &emsp;&emsp;&emsp; 2-<input width="100px"/>,</div>
          <div><b>all the other examples:</b>#,#,#,#,#,#,#,#,#.</div>
        </div>

        `,
        answer: [
          'had',
          `hadn't`,
          `had thrown away`,
          `had risen`,
          `had increased`,
          `had spilled`,
          `had first kept`,
          ` had sold`,
          `had thrown`,
          `had totally forgotten`,
          `hadn't saved`,
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 6',
    id: 'SB10-U6-P74-E3',
    exerciseKey: '/img/FriendsPlus/Page74/E3/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 800,
    maxLength: 73,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      //borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
    },
    textareaStyle: { width: 600 },
    titleQuestion: [
      {
        num: '3',
        title: `
              Read rules b and c in the <span style="color: rgb(246,140,32);">Learn this!</span> box. 
              Explain the difference in meaning between the sentences below
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div style='margin-left: 200px;margin-top: 20px;'><img src="/img/FriendsPlus/Page74/E2/E2.png" ></div>
          <div  style='margin-top: 20px;' >
              <b>1</b> <span>When he threw away the computer, Howells
                forgot about the bitcoins.</span> <br>
                <b>2</b> <span>When he threw away the computer, Howells had
                  forgotten about the bitcoins.</span>
          </div>
          <div style="margin-top: 20px;">
            <b>Answers:</b> <br>
            <b>1</b> <span> #</span> <br>
            <b>2</b> <span> #</span>
          </div>
        `,
        answer: [
          'Howell threw the computer away and then forgot about the bitcoins.',
          'Howell had forgotten about the bitcoins before he threw the computer away',
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 6',
    id: 'SB10-U6-P74-E4',
    exerciseKey: '/img/FriendsPlus/Page74/E4/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 160,
    maxLength: 73,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      //borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '4',
        title: `
              Complete the sentences. Use the past perfect form of the verbs in brackets.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
            <div style='line-height:60px'>
                  <b>1</b> After I <span><input type="text" width="60px"></span> (leave) the newsagent’s 
                  <span>I realised I</span> <span> #</span> (forget) to buy a paper.
                <br>
                  <b>2</b> When I got to the shopping centre, most of the
                  shops <span> <input type="text" width="60px"></span> already
                  <span> <input type="text" width="100px"></span> . (close)

                <br>
                  <b>3</b> We didn’t have any bread because I <span> #</span> (not go) to the baker’s. 
                <br>
                  <b>4</b> My sister wasn’t there when I got home because
                  she  <span> <input type="text" width="120px"></span> (go) to the cinema.
            </div>
        `,
        answer: ['left', 'had forgotten', 'had', 'closed', `hadn't gone`, 'had gone'],
      },
    ],
  },
  5: {
    unit: 'Unit 6',
    id: 'SB10-U6-P74-E7',
    exerciseKey: '/img/FriendsPlus/Page74/E5/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 750,
    maxLength: 80,
    stylesTextInput: {
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '5',
        title: `
              <b style="color: rgb(146,39,143);">USE OF ENGLISH </b >
              Rewrite each pair of sentences as one sentence, using <i>after</i>.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div>
            <b>1.</b>
            <span> I bought a lottery ticket. Then I went home.</span> <br>
            <span style="color: rgb(39,131,197);margin-left: 23px;">After I’d bought a lottery ticket, I went home</span><br>
            <b>2.</b> 
            <span style="margin-left:10px">I went to the cosmetics store. Then I went to the
              hairdresser’s.</span> <br>
              <b>3</b> 
            <span style="margin-left:10px"> I paid for the flowers. Then I left the shop.</span> <br>
              <b>4</b> 
            <span style="margin-left:10px"> Jim did some shopping. Then he caught the bus
              home.</span>
          </div>
          <div style="margin-top: 20px;">
            <b>Answers:</b> <br>
            <b>2</b> <span> #</span> <br>
            <b>3</b> <span> #</span> <br>
            <b>4</b> <span> #</span> <br>
          </div>
        `,
        answer: [
          `After I'd gone to the cosmetics store, I went to the hairdresser's.`,
          `After I'd paid for the flowers, I left the shop.`,
          `After Jim had done some shopping, he caught the bus home.`,
        ],
      },
    ],
  },
  6: {
    unit: 'Unit 6',
    id: 'SB10-U6-P74-E6',
    exerciseKey: '/img/FriendsPlus/Page74/E6/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 360,
    maxLength: 50,
    stylesTextInput: {
      textAlign: 'center',
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '6',
        title: `
              Complete the text with the phrases below. Use the
              past perfect.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
         <div style="color: rgb(246,140,32)">
            <span style="margin-right:60px">buy his ticket </span> <span>not check the numbers</span> <br>
            <span style="margin-right:155px">lose </span> <span>lose his ticket</span> <br>
            <span style="margin-right:50px">win the lottery </span> <span>take the lottery company</span>
          </div>
         <div style="width:800px;background-color: rgb(226, 231, 235); padding: 10px 20px; border-radius: 20px;margin:10px;">
            When Martyn Tott learned that he <b><sup>1</sup></b># , he was, 
            of course, delighted. He <b><sup>2</sup></b># months earlier 
            at his local newsagent’s. He <b><sup>3</sup></b># at the time, 
            but he knew he was the winner because he always 
            chose the same six numbers. But there was a 
            problem. He <b><sup>4</sup></b># ! Martyn Tott’s computer 
            records proved that he was the winner, but the 
            lottery company refused to pay him without a 
            ticket. Two years later, he was almost penniless. 
            He <b><sup>5</sup></b># to court, but <b><sup>6</sup></b># the battle. 
            He said he wished he had never played the lottery!
         </div>
        `,
        answer: [
          'had won the lottery',
          'had bought his ticket',
          `hadn't checked the numbers`,
          'had lost his ticket',
          'had taken the lottery company',
          'had lost',
        ],
      },
    ],
  },
  7: {
    unit: 'Unit 6',
    id: 'SB10-U6-P74-E7',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '7',
        title: `
                    <b style='color:rgb(0,147,70);'>SPEAKING</b> 
                                Work in pairs. Discuss the explanations for
                                the situation or event using the past perfect. What
                                had happened?
          <div style="margin-top: 20px; font-weight: 400;">
            <b>1</b>&nbsp<span> The car drove onto the pavement and hit a lamp
              post.</span> <br>
            <b>2</b> &nbsp<span> A cleaner found a wallet under the seats in the
              cinema.</span> <br>
            <b>3</b> &nbsp<span> The boy opened the envelope, read the letter and
              started to dance around.</span> <br>
          </div>
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [],
  },
};

export default json;
