import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';

const json = {
	1: {
		// Exercise num
		unit: 'Unit 1',
		id: 'SB10-U1-P19-E1',
		audio: '',
		video: '',
		component: UI,
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. Describe the photo. What is the boy planning to do? <br/>Use the words below to help you.
<br/>		
              <span style="color: rgb(34, 132, 198);">
                bathroom &emsp; fake spider &emsp; play a prank &emsp; put &emsp; scare &emsp;            
              </span>
        `,
				color: '#860052',
			},
		],
		recorder: true,
		questionImage: [
			[
				// Column1
				{ url: '/img/FriendsPlus/Page19/E1/1.jpg' },
			],
		],
	},

	2: {
		unit: 'Unit 1',
		id: 'SB10-U1-P19-E2',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page19/E2/Key/answerKey.png',
		component: T6,
		inputSize: 150,
		maxLength: 10,
		stylesTextInput: {
			fontSize: 23,
		},
		textareaStyle: { width: 600 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '2',
				title: `
          Read the descriptions of two events and check your ideas for exercise 1. 
          Use the adjectives below or your own ideas to explain why the pranks should not be played.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex; gap: 30px;">
            <div><img src="/img/FriendsPlus/Page19/E2/1.jpg" style="max-width: 100%;" /></div>

            <div>
              <div style="font-weight: bold; color: rgb(34, 132, 198); display: flex; gap: 10px 30px; flex-wrap: wrap;">
                <span>childish</span><span>annoying</span><span>cruel</span><span>scary</span><span>disappointed</span>
              </div>
              <textarea id="0" rows="23"></textarea>
            </div>
          </div>
        `,
				answer: [],
			},
		],
	},
	3: {
		unit: 'Unit 1',
		id: 'SB10-U1-P19-E3',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page19/E3/Key/answerKey.png',
		component: T6,
		inputSize: 130,
		maxLength: 10,
		textAlign: 'center',
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		textareaStyle: { width: 700 },
		titleQuestion: [
			{
				num: '3',
				title: `
          Read the <span style="font-weight: bold; color: rgb(34, 132, 198);">Writing Strategy</span>. 
          Match the adjectives below with people in the stories (A–D). 
          Say when and why they felt that way.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex; gap: 30px;">
            <div style="flex: 2;"><img src="/img/FriendsPlus/Page19/E2/1.jpg" style="max-width: 100%;" /></div>

            <div style="flex: 3; display: flex; flex-direction: column; justify-content: space-between;">
              <div style="padding: 10px; border-radius: 10px; border: 4px dotted rgb(34, 132, 198); background-color: rgb(232, 239, 248); box-shadow: 3px 3px 7px rgba(0,0,0,0.4);">
                <span style="font-weight: bold; color: rgb(34, 132, 198);">Writing Strategy</span>
                <div>
                  When you write a description of an event, 
                  remember to say how you and other people felt at the time and how you all reacted to the events. 
                  This will make your anecdote come to life and engage the reader’s interest.
                </div>
              </div>

              <div style="padding: 10px; border-radius: 10px; border: 1px solid rgb(34, 132, 198); font-weight: bold; color: rgb(34, 132, 198); display: flex; gap: 10px 30px; flex-wrap: wrap;">
                <span>amused</span><span>angry (2 people)</span><span>anxious</span><span>frightened</span><span>guilty</span><span>pleased (2 people)</span>           
              </div>

              <div style="padding: 10px; border-radius: 10px; border: 1px solid rgb(34, 132, 198)">
                <div style="display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">A</div>
                  <div>Dave #, #</div>
                </div>
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">B</div>
                  <div>Dave’s big sister #, #</div>
                </div>	
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">C</div>
                  <div>Kate #, #</div>
                </div>	
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">D</div>
                  <div>Kate’s brother #, #</div>
                </div>	
              </div>

              <div><textarea rows="7"></textarea></div>
          </div>
        `,
				answer: ['amused', 'guilty', 'angry', 'frightened', 'anxious', 'pleased', 'angry', 'pleased'],
			},
		],
	},
	4: {
		unit: 'Unit 1',
		id: 'SB10-U1-P19-E4',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page19/E4/Key/answerKey.png',
		component: T6,
		inputSize: 150,
		maxLength: 10,
		textareaStyle: { width: 600 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '4',
				title: `
          Read the task above. 
          Make notes using one of the ideas below, real information or your own ideas.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex; gap: 30px; align-items: start;">
            <div style="flex: 1; padding: 10px; border-radius: 10px; background-color: rgb(209, 223, 242); box-shadow: 3px 3px 5px rgba(0,0,0,0.4)">
              <span style="font-weight: bold; color: rgb(34, 132, 198);">Task</span>
              <div>
                You played a prank on a friend or family member. 
                Write a post for an internet forum called ‘Own Up!’
                <ul>
                  <li>Give a short description of the prank.</li>
                  <li>Say how your friend or family member reacted.</li>
                  <li>Describe your feelings at the time.</li>
                  <li>Say how bad you feel about it now, and why.</li>
                </ul>
              </div>
            </div>

            <div>
              <div style="flex: 1; padding: 10px; border-radius: 10px; background-color: rgb(209 242 226); box-shadow: 3px 3px 5px rgba(0,0,0,0.4)">
                <span style="font-weight: bold; color: rgb(185 101 222);">Ideas</span>
                <ul>
                  <li>put salt on someone’s ice cream</li>
                  <li>put a fake mouse in a kitchen cupboard</li>
                  <li>glue some coins to the classroom floor</li>
                </ul>
              </div>
              <div style="margin-top: 10px;"><textarea id="0" rows="6"></textarea></div>
            </div>
          </div>
        `,
				answer: [],
			},
		],
	},
	5: {
		unit: 'Unit 1',
		id: 'SB10-U1-P19-E5',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page19/E5/Key/answerKey.png',
		component: T6,
		inputSize: 150,
		maxLength: 10,
		textareaStyle: { width: 800 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
          Write your forum post (100 - 120 words).
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <textarea id="0" rows="10"></textarea>
        `,
				answer: [],
			},
		],
	},
};

export default json;
