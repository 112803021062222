import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U-P15-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
          <b style="color: rgb(2, 146, 97);">SPEAKING</b> Look at the title of the text and the photo. What do you think the story is about?
        `,
      },
    ],
    recorder: true,
    questionImage: [[{ url: 'img/FriendsPlus/Page15/E1/1.jpg' }]],
  },
  2: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U-P15-E2',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '2',
        title: `
          Read the text. Who replied to Zoe’s message? When did they reply, and from where?
        `,
      },
    ],
    recorder: true,
    questionImage: [[{ url: 'img/FriendsPlus/Page15/E1/1.jpg' }]],
  },
  3: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U-P15-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: `
            Find all the <i>-ed</i> and <i>-ing</i> adjectives in the text in exercise 2. <br/>Complete the rules in the <i style="color:rgb(153, 34, 136);">Learn this!</i> box.

        `,
      },
    ],
    isHiddenCheck: true,
    stylesTextInput: {
      backgroundColor: 'transparent',
      padding: 0,
    },
    exerciseKey: 'img/FriendsPlus/Page15/E3/Key/answerKey.png',
    inputSize: 130,
    selectStyle: {
      width: 87,
      textAlign: 'center',
    },
    selectOptionValues: ['-ed', '-ing'],
    questions: [
      {
        title: `

					  <div style=" width: 750px; background-image: url('img/FriendsPlus/Page15/E3/2.jpg');  background-repeat: no-repeat;background-size: 750px 300px;">
              <div style='padding:25px 15px 25px 65px;'>
                    <div><b style='color:rgb(153, 34, 136);'>LEARN THIS!</b><b> <i>-ed / -ing</i> adjectives</b></div>
                    <div  style='display: flex;flex-wrap:wrap;'>Many adjectives ending in &nbsp;
                    <b><sup >1</sup></b><select id='9' ></select> <span>describe how people feel.</span> Adjectives ending in &nbsp;
                    <b><sup>2</sup></b><select id='10'></select> <span>describe something</span> which makes them feel that way. Pairs of <i> -ed / -ing</i> &ensp; adjectives like these are formed from verbs, e.g. <i>disappoint</i>.
                      </div>
                    <i style='color:rgb(124, 162, 211)'>I’m disappointed. My exam result is disappointing.</i>
                </div>
            </div>

        <div>
          <div><b>&nbsp;-ed:</b> #,#,#,#.</div>
          <div><b> -ing:</b> #,#,#,#,#.</div>
        </div>
        `,
        answer: [
          'bored',
          'amazed',
          'surprised',
          'delighted',
          'tiring',
          'interesting',
          'moving',
          'astonishing',
          'exciting',
          '-ed',
          '-ing',
        ],
      },
    ],
  },
  4: {
    unit: 'Feelings',

    id: 'FG10-F-P15-E4',
    audio: '',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '4',
        title: `
          Circle the correct forms to complete the sentences.
        `,
        color: '#860052',
      },
    ],
    exerciseKey: '/img/FriendsPlus/Page15/E4/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          margin: '-2px',
        },
        limitSelect: 1,
        listWords: [
          'excited / exciting',
          'frightened / frightening',
          'shocked / shocking',
          'annoyed / annoying',
          'worried / worrying?',
        ],
        answers: ['0-4', '1-0', '2-0', '3-0', '4-0'],
        initialValue: [],
      },
      Layout: `
         <div style="line-height: 50px;">
          <div>
            <b>1 </b> <span>I don’t find computer games very </span>
            <b><input type="Circle" id="0"></b>
          </div>
          <div>
            <b>2 </b> <span>Don’t be </span>
            <b><input type="Circle" id="1"></b>
            <span> The dog won’t</span>
          </div>
          <div>
            <b>3 </b> <span>I was </span>
            <b><input type="Circle" id="2"></b>
            <span> when I heard the news.</span>
          </div>
          <div>
            <b>4 </b> <span>It’s really </span>
            <b><input type="Circle" id="3"></b>
            <span> when you interrupt.</span>
          </div>
          <div>
            <b>5 </b> <span>Why are you looking so </span>
            <b><input type="Circle" id="4"></b>
          </div>

        </div>
      `,
    },
  },

  5: {
    // Exercise num
    unit: 'Unit 1',
    id: 'WB10-U1-P5-E1',
    audio: '',
    video: '',
    component: T6,
    isHiddenCheck: true,
    stylesTextInput: {
      textAlign: 'center',
      borderBottom: 'dotted 1px',
    },
    titleQuestion: [
      {
        num: '5',
        title: 'Complete each pair of sentences with -ed and -ingadjectives formed <br/>from the verbs in brackets.',
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page15/E5/Key/answerKey.png',
    inputSize: 170,
    recorder: true,
    questionImage: [],
    questions: [
      {
        title: `
        <div style="width: 900px">
          <div style="display: flex; margin-block: 30px;">
              <div style="flex: 1"><b>1</b></div>
              <div style="flex: 25">
                  <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>a</b></div> <div style="flex: 15">I don’t understand this map. It’s very # . (confuse)</div></div>
                  <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>b</b></div> <div style="flex: 15">Can you help me with my maths? I’m # . (confuse)</div> </div>
              </div>
          </div>
          <div style="display: flex; margin-block: 30px">
              <div style="flex: 1"><b>2</b></div>
              <div style="flex: 25">
                  <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>a</b></div> <div style="flex: 15"> I was # when I fell over. (embarrass)</div></div>
                  <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>b</b></div> <div style="flex: 15"> I hate it when my dad dances. It’s so # ! (embarrass)</div></div>
              </div>
          </div>
          <div style="display: flex; margin-block: 30px">
              <div style="flex: 1"><b>3 </b></div>
              <div style="flex: 25">
                  <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>a</b></div> <div style="flex: 15">Are you # in photography? (interest)</div></div>
                  <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>b</b></div> <div style="flex: 15">Which is the most # lesson in this unit? (interest)</div></div>
              </div>
          </div>
        </div>
        `,
        answer: ['confusing', 'confused', ' embarrassed', 'embarrassing', ' interested', 'interesting'],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U1-P15-E6',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '6',
        title: `
          <b style="color: rgb(2, 146, 97);">SPEAKING</b> Read the <i style="color:rgb(153, 34, 136);">Learn this!</i> box. Then work in pairs.
        `,
      },
    ],
    recorder: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page15/E6/1.jpg' },
      ],
    ],
  },
};

export default json;
