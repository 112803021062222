import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Ambition',
		id: 'FG10-A-P61-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page61/E1/Key/answerKey.png',
		component: T6,
		inputSize: 260,
		maxLength: 20,
		// checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 23,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="color: rgb(146, 39, 144)">VOCABULARY</span> Match five of the jobs below with photos A–E.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex; justify-content: center; align-items: center;">
            <img src="img/FriendsPlus/Page61/E1/1.jpg" style="max-width: 100%;" />
          </div>

          <div style="margin-top: 10px; color: rgb(196, 12, 70);">
            <div>
              <strong>Jobs(2)</strong>
              <span style="margin-left: 91px;">builder</span>
              <span style="margin-left: 45px;">estate agent</span>
              <span style="margin-left: 51px;">gardener</span>
            </div>
            <div>
              <span>groundskeeper</span>
              <span style="margin-left: 20px;">journalist</span>
              <span style="margin-left: 20px;">locksmith</span>
            </div>
            <div>
              <span>photographer</span>
              <span style="margin-left: 36px;">solicitor</span>
              <span style="margin-left: 38px;">pizza delivery man / woman</span>
            </div>
            <div>
              <span>police officer</span>
              <span style="margin-left: 45px;">stunt performer</span>
            </div>
            <div>
              <span>surfing instructor</span>
              <span style="margin-left: 73px;">video game developer</span>
            </div>
          </div>	

          <div>
            <i>Answer:</i>
            <div style="display: flex; align-items: space-between; justify-content: space-between;">
              <div style="display:flex; flex-direction: column;">
                <span><strong>A</strong> #</span>
                <span><strong>B</strong> #</span>
              </div>
              <div style="margin-left: 10px; display:flex; flex-direction: column;">
                <span><strong>C</strong> #</span>
                <span><strong>D</strong> #</span>
              </div>
              <div style="margin-left: 10px; display:flex; flex-direction: column;">
                <span><strong>E</strong> #</span>
              </div>
            </div>
          </div>
        `,
				answer: ['locksmith', 'estate agent', 'roundskeeper', 'stunt performer', 'builder'],
			},
		],
	},
	2: {
		unit: 'Ambition',
		id: 'FG10-A-P61-E2',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 180,
		maxLength: 18,
		checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '2',
				title: `
					Answer the questions.
           Use the jobs and work activites from lesson 5A to help you.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="width: 600px;">
            <div style="display: flex;">
              <strong style="padding-right: 10px;">1</strong>
              <span>
                Choose two jobs from exercise 1 and describe them. <br/>
                <span style="color: rgb(75, 142, 168);">
                  Builders work outdoors. They’re on their feet all day. 
                  I think it’s repetitive work but it is skilled.
                </span>
              </span>			
            </div>
            <div style="display: flex;">
              <strong style="padding-right: 10px;">2</strong>
              <span>
                Which is the easiest / most difficult of all the jobs in your opinion? Why? <br/>
                <span style="color: rgb(75, 142, 168);">
                  I think the easiest job is … . That’s because … .
                </span>
              </span>			
            </div>
          </div>
	      `,
				answer: [],
			},
		],
	},
	3: {
		unit: 'Ambition',
		id: 'FG10-A-P61-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page61/E3/Key/answerKey.png',
		component: T6,
		inputSize: 180,
		maxLength: 17,
		// checkDuplicated: true,
		// stylesTextInput: {
		// 	textAlign: 'center',
		// 	fontSize: 23,
		// },
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
          <span style="color: rgb(146, 39, 144)">KEY PHRASES</span> 
          Read the <span style="color: rgb(196, 12, 70)">Listening Strategy</span>.  
          Match the words below with what they indicate (1–6).

        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex;">
            <div style="width: 470px; padding: 10px; border-radius: 10px; border: 2px dotted rgb(196, 12, 70); background-color: rgb(248, 228, 226);">
                <span style="font-weight: bold; color: rgb(196, 12, 70);">Listening Strategy</span>
                <div>
                  Some words and phrases can help you to predict what is 
                  coming next in a listening. For example, if you hear <i>however</i>, 
                  you know that it will be followed by a contrasting point. 
                  Listen out for “signposts” like these that help you understand 
                  the structure of the listening.
                </div>
            </div>

            <div style="margin-left: 20px; display: flex; color: rgb(196, 12, 70);  padding: 10px; border-radius: 10px; border: 1px solid rgb(196, 12, 70);">
              <div>
                although<br/>
                however<br/>
                in other words<br/>
                for example<br/>
                not only that<br/>
                on the other hand<br/>
              </div>
              <div style="margin-left: 20px;">
                that’s because<br/>
                what is more<br/>
                what I mean is<br/>
                for instance<br/>
                nevertheless<br/>
                <br/>
              </div>
              <div style="margin-left: 20px;">
                in my opinion<br/>
                moreover<br/>
                as I see it<br/>
                such as<br/>
                in spite of this<br/>
                therefore<br/>
              </div>
            </div>
          </div>

          <div style="margin-top: 10px;">
            <i>Answer:</i> <br/>

            <div style="display: flex;">
              <span><strong>1</strong> a contrasting point</span>
              <span style="padding-left: 10px;"># # <br/> # # <input id='4' width='220px'/></span>
            </div>

            <div style="display: flex;">
              <span><strong>2</strong> an additional point</span>
              <span style="padding-left: 10px;"># # #</span>
            </div>

            <div style="display: flex;">
              <span><strong>3</strong> an example</span>
              <span style="padding-left: 81px;"># # #</span>
            </div>

            <div style="display: flex;">
              <span><strong>4</strong> a paraphrase</span>
              <span style="padding-left: 64px;"># #</span>
            </div>

            <div style="display: flex;">
              <span><strong>5</strong> an opinion</span>
              <span style="padding-left: 92px;"># #</span>
            </div>

            <div style="display: flex;">
              <span><strong>6</strong> a reason</span>
              <span style="padding-left: 108px;"># #</span>
            </div>
          </div>
        `,
				answer: [
					'although',
					'however',
					'nevertheless',
					'in spite of this',
					'on the other hand',
					'what is more',
					'moreover',
					'not only that',
					'for example',
					'for instance',
					'such as',
					'in other words',
					'what I mean is',
					'in my opinion',
					'as I see it',
					"that's because",
					'therefore',
				],
			},
		],
	},
	4: {
		unit: 'Ambition',
		id: 'FG10-A-P61-E1',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_16.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page61/E4/Key/answerKey.png',
		component: T6,
		inputSize: 35,
		maxLength: 1,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 22,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '4',
				title: `
					<audioimage name="2.16"></audioimage>
					Listen to the sentences (A–F). 
					What do you expect to hear next? Choose from items 1–6 in exercise 3.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="marrgin-top: 10px; width: 550px; display: flex; justify-content: space-between; background-color: rgb(2255, 237, 207); padding: 20px; border-radius: 10px;">
						<div>
							<span style="font-weight: bold; padding-right: 10px;">1</span>a contrasting point<br/> 
							<span style="font-weight: bold; padding-right: 10px;">2</span>an additional point<br/> 
							<span style="font-weight: bold; padding-right: 10px;">3</span>an example<br/> 
						</div>
						<div>
							<span style="font-weight: bold; padding-right: 10px;">4</span>a paraphrase<br/> 
							<span style="font-weight: bold; padding-right: 10px;">5</span>an opinion<br/> 
							<span style="font-weight: bold; padding-right: 10px;">6</span>a reason<br/> 
						</div>
					</div>

					<div style="margin-top: 20px;">
						<i>Answer:</i> <br/>
						<strong>A</strong> 1
						<span style="padding-left: 30px;"><strong>B</strong>#</span>
						<span style="padding-left: 30px;"><strong>C</strong>#</span>
						<span style="padding-left: 30px;"><strong>D</strong>#</span>
						<span style="padding-left: 30px;"><strong>E</strong>#</span>
						<span style="padding-left: 30px;"><strong>F</strong>#</span>
					</div>
        `,
				answer: ['2', '4', '1', '3', '6'],
			},
		],
	},
	5: {
		unit: 'Ambition',
		id: 'FG10-A-P61-E1',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_17.mp3',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 1090,
		maxLength: 127,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 20,
		},
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
					<audioimage name="2.17"></audioimage>
					Listen to the completed sentences and check your answers to exercise 4.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
        `,
				answer: [],
			},
		],
	},
	6: {
		unit: 'Ambition',
		id: 'FG10-A-P61-E1',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_18.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page61/E6/Key/answerKey.png',
		component: T6,
		inputSize: 1000,
		maxLength: 200,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '6',
				title: `
					<audioimage name="2.18"></audioimage>
					Listen to an interview with Sean Aiken, 
					who did 52 different jobs in a single year. Make notes about...
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 300px; padding: 20px; border-radius: 10px; border: 1px solid rgb(196, 12, 70);">
						<span style="font-weight: bold; padding-right: 10px;">a</span> how he got the idea. <br/>
						<span style="font-weight: bold; padding-right: 10px;">b</span> money.
					</div>

					<div style="margin-top: 20px;">
						<i>Answer:</i> <br/>
						<span style="font-weight: bold; padding-right: 10px;">a</span> # <br/>
						<span style="font-weight: bold; padding-right: 10px;">b</span> #
					</div>
		    `,
				answer: [
					"His dad told him to do something he was passionate about, but he didn't know what that was. He set up a website and asked employers to give him a job for just one week and he did a new job every week.",
					'He was always short of money because he donated all of his money to charity.',
				],
			},
		],
		// questions: [
		// 	// For capture image purpose only
		// 	{
		// 		title: `
		// 			<div style="width: 300px; padding: 20px; border-radius: 10px; border: 1px solid rgb(196, 12, 70);">
		// 				<span style="font-weight: bold; padding-right: 10px;">a</span> how he got the idea. <br/>
		// 				<span style="font-weight: bold; padding-right: 10px;">b</span> money.
		// 			</div>

		// 			<div style="margin-top: 20px;">
		// 				<i>Answer:</i> <br/>
		// 				<span style="font-weight: bold; padding-right: 10px;">a</span> # <br/>
		// 				<span style="font-weight: bold; padding-right: 30px;"></span> # <br/>
		// 				<span style="font-weight: bold; padding-right: 10px;">b</span> #
		// 			</div>
		//     `,
		// 		answer: [
		// 			"His dad told him to do something he was passionate about, but he didn't know what that was.",
		// 			'He set up a website and asked employers to give him a job for just one week and he did a new job every week.',
		// 			'He was always short of money because he donated all of his money to charity.',
		// 		],
		// 	},
		// ],
	},
	7: {
		unit: 'Ambition',
		id: 'FG10-A-P61-E1',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_18.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page61/E7/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		// checkDuplicated: true,
		// stylesTextInput: {
		// 	textAlign: 'center',
		// 	fontSize: 20,
		// },
		titleQuestion: [
			{
				num: '7',
				title: `
					<audioimage name="2.18"></audioimage>
					Read the sentences below. Then listen again. 
					Are the sentences true or false? Write T or F.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px; margin-bottom: 16px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>After talking to his dad, Sean knew what job he wanted to do. #</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>He found the majority of the jobs on the internet. #</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span>He took a few short breaks between the jobs. #</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">4</strong>
							<span>Sean saved a lot of money while he was working. #</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">5</strong>
							<span>He thinks that it’s important to work with people who you have a lot in common with. #</span>				
						</div>
					</div>
        `,
				answer: ['F', 'T', 'F', 'F', 'T'],
			},
		],
	},
	8: {
		unit: 'Ambition',
		id: 'FG10-A-P61-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page61/E8/Key/answerKey.png',
		component: T6,
		inputSize: 180,
		maxLength: 18,
		recorder: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 22,
		},
		hintBox: [
			{
				src: [
					'I’d like to be a dentist for a week because it’s a well-paid and challenging job. I’d also like to deal with the public.',
				],
				borderColor: 'rgb(236, 181, 179)',
				arrow: true,
				position: 1,
				width: 700,
			},
		],
		textareaStyle: { width: 900 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '8',
				title: `
					<span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in pairs. Think of a job that you would like to try for a week. 
					<br/>Tell your partner what you would like about it.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 10px;"><hintbox id='0'></hintbox></div>
					<div style="margin-top: 40px;"><textarea id="0" rows="5"></textarea></div>
        `,
				answer: [],
			},
		],
	},
};

export default json;
