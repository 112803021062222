import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Unit 6',
    id: 'SB10-U6-P73-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
            <b style='color:rgb(0,147,70);'>SPEAKING</b> Have you ever found any money that was
            not yours? What did you do with it?
            
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [],
  },
  2: {
    unit: 'Unit 6',
    id: 'SB10-U6-P73-E2',
    exerciseKey: '/img/FriendsPlus/Page73/E2/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_30.mp3',
    video: '',
    component: T6,
    inputSize: 600,
    maxLength: 55,
    // checkDuplicated: true,

    textareaStyle: { width: 520 },
    titleQuestion: [
      {
        num: '2',
        title: `
          <headphone1 name="2.30"></headphone1>
           Listen to the story about Glen James. What did he do?
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
           <div style='display:flex'>
              <img style="width: 40%" src="/img/FriendsPlus/Page73/E2/E2.jpg" >
              <div style="margin:60px 10px;">
                <b >Answers:</b> <br>
                <textarea id='0' rows='3'></textarea>
              </div>
            </div>

      
        `,
        answer: [
          'He found a lot of money in a shopping centre. Although he was homeless, he handed it in to the police',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 6',
    id: 'SB10-U6-P73-E3',
    exerciseKey: '/img/FriendsPlus/Page73/E3/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 120,
    maxLength: 6,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      borderBottom: 'none',
      fontSize: 27,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '3',
        title: `
            Read the
            Listening Strategy
            and the summary
              below. What kind of information do you need for
              each answer?
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div>
            <img style="width:60%" src="/img/FriendsPlus/Page73/E3/E3.jpg">
          </div>
         
            <div style='position: relative;'>
              <div><img src="/img/FriendsPlus/Page73/E3/E3-2.jpg"></div>
              <div style='position: absolute; top: 85px; left: 94px;'>#</div>
              <div style='position: absolute; top: 124px; left: 60px;'>#</div>
              <div style='position: absolute; top: 166px; left: 60px;'>#</div>
              <div style='position: absolute; top: 206px; left: 60px;'>#</div>
              <div style='position: absolute; top: 246px; left: 315px;'>#</div>
              <div style='position: absolute; top: 326px; left: 550px;'>#</div>
              <div style='position: absolute; top: 406px; left: 120px;'>#</div>
              
            </div>

        `,
        answer: ['verb', 'place', 'noun', 'people', 'person', 'noun', 'number'],
      },
    ],
  },
  4: {
    unit: 'Unit 6',
    id: 'SB10-U6-P73-E4',
    exerciseKey: '/img/FriendsPlus/Page73/E4/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_30.mp3',
    video: '',
    component: T6,
    inputSize: 250,
    maxLength: 17,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '4',
        title: `
          <headphone1 name="2.30"></headphone1>
           Listen to the story again and complete the summary in exercise 3. Use between 1 and 3 words in each gap.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
      <b>Answers:</b>
      <div style="display: flex;margin:10px;line-height:50px">
        <div style="margin-right: 40px;">
          <b>1</b>  # <br>
          <b>2</b>  # <br>
          <b>3</b>  # <br>
          <b>4</b>  # <br>
        </div>
        <div>
          <b>5</b>  # <br>
          <b>6</b>  # <br>
          <b>7</b>  #
        </div>
      </div>
        `,
        answer: [
          'has been homeless',
          'a shopping centre',
          'money',
          'to the police',
          'Chinese student',
          'website',
          'over $100,000',
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 6',
    id: 'SB10-U6-P73-E5',
    exerciseKey: '/img/FriendsPlus/Page73/E5/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 300,
    maxLength: 26,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '5',
        title: `
              <b style="color: rgb(146,39,143);">VOCABULARY </b>
              Check the meaning of the verbs below. Find four pairs of verbs with opposite meanings
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div style="color: rgb(192,19,78); width:19cm">
            <b>Verbs to do with money</b>  afford  exchange  
            borrow  buy   charge cost   
            get / give a refund   lend  owe  pay for 
            save  save up sell  spend  waste 
              
          </div>
          <div style="margin-top: 20px;">
            <b>Answers:</b> <br>
            <b>1</b> <span> #</span> <br>
            <b>2</b> <span> #</span> <br>
            <b>3</b> <span> #</span> <br>
            <b>4</b> <span> #</span>
          </div>
        `,
        answer: ['borrow-lend', 'buy-sell', 'get a refund-give a refund', 'save-spend'],
      },
    ],
  },
  6: {
    unit: 'Unit 6',
    id: 'SB10-U6-P73-E6',
    exerciseKey: '/img/FriendsPlus/Page73/E6/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_31.mp3',
    video: '',
    component: T6,
    inputSize: 220,
    maxLength: 18,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      // fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '6',
        title: `
          <headphone1 name="2.31"></headphone1>
           You are going to hear three speakers. Listen and answer the questions.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
           <div style="margin-top: 20px;">
              <b>1</b> <span> Where did the first boy buy the present?</span> <br> 
              <b>2</b> <span> How did the second boy pay for the baseball cap?</span> <br>
              <b>3</b> <span> How much pocket money does the girl receive?</span>
            </div>
          <div style="margin-top: 20px;">
            <b>Answers:</b> <br>
            <b>1</b> <span> #</span> <br>
            <b>2</b> <span> #</span> <br>
            <b>3</b> <span> #</span> <br>
          </div>
        `,
        answer: ['in a clothes store', 'by (credit) card', '£6 a week'],
      },
    ],
  },
  7: {
    unit: 'Unit 6',
    id: 'SB10-U6-P73-E7',
    exerciseKey: '/img/FriendsPlus/Page73/E7/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_31.mp3',
    video: '',
    component: T6,
    inputSize: 45,
    maxLength: 1,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '7',
        title: `
          <headphone1 name="2.31"></headphone1>
           Listen again. Match speakers 1–3 with sentences A–D. There is one extra sentence.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div style="margin-top: 20px;">
            <b>This speaker...</b> <br>
            <b>A</b> <span>had an argument with a shop assistant.</span><span> #</span> <br>
            <b>B</b> <span> returned something to a shop and got his / her  money back.</span><span> #</span> <br>
            
            <b>C</b> <span> found some money in a shop.</span><span> #</span> <br>
            <b>D</b> <span> is saving money for an expensive item of clothing.</span> <span> #</span><br>
          </div>
        `,
        answer: ['C / B', 'A', 'D', ''],
      },
    ],
  },
  8: {
    unit: 'Unit 6',
    id: 'SB10-U6-P73-E8',
    exerciseKey: '/img/FriendsPlus/Page73/E8/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 95,
    maxLength: 10,
    // checkDuplicated: true,
    stylesTextInput: {
      //textAlign: 'center',
      // borderBottom: 'none',
      //fontWeight: 'bold',
      // color: 'rgb(40, 130, 157)',
      // fontSize: 23,
      marginLeft: -12,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '8',
        title: `
           Complete the questions with the correct form of the verbs from exercise 5. <br/>Do not use any verbs more than once.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
           <div style="margin-top: 20px;">
              <b>1</b> <span> Does anybody o<span style="color: rgb(40,130,157);">we</span> you any money?</span> <br>
              <b>2</b> <span> Have you ever b<span>#</span> money from your family or friends?</span> <br>
              <b>3</b> <span>Have you ever l<span>#</span> anyone any money?</span> <br>
              <b>4</b> <span>Are you s<span>#</span> for anything at the moment?</span> <br>
              <b>5</b> <span>What’s the most expensive thing you’ve ever b<span>#</span> ?</span> <br>
              <b>6</b> <span>Has a shop assistant ever c<span>#</span> you too much for something in a shop?</span> <br>
              <b>7</b> <span>How much do you s<span>#</span> on clothes each month? </span> <br>
              <b>8</b> <span>Have you ever s<span>#</span> anything online?</span> <br>
            </div>
        `,
        answer: ['orrow', 'ent', 'aving', 'ought', 'harged', 'pend', 'old'],
      },
    ],
  },
  9: {
    unit: 'Unit 6',
    id: 'SB10-U6-P73-E9',
    audio: '',
    video: '',
    component: UI,
    imageStyle: { width: '60%' },
    titleQuestion: [
      {
        num: '9',
        title: `
          <b style='color:rgb(0,147,70);'>SPEAKING</b> Work in pairs. Ask and answer the
          questions in exercise 8. Where you can, give extra information with your answers.

        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page73/E9/1.jpg' }]],
  },
};
export default json;
