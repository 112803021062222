import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Feelings',
    id: 'FG10-F-P13-E1',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
        <span style="color: rgb(2, 146, 97);">SPEAKING</span> Describe the photo. What are the girls 
doing? How are they feeling? What do you think they 
are saying?
        `,
        color: '#860052',
      },
    ],
    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page13/E1/1.jpg' }]],
  },
  2: {
    unit: 'Feelings',
    id: 'FG10-F-P13-E2',
    exerciseKey: '/img/FriendsPlus/Page13/E2/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD1_08.mp3',
    video: '',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    stylesTextInput: {
      textAlign: 'center',
    },
    titleQuestion: [
      {
        num: '2',
        title: `
        <audioimage name="1.08"></audioimage> <span>Read the </span>
              <span style="color: rgb(195,9,68);"> Listening Strategy</span>
              <span> above and the three summaries of a dialogue below. <br/>Then listen to the dialogue. Which is the best summary? </span>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div>
      <img src="/img/FriendsPlus/Page13/E2/E2.png" alt="" style="width:600px;">
    </div>
         <div>
      <div style="margin-top:20px;">
        <b>a</b>
        <span>&nbsp
          Zak refuses to go out with Tom because he’s disappointed about his
          exam results.</span
        >
      </div>
      <div>
        <b>b</b>
        <span>&nbsp
          Zak is anxious about his exams and decides not to go out with
          Tom.</span
        >
      </div>
      <div>
        <b>c</b>
        <span>&nbsp
          Zak is relieved that his exams are six weeks away, and agrees to go
          out with Tom.</span
        >
      </div>
    </div>
     <div style="margin-top: 10px;">
      <b>Answers:</b>
      <span> #</span>
    </div>
        
        `,
        answer: ['b'],
      },
    ],
  },
  3: {
    unit: 'Feelings',
    id: 'FG10-F-P13-E3',
    exerciseKey: '',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: '3',
        title: `
        <span style="color: rgb(2, 146, 97);">SPEAKING</span> Read the <span style="color: rgb(195,9,68);">Learn this!</span> box. Then say what Zak should do. <br/>Use <i>I (don’t) think …</i> and the phrases below.
        `,
        color: '#860052',
      },
    ],

    questionImage: [[{ url: '/img/FriendsPlus/Page13/E3/2.png' }, { url: '/img/FriendsPlus/Page13/E3/1.png' }]],
  },
  4: {
    unit: 'Feelings',
    id: 'FG10-F-P13-E4',
    exerciseKey: '/img/FriendsPlus/Page13/E4/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD1_09.mp3',
    video: '',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    stylesTextInput: {
      textAlign: 'center',
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '4',
        title: `
         <audioimage name="1.09"></audioimage> Listen to four dialogues, pausing after each one. <br/>Match the dialogues (A–D) with the sentences below. 
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div>
        <span><b>The person with a problem...</b></span><br>
        <span><b>1</b></span> <span> accepts an offer of help.</span> <span>#</span> <br>
        <span><b>2</b></span> <span> does not follow the advice.</span> <span>#</span><br>
        <span><b>3</b></span> <span> feels bad because a friend is cross.</span> <span>#</span><br>
        <span><b>4</b></span> <span> refuses an offer of help. </span> <span>#</span>
      </div>
     
        `,
        answer: ['C', 'D', 'B', 'A'],
      },
    ],
  },
  5: {
    unit: 'Feelings',
    id: 'FG10-F-P13-E5',
    exerciseKey: '/img/FriendsPlus/Page13/E5/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD1_10.mp3',
    video: '',
    component: T6,
    inputSize: 85,
    maxLength: 6,
    stylesTextInput: {
      textAlign: 'center',
      color: 'rgb(40,130,157)',
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '5',
        title: `
         <audioimage name="1.10"></audioimage> Listen again to dialogues B, C and D.
            <br/>Complete the collocations (1–6) with the verbs below.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
         <div style="color: rgb(192,19,78);">
        <span><del>find</del> give have make tell (x2)</span>
      </div>
       
      <div style="display: flex;margin-top:10px">
      <div style="margin-right: 50px;">
        <b>Dialogue B</b><br>
      <b>1</b><span style="color:rgb(40,130,157) ; "> find </span><span> something funny</span><br>
      <b>2</b><span>#</span> somebody a call<br>
      </div>

      <div>
        <b>Dialogue D</b><br>
      <b>5</b> <span>#</span><span> an excuse</span><br>
      <b>6</b><span> #</span> a lie<br>
      </div>

    </div>
    <div style="margin-top: 20px;">
      <b>Dialogue C</b><br>
    <b>3</b> <span>#</span><span> the truth</span><br>
    <b>4</b><span> #</span> a word (with somebody)<br>
    </div>
     
        `,
        answer: ['give', 'make', 'tell', 'tell', 'have'],
      },
    ],
  },
  6: {
    unit: 'Feelings',
    id: 'FG10-F-P13-E6',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '6',
        title: `
        <span style="color: rgb(2, 146, 97);">SPEAKING</span> Work in pairs. Choose a problem and two suggestions from exercise 1 or use your own ideas. Plan a dialogue and act it out to the class.
        `,
        color: '#860052',
      },
    ],

    recorder: true,
    questionImage: [[{ url: 'img/FriendsPlus/Page13/E6/1.png' }]],
  },
};

export default json;
