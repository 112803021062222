import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Unit 6',
    id: 'SB10-U6-P72-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
        <b style='color:rgb(0,147,70);'>SPEAKING</b>  Work in pairs. What does the photo tell you
            about the lives of people who live in this city?
        
        
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page72/E1/E1.jpg' }]],
  },
  2: {
    unit: 'Unit 6',
    id: 'SB10-U6-P72-E2',
    exerciseKey: '',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_29.mp3',
    video: '',
    component: UI,
    inputSize: 100,
    maxLength: 81,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 23,
    },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '2',
        title: `
        
            <headphone1 name="2.29"></headphone1> Read and listen to the dialogue. Who do you agree with more, Anne or Nam?
          <div style='width:20cm;font-weight:520;margin:10px 0px'>
              <div style='display:flex'>
                <b>Anne</b>
                <div style='font-weight:400;margin-left:20px'>
                  What are you doing?
                </div>
              </div>
              <div style='display:flex'>
                <b>Nam</b>
                <div style='font-weight:400;margin-left:27px'>
                  I’m doing my geography project. Look at this photo.
                  It’s shocking that some people have so much and
                  others have so little! The world would be much
                  better if money didn’t exist.
                </div>
              </div>
              <div style='display:flex'>
                <b>Anne</b>
                <div style='font-weight:400;margin-left:20px'>
                  What do you mean? If money didn’t exist, how
                  would you buy things?
                </div>
              </div>
              <div style='display:flex'>
                <b>Nam</b>
                <div style='font-weight:400;margin-left:27px'>
                  If you needed something, you would make it.
                  If you couldn’t make it, you would swap with
                  somebody else.
                </div>
              </div>
              <div style='display:flex'>
                <b>Anne</b>
                <div style='font-weight:400;margin-left:20px'>
                  So if I wanted a new mobile phone, how would I
                  get it?
                </div>
              </div>
              <div style='display:flex'>
                <b>Nam</b>
                <div style='font-weight:400;margin-left:27px'>
                  You don’t need things like that! I’m talking about
                  essentials: food, clothes, that kind of thing. At the
                  moment, millions of people haven’t even got those
                </div>
              </div>
              <div style='display:flex'>
                <b>Anne</b>
                <div style='font-weight:400;margin-left:20px'>
                  If money didn’t exist, life wouldn’t be better for
                  poor people.
                </div>
              </div>
              <div style='display:flex'>
                <b>Nam</b>
                <div style='font-weight:400;margin-left:27px'>
                  No? I think it would. If nobody had any money,
                  everybody would be equal.
                </div>
              </div>
          </div>
      
        `,
        color: '#860052',
      },
    ],
    recorder: true,
    questionImage: [],
  },
  3: {
    unit: 'Unit 6',
    id: 'SB10-U6-P72-E3',
    exerciseKey: '/img/FriendsPlus/Page72/E3/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 150,
    maxLength: 11,
    isHiddenCheck: true,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      borderBottom: 'none',
      // fontWeight: 'bold',
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '3',
        title: `
            Read the
            <span style="color: rgb(239,140,27);"> Learn this!</span>
             box and complete rule a with
              <i>infinitive, past simple and would</i>. Then find seven
              second conditional sentences in the dialogue in
              exercise 2
            
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          
          <div style='display: flex;'>
              <div style='position: relative;'>
              <div><img src="/img/FriendsPlus/Page72/E3/E3.jpg" ></div>
              <div style='position: absolute; top: 198px; left: 250px;'>#</div>
              <div style='position: absolute; top: 234px; left: 120px;'>#</div>
              <div style='position: absolute; top: 234px; left: 310px;'>#</div>
            </div>
          </div>

        `,
        answer: ['past simple', 'would', 'infinitive'],
      },
    ],
  },
  4: {
    unit: 'Unit 6',
    id: 'SB10-U6-P72-E4',
    exerciseKey: '/img/FriendsPlus/Page72/E4/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 160,
    maxLength: 11,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      // fontSize: 20,
    },
    textareaStyle: {},
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '4',
        title: `
            Match 1–5 with a–e and make second conditional sentences using the correct form of the verbs in brackets.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div style='display:flex;justify-content:space-between;padding:5px 20px;background:rgb(253 247 223) '>
            <div style='width:45%'>
              <b>1</b>&ensp;I#(can) buy clothes really cheaply <br>
              <b>2</b>&ensp;If tablet computers#(not cost) so much,<br>
              <b>3</b>&ensp;I#(be) upset <br>
              <b>4</b>&ensp;If I#(need) money,<br>
              <b>5</b>&ensp;If I#(not have) a mobile phone, 
            </div>
            <div style='width:50%'>
              <span style='border:2px solid black'><input id='0' width='34px'/></span> <b>a</b>&ensp;I#(ask) my dad to buy one for me.<br>
              <span style='border:2px solid black'><input id='0' width='34px'/></span> <b>b</b>&ensp;I#(use) my mum’s laptop to send messages.<br>
              <span style='border:2px solid black'><input id='0' width='34px'/></span> <b>c</b>&ensp;if I#(shop) at the charity shop.<br>
              <span style='border:2px solid black'><input id='0' width='34px'/></span> <b>d</b>&ensp;I#(get) a holiday job.<br>
              <span style='border:2px solid black'><input id='0' width='34px'/></span> <b>e</b>&ensp;if the coffee shop in my village#(close).
            </div>
          </div>
    
        `,
        answer: [
          'could',
          "didn't cost",
          'would be',
          'needed',
          "didn't have",
          '2',
          'would ask',
          '5',
          'would use',
          '1',
          'shopped',
          '4',
          'would get',
          '3',
          'closed',
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 6',
    id: 'SB10-U6-P72-E5',
    exerciseKey: '/img/FriendsPlus/Page72/E5/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 160,
    maxLength: 10,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      borderBottom: 'none',
    },
    isHiddenCheck: true,
    textareaStyle: {},
    titleQuestion: [
      {
        num: '5',
        title: `
            Complete the money tips with the second conditional. Use the correct form of the verbs below.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <span style="color: rgb(239,140,27);">can get make save sell swap</span>
       
            <div style='position: relative;'>
              <div><img src="/img/FriendsPlus/Page72/E5/E5.jpg" ></div>
              <div style='position: absolute; top: 66px; left: 399px;'>#</div>
              <div style='position: absolute; top: 103px; left: 153px;'>#</div>
              <div style='position: absolute; top: 218px; left: 100px;'>#</div>
              <div style='position: absolute; top: 254px; left: 100px;'>#</div>
              <div style='position: absolute; top: 333px; left: 300px;'>#</div>
              <div style='position: absolute; top: 369px; left: 97px;'>#</div>
              
            </div>

        `,
        answer: ['would save', 'made', 'swapped', 'would get', 'sold', 'could'],
      },
    ],
  },
  6: {
    unit: 'Unit 6',
    id: 'SB10-U6-P72-E6',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '6',
        title: `
            <b style='color:rgb(0,147,70);'>SPEAKING</b> Work in pairs. Ask and answer the following questions.
            <div style="margin-top: 10px; font-weight:400">
              <span>What would you do if …</span> <br>
              <b>1</b>
              <span> there were no shops in your town?</span> <br>
              <b>2</b>
              <span> you won 1 billion VND?</span> <br>
              <b>3</b>
              <span> you could have a free holiday anywhere in the world?</span>
              
            </div>
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [],
  },
};

export default json;
