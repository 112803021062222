import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

const json = {
	1: {
		unit: 'Introduction',
		id: 'FG10-I-P9-E1',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		titleQuestion: [
			{
				num: '1',
				title: `Read the text. Would you like to use 3D glasses like this at school? Why? / Why not?`,
				color: '#92278f',
			},
		],
		questionImage: [[{ url: 'img/FriendsPlus/Page9/E1/1.jpg' }]],
	},
	2: {
		unit: 'Introduction',
		id: 'FG10-I-P9-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page9/E2/Key/answerKey.png',
		component: T6v2,
		padding: 0,
		inputSize: 120,
		textAlign: 'center',
		titleQuestion: [
			{
				num: '2',
				title:
					"Read the <span style='color: rgb(246,141,31)'>Learn this!</span> box. Complete the rules with <i>a / an, the</i>, or no article .",
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
          <div style='box-shadow: rgb(246,141,31) 0 0 5px 0; border-radius: 10px; padding: 10px;width: 850px;'>
            <div><b><span style='color: rgb(246,141,31)'>Learn this!</span> Articles</b></div>
            <div style='display: flex;'>
              <div style='margin-right: 10px; width: 20px;'><b>a</b></div>
              <div>
                <div>We use <b><sup>1</sup></b># to talk about something for the first time.</div>
                <div style='color: rgb(10,111,142)'>There’s an interactive whiteboard in our classroom.</div>
              </div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px; width: 20px;'><b>b</b></div>
              <div>
                <div>We use <b><sup>2</sup></b># when we mention something again.</div>
                <div style='color: rgb(10,111,142)'>Our teacher often uses the interactive whiteboard.</div>
              </div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px; width: 20px;'><b>c</b></div>
              <div>
                <div>We use <b><sup>3</sup></b># when it is clear what we are talking about, or when there is only one of something.</div>
                <div><span style='color: rgb(10,111,142)'>We play basketball in the gym.</span> (The gym at school)</div>
                <div style='color: rgb(10,111,142)'>The sun is shining.</div>
              </div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px; width: 20px;'><b>d</b></div>
              <div>
                <div>We use <b><sup>4</sup></b># to say what someone’s job is.</div>
                <div style='color: rgb(10,111,142)'>My mum’s a teacher.</div>
              </div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px; width: 20px;'><b>e</b></div>
              <div>
                <div>Note these set phrases.</div>
                <div>
                  <ul>
                    <li><b><sup>5</sup></b>#: <i>listen to the radio, go to the cinema, play the guitar</i></li>
                    <li><b><sup>6</sup></b>#: <i>watch TV, listen to music, in / to bed, to / at / from school, at home / work, in hospital</i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        `,
				answer: ['a / an', 'the', 'the', 'a / an', 'the', 'no article'],
			},
		],
	},
	3: {
		unit: 'Introduction',
		id: 'FG10-I-P9-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page9/E3/Key/answerKey.png',
		component: T6,
		padding: 0,
		inputSize: 180,
		checkUppercase: true,
		titleQuestion: [
			{
				num: '3',
				title:
					"Read the text in exercise 1 again. Match each of the highlighted words with a rule in the <span style='color: rgb(246,141,31)'>Learn this!</span> box.",
				color: '#92278f',
			},
		],
		questions: [
			{
				title: `
          <div style='display: flex; gap:10px;'>
            <div><img src='img/FriendsPlus/Page9/E1/1.jpg' /></div>
            <div style='box-shadow: rgb(246,141,31) 0 0 5px 0; border-radius: 4px; padding: 10px;'>
              <div><b><span style='color: rgb(246,141,31)'>Learn this!</span> Articles</b></div>
              <div style='display: flex;'>
                <div style='margin-right: 10px; width: 20px;'><b>a</b></div>
                <div>
                  <div>We use <sup>1</sup>... to talk about something for the first time.</div>
                  <div style='color: rgb(10,111,142)'>There’s an interactive whiteboard in our classroom.</div>
                </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px; width: 20px;'><b>b</b></div>
                <div>
                  <div>We use <sup>2</sup>... when we mention something again.</div>
                  <div style='color: rgb(10,111,142)'>Our teacher often uses the interactive whiteboard.</div>
                </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px; width: 20px;'><b>c</b></div>
                <div>
                  <div>We use <sup>3</sup>... when it is clear what we are talking about, or when there is only one of something.</div>
                  <div><span style='color: rgb(10,111,142)'>We play basketball in the gym.</span> (The gym at school)</div>
                  <div style='color: rgb(10,111,142)'>The sun is shining.</div>
                </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px; width: 20px;'><b>d</b></div>
                <div>
                  <div>We use <sup>4</sup>... to say what someone’s job is.</div>
                  <div style='color: rgb(10,111,142)'>My mum’s a teacher.</div>
                </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px; width: 20px;'><b>e</b></div>
                <div>
                  <div>Note these set phrases.</div>
                  <div>
                    <ul>
                      <li><sup>5</sup>...: <i>listen to the radio, go to the cinema, play the guitar</i></li>
                      <li><sup>6</sup>...: <i>watch TV, listen to music, in / to bed, to / at / from school, at home / work, in hospital</i></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div><b>a.&emsp;</b>#,#,#.</div>
          <div><b>b.&emsp;</b>#,#,#.</div>
          <div><b>c.&emsp;</b>#,#,#,#,#,#.</div>
          <div><b>d.&emsp;</b>#.</div>
          <div><b>e.&emsp;</b>#.</div>
        `,
				answer: [
					'a classroom/a secondary school/an earthquake',
					'a classroom/a secondary school/an earthquake',
					'a classroom/a secondary school/an earthquake',
					'The classroom/The glasses/the students',
					'The classroom/The glasses/the students',
					'The classroom/The glasses/the students',
					'The students/The man/the student/the backgound/the foreground/the lessons',
					'The students/The man/the student/the backgound/the foreground/the lessons',
					'The students/The man/the student/the backgound/the foreground/the lessons',
					'The students/The man/the student/the backgound/the foreground/the lessons',
					'The students/The man/the student/the backgound/the foreground/the lessons',
					'The students/The man/the student/the backgound/the foreground/the lessons',
					'the lessons',
					'at school',
				],
			},
		],
	},
	4: {
		unit: 'Introduction',
		id: 'FG10-I-P9-E4',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '4',
				title: 'Complete the dialogue. Use <i>a / an</i> or <i>the</i>. Explain your choices.',
				color: '#92278f',
			},
		],
		exerciseKey: 'img/FriendsPlus/Page9/E4/Key/answerKey.png',
		inputSize: 70,
		questions: [
			{
				title: `
          <div style='display: flex;'>
            <div style='margin-right: 30px; width: 70px;'><b>Martha</b></div>
            <div>I go to school near <sup>1</sup> <span style='color: rgb(10,111,142)'>the</span> city centre.</div>
          </div>
          <div style='display: flex;'>
            <div style='margin-right: 30px; width: 70px;'><b>Jake</b></div>
            <div>Really? What’s <sup>2</sup># name of <sup>3</sup># school?</div>
          </div>
          <div style='display: flex;'>
            <div style='margin-right: 30px; width: 70px;'><b>Martha</b></div>
            <div>St Mark’s. It’s <sup>4</sup># private school.</div>
          </div>
          <div style='display: flex;'>
            <div style='margin-right: 30px; width: 70px;'><b>Jake</b></div>
            <div>I know <sup>5</sup>#  girl who goes there. Alice Smith.</div>
          </div>
          <div style='display: flex;'>
            <div style='margin-right: 30px; width: 70px;'><b>Martha</b></div>
            <div>There’s <sup>6</sup>#  Alice Smith in my class. I wonder if it’s <sup>7</sup># same one.</div>
          </div>
          <div style='display: flex;'>
            <div style='margin-right: 30px; width: 70px;'><b>Jake</b></div>
            <div>She’s got blue eyes, long hair and <sup>8</sup># nice smile.</div>
          </div>
          <div style='display: flex;'>
            <div style='margin-right: 30px; width: 70px;'><b>Martha</b></div>
            <div>That’s her! Which school do you go to?</div>
          </div>
          <div style='display: flex;'>
            <div style='margin-right: 30px; width: 70px;'><b>Jake</b></div>
            <div>Hadfield Highschool.</div>
          </div>
          <div style='display: flex;'>
            <div style='margin-right: 30px; width: 70px;'><b>Martha</b></div>
            <div>Oh. My dad’s <sup>9</sup># maths teacher there: John Lee.</div>
          </div>
          <div style='display: flex;'>
            <div style='margin-right: 30px; width: 70px;'><b>Jake</b></div>
            <div>You’re joking! He’s my teacher!</div>
          </div>
        `,
				answer: ['the', 'the', 'a', 'a', 'an', 'the', 'a', 'a'],
			},
		],
	},
	5: {
		unit: 'Introduction',
		id: 'FG10-I-P9-E5',
		audio: '',
		video: '',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '5',
				title: `Read the <span style='color: rgb(246,141,31)'>Look out!</span> box. Are the sentences below generalisations or not? <br/>Circle the correct words.`,
				color: '#92278f',
			},
		],
		exerciseKey: 'img/FriendsPlus/Page9/E5/Key/answerKey.png',
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				// initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
				initialWordStyle: {
					margin: '5px 0',
					border: 'none',
					borderColor: 'transparent',
					borderRadius: '50%',
				},
				selectWordStyle: {
					border: 'solid 2px',
					borderColor: '#00a8ec',
					margin: '-2px',
				},
				limitSelect: 1,
				listWords: ['Dogs / The_dogs', 'cats / the_cats.', 'Weather / The_weather'],
				answers: ['0-0', '1-0', '2-4'],
				initialValue: [],
			},
			Layout: `
        <div><img src='img/FriendsPlus/Page9/E5/1.jpg'/></div>
        <div style='width: 1100px;position:relative;'>
          <div style='letter-spacing: 0px;display: flex;'>
			 		  <span><b>1</b>  &nbsp;</span>
            <div style='display: flex;flex-direction: column;'> 
              <span><b><input id='0' type='Circle' /></b> are more intelligent than <b><input id='1' type='Circle' /></b></span>
            </div>
          </div>
          <div style='letter-spacing: 0px;display: flex;'>
            <span><b>2</b>   &nbsp;</span>
            <div style='display: flex;flex-direction: column;'>
              <span><b><input id='2' type='Circle' /></b> is nice today.</span>
            </div>
          </div>
        </div>
        <div style='width:500px;box-shadow: rgb(246,141,31) 0 0 5px 0; border-radius: 4px; color: rgb(246,141,31); padding: 10px;'><b>Grammar Builder ID</b> Articles: page 106</div>
      `,
		},
	},
	6: {
		unit: 'Introduction',
		id: 'FG10-I-P9-E6',
		audio: '',
		video: '',
		component: UI,
		titleQuestion: [
			{
				num: '6',
				title: `Imagine your school 50 years from now. Describe your future classroom to a partner.`,
				color: '#92278f',
			},
		],
		recorder: true,
		questionImage: [[{ url: 'img/FriendsPlus/Page9/E6/1.jpg' }]],
	},
}

export default json
