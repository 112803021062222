import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P27-E1',
		audio: '',
		video: '',
		component: T6,
		recorder: true,
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: '1',
				title:
					'Read the text. Find the name of the person in the photo. <br/>Do you agree with the theory at the end? Why? / Why not?',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
            <img src='img/FriendsPlus/Page27/E1/1.jpg'>
          `,
				answer: [],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P27-E2',
		audio: '',
		video: '',
		component: D1,
		fontSize: 28,
		maxLength: 11,
		exerciseKey: 'img/FriendsPlus/Page27/E2/Key/answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '2',
				title: 'Complete the table with words from the text in exercise 1.',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],

		questionImage: [
			[{ url: 'img/FriendsPlus/Page27/E2/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page27/E2/2.jpg' },
				{ url: 'img/FriendsPlus/Page27/E2/3.jpg', input: true, answer: 'attempt' },
				{ url: 'img/FriendsPlus/Page27/E2/4.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page27/E2/5.jpg' },
				{ url: 'img/FriendsPlus/Page27/E2/6.jpg', input: true, answer: 'complete' },
				{ url: 'img/FriendsPlus/Page27/E2/7.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page27/E2/8.jpg' },
				{ url: 'img/FriendsPlus/Page27/E2/9.jpg', input: true, answer: 'achieve' },
				{ url: 'img/FriendsPlus/Page27/E2/10.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page27/E2/11.jpg' },
				{ url: 'img/FriendsPlus/Page27/E2/12.jpg', input: true, answer: 'need' },
				{ url: 'img/FriendsPlus/Page27/E2/13.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page27/E2/14.jpg' },
				{ url: 'img/FriendsPlus/Page27/E2/15.jpg', input: true, answer: 'exploration' },
				{ url: 'img/FriendsPlus/Page27/E2/16.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page27/E2/17.jpg' },
				{ url: 'img/FriendsPlus/Page27/E2/18.jpg', input: true, answer: 'experience' },
				{ url: 'img/FriendsPlus/Page27/E2/19.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page27/E2/20.jpg' },
				{ url: 'img/FriendsPlus/Page27/E2/21.jpg', input: true, answer: 'dream' },
				{ url: 'img/FriendsPlus/Page27/E2/22.jpg' },
			],

			[{ url: 'img/FriendsPlus/Page27/E2/23.jpg' }],
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P27-E3',
		audio: '',
		video: '',
		component: T6,
		inputSize: 160,
		maxLength: 20,
		titleImage: '',
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page27/E3/Key/answerKey.png',
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '3',
				title:
					'Read the <span style="color: #8e258c">Learn this!</span> Which different noun suffixes can you find in the text in exercise 1? Which nouns do not have a suffix?',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <div style='display:flex'>
          <div><img src='img/FriendsPlus/Page27/E3/1.jpg'></div>     
          <div><img src='img/FriendsPlus/Page27/E3/3.jpg'></div>
        </div>
        <div style='display:flex; gap:80px;margin:30px 10px;'>
            <div><b style='width:100px;'>-tion :</b>#, #</div>
            <div><b>-ure : </b>#</div>
            <div><b>-ment: </b>#</div>
            <div><b>no suffix: </b>#,#,#,#</div>
          </div>
          `,
				answer: [' exploration', 'completion', ' adventure', 'achievement', `attempt`, `need`, `experiences`, `dream`],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P27-E4',
		audio: '',
		video: '',
		component: T6,
		inputSize: 170,
		textareaStyle: { width: 700 },
		// inputSize: 50,
		// isHiddenCheck: true,
		maxLength: 13,
		titleImage: '',
		textAlign: 'center',
		exerciseKey: 'img/FriendsPlus/Page27/E4/Key/answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title:
					'Read the <span style="color: #8e258c">DICTIONARY WORK</span> Work in pairs. Using a dictionary, <br/>find the nouns related to the verbs below. Check their meanings.',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <div style='display:flex'>
          <div style='margin-right:150px;'>
            <span style="color: rgb(142 37 140)">entertain:</span>#<br>
            <span style="color: rgb(142 37 140)">erupt:</span>#<br>
            <span style="color: rgb(142 37 140)">examine:</span>#<br>
          </div>
        
          <div >
            <span style="color: rgb(142 37 140)">motivate:</span>#<br>
            <span style="color: rgb(142 37 140)">relax:</span>#<br>
            <span style="color: rgb(142 37 140)">rescue:</span>#<br>
            
          </div>
        </div>
          `,
				answer: ['entertainment', 'eruption', 'examination', 'motivation', 'relaxation', 'rescue'],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P27-E5',
		audio: 'Audios/Friends Global Grade 10 SB_CD1_25.mp3',
		video: '',
		component: T6,
		inputSize: 200,
		maxLength: 13,
		hideBtnFooter: true,
		recorder: true,
		textAlign: 'center',
		// titleImage: 'img/FriendsPlus/Page27/E5/1.jpg',
		exerciseKey: 'img/FriendsPlus/Page27/E5/Key/answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title:
					'<headphone1 name="1.25"></headphone1> <b style="color:#0563ac">PRONUNCIATION</b> Listen to the words and underline the stress. Then work in pairs. Take it in turns to say a word and say the form. ',
				color: '#8e258c',
			},
		],
		questionImage: [],
		questions: [
			{
				title: ``,
				//   <div style=" display: flex;justify-content: space-around;">
				//     <div >
				//         #<br>
				//         #<br>
				//         #<br>
				//         #<br>
				//     </div>
				//     <div style='margin:0px 100px'>
				//         #<br>
				//         #<br>
				//         #<br>
				//         #<br>
				//     </div>
				//     <div >
				//         #<br>
				//         #<br>
				//         #<br>
				//         #<br>
				//     </div>
				//   </div>
				answer: [
					'entertain / entertainment / erupt / eruption / examine / examination / motivate / motivation / relax / relaxation / rescue / rescue',
					'entertain / entertainment / erupt / eruption / examine / examination / motivate / motivation / relax / relaxation / rescue / rescue',
					'entertain / entertainment / erupt / eruption / examine / examination / motivate / motivation / relax / relaxation / rescue / rescue',
					'entertain / entertainment / erupt / eruption / examine / examination / motivate / motivation / relax / relaxation / rescue / rescue',
					'entertain / entertainment / erupt / eruption / examine / examination / motivate / motivation / relax / relaxation / rescue / rescue',
					'entertain / entertainment / erupt / eruption / examine / examination / motivate / motivation / relax / relaxation / rescue / rescue',
					'entertain / entertainment / erupt / eruption / examine / examination / motivate / motivation / relax / relaxation / rescue / rescue',
					'entertain / entertainment / erupt / eruption / examine / examination / motivate / motivation / relax / relaxation / rescue / rescue',
					'entertain / entertainment / erupt / eruption / examine / examination / motivate / motivation / relax / relaxation / rescue / rescue',
					'entertain / entertainment / erupt / eruption / examine / examination / motivate / motivation / relax / relaxation / rescue / rescue',
					'entertain / entertainment / erupt / eruption / examine / examination / motivate / motivation / relax / relaxation / rescue / rescue',
					'entertain / entertainment / erupt / eruption / examine / examination / motivate / motivation / relax / relaxation / rescue / rescue',
				],
			},
		],
	},
	6: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P27-E6',
		audio: '',
		video: '',
		component: T6,
		inputSize: 210,
		textAlign: 'center',
		maxLength: 15,
		// titleImage: 'img/FriendsPlus/Page27/E5/1.jpg',
		exerciseKey: 'img/FriendsPlus/Page27/E6/Key/answerKey.png',
		titleQuestion: [
			{
				num: '6',
				title:
					'Complete sentence b in each pair with the correct noun formed from the underlined verb in sentence a. Use a dictionary to help you if necessary ',
				color: '#8e258c',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
          <img style='margin-left:200px' src='img/FriendsPlus/Page27/E6/1.jpg'>
          <div style='display:flex;margin-top:10px'>
            <b>1</b>
            <div style='margin-left:20px'><b>a<br>b</b></div>
            <div style='margin-left:20px'>
              Teenagers who go on adventures should be
              proud of what they <u >accomplish</u>.
              <br>
              Teenagers who go on adventures should be
              proud of their #.
            </div>
          </div>
          <div style='display:flex;margin-top:10px'>
            <b>2</b>
            <div style='margin-left:20px'><b>a<br>b</b></div>
            <div style='margin-left:20px'>
              Companies will want to <u >employ</u> them.
              <br>
              Companies will want to offer them #.
            </div>
          </div>
          <div style='display:flex;margin-top:10px'>
            <b>3</b>
            <div style='margin-left:20px'><b>a<br>b</b></div>
            <div style='margin-left:20px'>
              <u >Preparing</u> for the trips is the most difficult part.
              <br>
              The most difficult part of the trips is the #.
            </div>
          </div>
          <div style='display:flex;margin-top:10px'>
            <b>4</b>
            <div style='margin-left:20px'><b>a<br>b</b></div>
            <div style='margin-left:20px'>
               These trips are a great way to <u >develop</u> personal skills.
              <br>
              These trips are great for the # of
              personal skills.
            </div>
          </div>
          <div style='display:flex;margin-top:10px'>
            <b>5</b>
            <div style='margin-left:20px'><b>a<br>b</b></div>
            <div style='margin-left:20px'>
              Most teenagers <u >dream</u> of becoming famous.
              <br>
              The # of most teenagers is to become
                famous.
            </div>
          </div>
          
          `,
				answer: ['accomplishments', 'employment', 'preparation', 'development', 'dream'],
			},
		],
	},
	7: {
		// Exercise num
		unit: 'Unit 2',
		id: 'SB10-U2-P28-E3',
		audio: '',
		video: '',
		component: UI,
		recorder: true,
		// exerciseKey: 'img/FriendsPlus/Page24/E4/Key/answerKey.png',
		question: [],
		titleQuestion: [
			{
				num: '7',
				title:
					'<b style="color:rgb(10 127 64)">SPEAKING</b> Work in pairs. Check your answers to exercise 6. Then discuss whether you agree or disagree with the sentences.',
				color: '#8e258c',
				prefix: { icon: [''], text: '' },
			},
		],
		questionImage: [],
	},
}

export default json
