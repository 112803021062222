import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 3',
    id: 'SB10-U3-P40-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '1',
        title: `
				<span style='color:rgb(0,147,70);'>SPEAKING</span> Look at the headlines. Do you think playing computer games is bad for your health? Why? / Why not?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    hideBtnFooter: true,
    recorder: true,
    textareaStyle: { width: 800 },
    questions: [
      {
        title: `
			  <div ><img src='img/FriendsPlus/Page40/E1/1.jpg' alt='' style='margin-left: 100px; width:550px;'/></div>        
        <textarea id='0' row='5'></textarea></div>
			  `,
        answer: [],
      },
    ],
  },
  2: {
    unit: 'Unit 3',
    id: 'SB10-U3-P40-E2',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_38.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page40/E2/key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `
				Read the text. How many different video games does the writer mention by name? What are they?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 400,
    stylesTextInput: [],
    maxLength: 60,
    textAlign: 'left',
    questions: [
      {
        title: `
			  <div ><img src='img/FriendsPlus/Page40/E2/1.jpg' alt='' style='width:900px;'/></div>
        <div><b> a. </b> How many different video games does the writer mention by name?</div>
        <div>#</div>
        <div><b> b. </b> What are they?</div>
        <div>#</div>        
			  `,
        answer: ['Two games are mentioned', 'Angry Birds and Farmville'],
      },
    ],
  },
};

export default json;
