import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Weather',
    id: 'FG10-W-P52-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `
          <span style="color: rgb(45,206,137);margin-right:10px">SPEAKING</span> 
          <span>Work in pairs. Look at the title and the
            photos, read the <i>I can</i> … statement for this lesson
            and predict what happens in the text. Tell the class
            your ideas.
          </span>
        
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: '/img/FriendsPlus/Page52/E1/E1.jpg' }]],
  },

  2: {
    unit: 'Weather',
    id: 'FG10-W-P52-E2',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    inputSize: 830,
    maxLength: 76,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',

      fontSize: 23,
    },
    textareaStyle: {},
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '2',
        title: `
          <span>Read the text, ignoring the gaps. How accurate
            were your predictions?
          </span>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <img src="/img/FriendsPlus/Page52/E2/1.jpg" style="width: 950px">
        `,
        answer: [],
      },
    ],
  },
  3: {
    unit: 'Weather',
    id: 'FG10-W-P52-E3',
    exerciseKey: '/img/FriendsPlus/Page52/E3/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    checkDuplicated: true,
    titleQuestion: [
      {
        num: '3',
        title: `
        <span>Read the Reading Strategy. Then look at the gaps in
          the text. Which gaps begin with a verb in the past
          simple?
        </span>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
         <div >
      <div style="border: 3px dotted rgb(62,63,153); border-radius: 10px;background-color: rgb(227,226,241);">
          <div style="margin: 10px; padding:10px">
            <div ><span style="color: rgb(62,63,153);"><b>Reading Strategy</b></span><br></div>
            <div>Steps to help you decide which phrases fit which gaps:</div>
            <div>- Read the whole sentence to get a general
                understanding of it.
          </div>
          <div>
            - Look at the grammatical structure and identify the role
            the gap plays in the sentence (Is it a verb / an adverb /
            the subject or object of a verb? etc.)
          </div>
          <div>
            - Check if the phrase you choose agrees with the words
            around it.
          </div>
          <div>
            - Check if the completed sentence makes sense.
          </div>
          </div>
      </div>
    </div>
        <div style=" margin-top: 20px"">
          <img src="/img/FriendsPlus/Page52/E3/1.jpg" style="width: 950px">
        </div>
     <div style="margin-top: 20px;display:flex">
      <b>Answers:</b>#,#,#.
    </div>
        `,
        answer: ['3', '4', '5'],
      },
    ],
  },
  4: {
    unit: 'Weather',
    id: 'FG10-W-P52-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page52/E4/Key/answerKey.png',
    selectStyle: {
      color: '#00ADFE',
      textAlign: 'center',
      fontSize: 17,
      width: 100,
    },
    selectOptionValues: ['1', '2', '3', '4', '5', '6', '7', '8'],
    titleImage: '',
    questionImage: [],
    titleQuestion: [
      {
        num: '4',
        title: `
          <div>
          Match the phrases (A–H) with gaps 1–8 in the text.
          Use your answers to exercise 3 to help you.
        </div>
          `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
          <div style="margin-right: 20px; width: 600px">
            <div style='display: flex; justify-content: space-between;'><span> <b>A&nbsp;</b> flew higher and higher</span><select id='0' ></select></div>
            <div style='display: flex; justify-content: space-between;'><span> <b>B&nbsp;</b> keep away from some dark clouds</span><select id='1' "></select></div>
            <div style='display: flex; justify-content: space-between;'><span> <b>C&nbsp;</b> which was <span style="background-color: rgb(252,194,135);">spinning</span> above her head</span><select id='2' "></select></div>
            <div style='display: flex; justify-content: space-between;'><span> <b>D&nbsp;</b> seemed certain</span><select id='3' "></select></div>
            <div style='display: flex; justify-content: space-between;'><span> <b>E&nbsp;</b> where she <span style="background-color: rgb(252,194,135);">took off</span></span><select id='4' "></select></div>
            <div style='display: flex; justify-content: space-between;'><span> <b>F&nbsp;</b> were getting ready to take off</span><select id='5' "></select></div> 
            <div style='display: flex; justify-content: space-between;'><span> <b>G&nbsp;</b> she survived</span><select id='6' "></select></div>  
            <div style='display: flex; justify-content: space-between;'><span> <b>H&nbsp;</b> lost consciousness</span><select id='7' "></select></div>  
          </div>
      

        <div style=" margin-top: 20px"">
          <img src="/img/FriendsPlus/Page52/E4/1.jpg" style="width: 950px">
        </div>
          
          `,
        answer: ['3', '2', '6', '5', '7', '1', '8', '4'],
      },
    ],
  },
  5: {
    unit: 'Weather',
    id: 'FG10-W-P52-E5',
    exerciseKey: '/img/FriendsPlus/Page52/E5/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    selectStyle: {
      color: '#00ADFE',
      textAlign: 'center',
      width: 100,
    },
    selectOptionValues: ['T', 'F'],
    titleQuestion: [
      {
        num: '5',
        title: `
          <span>Read the text again. Are the sentences true or false.
            Write T or F. 
          </span>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div style="margin-right: 20px; width: 900px">
            <div style='display: flex; justify-content: space-between;gap:15px'><b>1</b><span> The paragliders decided not to practise because 
the weather was so bad.</span><select id='0' ></select></div>
            <div style='display: flex; justify-content: space-between;gap:15px'><span><b>2</b> The weather got worse during the morning. </span><select id='1' ></select></div>
            <div style='display: flex; justify-content: space-between;gap:15px'><span><b>3</b> The storm damaged one of the wings of her glider. </span><select id='2' ></select></div>
            <div style='display: flex; justify-content: space-between;gap:15px'><span><b>4</b> Ewa was still able to compete in the World 
Championships. 
</span><select id='3' ></select></div>
          </div>

          <div style="margin-top: 20px">
              <img src="/img/FriendsPlus/Page52/E5/1.jpg" alt="" style="width: 900px"/>
          </div>
        `,
        answer: ['F', 'T', 'F', 'T'],
      },
    ],
  },
};

export default json;
