import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  4: {
    unit: 'Unit 7',
    id: 'SB10-U7-P89-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page89/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `
				Read the <span style='color:rgb(75, 73, 153);'> Reading Strategy</span>.  Then look at questions 1–4 in exercise 5. Find the underlined words in the text and explain why those options are not correct.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    textareaStyle: { width: 900 },
    questions: [
      {
        title: `
			  <div style='background-color: rgb(209, 203, 232); margin-top: 20px; margin-left:200px; width:700px; border-radius: 15px; border: 5px dotted rgb(75, 73, 153)'>
          <div style='padding: 15px 25px 25px 25px;' >
            <div ><b style='color:rgb(75, 73, 153) ;'> Reading Strategy</b></div>
            <div>Do not assume that because a multiple-choice option contains words from the text, it is correct. Focus on meaning, not on individual words.</div>
            <div>Treat each option as a true or false task in order to find the option that is true.</div>
          </div>
        </div>

        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div>
            <div> Hilary loved her holiday because</div>
              <div style='margin-right: 10px;'><b> a </b> she drank a lot of <u>coffee</u>.</div>
              <div style='margin-right: 10px;'><b> b </b> she made a new friend.</div>
              <div style='margin-right: 10px;'><b> c </b> she learned some Spanish.</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>
            <div> Kevin first saw the baby turtles </div>
              <div style='margin-right: 10px;'><b> a </b> in a conservation centre.</div>
              <div style='margin-right: 10px;'><b> b </b> near the diving centre.</div>
              <div style='margin-right: 10px;'><b> c </b> in their <u>natural habitat</u>.</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>
            <div> On his holiday, Kevin </div>
              <div style='margin-right: 10px;'><b> a </b> bought some suncream.</div>
              <div style='margin-right: 10px;'><b> b </b> caught lots of <u>fish</u>.</div>
              <div style='margin-right: 10px;'><b> c </b> learned a new skill.</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>
            <div> Terry got lost in the jungle because</div>
              <div style='margin-right: 10px;'><b> a </b> the guide walked too quickly.</div>
              <div style='margin-right: 10px;'><b> b </b> he chased a monkey.</div>
              <div style='margin-right: 10px;'><b> c </b> he dropped his <u>sunglasses</u>.</div>
          </div>
        </div>

        <div style='margin-right: 10px;'><b> Explain why those options are not correct. </b></div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <textarea id="0" rows="2"></textarea>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <textarea id="1" rows="2"></textarea>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <textarea id="2" rows="1"></textarea>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <textarea id="3" rows="1"></textarea>
        </div>
        
			  <div ><img src='img/FriendsPlus/Page89/E4/1.jpg' alt='' style='width:900px;'/></div>
			  `,
        answer: [
          "Hilary said she harvested the coffee, but she didn't say she drank it.In fact, she says she doesn't like it.",
          "He helped to take the baby turtles to their natural habitat, but he didn't see them for the first time there.",
          "Kevin saw lots of fish when he was diving. He didn't catch any.",
          "Terry didn't drop his sunglasses; they were stolen by a monkey.",
        ],
      },
    ],
  },

  5: {
    unit: 'Unit 7',
    id: 'SB10-U7-P89-E5',
    audio: '',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '5',
        title: `
          Circle the correct option (a–c).
        `,
        color: '#860052',
      },
    ],
    exerciseKey: '/img/FriendsPlus/Page89/E5/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          margin: '-2px',
        },
        limitSelect: 1,
        listWords: ['a <br> b <br> c', 'a <br> b <br> c', 'a <br> b <br> c', 'a <br> b <br> c'],
        answers: ['0-4', '1-0', '2-8', '3-4'],
        initialValue: [],
      },
      Layout: `     
        <div>
          <div style='margin-right: 10px;'><b> 1 </b> Hilary loved her holiday because </div>
          <div style='display: flex; gap:15px; margin-left:30px;'>
            <div><b><input type="Circle" id="0" /></b></div>
            <div>
              <div> she drank a lot of <u>coffee</u>.</div>
              <div> she made a new friend.</div>
              <div> she learned some Spanish.</div>
            </div>
          </div>
        </div>      
        <div>
          <div style='margin-right: 10px;'><b> 2 </b> Kevin first saw the baby turtles</div>
          <div style='display: flex; gap:15px; margin-left:30px;'>
            <div><b><input type="Circle" id="1" /></b></div>
            <div>
              <div> in a conservation centre.</div>
              <div> near the diving centre.</div>
              <div> in their <u>natural habitat</u>.</div>
            </div>
          </div>
        </div>      
        <div>
          <div style='margin-right: 10px;'><b> 3 </b> On his holiday, Kevin</div>
          <div style='display: flex; gap:15px; margin-left:30px;'>
            <div><b><input type="Circle" id="2" /></b></div>
            <div>
              <div> bought some suncream.</div>
              <div> caught lots of <u>fish</u>.</div>
              <div> learned a new skill.</div>
            </div>
          </div>
        </div>      
        <div>
          <div style='margin-right: 10px;'><b> 4 </b> Terry got lost in the jungle because</div>
          <div style='display: flex; gap:15px; margin-left:30px;'>
            <div><b><input type="Circle" id="3" /></b></div>
            <div>
              <div> the guide walked too quickly.</div>
              <div> he chased a monkey.</div>
              <div> he dropped his <u>sunglasses</u>.</div>
            </div>
          </div>
        </div>

			  <div ><img src='img/FriendsPlus/Page89/E5/1.jpg' alt='' style='width:900px;'/></div>
      `,
    },
  },
  6: {
    unit: 'Unit 7',
    id: 'SB10-U7-P89-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page89/E6/key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `
				<span style='color:rgb(2, 146, 97);'>SPEAKING</span> Work in groups. Read the definition of an “ecotourist”. Then discuss why holiday A is called an eco-holiday and in what ways the other holidays are good / bad for the environment. Give reasons.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    textareaStyle: { width: 1100 },
    questions: [
      {
        title: `
        <div style='background-color: rgb(218, 191, 222); margin-top: 20px; margin-left:200px; width:500px; border-radius: 15px; '>
          <div style='padding: 15px 25px 25px 25px;' >
            <b>Ecotourists</b> travel in a way that shows respect to nature and does not damage it. They also try to learn about the local people, so they can help to improve their quality of life.
          </div>
        </div>
        
        <div >
          <div style='margin-right: 10px;'><b> Text A: </b></div>
          <textarea id="0" rows="2"></textarea>
        </div>

        <div style='margin-right: 10px;'><b> Text B: </b></div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><i>Good for the environment, because: </i>
          <textarea id="1" rows="1"></textarea></div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><i>Bad for the environment, because: </i>
          <textarea id="2" rows="1"></textarea></div>
        </div>

        <div style='margin-right: 10px;'><b> Text C: </b></div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><i>Good for the environment, because: </i>
          <textarea id="3" rows="1"></textarea></div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><i>Bad for the environment, because: </i>
          <textarea id="4" rows="1"></textarea></div>
        </div>

			  `,
        answer: [
          'Hilary and her family stayed with local people, so they were learning about the local traditions and culture. In return, the family were earning money to help support their coffee growing.',
          "It's good to help conservation work in the turtle sanctuary. / It's good to support conservation work in the turtle sanctuary.",
          'Diving can damage the the Great Barrier Reef.',
          'Paying local tour guides help support the local economy.',
          'Some campers leave litter behind and start fires. Tour groups can damage the jungle.',
        ],
      },
    ],
  },
  7: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P89-E7',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page89/E7/key/answerKey.png',
    titleQuestion: [
      {
        num: '7',
        title: `<span style='color:rgb(124, 8, 79);'> VOCABULARY</span> Form holiday-related compound nouns by matching the words below with words 1–8. All of the compounds are in the texts.
			  `,
        color: '#92278f',
      },
    ],
    isHiddenCheck: true,
    titleImage: '',
    questionImage: [],
    inputSize: 100,
    stylesTextInput: [],
    maxLength: 7,
    textAlign: 'left',

    checkDuplicated: true,
    hintBox: [
      {
        src: ['burn', 'cream', 'friend', 'glasses', 'guide', 'pack', 'tourism', 'site'],
        // arrow: true,
        width: 800,
        borderColor: '#2b7de9',
      },
    ],
    questions: [
      {
        title: `
				<div style='margin: 20px 100px 10px 100px;'><hintBox id='0' ></hintBox></div>

        <div style="margin-top: 20px; display: flex; gap: 50px;">
          <div>
            <div style="display: flex; gap: 10px;">
              <strong>1</strong>
              <div><span style="margin-right: -13px;">eco</span>#</div>
            </div>
            <div style="display: flex; gap: 10px;">
              <strong>2</strong>
              <div><span>local</span>#</div>
            </div>
          </div>
          <div>
            <div style="display: flex; gap: 10px;">
              <strong>3</strong>
              <div><span>pen</span>#</div>
            </div>
            <div style="display: flex; gap: 10px;">
              <strong>4</strong>
              <div><span style="margin-right: -13px;">pack</span>#</div>
            </div>
          </div>
          <div>
            <div style="display: flex; gap: 10px;">
              <strong>5</strong>
              <div><span style="margin-right: -13px;">camp</span>#</div>
            </div>
            <div style="display: flex; gap: 10px;">
              <strong>6</strong>
              <div><span style="margin-right: -13px;">sun</span>#</div>
            </div>
          </div>
          <div>
            <div style="display: flex; gap: 10px;">
              <strong>7</strong>
              <div><span style="margin-right: -13px;">sun</span>#</div>
            </div>
            <div style="display: flex; gap: 10px;">
              <strong>8</strong>
              <div><span style="margin-right: -13px;">sun</span>#</div>
            </div>
          </div>
        </div>

			  <div style="margin-top: 20px;"><img src='img/FriendsPlus/Page89/E7/1.jpg' alt='' style='width:100%;'/></div>
			  `,
        answer: [
          'tourism',
          'guide',
          'friend',
          'pack',
          'site',
          'cream / glasses / burn',
          'cream / glasses / burn',
          'cream / glasses / burn',
        ],
        // answer: ['tourism', 'guide', 'friend', 'pack', 'site', 'cream', 'glasses', 'burn'],
      },
    ],
  },
  8: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P38-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '8',
        title: `
				<span style='color:rgb(0,147,70);'>SPEAKING</span> Work in pairs. Think of some ideas for being a good ecotourist. Use the <i>if</i> clauses to help you.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    hideBtnFooter: true,
    hintBox: [
      {
        src: ['If you stay on a campsite, you’ll use less energy.'],
        arrow: true,
        position: 2,
        width: 500,
        borderColor: 'rgb(186, 180, 218)',
      },
    ],
    questions: [
      {
        title: `
        <div><span style='color: rgb(116, 114, 156);'>If you learn about your destination before you travel</span></div>
        <div><span style='color: rgb(116, 114, 156);'>If you go to the beach</span></div>
        <div><span style='color: rgb(116, 114, 156);'>If you visit a national park</span></div>
        <div><span style='color: rgb(116, 114, 156);'>If you eat local food</span></div>
        <div><span style='color: rgb(116, 114, 156);'>If you take public transport</span></div>
        <div><span style='color: rgb(116, 114, 156);'>If you stay on a campsite</span></div>
				<div style='margin: 20px 0 50px 350px;'><hintBox id='0' ></hintBox></div>
			  `,
        answer: [],
      },
    ],
  },
};

export default json;
