import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Unit 1',
    id: 'SB10-U-P21-E1',
    audio: '',
    video: '',
    exerciseKey: '/img/FriendsPlus/Page21/E1/Key/answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '',
        title: `
          <span style="color: rgb(0,110,147);"><strong>Reading</strong></span> <br>
          Read the following passage and circle True (T), False (F) or Doesn’t say (DS).
        `,
        color: '#860052',
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
          padding: '5px',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          margin: '-2px',
          padding: '5px',
        },
        limitSelect: 1,
        listWords: ['T / F / DS', 'T / F / DS', 'T / F / DS', 'T / F / DS', 'T / F / DS', 'T / F / DS'],
        answers: ['0-8', '1-4', '3-4', '4-0', '5-0', '2-8'],
        initialValue: [],
      },
      Layout: `
        <div style="display: flex; align-items: start; font-size: 21px;">
          <div style="flex: 3; margin-bottom: 20px;">
            <img src="/img/FriendsPlus/Page21/E1/E1.png" style="max-width: 90%;" />
          </div>

          <div style="flex: 3; padding: 10px 20px 10px; border-radius: 10px; border: 1px solid rgb(20, 217, 217);"> 
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">1</div>
              <div>
                The surveys show that teenagers and parents  have different ideas about happiness. 
                <strong><input type="Circle" id="0"></strong>
              </div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">2</div>
              <div>
                The things that make us happy never change. 
                <strong><input type="Circle" id="1"></strong>
              </div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">3</div>
              <div>
                The surveys show that teenagers who do exercise are happier. 
                <strong><input type="Circle" id="2"></strong>
              </div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">4</div>
              <div>
                When we feel depressed we shouldn’t eat chocolate. 
                <strong><input type="Circle" id="3"></strong>
              </div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">5</div>
              <div>
                Exercise and eating chocolate can produce similar results. 
                <strong><input type="Circle" id="4"></strong>
              </div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">6</div>
              <div>
                It’s important to value even short moments of happiness. 
                <strong><input type="Circle" id="5"></strong>
              </div>
            </div>
          </div>
        `,
    },
  },
  2: {
    unit: 'Unit 1',
    id: 'SB10-U-P21-E2',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_18.mp3',
    video: '',
    exerciseKey: '/img/FriendsPlus/Page21/E2/Key/answerKey.png',
    component: T6,
    inputSize: 160,
    maxLength: 11,
    checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 23,
    },
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    titleQuestion: [
      {
        num: '',
        title: `
          <span style="color: rgb(0,110,147);"><strong>Listening</strong></span> <br>
          Put the words below in the correct categories (A–D).
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div style="width: 700px; display: flex; gap: 40px; color: rgb(146,39,143); padding: 10px; border-radius: 10px; border: 1px solid rgb(146,39,143);">
            <div>boat<br/>cliffs<br/>cut<br/>kayaking<br/></div>
            <div>bored<br/>climbing<br/>delighted<br/>landscape<br/></div>
            <div>burn<br/>condition<br/>diving<br/>ocean<br/></div>
            <div>canoe<br/>confused<br/>embarrassed<br/>proud<br/></div>
            <div>cave<br/>cure<br/>injury<br/>stream<br/></div>
          </div>

          <div>
            <div style="margin-top: 20px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">A</div>
              <div>Feelings: #, #, #, #, #</div>
            </div>
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">B</div>
              <div>Sports: #, #, #, #, #</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">C</div>
              <div>Nature: #, #, #, #, #</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">D</div>
              <div>Health: #, #, #, #, #</div>
            </div>	
          </div>

          <div>
            <div style="margin-top: 20px;margin-bottom: 10px;">
              <img src="/img/FriendsPlus/Page21/E2/E2.png" width="95px">
              <b>Listen to four people talking about sport.</b> 
              <div style="margin-top: 5px;margin-left: 5px;"><span ><b>Match sentences A–D with speakers 1–4. </br></span></div>
            </div>
            <div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">A</div>
                <div>We find out about a new sporting venue. <input type="text" id="4" width="40px"></div>
              </div>
              <div style="m7gin-top: 20px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">B</div>
                <div>The speaker is talking about the different benefits of sport. <input type="text" id="5" width="40px"></div>
              </div>
              <div style="m7gin-top: 20px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">C</div>
                <div>The speaker is describing a personal experience of a sport. <input type="text" id="6"width="40px"></div>
              </div>
              <div style="m7gin-top: 20px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">D</div>
                <div>The speaker is explaining the advantages of extreme sports. <input type="text" id="7"width="40px"></div>
              </div>
            </div> 
          </div>
        `,
        answer: [
          'bored / confused / delighted / embarrassed / proud',
          'bored / confused / delighted / embarrassed / proud',
          'bored / confused / delighted / embarrassed / proud',
          'bored / confused / delighted / embarrassed / proud',
          'bored / confused / delighted / embarrassed / proud',
          'boat / canoe / climbing / diving / kayaking',
          'boat / canoe / climbing / diving / kayaking',
          'boat / canoe / climbing / diving / kayaking',
          'boat / canoe / climbing / diving / kayaking',
          'boat / canoe / climbing / diving / kayaking',
          'cave / cliffs / landscape / ocean / stream',
          'cave / cliffs / landscape / ocean / stream',
          'cave / cliffs / landscape / ocean / stream',
          'cave / cliffs / landscape / ocean / stream',
          'cave / cliffs / landscape / ocean / stream',
          'burn / condition / cure / cut / injury',
          'burn / condition / cure / cut / injury',
          'burn / condition / cure / cut / injury',
          'burn / condition / cure / cut / injury',
          'burn / condition / cure / cut / injury',
          '4',
          '3',
          '2',
          '1',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 1',
    id: 'SB10-U-P21-E3',
    audio: '',
    video: '',
    exerciseKey: '/img/FriendsPlus/Page21/E3/Key/answerKey.png',
    component: UI,
    titleQuestion: [
      {
        num: '',
        title: `
        <b style="color: rgb(0,110,147);">Speaking</b> <br>
          Work in groups. Talk about an event or time when
          you felt very happy. Try to explain what made you
          happy.`,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: 'img/FriendsPlus/Page6/E1/1.jpg' }]],
  },
  4: {
    unit: 'Unit 1',
    id: 'SB10-U-P21-E4',
    audio: '',
    video: '',
    exerciseKey: '/img/FriendsPlus/Page21/E4/Key/answerKey.png',
    component: T6,
    inputSize: 550,
    maxLength: 45,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 23,
    },
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '',
        title: `
          <b style="color: rgb(0,110,147);">Writing</b> <br>
            You have recently been on holiday with your family.
            Write an email (120–150 words) about it to a friend.
            Include the following points.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div>
          <span><i>Where you went</i></span> <br>
          <span><i>How you got there</i></span> <br>
          <span><i>Something interesting you did</i></span> <br>
          <span><i>How you felt</i></span> <br>
          <span><i>Invitation to your friend to stay with you for the next holiday.</i></span>
      </div> 
        `,
        answer: [],
      },
    ],
  },
};

export default json;
