import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Introduction',
    id: 'FG10-I-P8-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page8/E1/Key/answerKey.png',
    titleImage: '',
    questionImage: [],
    titleQuestion: [
      {
        num: '1',
        title: `Look at the photos of famous film characters. Match them with the names below. What films do they appear in? Do you know who the actors are?`,
        color: '#92278f',
      },
    ],
    selectStyle: {
      // color: '#00ADFE',
      width: 240,
      textAlign: 'center',
      // fontSize: 17,
    },
    selectOptionRandom: true,
    selectOptionValues: [
      'James Bond',
      'Maleficent',
      'Black Widow',
      'Newt Scamander',
      'Katniss Everdeen',
    ],
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <div><img src='img/FriendsPlus/Page8/E1/1.jpg'/></div>
            <div style='position: absolute; top: 92px; left: 99px;'><select id='0'></select></div>
            <div style='position: absolute; top: 30px; left: 450px;'><select id='1'></select></div>
            <div style='position: absolute; top: 330px; left: -40px;'><select id='2'></select></div>
            <div style='position: absolute; top: 270px; left: 300px;'><select id='3'></select></div>
            <div style='position: absolute; top: 270px; left: 600px;'><select id='4'></select></div>
          </div>
        `,
        answer: [
          'Katniss Everdeen',
          'Newt Scamander',
          'James Bond',
          'Black Widow',
          'Maleficent',
        ],
      },
    ],
  },
  2: {
    unit: 'Introduction',
    id: 'FG10-I-P8-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page8/E2/Key/answerKey.png',
    titleImage: '',
    questionImage: [],
    titleQuestion: [
      {
        num: '2',
        title: `Match the sentence beginnings (1–4) with the endings (a–d). Then match the descriptions with four of the characters in exercise 1.`,
        color: '#92278f',
      },
    ],
    selectStyle: {
      // color: '#00ADFE',
      width: 240,
      textAlign: 'center',
      // fontSize: 17,
    },
    selectOptionRandom: true,
    selectOptionValues: [
      'James Bond',
      'Maleficent',
      'Black Widow',
      'Newt Scamander',
      'Katniss Everdeen',
      'a',
      'b',
      'c',
      'd',
    ],
    questions: [
      {
        title: `
          <div>
            <div><img src='img/FriendsPlus/Page8/E1/1.jpg'/></div>
            <div style='display: flex;'><b style='margin-right: 10px;'>1</b><select id='0'></select> has got long brown hair. She’s wearing <select id='1'></select></div>
            <div style='display: flex;'><b style='margin-right: 10px;'>2</b><select id='2'></select> has got long wavy hair. She’s wearing <select id='3'></select></div>
            <div style='display: flex;'><b style='margin-right: 10px;'>3</b><select id='4'></select> has got short curly hair. He's wearing <select id='5'></select></div>
            <div style='display: flex;'><b style='margin-right: 10px;'>4</b><select id='6'></select> has got short fair hair. He’s wearing <select id='7'></select></div>
            <div style='display: flex;'><b style='margin-right: 10px;'>a</b> a black jacket and black trousers.</div>
            <div style='display: flex;'><b style='margin-right: 10px;'>b</b> a brown jacket and a black T-shirt.</div>
            <div style='display: flex;'><b style='margin-right: 10px;'>c</b> a suit and a tie.</div>
            <div style='display: flex;'><b style='margin-right: 10px;'>d</b> a waistcoat, a bow tie and a long coat.</div>
          </div>
        `,
        answer: [
          'Katniss Everdeen',
          'b',
          'Black Widow',
          'a',
          'Newt Scamander',
          'd',
          'James Bond',
          'c',
        ],
      },
    ],
  },
  3: {
    unit: 'Introduction',
    id: 'FG10-I-P8-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: `<span style='color: rgb(146,39,143);'>VOCABULARY</span> Add the adjectives below to the table. Note the order of the adjectives.`,
        color: '#92278f',
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page8/E3/Key/answerKey.png',
    inputSize: 458,
    questionImage: [],
    checkDuplicated: true,
    inputSize: 200,
    questions: [
      {
        title: `
          Describing hair black brown  fair red long medium-length straight  wavy
          <table style='border: 1px solid black;'>
            <tr style='background-color: rgb(146,39,143); color: white;'>
              <th style='border: 1px solid black;'></th>
              <th style='border: 1px solid black;'>length</th>
              <th style='border: 1px solid black;'>style</th>
              <th style='border: 1px solid black;'>colour</th>
              <th style='border: 1px solid black;'></th>
            </tr>
            <tr>
              <td rowspan="5" style='border: 1px solid black;'>He / She’s got</td>
              <td style='border: 1px solid black;'>short</td>
              <td style='border: 1px solid black;'>curly</td>
              <td style='border: 1px solid black;'>dark</td>
              <td rowspan="5" style='border: 1px solid black;'>hair.</td>
            </tr>
            <tr>
              <td rowspan="2"  style='border: 1px solid black;'>#</td>
              <td rowspan="2"  style='border: 1px solid black;'>#</td>
              <td style='border: 1px solid black;'>#</td>
            </tr>
            <tr>
              <td style='border: 1px solid black;'>#</td>
            </tr>
            <tr>
              <td rowspan="2"  style='border: 1px solid black;'>#</td>
              <td rowspan="2"  style='border: 1px solid black;'>#</td>
              <td style='border: 1px solid black;'>#</td>
            </tr>
            <tr>
              <td style='border: 1px solid black;'>#</td>
            </tr>
          </table>
        `,
        answer: [
          'long/medium-length',
          'straight/wavy',
          'black/brown/fair/red',
          'black/brown/fair/red',
          'long/medium-length',
          'straight/wavy',
          'black/brown/fair/red',
          'black/brown/fair/red',
        ],
      },
    ],
  },

  4: {
    unit: 'Introduction',
    id: 'FG10-I-P8-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page8/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `Work in pairs. How many different items of clothing can you write down? Include all the ones in exercise 2. Put them into groups A and B below.`,
        color: '#92278f',
      },
    ],
    questionImage: [],
    inputSize: 125,
    isHiddenCheck:true,
    questions: [
      {
        title: `
        <div style="display:flex; gap:30px;">
          <div>
            <div><b>A</b> Top half </div>
            <div><b>B</b> Bottom half </div>
          </div>
          <div>
            <div><span style='color: rgb(10,111,142)'>jacket, shirt,</span>#,#,#,#,#,#</div>
            <div><span style='color: rgb(10,111,142)'>trousers, shoes,</span>#</div>
          </div>
        </div>
        <br/>
          <div style='width:470px;box-shadow: rgb(156, 37, 138) 0 0 5px 0; border-radius: 4px; color: rgb(156, 37, 138); padding:5px 10px;'><b>Vocabulary Builder IC</b> Clothes: page 124</div>
        `,
        answer: [
          `coat`, `T-shirt`, `hat`, `tie`, `waistcoat`, `bow tie`,
          `jeans`,
        ],
      },
    ],
  },

  5: {
    unit: 'Introduction',
    id: 'FG10-I-P8-E5',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '5',
        title: `In your notebook, write a description of some of your classmates.`,
        color: '#92278f',
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    textareaStyle: { width: 800 },
    questions: [
      {
        title: `<div><textarea id="0" rows="5"></textarea></div>`,
        answer: [],
      },
    ],
  },

  6: {
    unit: 'Introduction',
    id: 'FG10-I-P8-E6',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    record: true,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '6',
        title: `<span style='color: rgb(0,147,70);'>SPEAKING</span> Tell your partner what clothes you usually wear when:`,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
          <div><b>a</b> you are at school.</div>
          <div><b>b</b> you go out with friends.</div>
          <div><b>c</b> you are relaxing at home.</div>
          <div><b>d</b> you do sport.</div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
