import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Feelings',
    id: 'FG10-F-P12-E1',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '1',
        title: `
          Is there a lottery in your country? Do you think it is a good idea to buy tickets? <br/>Why? / Why not?
        `,
        color: '#860052',
      },
    ],
    textareaStyle: { width: 800 },
    hideBtnFooter: true,
    questions: [
      {
        title: `
         <textarea rows="6"></textarea>
        `,
        answer: [],
      },
    ],
  },
  2: {
    unit: 'Feelings',
    id: 'FG10-F-P12-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page12/E2/Key/answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: `
          Read the text. Are all lottery winners happy?
        `,
        color: '#860052',
      },
    ],
    textareaStyle: { width: 800 },
    questions: [
      {
        title: `
         <div><img src="/img/FriendsPlus/Page12/E2/1.jpg" alt=""></div>
         <div>Are all lottery winners happy?</div>
         <textarea id="0" rows="3"></textarea>
        `,
        answer: [
          `People are not always happy when they win the lottery. People can make bad decisions or have terrible arguments with family and friends.`,
        ],
      },
    ],
  },
  3: {
    unit: 'Feelings',
    id: 'FG10-F-P12-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page12/E3/Key/answerKey.png',
    component: T6,
    inputSize: 130,
    maxLength: 20,
    titleQuestion: [
      {
        num: '3',
        title: `
          Read the <span style="color:rgb(245, 147, 37)">Learn this!</span> box. Find all the past simple forms in the text in exercise 2. <br/>Match them with rules a–d in the <span style="color:rgb(245, 147, 37)">Learn this!</span> box.
        `,
        color: '#860052',
      },
    ],
    questions: [
      {
        title: `
        <div style="display:flex; margin-left: -100px;">
         <div><br/><img src="/img/FriendsPlus/Page12/E3/E3.png" alt=""  style="width:600px"></div>
         <div><img src="/img/FriendsPlus/Page12/E3/1.png" alt="" style="width:500px;"></div>
        </div>

        <div><b>Rule a: &ensp;</b>#,#.</div>
        <div><b>Rule b: &ensp;</b>#,#,#.</div>
        <div><b>Rule c: &ensp;</b>#,#,#,#,#,<br/>#,#,#.</div>
        <div><b>Rule d: &ensp;</b>#,#.</div>
        `,
        answer: [
          `returned/looked`,
          `returned/looked`,
          `studied/compared/stopped`,
          `studied/compared/stopped`,
          `studied/compared/stopped`,
          `bought/chose/won/felt/gave/found/spent/had`,
          `bought/chose/won/felt/gave/found/spent/had`,
          `bought/chose/won/felt/gave/found/spent/had`,
          `bought/chose/won/felt/gave/found/spent/had`,
          `bought/chose/won/felt/gave/found/spent/had`,
          `bought/chose/won/felt/gave/found/spent/had`,
          `bought/chose/won/felt/gave/found/spent/had`,
          `bought/chose/won/felt/gave/found/spent/had`,
          `were/was`,
          `were/was`,
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U-P16-E4',
    audio: '/Audios/Friends Global Grade 10 SB_CD1_07.mp3',
    video: '',
    component: T6,
    isHiddenCheck: true,
    stylesTextInput: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
    },
    exerciseKey: 'img/FriendsPlus/Page12/E4/Key/answerKey.png',
    inputSize: 127,
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative; ">
          	<div> <img src='img/FriendsPlus/Page12/E4/1.jpg' /> </div>
          	<div style=" position: absolute; top: 323px; left: 295px; "><input id='0' /></div>
            <div style=" position: absolute; top: 355px; left: 138px; "><input id='1' /></div>
            <div style=" position: absolute; top: 388px; left: 108px; "><input id='2' /></div>
            <div style=" position: absolute; top: 421px; left: 387px; "><input id='3' /></div>
            <div style=" position: absolute; top: 486px; left: 183px; "><input id='4' /></div>
            <div style=" position: absolute; top: 517px; left: 377px; "><input id='5' /></div>
            <div style=" position: absolute; top: 552px; left: 223px; "><input id='6' /></div>
            <div style=" position: absolute; top: 582px; left: 100px; "><input id='7' /></div>
            <div style=" position: absolute; top: 617px; left: 102px; "><input id='8' /></div>
            <div style=" position: absolute; top: 650px; left: 346px; "><input id='9' /></div>
        </div>
        `,
        answer: ['were ', 'won ', 'gave ', 'spent ', 'got ', 'was', 'decided ', 'had ', 'began ', 'said'],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U-P16-E5',
    audio: '',
    video: '',
    component: T6,
    maxLength: 1,
    isHiddenCheck: true,
    textAlign: 'center',
    stylesTextInput: {
      padding: 0,
    },
    exerciseKey: 'img/FriendsPlus/Page12/E5/Key/answerKey.png',
    inputSize: 140,
    titleQuestion: [
      {
        num: '5',
        title: `
          Complete the sentences with the adjectives below and the past simple form of the verbs in brackets.
        `,
        color: '#860052',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
              <div style="color: rgb(246, 140, 31); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px">
                <div>bored</div>
                <div>delighted</div>
                <div>embarrassed </div>
                <div>suspicious</div>
                <div><s>upset</s></div>
              </div>
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">Sandra Carroll <span style="color: rgb(0, 68, 98)">felt</span> (feel) <span style="color: rgb(0, 68, 98)">upset</span> when Michael <span style="color: rgb(0, 68, 98)">spent</span>(spend) a lot of his money on parties.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">Spencer # (be) # when he # (drop) all his money on the floor of the shop.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> Bess # (be) # when she # (win) the essay competition.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">I # (feel) a bit # when the man # (say) he was a millionaire.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">I # (get) a bit # because Dan # (talk) about his new girlfriend all evening.</div></div>
              </div>
        `,
        answer: [
          'was',
          'embarrassed',
          'dropped',
          'was',
          'delighted',
          'won',
          'felt',
          'suspicious',
          'said',
          'got',
          'bored',
          'talked',
        ],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB10-U-P16-E6',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    questionImage: [[{ url: 'img/FriendsPlus/Page12/E6/1.jpg' }]],
  },
};

export default json;
