import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P82-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page82/E1/key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `
				<span style='color:rgb(2, 146, 97);'>SPEAKING</span> Work in pairs. Look at the photos of tourist attractions (A–G). Do you know which countries they are in? Match them with the countries in the box.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 160,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: ['England', 'France', 'Kenya', 'Peru', 'Turkey', 'the USA', 'Viet Nam'],

    // checkDuplicated: true,
    hintBox: [
      {
        src: ['England', 'France', 'Kenya', 'Peru', 'Turkey', 'the USA', 'Viet Nam'],
        // arrow: true,
        width: 800,
        borderColor: '#000',
      },
    ],
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div>
				    <div><hintBox id='0' ></hintBox></div>
            <div style='display: flex; justify-content: space-around;'>
              <div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> A </b></div>
                  <b> <select id='0'></select>  </b>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> B </b></div>
                  <b> <select id='1'></select>  </b>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> C </b></div>
                  <b> <select id='2'></select>  </b>
                </div>
                
              </div>
              <div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> D </b></div>
                  <b> <select id='3'></select>  </b>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> E </b></div>
                  <b> <select id='4'></select>  </b>
                </div>
              </div>
              <div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> F </b></div>
                  <b> <select id='5'></select>  </b>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> G </b></div>
                  <b> <select id='6'></select>  </b>
                </div>
              </div>
            </div>
          </div>
          <div><img src= 'img/FriendsPlus/Page82/E1/1.jpg'/></div>
        </div>
			  `,
        answer: ['France', 'Turkey', 'the USA', 'Peru', 'Viet Nam', 'Kenya', 'England'],
      },
    ],
  },

  2: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P82-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page82/E2/key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `
				<span style='color:#92278f;'>VOCABULARY</span>  Match seven of the words below with photos A–G from exercise 1.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 210,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: [
      'aquarium',
      'botanical gardens',
      'castle',
      'cathedral',
      'fountain',
      'bay',
      'market',
      'mosque',
      'museum',
      'national park',
      'palace',
      'ruins',
      'statue',
      'temple',
      'theme park',
      'tower',
      'water park',
    ],

    // checkDuplicated: true,
    hintBox: [
      {
        src: [
          'aquarium',
          'botanical gardens',
          'castle',
          'cathedral',
          'fountain',
          'bay',
          'market',
          'mosque',
          'museum',
          'national park',
          'palace',
          'ruins',
          'statue',
          'temple',
          'theme park',
          'tower',
          'water park',
        ],
        // arrow: true,
        width: 800,
        borderColor: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div>
				    <div>
              <span style='color:rgb(153, 34, 136);'><b >Tourist attractions (1)</b></span>
              <hintBox id='0' ></hintBox>
            </div>
            <div style='display: flex; justify-content: space-around; margin: 20px 0;'>
              <div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> A </b></div>
                  <b> <select id='0'></select>  </b>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> B </b></div>
                  <b> <select id='1'></select>  </b>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> C </b></div>
                  <b> <select id='2'></select>  </b>
                </div>
                
              </div>
              <div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> D </b></div>
                  <b> <select id='3'></select>  </b>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> E </b></div>
                  <b> <select id='4'></select>  </b>
                </div>
              </div>
              <div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> F </b></div>
                  <b> <select id='5'></select>  </b>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> G </b></div>
                  <b> <select id='6'></select>  </b>
                </div>
              </div>
            </div>
          </div>
          <div><img src= 'img/FriendsPlus/Page82/E2/1.jpg'/></div>
        </div>
			  `,
        answer: ['tower', 'mosque', 'statue', 'ruins', 'bay', 'national park', 'palace'],
      },
    ],
  },
};

export default json;
