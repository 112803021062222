import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P116-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title:
          "<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>5B</span><i>will</i>, be going to and the first conditional </strong> <br /> <strong>1</strong> Complete the sentences with the correct affirmative or negative form of <i>will.</i> <leftarrow name='5.1'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 100,
    maxLength: 200,
    exerciseKey: 'img/FriendsPlus/Page116/E1/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              Don’t worry. My dad <input /> pay for our tickets.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              "<input /> Tamer be at the party?" "No, he <input />."
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              Suzie’s the best student in the class. She <input /> pass all her exams easily.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              "<input /> your friends be at the beach?" "Yes, they <input />"
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              Max is good at keeping secrets. He <input /> tell anyone.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              August is always very dry, so it <input /> rain then.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>7</strong>
            <div>
              "<input /> you be in Year 5 next year?" "No, I <input />"
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>8</strong>
            <div>
              Kyle wants to have a motorbike, but his parents <input /> allow it.
            </div>
          </div>
				`,
        answer: ['will ', 'Will', `won't`, 'Will', 'Will', 'Will', `won't`, `won't`, 'Will', `won't`, `won't`],
      },
    ],
  },
  2: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P116-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title:
          "Write offers or promises in reply to sentences 1–6. Use the verbs and object pronouns below. <leftarrow name='5.1, 5.2'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 200,
    maxLength: 200,
    exerciseKey: 'img/FriendsPlus/Page116/E2/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="">
            <div style="display: flex; column-gap: 20px; color: rgb(246 140 31) ">
              <strong>Verbs</strong>
              <span>ask</span>
              <span style="text-decoration: line-through">carry</span>
              <span>eat</span>
              <span>invite</span>
              <span>tell</span>
              <span>video</span>
            </div>
            <div style="display: flex; column-gap: 20px; color: rgb(246 140 31) ">
              <strong>Pronouns</strong>
              <span>her</span>
              <span>him</span>
              <span style="text-decoration: line-through">it</span>
              <span>them</span>
              <span>you</span>
              <span>us</span>
            </div>
          </div>
          <div style="margin-top: 20px">
            <div>
              <strong>1</strong>
              <span style="margin-left: 20px">This bag is too heavy for me <span style="color: rgb(4 84 121); font-style: oblique;">I’ll carry it</span></span>
            </div>
            <div>
              <strong>2</strong>
              <span style="margin-left: 20px">I don’t really like these crisps.</span> <input/>
            </div>
            <div>
              <strong>3</strong>
              <span style="margin-left: 20px">I really want Emma to come to my party.</span> <input/>
            </div>
            </div>
              <strong>4</strong>
              <span style="margin-left: 20px">Let’s send a video message to Grandma.</span> <input/>
            </div>
            <div>
              <strong>5</strong>
              <span style="margin-left: 20px">I want to know what happens at the end of the film.</span> <input/>
            </div>
            <div>
              <strong>6</strong>
              <span style="margin-left: 20px">Maybe we can borrow my dad’s laptop.</span> <input/>
            </div>
          </div>
          
				`,
        answer: [`I'll eat them.`, `I'll invite her.`, `I'll video us.`, `I'll tell you.`, `I'll ask him.`],
      },
    ],
  },
  3: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P116-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title:
          "Write decisions with will in reply to sentences 1–6. Use your own ideas. <leftarrow name='5.1, 5.2'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 100,
    maxLength: 7,
    exerciseKey: 'img/FriendsPlus/Page6/E1/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div >
            <div>
              <strong>1</strong>
              <span style="margin-left: 20px">Somebody’s knocking at the door. I<span style="color: rgb(4 84 121); font-style: oblique;">I’ll answer it</span></span>
            </div>
            <div>
              <strong>2</strong>
              <span style="margin-left: 20px">Would you like the blue T-shirt or the red one?</span>
            </div>
            <div>
              <strong>3</strong>
              <span style="margin-left: 20px">There’s chicken or fish for lunch.</span>
            </div>
            </div>
              <strong>4</strong>
              <span style="margin-left: 20px">It looks freezing outside.</span>
            </div>
            <div>
              <strong>5</strong>
              <span style="margin-left: 20px">The next bus into town is in an hour.</span>
            </div>
            <div>
              <strong>6</strong>
              <span style="margin-left: 20px">What would you like to drink?</span>
            </div>
          </div>
				`,
        answer: [],
      },
    ],
  },
  4: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P116-E4',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title:
          "Complete the sentences with the verbs below. Use the present simple. <leftarrow name='5.7, 5.8'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 200,
    exerciseKey: 'img/FriendsPlus/Page116/E4/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
            <div style="display: flex; column-gap: 20px; color: rgb(246 140 31) ">
              <span>become</span>
              <span>drop</span>
              <span>have</span>
              <span>not hurry up</span>
              <span>not understand</span>
              <span>want</span>
            </div>
            <div style="margin-top: 20px; display: flex; column-gap: 20px">
              <strong>1</strong>
              <div>If you <input />, you’ll be late for school.</div>
            </div>
            <div style="margin-top: 20px; display: flex; column-gap: 20px">
              <strong>2</strong>
              <div>If I <input />time, I’ll phone you later.</div>
            </div>
            <div style="margin-top: 20px; display: flex; column-gap: 20px">
              <strong>3</strong>
              <div>She’ll earn a lot of money if she<input />a solicitor.</div>
            </div>
            <div style="margin-top: 20px; display: flex; column-gap: 20px">
              <strong>4</strong>
              <div>If you <input />your phone into water, it’ll stop working.</div>
            </div>
            <div style="margin-top: 20px; display: flex; column-gap: 20px">
              <strong>5</strong>
              <div>I’ll help you if you <input />the exercise.</div>
            </div>
            <div style="margin-top: 20px; display: flex; column-gap: 20px">
              <strong>6</strong>
              <div>She’ll have to work very hard if she <input />to go to university.</div>
            </div>
				`,
        answer: [`don't hurry up`, 'have', ' becomes', 'drop', `don't understand`, 'wants'],
      },
    ],
  },
  5: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P116-E5',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '5',
        title:
          "Complete the sentences with the will form of the verbs in brackets. <leftarrow name='5.7, 5.8'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 200,
    exerciseKey: 'img/FriendsPlus/Page116/E5/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="margin-top: 20px; display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>If you text me, I <input />(reply) immediately.</div>
          </div>
          <div style="margin-top: 20px; display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>We <input /> (not go) skiing this winter if there isn’t enough snow.</div>
          </div>
          <div style="margin-top: 20px; display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>If you come home late, your parents<input />(worry).</div>
          </div>
          <div style="margin-top: 20px; display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>You <input /> (have) a great time if you come to the party.</div>
          </div>
          <div style="margin-top: 20px; display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>If we leave before 10 o’clock, we <input />(not be) late.</div>
          </div>
          <div style="margin-top: 20px; display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>I <input />(lend) you my phone if you can’t find yours.</div>
          </div>
				`,
        answer: [`'ll reply`, `won't go`, ' will worry', `'ll have`, `won't be`, `'ll lend`],
      },
    ],
  },
  6: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P116-E6',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page116/E6/answerKey.png',
    titleQuestion: [
      {
        num: '',
        title:
          "<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>5D</span>Definning and non-defining relative clauses</strong> <br /> <strong>6</strong> Choose the correct answer (a–d). Sometimes more than one answer is possible.",
        color: 'black',
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          margin: '-2px',
        },
        limitSelect: 3,
        listWords: [
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; d ',
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; d ',
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; d ',
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; d ',
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; d ',
          'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; d ',
        ],
        answers: ['0-0', '1-0', '1-4', '1-12', '2-0', '2-4', '2-12', '3-0', '3-4', '3-12', '4-8', '5-0', '5-8'],
        initialValue: [],
      },
      Layout: /*html */ `
        <div style="display: flex; column-gap: 20px;">
          <strong>1</strong>
            <div style="display: flex; ">
              I met a boy <div style="border-bottom: 2px solid rgb(0 0 0 ); width: 50px; margin-bottom: 5px"></div> brother is in class
            </div>
        </div>
        <div style="position: relative; width: 1000px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="0" type="Circle"/></strong>
          <div style="position: absolute; left:60px; top: 0; z-index: 1">
            <span>whose</span>
            <span style="margin-left: 140px">that</span>
            <span style="margin-left: 170px">Which</span>
            <span style="margin-left: 140px">no pronoun</span>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px; margin-top: 50px">
          <strong>2</strong>
            <div style="display: flex; ">
              He’s the man <div style="border-bottom: 2px solid rgb(0 0 0 ); width: 50px; margin-bottom: 5px"></div> I saw on the train.
            </div>
        </div>
        <div style="position: relative; width: 1000px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="1" type="Circle"/></strong>
          <div style="position: absolute; left:60px; top: 0; z-index: 1">
            <span>who</span>
            <span style="margin-left: 160px">that</span>
            <span style="margin-left: 170px">which</span>
            <span style="margin-left: 140px">no pronoun</span>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px; margin-top: 50px">
          <strong>3</strong>
            <div style="display: flex; ">
              Is that the dictionary <div style="border-bottom: 2px solid rgb(0 0 0 ); width: 50px; margin-bottom: 5px"></div> you bought yesterday?
            </div>
        </div>
        <div style="position: relative; width: 1000px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="2" type="Circle"/></strong>
          <div style="position: absolute; left:60px; top: 0; z-index: 1">
            <span>which</span>
            <span style="margin-left: 140px">that</span>
            <span style="margin-left: 170px">where</span>
            <span style="margin-left: 140px">no pronoun</span>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px; margin-top: 50px">
          <strong>4</strong>
            <div style="display: flex; ">
              Show me the computer <div style="border-bottom: 2px solid rgb(0 0 0 ); width: 50px; margin-bottom: 5px"></div> you got for your birthday
            </div>
        </div>
        <div style="position: relative; width: 1000px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="3" type="Circle"/></strong>
          <div style="position: absolute; left:60px; top: 0; z-index: 1">
            <span>that</span>
            <span style="margin-left: 160px">which</span>
            <span style="margin-left: 150px">who</span>
            <span style="margin-left: 160px">no pronoun</span>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px; margin-top: 50px">
          <strong>5</strong>
            <div style="display: flex; ">
              Is that the café <div style="border-bottom: 2px solid rgb(0 0 0 ); width: 50px; margin-bottom: 5px"></div> you worked last summer?
            </div>
        </div>
        <div style="position: relative; width: 1000px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="4" type="Circle"/></strong>
          <div style="position: absolute; left:60px; top: 0; z-index: 1">
            <span>that</span>
            <span style="margin-left: 160px">which</span>
            <span style="margin-left: 150px">where</span>
            <span style="margin-left: 140px">no pronoun</span>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px; margin-top: 50px">
          <strong>6</strong>
            <div style="display: flex; ">
            New Year’s Day is the day <div style="border-bottom: 2px solid rgb(0 0 0 ); width: 50px; margin-bottom: 5px"></div> comes after New Year’s Eve
            </div>
        </div>
        <div style="position: relative; width: 1000px">
          <strong style="z-index: 2; position: absolute; left: 30px; top: 0;"><input id="5" type="Circle"/></strong>
          <div style="position: absolute; left:60px; top: 0; z-index: 1">
            <span>which</span>
            <span style="margin-left: 140px">who</span>
            <span style="margin-left: 170px">that</span>
            <span style="margin-left: 160px">no pronoun</span>
          </div>
        </div>
        `,
    },
  },
  7: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P116-E7',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '7',
        title:
          'Join the two sentences together with a relative clause. Use the pronouns <i>who, which, where</i> or <i>whose</i>.',
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 600,
    exerciseKey: 'img/FriendsPlus/Page116/E7/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              <div>That’s the man. / His job is to look after the garden.</div>
              <div  style="color: rgb(4 84 121); font-style: oblique;">That’s the man whose job is to look after the garden.</div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px; margin-top: 20px">
            <strong>2</strong>
            <div>
              <div>A hostel is a place. / You can stay there quite cheaply</div>
            <input/>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px; margin-top: 20px">
            <strong>3</strong>
            <div>
              <div>That’s the song. / I listened to it at Emma’s house.</div>
            <input/>
              
            </div>
          </div>
          <div style="display: flex; column-gap: 20px; margin-top: 20px">
            <strong>4</strong>
            <div>
              <div>He’s the man. / I saw him on TV last night.</div>
            <input/>
              
            </div>
          </div>
          <div style="display: flex; column-gap: 20px; margin-top: 20px">
            <strong>5</strong>
            <div>
              <div>That’s the hotel. / We stayed there last summer.</div>
            <input/>
              
            </div>
          </div>
          <div style="display: flex; column-gap: 20px; margin-top: 20px">
            <strong>6</strong>
            <div>
              <div>That’s the dog. / It barked all night. </div>
            <input/>
              
            </div>
          </div>
          <div style="display: flex; column-gap: 20px; margin-top: 20px">
            <strong>7</strong>
            <div>
              <div>She’s the girl. / I borrowed money from her.</div>
            <input/>
              
            </div>
          </div>
          <div style="display: flex; column-gap: 20px; margin-top: 20px">
            <strong>8</strong>
            <div>
              <div>That’s the boy. / His father owns the shop on the corner.</div>
            <input/>
              
            </div>
          </div>
        `,
        answer: [
          'A hostel is a place where you can stay quite cheaply.',
          `That's the song which I listened to at Emma's house.`,
          `He's the man who I saw on TV last night.`,
          `That's the hotel where we stayed last summer.`,
          `That's the dog which barked all night.`,
          `She's the girl who I borrowed money from.`,
          `That's the boy whose father owns the shop on the corner.`,
        ],
      },
    ],
  },
  8: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P116-E8',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '8',
        title:
          'Complete the text with the clauses below (a–f). Add the correct relative pronoun to the start of each clause.',
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 50,
    exerciseKey: 'img/FriendsPlus/Page6/E1/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
        <div style="display:flex; column-gap: 50px">
          <div style="width: 600px;  background-color: rgb(241 235 216); box-shadow: 10px 10px 5px rgb(203 202 202); padding: 20px; font-size: 22px">
            <span>
              We walked up the steps and knocked on the door, <input /> 
              For a minute or two, we heard nothing. Then Alex, <input />
              knocked again. Almost immediately the door opened. There stood a man in a formal, black suit <input />
              He smiled and invited us into the hall, <input />
              "Lord Bingley will be here shortly," said the man and left. I took out my phone, <input /> 
              , but the battery was completely dead. "You should've recharged it," said Alex, <input />
              . "I did," I said. "Something strange is happening." <input />
            </span>
          </div>
          <div >
            <div style="display: flex; column-gap: 20px; justify-conten: space-between">
              <strong>a</strong>
              <div>
                <input width="150px" />
                I always carried in my pocket
              </div>
            </div>
            <div style="display: flex; column-gap: 20px; justify-conten: space-between">
              <strong>b</strong>
              <div>
                <input width="150px" />
                was probably in fashion a hundred years ago
              </div>
            </div>
            <div style="display: flex; column-gap: 20px; justify-conten: space-between">
              <strong>c</strong>
              <div>
                <input width="150px" />
                  we stood and looked at the paintings on the wall
              </div>
            </div>
            <div style="display: flex; column-gap: 20px; justify-conten: space-between">
              <strong>d</strong>
              <div>
                <input width="150px" />
                was heavy, dark and wooden
              </div>
            </div>
            <div style="display: flex; column-gap: 20px; justify-conten: space-between">
              <strong>e</strong>
              <div>
                <input width="150px" />
                footsteps echoed loudly as he explored the impressive room
              </div>
            </div>
            <div style="display: flex; column-gap: 20px; justify-conten: space-between">
              <strong>f</strong>
              <div>
                <input width="150px" />
                had insisted on coming with me
              </div>
            </div>
          </div>
        </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
