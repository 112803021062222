import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 7',
    id: 'SB10-U7-P93-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: '/img/FriendsPlus/Page93/E1/key/answerKey.png',
    inputSize: 760,
    stylesTextInput: [],
    maxLength: 70,
    textAlign: 'left',
    titleQuestion: [
      {
        num: 'Reading',
        title: `
				Read the texts. Circle the correct option (a–d).
			  `,
        color: '#007393',
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
          fontWeight: 'bold',
          marginLeft: 25,
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          marginLeft: 25,
        },
        limitSelect: 1,
        listWords: [`a <br> b <br> c <br> d`, `a <br> b <br> c <br> d`, `a <br> b <br> c <br> d`],
        answers: ['2-0', '1-0', '0-12'],
        initialValue: [],
      },
      Layout: `
        <div style='background-color: rgb(229, 241, 205); margin-top: 20px; margin-left:0px; width:700px; border-radius: 15px; '>
          <div style='padding: 15px 25px 25px 25px;' >
            <div><b> Living cheaply in New Zealand</b></div>
            <div> I’ve been in New Zealand for six months, and, as 
far as I’m concerned, it’s the adventure capital 
of the world! Hiking, skydiving, caving, … the 
list goes on. And it doesn’t have to cost a lot to 
live here. When I arrived, I stayed in a hostel in 
Auckland. It was cheap, and I met lots of people. 
I joined up with a few of them and we started 
touring the country – buses are the cheapest way 
to get about. We stayed in affordable campsites 
in the most amazing places. Right now, I’m back 
in a hostel in Wellington. I’m taking a city break 
before I start touring again.</div>
          </div>
        </div>

      <b>1</b> <span> What does the author say about being in New Zealand?</span> <br>
      <div style="display: flex;">
        <div> <input type="Circle" id="0"></div>
        <div style="margin-left: 20px;margin-bottom: 20px;">
          <span> He’s planning to go home now.</span> <br>
          <span> He prefers travelling on his own.</span> <br>
          <span> He’s spent a lot of money.</span> <br>
          <span> He has seen both town and country. </span> <br>
        </div>
      </div>
        <div style='background-color: rgb(229, 241, 205); margin-top: 20px; margin-left:0px; width:700px; border-radius: 15px; '>
          <div style='padding: 15px 25px 25px 25px;' >
            <div><b> How to make money travelling</b></div>
            <div> After I left university, I set out on a three-month 
trip to Asia. The idea was to travel and then go 
home and find a job. That was two years ago, 
and I’m still here! But how, you may ask, can 
you make money and travel at the same time? I 
teach English. I also write a travel blog, and that 
makes money too. I don’t have a lot of money, 
but I can pay my bills. I have friends that do 
other things, such as hairdressing. There are lots 
of different ways to make money while you’re 
travelling. </div>
          </div>
        </div>
      <b>2</b> <span> What does the author say about his experience?</span> <br>
      <div style="display: flex;">
        <div> <input type="Circle" id="1"></div>
        <div style="margin-left: 20px;margin-bottom: 20px;">
          <span> He spent three months in Asia.</span> <br>
          <span> He now knows what job he wants to train for.</span> <br>
          <span> He became very rich.</span> <br>
          <span> He changed his plans.</span> <br>
        </div>
      </div>
        <div style='background-color: rgb(229, 241, 205); margin-top: 20px; margin-left:0px; width:700px; border-radius: 15px; '>
          <div style='padding: 15px 25px 25px 25px;' >
            <div><b>  So you want to be a billionaire? </b></div>
            <div> Self-made billionaires have one thing in 
common: They’re good at making money. Most 
of them will also tell you that this talent is only 
part of the story. So what else do you need to be 
a success in the business world? <i>So you want to 
be a billionaire?</i> helps you understand. The book 
looks at the careers and methods of the world’s 
most famous self-made billionaires. Each chapter 
examines how these billionaires made their 
fortune – and how you can do the same. Read 
<i>So you want to be a billionaire</i> and open the door 
to a great future! </div>
          </div>
        </div>
      <b>3</b> <span> The author of the text wants to</span> <br>
      <div style="display: flex;">
        <div> <input type="Circle" id="2"></div>
        <div style="margin-left: 20px;margin-bottom: 20px;">
          <span> sell people a book. </span> <br>
          <span> explain the life of a billionaire.</span> <br>
          <span> encourage readers to write a book about their lives.</span> <br>
          <span> describe how he became successful.</span> <br>
        </div>
      </div>
      `,
    },
  },
  2: {
    unit: 'Unit 7',
    id: 'SB10-U7-P93-E2',
    audio: '/Audios/Friends Global Grade 10 SB_CD3_13.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page93/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: 'Listening',
        title: `
				<br/><headphone1 name='3.13'></headphone1> Listen to the conversation twice. Decide if the statements are true or false. Write T or F.
			  `,
        color: '#007393',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 60,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: ['T', 'F'],
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 &emsp;</b></div>
          <div> Lucy has a relative living in Spain.</div>
          <b> <select id='0'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 &emsp;</b></div>
          <div> Ben prefers the town to the country.</div>
          <b> <select id='1'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 &emsp;</b></div>
          <div> Ben prefers to relax when he is on holiday.</div>
          <b> <select id='2'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 &emsp;</b></div>
          <div> Ben’s uncle can teach Lucy Spanish cooking.</div>
          <b> <select id='3'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 &emsp;</b></div>
          <div> Lucy tells Ben about a good hotel to stay at.</div>
          <b> <select id='4'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 6 &emsp;</b></div>
          <div> Lucy gives Ben some advice about a job.</div>
          <b> <select id='5'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 7 &emsp;</b></div>
          <div> Lucy is planning to return to Spain in September.</div>
          <b> <select id='6'></select>  </b>
        </div>
			  `,
        answer: ['F', 'T', 'T', 'F', 'F', 'T', 'F'],
      },
    ],
  },
  3: {
    unit: 'Unit 7',
    id: 'SB10-U7-P93-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '',
        title: `
        <b style='color: rgb(0, 115, 147)'>Speaking</b>
				<br/>&emsp;You are planning a two-day city break in June. Discuss the trip with a friend and agree on...
			  `,
        color: '#007393',
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <ul>
          <li>which city to go to</li>
          <li>tourist attractions you will visit</li>
          <li>where you will stay</li>
          <li>how you will get there.</li>
        </ul>
        <div>Tell the rest of the class what you have planned.</div>
			  `,
        answer: [],
      },
    ],
  },
  4: {
    unit: 'Unit 7',
    id: 'SB10-U7-P93-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '',
        title: `
        <b style='color: rgb(0, 115, 147)'>Writing</b>
				<br/>&emsp;Choose a tourist destination in Viet Nam. Write a blog post (120–150 words) about it giving advice to other tourists visiting there. Include information about
			  `,
        color: '#007393',
      },
    ],
    textareaStyle: { width: 1100 },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <ul>
          <li>the best time of year to visit,</li>
          <li>what tourist attractions to see, and which to avoid, if any,</li>
          <li>the best times of day to visit them,</li>
          <li>best places to get good Instagram photos.</li>
        </ul>
            <textarea id='0' rows='8'></textarea>
			  `,
        answer: [],
      },
    ],
  },
};

export default json;
