import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Science',
		id: 'FG10-S-P97-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page97/E1/Key/answerKey.png',
		component: T6,
		inputSize: 280,
		maxLength: 23,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. Describe the photos. 
          Where are the people? What do you think they are saying?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex; justify-content: center; align-items: center;">
            <img src="img/FriendsPlus/Page97/E1/1.jpg" style="max-width: 100%;" />
          </div>
				`,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Science',
		id: 'FG10-S-P97-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page97/E2/Key/answerKey.png',
		component: T6,
		inputSize: 150,
		maxLength: 12,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '2',
				title: `
          Read the <span style="font-weight: bold; color: rgb(196, 12, 70);">Listening Strategy</span>. 
          What are the intentions of the speakers in sentences 1-6? 
          Choose from the verbs below. 
          Match three of these sentences (1-6) with the photos.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; font-size: 20px;">
						<div style="width: 500px;">
							<div>
								<img src="img/FriendsPlus/Page97/E2/1.jpg" style="max-width: 100%; border-radius: 15px;" />
							</div>

							<div style="margin-top: 20px; padding: 10px; border-radius: 10px; border: 1px solid rgb(196, 12, 70);">
								<div style="display: flex;">
									<strong style="padding-right: 10px;">1</strong>
									<span>
										"Be careful not to touch the cooker. It’s hot."
									</span>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">2</strong>
									<span>
										"This is not the phone I ordered. 
										I said I would like one with a memory of 512 GB."
									</span>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">3</strong>
									<span>
										"I’d buy a new refrigerator if I were you. 
										This one is too small."
									</span>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">4</strong>
									<span>
										"Hello everyone. 
										I’ll show you our company’s latest gadget: an apple cutter."
									</span>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">5</strong>
									<span>
										"I’d like some information about the new wireless earphones."
									</span>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">6</strong>
									<span>
										"Don’t worry. You’ll get used to the new software soon."
									</span>				
								</div>
							</div>
						</div>

						<div style="margin-left: 20px; width: 500px;">
							<div style="padding: 10px; border-radius: 10px; border: 4px dotted rgb(196, 12, 70); background-color: rgb(248, 228, 226); box-shadow: 3px 3px 7px rgba(0,0,0,0.4);">
								<span style="font-weight: bold; color: rgb(196, 12, 70);">Listening Strategy</span>
								<div>
									You will sometimes have to identify the intentions of the speaker (e.g. to persuade, to warn, to inform, etc.). 
									Listen for phrases such as <i>I think you should, Be careful to, I’m going to tell you about</i> … that give you a clue to the speaker’s intentions.
								</div>
							</div>

							<div style="margin-top: 20px; display: flex; color: rgb(196, 12, 70);  padding: 10px; border-radius: 10px; border: 1px solid rgb(196, 12, 70);">
								<div>
									to apologise<br/>
									to complain<br/>
									to enquire<br/>
									to persuade<br/>
									to thank<br/>
								</div>
								<div style="margin-left: 20px;">
									to challenge</br>
									to describe</br>
									to entertain</br>
									to recommend</br>
									to warn</br>
								</div>
								<div style="margin-left: 20px;">
									to comfort</br>
									to encourage</br>
									to inform</br>
									to tell a story</br>
									to welcome</br>
								</div>
							</div>

							<div style="margin-top: 10px; padding: 10px; border-radius: 10px; border: 1px solid rgb(196, 12, 70);">
								<span style="font-weight: bold; font-style: italic;">Answer:</span>
								<div>
									<strong>Sentences:</strong> <br />
									<span style="padding-right: 10px; font-weight: bold;">1</span>#
									<span style="padding-right: 10px; padding-left: 15px; font-weight: bold;">2</span>#<br />
									<span style="padding-right: 10px; font-weight: bold;">3</span>#
									<span style="padding-right: 10px; padding-left: 15px; font-weight: bold;">4</span>#<br />
									<span style="padding-right: 10px; font-weight: bold;">5</span>#
									<span style="padding-right: 10px; padding-left: 15px; font-weight: bold;">6</span>#<br />
								</div>
								<div>
									<strong>Photos:</strong> <br />
									<span style="padding-right: 10px; font-weight: bold;">A</span><input id='6' width='50px' />
									<span style="padding-right: 10px; padding-left: 15px; font-weight: bold;">B</span><input id='7' width='50px' />
									<span style="padding-right: 10px; padding-left: 15px; font-weight: bold;">C</span><input id='8' width='50px' />
								</div>
							</div>
						</div>
					</div>
				`,
				answer: ['to warn', 'to complain', 'to recommend', 'to welcome', 'to enquire', 'to comfort', '2', '6', '4'],
			},
		],
	},
	3: {
		unit: 'Science',
		id: 'FG10-S-P97-E3',
		audio: 'Audios/Friends Global Grade 10 SB_CD3_16.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page97/E3/Key/answerKey.png',
		component: T6,
		inputSize: 160,
		maxLength: 7,
		titleQuestion: [
			{
				num: '3',
				title: `
				<audioimage name="3.16"></audioimage> 
					Listen and identify the intentions of the speakers. 
					Choose one verb in exercise 2.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; align-items: start;">
						<div style="width: 280px; padding: 10px; border-radius: 10px; border: 1px solid rgb(196, 12, 70);">
							<span style="font-weight: bold; font-style: italic;">Answer:</span>
							<div>
								<span style="padding-right: 10px; font-weight: bold;">1</span>#<br />
								<span style="padding-right: 10px; font-weight: bold;">2</span>#<br />
								<span style="padding-right: 10px; font-weight: bold;">3</span>#<br />
								<span style="padding-right: 10px; font-weight: bold;">4</span>#<br />
								<span style="padding-right: 10px; font-weight: bold;">5</span>#<br />
								<span style="padding-right: 10px; font-weight: bold;">6</span>#<br />
							</div>
						</div>

						<div style="margin-left: 30px; width: 500px; display: flex; color: rgb(196, 12, 70);  padding: 10px; border-radius: 10px; border: 1px solid rgb(196, 12, 70);">
							<div>
								to apologise<br/>
								to complain<br/>
								to enquire<br/>
								to persuade<br/>
								to thank<br/>
							</div>
							<div style="margin-left: 20px;">
								to challenge</br>
								to describe</br>
								to entertain</br>
								to recommend</br>
								to warn</br>
							</div>
							<div style="margin-left: 20px;">
								to comfort</br>
								to encourage</br>
								to inform</br>
								to tell a story</br>
								to welcome</br>
							</div>
						</div>
					</div>
				`,
				answer: ['to thank', 'to invite', 'to encourage', 'to complain', 'to comfort', 'to welcome'],
			},
		],
	},
	4: {
		unit: 'Science',
		id: 'FG10-S-P97-E4',
		audio: 'Audios/Friends Global Grade 10 SB_CD3_17.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page97/E4/Key/answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '4',
				title: `
					<audioimage name="3.17"></audioimage> 
					Listen to a doctor and a headteacher, 
					and circle the TWO correct options for each person.
				`,
				color: '#860052',
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				// initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
				initialWordStyle: {
					margin: '5px 0',
					border: 'none',
					borderColor: 'transparent',
					borderRadius: '50%',
					fontWeight: 'bold',
				},
				selectWordStyle: {
					border: 'solid 2px',
					borderColor: '#00a8ec',
					margin: '-2px',
					padding: '5px',
				},
				limitSelect: 2,
				listWords: ['a<br> b<br> c', 'a<br> b<br> c'],
				// listWords: ['before / after', 'makes_sense / does_not_make_sense', "has / doesn't_have"],
				answers: ['0-0', '0-2', '1-2', '1-4'],
				initialValue: [],
			},
			Layout: `
				<div style="padding-left: 10px;">
					<div style="display: flex;">
						<strong style="padding-right: 10px;">1</strong>
						<span>The doctor’s aims are to</span>				
					</div>
					<div style="margin-left: 30px; display: flex;">
						<div>
							<input id='0' type='Circle' />
						</div>
						<div style="margin-left: 10px;">
							<div>encourage the patient to set up a healthier working space and habit.</div>
							<div>warn the patient of the dangers of using digital devices before bedtime.</div>
							<div>recommend a course of treatment.</div>
						</div>
					</div>

					<div style="display: flex;">
						<strong style="padding-right: 10px;">2</strong>
						<span>The headteacher’s aims are to</span>				
					</div>
					<div style="margin-left: 30px; display: flex;">
						<div>
							<input id='1' type='Circle' />
						</div>
						<div style="margin-left: 10px;">
							<div>persuade students to use the multi-media classroom.</div>
							<div>inform people about the new multi-media classroom.</div>
							<div>thank people for helping the school to raise money.</div>
						</div>
					</div>
				</div>
      `,
		},
	},
	5: {
		unit: 'Science',
		id: 'FG10-S-P97-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page97/E5/Key/answerKey.png',
		component: T6,
		inputSize: 200,
		maxLength: 19,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
          Prepare a short speech to do one of these things. 
					Use the questions and phrases to help you.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>
									persuade someone to sign up for an account on a social network <br/>
									<i>What? How? Why will they enjoy it?</i> <br/>
									<span style="color: rgb(10, 111, 143);">Why don’t you...? I think you’ll enjoy... It’ll be (great fun). (George) is joining too.</span>
							</span>				
						</div>
						<div style="margin-top: 15px; display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>
									thank your uncle for the new desktop as your birthday present <br/>
									<i>How did it make you feel fascinated? What do you particularly enjoy?</i> <br/>
									<span style="color: rgb(10, 111, 143);">I had a (nice) surprise. It was so kind of you to... I really enjoyed the ...</span>
							</span>				
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
	6: {
		unit: 'Science',
		id: 'FG10-S-P97-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page97/E6/Key/answerKey.png',
		component: T6,
		inputSize: 200,
		maxLength: 17,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '6',
				title: `
					<span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. Practise your speech with your partner. 
					Then present your speech to the class.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>
									persuade someone to sign up for an account on a social network <br/>
									<i>What? How? Why will they enjoy it?</i> <br/>
									<span style="color: rgb(10, 111, 143);">Why don’t you...? I think you’ll enjoy... It’ll be (great fun). (George) is joining too.</span>
							</span>				
						</div>
						<div style="margin-top: 15px; display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>
									thank your uncle for the new desktop as your birthday present <br/>
									<i>How did it make you feel fascinated? What do you particularly enjoy?</i> <br/>
									<span style="color: rgb(10, 111, 143);">I had a (nice) surprise. It was so kind of you to... I really enjoyed the ...</span>
							</span>				
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
};

export default json;
