import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P126-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page126/E1/Key/answerKey.png',
		component: T6,
		textAlign: 'center',
		titleQuestion: [
			{
				num: '',
				title: `
          <div style="font-size: 30px;"><span style="color: rgb(146, 39, 144);">4G</span> In the street</div>
          <div><span style="padding-right: 10px;">1</span> Are all of the things below in the picture?</div>
        `,
				color: 'black',
			},
		],
		inputSize: 100,
		maxLength: 21,
		isHiddenCheck: true,
		questions: [
			{
				title: `
          <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>billboard</span><span>bus stop</span><span>fire hydrant</span><span>parking meter</span><span>pedestrian crossing</span>
            <span>phone box</span><span>postbox</span><span>road sign</span><span>roadworks</span><span>rubbish bin</span>
            <span>shop sign</span><span>shop window</span><span>street lamp</span><span>telegraph pole</span><span>traffic lights</span>
          </div>

          <div style="margin-top: 20px; display: flex; gap: 30px;">
            <div><img src="img/FriendsPlus/Page126/E1/1.jpg" style="max-width: 100%;" /></div>
            <div><span style="font-weight: bold; font-style: italic;">Answer: </span>#</div>
          </div>
				`,
				answer: ['Yes'],
				// answer: ['hall', "head teacher's office", 'stairs', 'playground', 'science lab'],
			},
		],
	},
	2: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P126-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page126/E2/Key/answerKey.png',
		component: T6,
		titleQuestion: [
			{
				num: '2',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          In pairs, ask and answer about the picture in exercise 1. 
          Use <i>next to, behind, in front of and between.</i>
        `,
				color: 'black',
			},
		],
		inputSize: 190,
		maxLength: 21,
		textareaStyle: { width: 800 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		hintBox: [
			{
				src: ['Where’s the fire hydrant?'],
				borderColor: 'rgb(146, 39, 144)',
				arrow: true,
				position: 2,
				width: 350,
			},
			{
				src: ['It’s next to the rubbish bin. / It’s in front of the billboard.'],
				borderColor: 'rgb(146, 39, 144)',
				arrow: true,
				position: 1,
				width: 350,
			},
		],
		questions: [
			{
				title: `
          <div><img src="img/FriendsPlus/Page126/E1/1.jpg" style="max-width: 100%;" /></div>
          <div style="margin-top: 30px; display: flex; gap: 50px;">
            <hintbox id='0'></hintbox>
            <hintbox id='1'></hintbox>
          </div>
        `,
				answer: [],
			},
		],
	},
	3: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P126-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page126/E3/Key/answerKey.png',
		component: T6,
		titleQuestion: [
			{
				num: '',
				title: `
          <div style="font-size: 30px;"><span style="color: rgb(146, 39, 144);">4H</span> Climate change</div>
          <div><span style="padding-right: 10px;">3</span> Complete the collocations with the verbs.</div>
        `,
				color: 'black',
			},
		],
		stylesTextInput: {
			textAlign: 'center',
		},
		inputSize: 110,
		// maxLength: 9,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		questions: [
			{
				title: `
          <div>
            <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
              <span>emit</span><span>rely on</span><span style="text-decoration: line-through;">use up</span>
            </div>

            <div style="display: flex; align-items: center; border: 1px solid rgb(146, 39, 144);">
              <div style="width: 150px; text-align: center;"><img src="img/FriendsPlus/Page126/E3/1.jpg" style="max-width: 100%;" /></div>
              <div style="width: 400px; border-left: 1px solid rgb(146, 39, 144);">
                <div style="padding: 0 20px;"><sup>1</sup><span style="color: rgb(10, 111, 143);">use up</span> natural resources</div>
                <div style="padding: 0 20px; border-top: 1px solid rgb(146, 39, 144);"><sup>2</sup># fossil fuels</div>
                <div style="padding: 0 20px; border-top: 1px solid rgb(146, 39, 144);"><sup>3</sup># carbon dioxide</div>
              </div>
            </div>
          </div>

          <div style="margin-top: 20px;">
            <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
              <span>invest in</span><span style="text-decoration: line-through;">protect</span><span>reduce</span><span>reduce</span><span>save</span>
            </div>

            <div style="display: flex; align-items: center; border: 1px solid rgb(146, 39, 144);">
              <div style="width: 150px; text-align: center;"><img src="img/FriendsPlus/Page126/E3/2.jpg" style="max-width: 100%;" /></div>
              <div style="width: 400px; border-left: 1px solid rgb(146, 39, 144);">
                <div style="padding: 0 20px;"><sup>4</sup># your carbon footprint</div>
                <div style="padding: 0 20px; border-top: 1px solid rgb(146, 39, 144);"><sup>5</sup># renewable energy</div>
                <div style="padding: 0 20px; border-top: 1px solid rgb(146, 39, 144);"><sup>6</sup># carbon emissions</div>
                <div style="padding: 0 20px; border-top: 1px solid rgb(146, 39, 144);"><sup>7</sup><span style="color: rgb(10, 111, 143);">protect</span> endangered species</div>
                <div style="padding: 0 20px; border-top: 1px solid rgb(146, 39, 144);"><sup>8</sup># energy</div>
                
              </div>
            </div>
          </div>
        `,
				answer: ['rely on', 'emit', 'reduce', 'invest in', 'reduce', 'save'],
			},
		],
	},
	4: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P126-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page126/E4/Key/answerKey.png',
		component: T6,
		textAlign: 'center',
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '4',
				title: `
          Complete the sentences with the correct form of collocations from exercise 3.
        `,
				color: 'black',
			},
		],
		inputSize: 330,
		// maxLength: 21,
		questions: [
			{
				title: `
          <div style="display: flex; flex-direction: column; align-items: center;">
            <div>
              <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
                <span>emit</span><span>rely on</span><span style="text-decoration: line-through;">use up</span>
              </div>

              <div style="width: 550px; display: flex; align-items: center; border: 1px solid rgb(146, 39, 144);">
                <div style="width: 150px; text-align: center;"><img src="img/FriendsPlus/Page126/E3/1.jpg" style="max-width: 100%;" /></div>
                <div style="width: 400px; border-left: 1px solid rgb(146, 39, 144);">
                  <div style="padding: 0 20px;"><sup>1</sup><span style="color: rgb(10, 111, 143);">use up</span> natural resources</div>
                  <div style="padding: 0 20px; border-top: 1px solid rgb(146, 39, 144);"><sup>2</sup>_______ fossil fuels</div>
                  <div style="padding: 0 20px; border-top: 1px solid rgb(146, 39, 144);"><sup>3</sup>_______ carbon dioxide</div>
                </div>
              </div>
            </div>

            <div style="margin-top: 20px;">
              <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
                <span>invest in</span><span style="text-decoration: line-through;">protect</span><span>reduce</span><span>reduce</span><span>save</span>
              </div>

              <div style="width: 550px; display: flex; align-items: center; border: 1px solid rgb(146, 39, 144);">
                <div style="width: 150px; text-align: center;"><img src="img/FriendsPlus/Page126/E3/2.jpg" style="max-width: 100%;" /></div>
                <div style="width: 400px; border-left: 1px solid rgb(146, 39, 144);">
                  <div style="padding: 0 20px;"><sup>4</sup>_______ your carbon footprint</div>
                  <div style="padding: 0 20px; border-top: 1px solid rgb(146, 39, 144);"><sup>5</sup>_______ renewable energy</div>
                  <div style="padding: 0 20px; border-top: 1px solid rgb(146, 39, 144);"><sup>6</sup>_______ carbon emissions</div>
                  <div style="padding: 0 20px; border-top: 1px solid rgb(146, 39, 144);"><sup>7</sup><span style="color: rgb(10, 111, 143);">protect</span> endangered species</div>
                  <div style="padding: 0 20px; border-top: 1px solid rgb(146, 39, 144);"><sup>8</sup>_______ energy</div>
                  
                </div>
              </div>
            </div>
          </div>

          <div style="margin-top: 20px;">
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">1</div>
              <div>The government should # such as wind and solar power.</div>
            </div>
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">2</div>
              <div>You can # by insulating your house, turning off lights and using public transport.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">3</div>
              <div>At the moment, we # such as coal, shale gas and oil to give us energy.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">4</div>
              <div>Factories # and other greenhouse gases into the atmosphere.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">5</div>
              <div>How can we # such as orang-utans, gorillas and tigers?</div>
            </div>	
          </div>
        `,
				answer: [
					'invest in renewable energy',
					'save energy',
					'rely on fossil fuels',
					'emit carbon dioxide',
					' protect endangered species',
				],
			},
		],
	},
	5: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P126-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page126/E5/Key/answerKey.png',
		component: T6,
		titleQuestion: [
			{
				num: '',
				title: `
          <div style="font-size: 30px;"><span style="color: rgb(146, 39, 144);">5G</span> Personal qualities</div>
          <div><span style="padding-right: 10px;">1</span> Match the adjectives with the descriptions of the people.</div>
        `,
				color: 'black',
			},
		],
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 22,
		},
		inputSize: 220,
		maxLength: 18,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		questions: [
			{
				title: `
          <div style="font-size: 22px;">
            <div style="margin-left: 15px; font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
              <span style="text-decoration: line-through;">confident</span><span>enthusiastic</span><span>friendly</span>
              <span>good at communicating</span><span>honest</span><span>physically fit</span><span>sensitive</span>
            </div>

            <div style="margin-top: 10px;">
              <div style="display: flex; gap: 20px;">
                <div style="width: 35px; font-weight: bold; text-align: right;">1</div>
                <div>She feels sure that she can do the job well. <span style="color: rgb(10, 111, 143);">confident</span></div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 35px; font-weight: bold; text-align: right;">2</div>
                <div>He smiles and talks to everyone at work. #</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 35px; font-weight: bold; text-align: right;">3</div>
                <div>She’s good at explaining things to people. #</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 35px; font-weight: bold; text-align: right;">4</div>
                <div>He always tells the truth. #</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 35px; font-weight: bold; text-align: right;">5</div>
                <div>She’s always excited and interested in new ideas. #</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 35px; font-weight: bold; text-align: right;">6</div>
                <div>He is active and strong. #</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 35px; font-weight: bold; text-align: right;">7</div>
                <div>She understands other people’s feelings. #</div>
              </div>	
            </div>

            <div style="margin-top: 20px; margin-left: 15px; font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
              <span style="text-decoration: line-through;">flexible</span><span>hard-working</span><span>organised</span>
              <span>outgoing</span><span>patient</span><span>punctual</span><span>reliable</span>
            </div>

            <div style="margin-top: 10px;">
              <div style="display: flex; gap: 20px;">
                <div style="width: 35px; font-weight: bold; text-align: right;">8</div>
                <div>He doesn’t mind if things change at work. <span style="color: rgb(10, 111, 143);">flexible</span></div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 35px; font-weight: bold; text-align: right;">9</div>
                <div>She plans her work very well. #</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 35px; font-weight: bold; text-align: right;">10</div>
                <div>He always arrives at work on time. #</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 35px; font-weight: bold; text-align: right;">11</div>
                <div>She likes being with other people. #</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 35px; font-weight: bold; text-align: right;">12</div>
                <div>He works very hard. #</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 35px; font-weight: bold; text-align: right;">13</div>
                <div>You can trust her to do her job well. #</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 35px; font-weight: bold; text-align: right;">14</div>
                <div>He doesn’t mind waiting. #</div>
              </div>	
            </div>
          </div>
        `,
				answer: [],
			},
		],
	},
	6: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P126-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page126/E6/Key/answerKey.png',
		component: T6,
		titleQuestion: [
			{
				num: '2',
				title: `
          How many adjectives do you know with the opposite meanings of those in exercise 1? 
          You can use the prefixes <i>un-, dis-, in-</i> and <i>im-</i> with some of them.
        `,
				color: 'black',
			},
		],
		textareaStyle: { width: 600 },
		inputSize: 220,
		maxLength: 18,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		questions: [
			{
				title: `
          <div style="padding: 10px; border-radius: 10px; border: 1px solid rgb(146, 39, 144);">
            <div style="margin-bottom: 15px; font-weight: bold; font-style: italic;">Adjectives in exercise 1:</div>  

            <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
              <span>confident</span><span>enthusiastic</span><span>friendly</span>
              <span>good at communicating</span><span>honest</span><span>physically fit</span><span>sensitive</span>
              <span>flexible</span><span>hard-working</span><span>organised</span>
              <span>outgoing</span><span>patient</span><span>punctual</span><span>reliable</span>
            </div>
          </div>

          <div style="margin-top: 20px;">
            <span style="color: rgb(10, 111, 143);">patient - impatient, hard-working - ...</span>
          </div>

          <div><textarea id="0" rows="10"></textarea></div>
        `,
				answer: [],
			},
		],
	},
	7: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P126-E7',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page126/E7/Key/answerKey.png',
		component: T6,
		titleQuestion: [
			{
				num: '3',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Which adjectives in exercise 1 a) describe you and b) do not describe you?
        `,
				color: 'black',
			},
		],
		inputSize: 190,
		maxLength: 21,
		textareaStyle: { width: 800 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		hintBox: [
			{
				src: ['I’m very … and I’m sometimes …'],
				borderColor: 'rgb(146, 39, 144)',
				arrow: true,
				position: 2,
				width: 400,
			},
			{
				src: ['I’m not very …'],
				borderColor: 'rgb(146, 39, 144)',
				arrow: true,
				position: 1,
				width: 270,
			},
		],
		questions: [
			{
				title: `
          <div style="padding: 10px; border-radius: 10px; border: 1px solid rgb(146, 39, 144);">
            <div style="margin-bottom: 15px; font-weight: bold; font-style: italic;">Adjectives in exercise 1:</div>  

            <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
              <span>confident</span><span>enthusiastic</span><span>friendly</span>
              <span>good at communicating</span><span>honest</span><span>physically fit</span><span>sensitive</span>
              <span>flexible</span><span>hard-working</span><span>organised</span>
              <span>outgoing</span><span>patient</span><span>punctual</span><span>reliable</span>
            </div>
          </div>

          <div style="margin-top: 30px; display: flex; gap: 50px;">
            <hintbox id='0'></hintbox>
            <hintbox id='1'></hintbox>
          </div>
        `,
				answer: [],
			},
		],
	},
	8: {
		unit: 'Vocabulary Builder',
		id: 'SB10-V-P126-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page126/E5/Key/answerKey.png',
		component: T6,
		titleQuestion: [
			{
				num: '',
				title: `
          <div style="font-size: 30px;"><span style="color: rgb(146, 39, 144);">5H</span> Formal language</div>
          <div><span style="padding-right: 10px;">4</span> Replace the underlined words in the sentences with the more formal words below.</div>
        `,
				color: 'black',
			},
		],
		stylesTextInput: {
			textAlign: 'center',
		},
		inputSize: 140,
		maxLength: 11,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		questions: [
			{
				title: `
          <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
              <span>available</span><span>discuss</span><span>manager</span>
              <span>many</span><span>obtain</span><span>opportunity</span>
              <span>possess</span><span>require</span><span>sit</span>
              <span>succeed</span><span>telephone</span><span>wish</span>
            </div>

            <div style="margin-top: 10px;">
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">1</div>
                <div>Please <u>call</u> me if you <u>need</u> more information. #, #</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">2</div>
                <div>I can <u>get</u> a reference from my current <u>boss</u>. #, #</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">3</div>
                <div>I <u>have got</u> the personal qualities that are necessary to <u>do well</u> in business. #, #</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">4</div>
                <div>I <u>do</u> my exams in June and will be <u>free</u> to start work immediately afterwards. #, #</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">5</div>
                <div>There are <u>lots of</u> reasons why I <u>would like</u> to become a teacher. #, #</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">6</div>
                <div>I would be grateful for the <u>chance</u> to <u>chat about</u> my application in person. #, #</div>
              </div>
            </div>
        `,
				answer: [],
			},
		],
	},
}

export default json
