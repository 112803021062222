import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P38-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page38/E1/key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: ` <span style='color:rgb(193, 26, 101);'>PROJECT </span>Write and perform your own TV advert in groups. Choose from the products below or use your own ideas. Think of five adjectives to describe the product and make up a good slogan.</i>.
			  `,
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 1000 },
    titleImage: '',
    questionImage: [],
    questions: [
      {
        title: `
				  <div style="margin-left: 20px;"><img src='img/FriendsPlus/Page38/E1/1.jpg' alt=''></img></div>
					<div style='display: flex;'><b >1.</b> Do you know the game show in the photo?</div>
					<div><textarea id="0" rows="1"></textarea></div>
					<div style='display: flex;'><b >2.</b> What happens on the show?</div>
					<div><textarea id="1" rows="2"></textarea></div>
			  `,
        answer: [
          'The game show is Who Wants to be a Millionaire?',
          'The contestant has to pick the right answer. When they get an answer right the money goes up.For help, they can phone a friend, ask the audience or delete two wrong options.',
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 3',
    id: 'SB10-U3-P38-E2',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_37.mp3',
    video: '',
    component: Circle_Write,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page38/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `
				<headphone1 name='1.37' src=''></headphone1> Read and listen to the dialogue and circle the correct verbs.
			  `,
        color: '#92278f',
      },
    ],
    question: {
      Write: {
        inputStyle: {
          borderBottom: '',
          color: '#000',
          textAlign: 'center',
        },
        answers: [],
        initialValue: [],

        textAlign: 'center',
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: '#000',
          fontWeight: 700,
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: [
          'must / mustn’t',
          'mustn’t / needn’t',
          'must / needn’t',
          'don’t_have_to / must',
          'don’t_have_to / mustn’t',
        ],
        answers: ['0-0', '1-4', '2-0', '3-0', '4-0'],
        initialValue: [],
      },
      Layout: `
          <div style='display: flex;'>
            <div style='width: 100px;'><b> Host </b></div>
            <div style='width: 1000px;'>This question is for £125,000. Jill, in the sitcom <i>How I Met your Mother</i>, what is Ted Mosby’s job? Is it A, lawyer, B, designer, C, architect, or D, reporter?</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 100px;'><b> Jill </b></div>
            <div style='width: 1000px;'>Um … I’d like to ask the audience, please.</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 100px;'><b> Host </b></div>
            <div style='width: 1000px;'>OK. Audience, you <b> <sup>1</sup><input id='0' type='Circle' /></b> answer A, B, C or D on your keypads … now! … OK, 30% think it’s B and 50% think it’s C. But you <b><sup>2</sup><input id='1' type='Circle' /></b> take their advice.</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 100px;'><b> Jill </b></div>
            <div style='width: 1000px;'>Mmm, I’m not sure. I think I’ll phone my friend Danny.</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 100px;'><b> Host </b></div>
            <div style='width: 1000px;'>OK, Danny. Jill has a question worth £125,000. You<b> <sup>3</sup><input id='2' type='Circle' /></b> answer within thirty seconds. OK? </div>
          </div>
          <div style='display: flex;'>
            <div style='width: 100px;'><b> Danny </b></div>
            <div style='width: 1000px;'>Um, I think it’s B … or maybe C … No, it’s D. </div>
          </div>
          <div style='display: flex;'>
            <div style='width: 100px;'><b> Host </b></div>
            <div style='width: 1000px;'>Sorry, you’re out of time. OK Jill, you <b><sup>4</sup><input id='3' type='Circle' /></b> answer the question. You can walk away with £64,000. </div>
          </div>
          <div style='display: flex;'>
            <div style='width: 100px;'><b> Jill </b></div>
            <div style='width: 1000px;'>I’m going to answer C.</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 100px;'><b> Host </b></div>
            <div style='width: 1000px;'>Final answer?</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 100px;'><b> Jill </b></div>
            <div style='width: 1000px;'>No. Yes. No.</div>
          </div>
          <div style='display: flex;'>
            <div style='width: 100px;'><b> Host </b></div>
            <div style='width: 1000px;'>You <b><sup>5</sup><input id='4' type='Circle' /></b>  change your mind! </div>
          </div>
          <div style='display: flex;'>
            <div style='width: 100px;'><b> Jill </b></div>
            <div style='width: 1000px;'>The answer is B. Final answer</div>
          </div>
					<div style='display: flex;'>
            <div style='width: 100px;'><b> Host </b></div>
            <div style='width: 1000px;'>Oh, Jill, the answer is C, architect. You’ve just lost £32,000! </div>
          </div>

      `,
    },
  },
  3: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P38-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page38/E3/key/answerKey.png',
    inputSize: 170,
    textAlign: 'center',
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: '3',
        title: `
				Read the <span style='color:rgb(244, 143, 32);'>Learn this!</span> box and complete the rules. Use <i>must, mustn’t, needn’t</i> and <i>don’t have to</i>. Use your answers to exercise 2 to help you.
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
                <div style='border-radius: 20px; border: 2px solid rgb(244, 143, 32); '>
                  <div style='margin:15px;'>
                    <div><b style='color:rgb(244, 143, 32);'>LEARN THIS!</b><b><i> must, mustn’t</i> and <i>needn’t / don’t have to</i></b></div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> a </b></div>
                      <div>We use <b><sup>1</sup>#</b>  to say that something is necessary or very important to do.</div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> b </b></div>
                      <div>We use <b><sup>2</sup>#</b> and  <b><sup>3</sup>#</b> to say that something is not necessary.</div>
                    </div>
										<div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> c </b></div>
                      <div>We use <b><sup>4</sup>#</b> to say that something is prohibited (a negative order).</div>
                    </div>
										<div>Note: We can also use passive forms after modal verbs.</div>
										<span style='color:rgb(124, 162, 211)'>Helmets must be worn at all times.</span>
                  </div>
                </div>
        `,
        answer: ['must', "needn't", "don't have to", "mustn't"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P38-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page38/E4/key/answerKey.png',
    inputSize: 600,
    textAlign: 'left',
    // isHiddenCheck: true,
    titleQuestion: [
      {
        num: '4',
        title: `
				What are the rules for using mobiles at your school? Write sentences with must, mustn’t and needn’t and the phrases below. If you can, add ideas of your own.
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `

								
                <div style='border-radius: 20px; border: 2px solid rgb(244, 143, 32); '>
                  <div style='margin:15px;'>
                    <div><b style='color:rgb(244, 143, 32);'>LOOK OUT!</b></div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> a </b></div>
											<div>
                     	 	<div><i>must</i> and <i>have</i> to are very similar. However, <i>must</i> often expresses the feelings of the speaker.</div>
												<span style='color:rgb(124, 162, 211)'>You must do more exercise. (That’s my strong opinion.)</span>
                     	 	<div><i>have</i> to often expresses an external obligation.</div>
												<span style='color:rgb(124, 162, 211)'>You have to wear a helmet. (It’s the law.)</span>
											</div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> b </b></div>
                      <div><i>mustn’t</i> and <i>don’t have to</i> do NOT have the same meaning. <i>don’t have to</i> = <i>needn’t</i>, but <i>mustn’t</i> means ‘it is prohibited’.</div>
                    </div>
                  </div>
                </div>
								<div>
									<div><b style='color:rgb(244, 143, 32);'>Using mobiles</b></div>
									<span style='color:rgb(244, 143, 32)'>bring our mobiles to school</span>
									<span style='color:rgb(244, 143, 32)'>leave them in our bags </span>
									<div>#</div>
									<span style='color:rgb(244, 143, 32)'>put them on our desk during lessons </span>
									<div>#</div>
									<span style='color:rgb(244, 143, 32)'>send texts during lessons </span>
									<div>#</div>
									<span style='color:rgb(244, 143, 32)'>keep them on silent all day </span><br>
									<span style='color:rgb(124, 162, 211)'>Mobiles must be kept on silent all day.</span>
								</div>
        `,
        answer: [
          'We must leave them in our bags. / Mobiles must be left in our bags.',
          "We mustn't put them on our desk during lessons",
          "We mustn't send texts during lessons. / Texts mustn't be sent during lessons.",
        ],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P38-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page38/E5/key/answerKey.png',
    inputSize: 500,
    textAlign: 'left',
    // isHiddenCheck: true,
    titleQuestion: [
      {
        num: '5',
        title: `
				Read the <span style='color:rgb(244, 143, 32);'>Look out!</span> box. Then, using the words in brackets, rewrite the sentences so that they have the same meaning. 
			  `,
        color: '#92278f',
      },
    ],

    questions: [
      {
        title: `

								
                <div style='border-radius: 20px; border: 2px solid rgb(244, 143, 32); margin: 20px 100px 30px 30px;'>
                  <div style='margin:15px;'>
                    <div><b style='color:rgb(244, 143, 32);'>LOOK OUT!</b></div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> a </b></div>
											<div>
                     	 	<div><i>must</i> and <i>have</i> to are very similar. However, <i>must</i> often expresses the feelings of the speaker.</div>
												<span style='color:rgb(124, 162, 211)'>You must do more exercise. (That’s my strong opinion.)</span>
                     	 	<div><i>have</i> to often expresses an external obligation.</div>
												<span style='color:rgb(124, 162, 211)'>You have to wear a helmet. (It’s the law.)</span>
											</div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> b </b></div>
                      <div><i>mustn’t</i> and <i>don’t have to</i> do NOT have the same meaning. <i>don’t have to</i> = <i>needn’t</i>, but <i>mustn’t</i> means ‘it is prohibited’.</div>
                    </div>
                  </div>
                </div>
								<div>
									<div><b>1</b> It isn’t necessary for you to help me. (needn’t)</div>
									<span style='color:rgb(124, 162, 211)'>You needn’t help me.</span>
									<div><b>2</b> It’s compulsory for us to wear school uniforms. (have to)</div>
									<div>#</div>
									<div><b>3</b> You aren’t allowed to talk in the exam. (mustn’t)</div>
									<div>#</div>
									<div><b>4</b> It’s important that you listen to me. (must)</div>
									<div>#</div>
									<div><b>5</b> You needn’t write the answer. (have to)</div>
									<div>#</div>
								</div>
        `,
        answer: [
          'We have to wear school uniform.',
          'You mustn’t talk in the exam',
          'You must listen to me.',
          'You don’t have to write the answer.',
        ],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P38-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '6',
        title: `
				<b style='color:rgb(0,147,70);'>SPEAKING</b> Work in pairs. Ask and answer about the rules of a game show you know. Use <i>must, mustn’t</i> and <i>needn’t / don’t have to.</i> Can your partner guess the name of your game?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    recorder: true,
    questionImage: [],
    textAlign: 'center',
    textareaStyle: { width: 1100 },
    hideBtnFooter: true,
    hintBox: [
      {
        src: ['Can my partner help me?'],
        arrow: true,
        position: 1,
        width: 400,
        borderColor: '#f78153',
      },
      {
        src: ['No, your partner mustn’t help you.'],
        arrow: true,
        position: 2,
        width: 500,
        borderColor: '#f78153',
      },
    ],
    questions: [
      {
        title: `
				<div style='margin: 20px 100px 10px 150px;'><hintBox id='0' ></hintBox></div>
				<div style='margin: 20px 0 50px 350px;'><hintBox id='1' ></hintBox></div>
				<textarea id="0" rows="4"> </textarea>
			  `,
        answer: [],
      },
    ],
  },
};

export default json;
