import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Weather',
    id: 'FG10-W-P54-E1',
    exerciseKey: '/img/FriendsPlus/Page54/E1/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 180,
    maxLength: 20,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontWeight: 'bold',
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '1',
        title: `
					<span>Look at the photos. Match them with the events below.</span>

        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
				<div>
					<img src="/img/FriendsPlus/Page54/E1/1.jpg" style="width: 850px;">
				</div>

				<div style="margin-top: 30px;">
				<b><span style="color:rgb(45,206,137)">parade river market street market</span></b><br/>
					<b>Answers:</b>
				</div>
				<div style="gap: 30px; display: flex">
					<span style="margin-top: 10px;"><b>A</b> <span style="margin-right: 10px;">#</span></span><br>
					<span style="margin-top: 10px;"><b>B</b> <span style="margin-right: 10px;">#</span></span><br>
					<span style="margin-top: 10px;"><b>C</b> <span style="margin-right: 10px;">#</span></span><br>
				</div>
        `,
        answer: ['street market', 'river market', 'parade'],
      },
    ],
  },
  2: {
    unit: 'Weather',
    id: 'FG10-W-P54-E2',
    exerciseKey: '/img/FriendsPlus/Page54/E2/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    textAlign: 'center',
    inputSize: 250,
    maxLength: 19,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: '2',
        title: `
      <span style="color: rgb(146,39,143);">VOCABULARY</span> Find four of the things in the list below in photo C.

        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
				<div style="margin-top:10px">
					<img src="/img/FriendsPlus/Page54/E2/E2.jpg" alt="" style="width:900px">
				</div>
				<div style="margin-top: 20px; display: flex; color:green;">
						<div style="margin-right:20px;">
							<b>In the street</b> <br>
							pedestrian crossing  <br>
							traffic lights  <br>
						</div>
						<div style="margin-right:20px;">
							bus stop<br>
							road sign<br>
							pavement<br>
						</div>
						<div>
							lamp post<br>
							shop sign
						</div>
   			</div>
				<div style="margin-top:30px"><b>Answers:</b>
					<span style="margin-right: 10px;">#,#,#,#</span>
				</div>
        `,
        answer: ['pedestrian crossing ', ' road sign ', ' shop sign ', ' traffic light'],
      },
    ],
  },
  3: {
    unit: 'Weather',
    id: 'FG10-W-P54-E3',
    exerciseKey: '/img/FriendsPlus/Page54/E3/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_09.mp3',
    video: '',
    component: T6,
    inputSize: 220,
    maxLength: 39,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '3',
        title: `
					<audioimage name="2.09"></audioimage>
					Read the <span style="color:rgb(45,206,137) ;">Speaking Strategy</span>. Then listen to
					two candidates comparing the photos. Answer the
					questions.

        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
				<div>
					<img src="/img/FriendsPlus/Page54/E3/01.png" alt="" style=" width:600px"/>
				</div>
				<div style=" margin-top:20px">
					<span><b>1.</b> Which two photos does each student compare?</span><br>
					<div>
						<span><b>Candidate 1:</b><span style="margin-right: 10px;">#</span></span>
					</div>
					<div>
						<span><b>Candidate 2:</b><span style="margin-right: 10px;">#</span></span>
					</div>
					<span><b>2.</b> Do they both follow all of the advice?</span>
					<div>
						<span><span style="margin-right: 10px;">#</span></span>
					</div>
				</div>
				<div style="margin-top:10px">
					<img src="/img/FriendsPlus/Page54/E3/1.jpg" alt="" style="width:900px">
				</div>
        `,
        answer: ['photos A and B', 'photos B and C', 'Yes, they do.'],
      },
    ],
  },
  4: {
    unit: 'Weather',
    id: 'FG10-W-P54-E4',
    exerciseKey: '/img/FriendsPlus/Page54/E4/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_09.mp3',
    video: '',
    component: T6,
    inputSize: 550,
    maxLength: 100,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: '4',
        title: `
          <audioimage name="2.09"></audioimage>
          Read the <span style="color:rgb(45,206,137) ;">Learn this!</span> box. Then listen again
          to the first candidate and complete the deductions
          he makes.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
         <div>
          <img src="/img/FriendsPlus/Page54/E4/E4.png" alt="">
        </div>
        <div style="margin-top:20px;">
          <div>
              <b>1</b>
              <span style="margin-left: 10px"> It can’t be in Britain because</span> #
          </div>
          <div>
              <b>2</b>
              <span style="margin-left: 10px"> It must be quite warm because</span> #
          </div>
          <div>
              <b>3</b>
              <span style="margin-left: 10px"> It must be a cold night because</span> #
          </div>
        </div>
        `,
        answer: [
          `some of the shop signs aren't in English.`,
          `they are wearing raincoats.`,
          `the people are wearing winter coats and scarves.`,
        ],
      },
    ],
  },
  5: {
    unit: 'Weather',
    id: 'FG10-W-P54-E5',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '5',
        title: `
          <span style="color:rgb(146,39,143) ;">KEY PHRASES</span> Read and check the meaning of all the phrases.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="border-left: 5px solid rgb(192, 232, 198); padding: 0 15px;">
        <b>
          <span style="color: rgb(45,206,137);">Comparing and contrasting</span> <br>
          <span>Similarities</span>
        </b> 
        <div>
          <div>The common theme in the photos is …</div>
          <div>I / You can see … in both photos. <br>Both photos show … </div>
          <div>… looks / seems quite similar in both photos.</div>
        </div>
      <div>
        <div><b>Differences</b></div>
        <div>The first photo shows … , but the second photo shows …</div>
        <div>In the first photo … , whereas in the second photo … <br>One obvious difference is (that) … </div>
        <div>The main difference between the photos is (that) … <br>Unlike the first photo, the second photo shows …<br>
          In the first photo … In contrast, in the second photo …
        </div>
      </div>
        </div>
        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: 'Weather',
    id: 'FG10-W-P54-E6',
    exerciseKey: '/img/FriendsPlus/Page54/E6/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_10.mp3',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '6',
        title: `
      <audioimage name="2.10"></audioimage> Listen to the second candidate again.
        `,
        color: '#860052',
      },
    ],
    selectStyle: {
      color: '#00ADFE',
      width: 730,
      // textAlign: 'center',
    },
    // selectOptionRandom: true,
    selectOptionValues: [
      `The common theme in the photos is …`,
      `I / You can see … in both photos.`,
      `Both photos show … `,
      `… seems quite similar in both photos.`,
      `The first photo shows … , but the second photo shows …`,
      `In the first photo … , whereas in the second photo …`,
      `One obvious difference is (that) … `,
      `The main difference between the photos is (that) …`,
      `Unlike the first photo, the second photo shows …`,
      `In the first photo … In contrast, in the second photo …`,
    ],

    questions: [
      {
        title: `
        <div style="border-left: 5px solid rgb(192, 232, 198); padding: 0 15px;">
        <b>
          <span style="color: rgb(45,206,137);">Comparing and contrasting</span> <br>
          <span>Similarities</span>
        </b> 
        <div>
          <div>The common theme in the photos is …</div>
          <div>I / You can see … in both photos. <br>Both photos show … </div>
          <div>… looks / seems quite similar in both photos.</div>
        </div>
      <div>
        <div><b>Differences</b></div>
        <div>The first photo shows … , but the second photo shows …</div>
        <div>In the first photo … , whereas in the second photo … <br>One obvious difference is (that) … </div>
        <div>The main difference between the photos is (that) … <br>Unlike the first photo, the second photo shows …<br>
          In the first photo … In contrast, in the second photo …
        </div>
      </div>
        </div>
        
				<div style='margin-top:20px;'>
            <b>Which phrases from exercise 5 did she use?</b>
						<div style='display: flex;'><b style='padding-top:4px;'>1.</b> <b> <select id='0'></select>  </b></div>
						<div style='display: flex;'><b style='padding-top:4px;'>2.</b> <b> <select id='1'></select>  </b></div>
						<div style='display: flex;'><b style='padding-top:4px;'>3.</b> <b> <select id='2'></select>  </b></div>
						<div style='display: flex;'><b style='padding-top:4px;'>4.</b> <b> <select id='3'></select>  </b></div>
				</div>
        `,
        answer: [
          `The main difference between the photos is (that) …`,
          `In the first photo … , whereas in the second photo …`,
          `… seems quite similar in both photos.`,
          `In the first photo … In contrast, in the second photo …`,
        ],
      },
    ],
  },
  7: {
    unit: 'Weather',
    id: 'FG10-W-P54-E7',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '7',
        title: `
          Work in pairs. Take turns to compare and contrast photos A and C in exercise 1. Follow the steps in the
          <span style="color: rgb(45,206,137);">Speaking Strategy.</span>
        `,
        color: '#860052',
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
					<img src="/img/FriendsPlus/Page54/E7/1.jpg" alt="" style='width:1050px'>
					<img src="/img/FriendsPlus/Page54/E7/01.png" alt="" style='margin-left: 100px;width:650px'>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
