import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Ambition',
		id: 'FG10-A-P66-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page66/E1/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		titleQuestion: [
			{
				num: '1',
				title: `
          Match job adverts 1–3 with photos A–C.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="margin-top: 20px; display: flex; justify-content: center; align-items: start;">
            <div>
              <div><i>Answer:</i> <strong>1</strong> # <strong>2</strong> # <strong>3</strong> #</div>
              <div style="margin-top: 30px;"><img src="img/FriendsPlus/Page66/E1/1.jpg" style="max-width: 100%;" /></div>
            </div>
            <div style="margin-left: 20px;"><img src="img/FriendsPlus/Page66/E1/2.jpg" style="max-width: 100%;" /></div>
          </div>
        `,
				answer: ['C', 'A', 'B'],
			},
		],
	},
	2: {
		unit: 'Ambition',
		id: 'FG10-A-P66-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page66/E2/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		hintBox: [
			{
				src: ["In any job, it's important to be … , … and … ."],
				borderColor: 'rgb(0, 147, 69)',
				arrow: true,
				position: 2,
				width: 525,
			},
			{
				src: ['Fruit-pickers work long hours outdoors, so they really need to be …'],
				borderColor: 'rgb(0, 147, 69)',
				arrow: true,
				position: 2,
				width: 760,
			},
		],
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '2',
				title: `
          <span style="color: rgb(146, 39, 144)">VOCABULARY</span> 
          Check the meaning of the words below.<br/> 
          Then discuss questions 1–2 in pairs.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="max-width: 580px; margin-top: 10px; color: rgb(0, 147, 69);  padding: 10px; border-radius: 10px; border: 1px solid rgb(0, 147, 69);">
            <div>
              <strong>Personal qualities</strong>
              <span style="margin-left: 16px;">enthusiastic</span>
              <span style="margin-left: 31px;">flexible</span>
            </div>
            <div>
              <span>friendly</span>
              <span style="margin-left: 23px;">good at communicating</span>
              <span style="margin-left: 34px;">hard-working</span>
            </div>
            <div>
              <span>honest</span>
              <span style="margin-left: 31px;">patient</span>
              <span style="margin-left: 51px;">physically fit</span>
              <span style="margin-left: 29px;">reliable</span>
            </div>
          </div>	

          <div style="margin-top: 20px; max-width: 800px;">
            <div style="display: flex;">
              <strong style="padding-right: 10px;">1</strong>
              <div>
                <span>In your opinion, which three qualities are generally most important at work?</span>
                <div style="margin-top: 10px;"><hintbox id='0'></hintbox></div>
              </div>				
            </div>

            <div style="margin-top: 40px; display: flex;">
              <strong style="padding-right: 10px;">2</strong>
              <div>
                <span>
                  Choose one quality that you think is most important for 
                  each of the jobs in exercise 1 and explain why.
                </span>				
                <div style="margin-top: 10px;"><hintbox id='1'></hintbox></div>
              </div>	
            </div>
          </div>

          <div style="margin-top: 60px; margin-left: 30px; width: 550px; padding: 0 10px; border-radius: 10px; box-shadow: 0 0 10px  rgb(0, 147, 69); display: flex; justify-content: center; align-items: center;">
            <img src="img/FriendsPlus/Page60/E3/1.jpg" style="max-width: 100%;" />
            <span style="margin-left: 10px; color:  rgb(0, 147, 69); font-size: 20px;"><span style="font-weight: bold; padding-right: 20px;">Vocabulary Builder 5G</span> Personal qualities: page 126</span>
          </div>
				`,
				answer: ['2', 'A', '', '', '1', 'B'],
			},
		],
	},
	3: {
		unit: 'Ambition',
		id: 'FG10-A-P66-E3',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_21.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page66/E3/Key/answerKey.png',
		component: T6,
		inputSize: 300,
		maxLength: 135,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
          <audioimage name="2.21"></audioimage> 
          Listen to a conversation between an examiner and a candidate 
          who wants to get a holiday job in order to save some money. 
          Answer the questions.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				<div style="padding-left: 10px;">
				  <div style="display: flex;">
				    <strong style="padding-right: 10px;">1</strong>
				    <span>Which job does the candidate choose? <br>#</span>
				  </div>
				  <div style="display: flex;">
				    <strong style="padding-right: 10px;">2</strong>
				    <span>What is her main reason for choosing it? <br>#</span>
				  </div>
				  <div style="display: flex;">
				    <strong style="padding-right: 10px;">3</strong>
				    <span>What are her main reasons for not choosing the other two jobs? <br><input id='2' width='1410px' /></span>
				  </div>
				</div>
				`,
				// title: ` // For the image capture purpose only
				// <div style="padding-left: 10px;">
				//   <div style="display: flex;">
				//     <strong style="padding-right: 10px;">1</strong>
				//     <span>Which job does the candidate choose? <br>#</span>
				//   </div>
				//   <div style="display: flex;">
				//     <strong style="padding-right: 10px;">2</strong>
				//     <span>What is her main reason for choosing it? <br>#</span>
				//   </div>
				//   <div style="display: flex;">
				//     <strong style="padding-right: 10px;">3</strong>
				//     <span>What are her main reasons for not choosing the other two jobs? <br><input id='2' width='580px' /><br><input id='3' width='860px' /></span>
				//   </div>
				// </div>
				// `,
				// answer: [
				// 	'Sales assistant',
				// 	"She's interested in fashion.",
				// 	"Fruit-picking is hard work and she isn't physically fit.",
				// 	"She doesn't want to work in a kitchen, so she won't apply to be a dish-washer.",
				// ],
				answer: [
					'Sales assistant',
					"She's interested in fashion.",
					"Fruit-picking is hard work and she isn't physically fit. She doesn't want to work in a kitchen, so she won't apply to be a dish-washer.",
				],
			},
		],
	},
	4: {
		unit: 'Ambition',
		id: 'FG10-A-P66-E4',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_21.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page66/E4/Key/answerKey.png',
		component: T6,
		inputSize: 120,
		maxLength: 12,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '4',
				title: `
        <audioimage name = "2.21"></audioimage> <span style="color: rgb(146, 39, 144)">KEY PHRASES</span> 
        Read the <span style="color: rgb(0, 147, 69);">Speaking Strategy</span>.<br/>
        Then listen again and complete the sentences using the words below.
 
			`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="margin-top: 20px; width: 600px; padding: 10px; border-radius: 10px; border: 4px dotted rgb(0, 147, 69);; background-color: rgb(229, 240, 228);">
            <span style="font-weight: bold; color: rgb(0, 147, 69);">Listening Strategy</span>
            <div>
              When you discuss a topic and give reasons for your opinions, 
              try to include some complex sentences. 
              For example, use words like <i>although / even though, nevertheless</i> 
              and <i>however</i> to make contrasts.
            </div>
          </div>

          <div style="margin-top: 20px; color: rgb(0, 147, 69);">
            <div>
              <span>choosing</span>
              <span style="margin-left: 46px;">hours</span>
              <span style="margin-left: 43px;">kitchen</span>
              <span style="margin-left: 51px;">long</span>
              <span style="margin-left: 52px;">pay</span>
              <span style="margin-left: 30px;">tiring<span/>
            </div>
            <div>
              <span>well-paid</span>
              <span style="margin-left: 48px;">well-paid</span>
            </div>
          </div>

          <div style="margin-top: 20px; width: 700px; border-left: 5px solid rgb(0, 147, 69); padding-left: 15px;">
            Although it’s <sup>1</sup># , it’ll be very <sup>2</sup># . 
            I’m sure I’ll enjoy it even though the <sup>3</sup># are <sup>4</sup># . 
            The job is quite <sup>5</sup># . 
            However, I really don’t want to work in a <sup>6</sup># . 
            The <sup>7</sup># is not very good. 
            Nevertheless, I’m <sup>8</sup># this job because I’m interested in fashion.
          </div>
				`,
				answer: ['well-paid', 'tiring', 'hours', 'long', 'well-paid', 'kitchen', 'pay', 'choosing'],
			},
		],
	},
	5: {
		unit: 'Ambition',
		id: 'FG10-A-P66-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page66/E5/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
					Read the <span style="color: rgb(0, 147, 69);">Look out!</span> box.
					Then work in pairs and say which job in exercise 1 you would choose and why.
					Include the structures from the <span style="color: rgb(0, 147, 69);">Look out!</span> box.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 20px; display: flex; justify-content: center; align-items: start;">
	          <div>
							<div style="display: flex; width: 430px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(0, 147, 69);; background-image: radial-gradient(rgb(250 244 244), rgb(206, 226, 206)); font-size: 20px;">
								<div style="margin-right: 20px; margin-top: 20px; font-weight: bold; font-size: 50px; color: rgb(0, 147, 69);">!</div>
								<div>
									<span style="color: rgb(0, 147, 69); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LOOK OUT!</span>
									<div>
										We often use <i>find</i> + noun + adjective and <i>find it</i> + adjective (+ infinitive) to give a personal reaction or opinion.
										<div style="color: rgb(10, 111, 143); font-family: 'Brush Script MT', cursive;">
											I will find the job stressful. <br/>
											I find it difficult to concentrate.
										</div>
									</div>
								</div>
							</div>
							<div style="margin-top: 30px;"><img src="img/FriendsPlus/Page66/E1/1.jpg" style="max-width: 100%;" /></div>
						</div>
						<div style="margin-left: 20px;"><img src="img/FriendsPlus/Page66/E1/2.jpg" style="max-width: 100%;" /></div>
					</div>
	      `,
				answer: [],
			},
		],
	},
};

export default json;
