import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P120-E1',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '',
				title:
					"<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>7B</span>Present perfect vs past simple</strong> <br /> 1  Write the words in the correct order to make sentences. <leftarrow name='7.1'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 500,
		exerciseKey: 'img/FriendsPlus/Page120/E1/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
					<div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              <span>exams / finished / hasn’t / my / sister / her</span>
              <input />
            </div>
          </div>
					<div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              <span>you / new / game / played / computer / have / this / ?</span>
              <input />
            </div>
          </div>
					<div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <span>dog / its / my / leg / has / broken</span>
              <input />
            </div>
          </div>
					<div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              <span>parents / New York / been / have / to / my</span>
              <input />
            </div>
          </div>
					<div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <span>you / my / have / trainers / seen / ?</span>
              <input />
            </div>
          </div>
					<div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              <span>cousin / has / my / applied / job / new / for / a</span>
              <input />
            </div>
          </div>
				`,
				answer: [
					`My sister hasn't finished her exams.`,
					'Have you played this new computer game?',
					'My dog has broken its leg.',
					'My parents have been to New York.',
					'Have you seen my trainers?',
					'My cousin has applied for a new job.',
				],
			},
		],
	},
	2: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P120-E2',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '',
				title:
					"<strong style='font-size: 800'>2 Write the past participles of these regular and irregular verbs. <leftarrow name='7.2'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'left',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 100,
		exerciseKey: 'img/FriendsPlus/Page120/E2/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div style="display: flex; width: 600px ; justify-content: space-between;">
            <div>
              <div style="display: flex; column-gap: 20px">
                <div style="display: flex;">
                  <span style="width: 50px; font-weight: 700">1</span>
                  <span>visit</span>
                </div> 
                <div>
                  <span style="color: rgb(4 84 121); font-style: oblique; margin-left: 20px">visited</span>
                </div>
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between ">
                <div style="display: flex;">
                  <span style="width: 50px; font-weight: 700">2</span>
                  <span>ask</span>
                </div>  
                <div>
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between ">
                <div style="display: flex;">
                  <span style="width: 50px; font-weight: 700">3</span>
                  <span>take</span>
                </div>  
                <div>
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between ">
                <div style="display: flex;">
                  <span style="width: 50px; font-weight: 700">4</span>
                  <span>meet</span>
                </div>  
                <div>
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between ">
                <div style="display: flex;">
                  <span style="width: 50px; font-weight: 700">5</span>
                  <span>use</span>
                </div>  
                <div>
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between ">
                <div style="display: flex;">
                  <span style="width: 50px; font-weight: 700">6</span>
                  <span>see</span>
                </div>  
                <div>
                  <input />
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; column-gap: 20px">
                <div style="display: flex;">
                  <span style="width: 50px; font-weight: 700">7</span>
                  <span>do</span>
                </div> 
                <div>
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between ">
                <div style="display: flex;">
                  <span style="width: 50px; font-weight: 700">8</span>
                  <span>reach</span>
                </div>  
                <div>
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between ">
                <div style="display: flex;">
                  <span style="width: 50px; font-weight: 700">9</span>
                  <span>stop</span>
                </div>  
                <div>
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between ">
                <div style="display: flex;">
                  <span style="width: 50px; font-weight: 700">10</span>
                  <span>put</span>
                </div>  
                <div>
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between ">
                <div style="display: flex;">
                  <span style="width: 50px; font-weight: 700">11</span>
                  <span>be</span>
                </div>  
                <div>
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 20px; justify-content: space-between ">
                <div style="display: flex;">
                  <span style="width: 50px; font-weight: 700">12</span>
                  <span>spend</span>
                </div>  
                <div>
                  <input />
                </div>
              </div>
            </div>
          </div>

        `,
				answer: ['asked', 'taken', 'met', 'used', 'seen', 'done', 'reached', 'stopped ', ' put ', 'been', 'spent'],
			},
		],
	},
	3: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P120-E3',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page120/E3/Key/answerKey.png',
		inputSize: 450,
		checkUppercase: true,

		stylesTextInput: {
			fontSize: 20,
			textAlign: 'left',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		titleQuestion: [
			{
				num: '3',
				title: "Some of the sentences are incorrect. Rewrite them correctly. <leftarrow name='7.3'></leftarrow>",
				color: '#000000',
			},
		],
		questions: [
			{
				title: /*html */ `
          <div style="display: flex; column-gap: 20px"> 
            <strong>1</strong>
            <div>
              <div>I’ve read six books last year.</div>
              <div style="color: rgb(4 84 121); font-style: oblique;">I read six books last year</div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px"> 
            <strong>2</strong>
            <div>
              <div>Jo washed her hair last night.</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px"> 
            <strong>3</strong>
            <div>
              <div>I had this watch since March.</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px"> 
            <strong>4</strong>
            <div>
              <div>Have you ever been to Spain?</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px"> 
            <strong>5</strong>
            <div>
              <div>"Tidy your room, please." "I already tidied it."</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px"> 
            <strong>6</strong>
            <div>
              <div>We’ve eaten at that restaurant last Tuesday</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px"> 
            <strong>7</strong>
            <div>
              <div>Peter has gone to the shops on Friday morning.</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px"> 
            <strong>8</strong>
            <div>
              <div>I’ve been kayaking twice in the last year.</div>
              <input />
            </div>
          </div>
        `,
				answer: [
					'correct',
					'I have had this watch since March.',
					'correct',
					'I have already tidied it.',
					'We ate at that restaurant last Tuesday.',
					'Peter went to the shops on Friday morning.',
					'correct',
				],
			},
		],
	},
	4: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P120-E4',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page120/E4/Key/answerKey.png',
		inputSize: 200,
		checkUppercase: true,

		stylesTextInput: {
			fontSize: 20,
			textAlign: 'left',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		titleQuestion: [
			{
				num: '4',
				title:
					"Complete the sentences. Use the past simple form of the verb in one sentence, and the present perfect in the other. <leftarrow name='7.3,7.4'></leftarrow>",
				color: '#000000',
			},
		],
		questions: [
			{
				title: /*html */ `
          <div>
            <strong>1 <span style="margin-left: 15px">have</span></strong>
            <div style="margin-left: 20px">
              <div style="display: flex; column-gap: 15px">
                <strong>a</strong>
                <span>I <input /> this laptop since my birthday.</span>
              </div>
              <div style="display: flex; column-gap: 15px">
                <strong>b</strong>
                <span>We <input /> dinner in the garden last night.</span>
              </div>
            </div>
          </div>
          <div>
            <strong>2 <span style="margin-left: 15px">not buy</span></strong>
            <div style="margin-left: 20px">
              <div style="display: flex; column-gap: 15px">
                <strong>a</strong>
                <span>I <input /> a new jacket in the sale last weekend.</span>
              </div>
              <div style="display: flex; column-gap: 15px">
                <strong>b</strong>
                <span>I <input /> any new clothes for months.</span>
              </div>
            </div>
          </div>
          <div>
            <strong>3 <span style="margin-left: 15px">leave</span></strong>
            <div style="margin-left: 20px">
              <div style="display: flex; column-gap: 15px">
                <strong>a</strong>
                <span>I <input /> school at five.</span>
              </div>
              <div style="display: flex; column-gap: 15px">
                <strong>b</strong>
                <span>I <input /> your dinner in the fridge. You can eat it later</span>
              </div>
            </div>
          </div>
          <div>
            <strong>4 <span style="margin-left: 15px">not text</span></strong>
            <div style="margin-left: 20px">
              <div style="display: flex; column-gap: 15px">
                <strong>a</strong>
                <span>I <input /> you last night because I don’t have your number.</span>
              </div>
              <div style="display: flex; column-gap: 15px">
                <strong>b</strong>
                <span>Jim <input /> his girlfriend since Monday. She’s a bit upset about it.</span>
              </div>
            </div>
          </div>
        `,
				answer: [
					'have had',
					'had',
					`didn't buy`,
					`haven't bought`,
					'left',
					'have left',
					`a didn't text`,
					`hasn't texted`,
				],
			},
		],
	},

	5: {
		unit: 'Grammar Builder and Reference',
		id: 'SB10-GBAR-P120-E5',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page120/E5/Key/answerKey.png',
		inputSize: 200,
		checkUppercase: true,

		stylesTextInput: {
			fontSize: 20,
			textAlign: 'left',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		titleQuestion: [
			{
				num: '',
				title:
					"<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>7D</span>Reported speech</strong> <br /> <strong>5</strong> Complete the reported speech with the correct verb forms. <leftarrow name='7.5'></leftarrow>",
				color: '#000000',
			},
		],
		questions: [
			{
				title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              <div>"Sue is sleeping," said Toby.</div>
              <div>Toby said that Sue <input /></div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              <div>"I can’t find any clean socks," said Barney.</div>
              <div>Barney said that he <input /> any clean socks.</div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div>"I’ll help Mum," said William.</div>
              <div>William said that he <input /> Mum.</div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              <div>"I’ve never been to Portugal," said Joseph.</div>
              <div>Joseph said that he  <input /> to Portugal.</div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <div>"Gemma might be at home," said Fred.</div>
              <div>Fred said that Gemma <input /> at home.</div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              <div>"Kate’s going to the zoo," said Mike</div>
              <div>Mike said that Kate <input /> to the zoo.</div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>7</strong>
            <div>
              <div>"We arrived ten minutes ago," said Chris.</div>
              <div>Chris said that they <input /> ten minutes before.</div>
            </div>
          </div>
        `,
				answer: [
					'was sleeping',
					`couldn't find `,
					'would help ',
					'had never been',
					'might be',
					'was going',
					'had arrived',
				],
			},
		],
	},
	6: {
		unit: 'Grammar Builder and Reference',
		id: 'SB10-GBAR-P120-E6',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page120/E6/Key/answerKey.png',
		inputSize: 100,
		isHiddenCheck: true,
		stylesTextInput: {
			textAlign: 'left',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		titleQuestion: [
			{
				num: '6',
				title: "Complete the sentences with the correct form of said or told. <leftarrow name='7.5'></leftarrow>",
				color: '#000000',
			},
		],
		questions: [
			{
				title: /*html */ `
         <div style="display: flex; column-gap: 20px">
          <strong>1</strong>
          <div>He <input /> me that he was thirsty.</div>
         </div>
         <div style="display: flex; column-gap: 20px">
          <strong>2</strong>
          <div>Sarah <input /> to me that she would arrive at six.</div>
         </div>
         <div style="display: flex; column-gap: 20px">
          <strong>3</strong>
          <div>Martin <input /> Toby that he’d like to go to the cinema.</div>
         </div>
         <div style="display: flex; column-gap: 20px">
          <strong>4</strong>
          <div>They <input /> they would be here at four.</div>
         </div>
         <div style="display: flex; column-gap: 20px">
          <strong>5</strong>
          <div>Did you <input /> that you were thinking of becoming a doctor?</div>
         </div>
         <div style="display: flex; column-gap: 20px">
          <strong>6</strong>
          <div>Kate <input /> Madison would be angry with me.</div>
         </div>
         <div style="display: flex; column-gap: 20px">
          <strong>7</strong>
          <div>Your brother <input /> something to me, but I didn’t hear it.</div>
         </div>
         <div style="display: flex; column-gap: 20px">
          <strong>8</strong>
          <div>Juliet <input /> it was difficult to hear what the actors were saying.</div>
         </div>
        `,
				answer: ['told', ' said ', 'told', ' said ', ' say ', 'said', ' said ', 'said'],
			},
		],
	},
	7: {
		unit: 'Grammar Builder and Reference',
		id: 'SB10-GBAR-P120-E7',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page120/E7/Key/answerKey.png',
		inputSize: 900,
		checkUppercase: true,

		stylesTextInput: {
			fontSize: 20,
			textAlign: 'left',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		titleQuestion: [
			{
				num: '7',
				title:
					"Report the sentences. Take care to change the pronouns and expressions of time correctly. <leftarrow name='7.5'></leftarrow>",
				color: '#000000',
			},
		],
		questions: [
			{
				title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              <span>"I’ll call you tomorrow," said Tom to Ryan.</span>
            </div>
          </div>
          <input/>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              <span>"We went to Paris last month," Geoff told Gerard.</span>
            </div>
          </div>
          <input/>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <span>"I can’t come to your house today," said Julia to Miranda.</span>
            </div>
          </div>
          <input/>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              <span>"I texted Joanna an hour ago," Quentin told Leah.</span>
            </div>
          </div>
          <input/>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <span>"We’re all going out tonight," said Emma to Amy.</span>
            </div>
          </div>
          <input/>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              <span>"I want you to be here at two o’clock this afternoon," said Maisie to Sarah.</span>
            </div>
          </div>
          <input/>
        `,
				answer: [
					'Tom said to Ryan (that) he would call him the following day.',
					'Geoff told Gerard (that) they had gone to Paris the month before.',
					`Julia said to Miranda (that) she couldn't come to her house that day.`,
					'Quentin told Leah (that) he had texted Joanna an hour earlier.',
					'Emma said to Amy (that) they were all going out that night.',
					`Maisie said to Sarah (that) she wanted her to be there at two o'clock that afternoon.`,
				],
			},
		],
	},
}

export default json
