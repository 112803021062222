import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Science',
		id: 'FG10-S-P103-E1',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 280,
		maxLength: 23,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 500 },
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in pairs. 
					Put the following words/phrases in order of importance concerning online education. 
					Then compare your ideas with another pair.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="color: rgb(34, 132, 198);">
						<div>
							<span>lecture content</span>
							<span style="padding-left: 50px;">active participation</span>
						</div>
						<div>
							<span>devices</span>
							<span style="padding-left: 50px;">timetable</span>
							<span style="padding-left: 50px;">internet connection</span>
						</div>
						<div>
							<span>work</span>
							<span style="padding-left: 50px;">lecturer’s teaching skills</span>
						</div>
					</div>

					<textarea id="0" rows="7"></textarea>
				`,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Science',
		id: 'FG10-S-P103-E2',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 280,
		maxLength: 23,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 400 },
		titleQuestion: [
			{
				num: '2',
				title: `
          Read the task and the letter. 
					What problem(s) does the writer have? 
					What does she want the recipient to do?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex;">
						<div style="flex: 2; display: flex; flex-direction: column; justify-content: space-between;">
							<div style="padding: 10px 10px 10px 20px; border-radius: 10px; background-color: rgb(209, 223, 242); font-size: 20px;">
								As a result of the pandemic Covid-19, all your classes are now online.
								You have been having problems with the internet connection in your area.
								Write a letter of complaint to the internet provider.
							</div>

							<div style="border-radius: 10px; border: 1px solid rgb(209, 223, 242); display: flex; justify-content: center;"><textarea id="0" rows="7"></textarea></div>
						</div>

						<div style="margin-left: 20px; flex: 3;">
							<img src="img/FriendsPlus/Page103/E2/1.jpg" style="max-width: 100%; border-right: 3px solid rgb(209, 223, 242);" />
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
	3: {
		unit: 'Science',
		id: 'FG10-S-P103-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page103/E3/Key/answerKey.png',
		component: T6,
		inputSize: 120,
		maxLength: 11,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
					<audioimage name="3.19"></audioimage> 
					Read the <span style="font-weight: bold; color: rgb(34, 132, 198);">Writing Strategy</span>. 
					Match the bold conjunctions in the letter with the meanings below.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex;">
						<div style="flex: 2; display: flex; flex-direction: column; justify-content: space-between; font-size: 20px;">
							<div style="padding: 10px; border-radius: 10px; border: 4px dotted rgb(34, 132, 198); background-color: rgb(232, 239, 248); box-shadow: 3px 3px 5px rgba(0,0,0,0.4);">
								<span style="font-weight: bold; color: rgb(34, 132, 198);">Writing strategy</span>
								<div>
									Remember when writing a formal letter of complaint:
									<ul>
										<li>State the reason for writing clearly in opening paragraph.</li>
										<li>Use formal language.</li>
										<li>Be clear and concise.</li>
										<li>State what you want done.</li>
									</ul>
								</div>
							</div>

							<div style="margin-top: 20px; padding: 10px; border-radius: 10px; border: 1px solid rgb(34, 132, 198); box-shadow: 3px 3px 5px rgba(0,0,0,0.4);">
								<div>
									<span style="padding-right: 10px; font-weight: bold;">1</span>
									<span>because (×2)</span> #, #				
								</div>
								<div>
									<span style="padding-right: 10px; font-weight: bold;">2</span>
									<span>instead of</span>#				
								</div>
								<div>
									<span style="padding-right: 10px; font-weight: bold;">3</span>
									<span>if there is not</span>#
								</div>
								<div>
									<span style="padding-right: 10px; font-weight: bold;">4</span>
									<span>for that reason</span>#				
								</div>
							</div>
						</div>

						<div style="margin-left: 20px; flex: 3;">
							<img src="img/FriendsPlus/Page103/E2/1.jpg" style="max-width: 100%; border-right: 3px solid rgb(209, 223, 242);" />
						</div>
					</div>
				`,
				answer: ['as', 'since', 'rather than', 'unless', 'so'],
			},
		],
	},
	4: {
		unit: 'Science',
		id: 'FG10-S-P103-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page103/E4/Key/answerKey.png',
		component: T6,
		inputSize: 50,
		maxLength: 1,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		titleQuestion: [
			{
				num: '4',
				title: `
					Match the paragraphs of the letter (1–4) with four of the topics below (A–E).
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex;">
						<div style="flex: 2; display: flex; flex-direction: column; justify-content: space-between; font-size: 20px;">
							<div style="padding: 10px; border-radius: 10px; border: 1px solid rgb(209, 223, 242);">
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">A</span>
									<span>Give more details about the problem.</span>				
								</div>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">B</span>
									<span>State what you want to happen in the future.</span>				
								</div>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">C</span>
									<span>Explain the problem clearly and why it is an issue for you.</span>				
								</div>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">D</span>
									<span>Ask for information.</span>				
								</div>
								<div style="display: flex;">
									<span style="padding-right: 10px; font-weight: bold;">E</span>
									<span>Give reasons why you think the problem needs to be solved.</span>				
								</div>
							</div>

							<div style="padding: 10px; border-radius: 10px; border: 1px solid rgb(209, 223, 242);">
								<span style="font-weight: bold; font-style: italic;">Answer:</span>
								<div>Paragraph <strong>1</strong>: Topic #</div>	
								<div>Paragraph <strong>2</strong>: Topic #</div>	
								<div>Paragraph <strong>3</strong>: Topic #</div>	
								<div>Paragraph <strong>4</strong>: Topic #</div>	
							</div>
						</div>

						<div style="margin-left: 20px; flex: 3;">
							<img src="img/FriendsPlus/Page103/E2/1.jpg" style="max-width: 100%; border-right: 3px solid rgb(209, 223, 242);" />
						</div>
					</div>
				`,
				answer: ['C', 'A', 'E', 'B'],
			},
		],
	},
	5: {
		unit: 'Science',
		id: 'FG10-S-P103-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page103/E5/Key/answerKey.png',
		component: T6,
		inputSize: 420,
		maxLength: 46,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '5',
				title: `
          <span style="color: rgb(146, 39, 144)">KEY PHRASES</span> 
					Read the useful phrases for summing up. 
					Then find one more in the letter in exercise 2. 
					Which paragraph does it begin?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex;">
						<div style="flex: 2; display: flex; flex-direction: column; justify-content: space-between; font-size: 20px;">
							<div style="padding: 10px 10px 10px 20px; border-radius: 10px; background-color: rgb(209, 223, 242);">
								As a result of the pandemic Covid-19, all your classes are now online. 
								You have been having problems with the internet connection in your area. 
								Write a letter of complaint to the internet provider.
							</div>

							<div style="padding-left: 20px; border-left: 3px solid rgb(34, 132, 198);">
								<div style="color: rgb(34, 132, 198);">Summing up</div>
								<div>On balance, I think ...</div>
								<div>Overall, ... in my opinion.</div>
								<div>All in all, ...</div>
								<div>For these reasons, I strongly believe that ...</div>
							</div>

							<div>
								<span style="font-weight: bold; font-style: italic;">Answer:<br/> </span>#
							</div>
						</div>

						<div style="margin-left: 20px; flex: 3;">
							<img src="img/FriendsPlus/Page103/E2/1.jpg" style="max-width: 100%; border-right: 3px solid rgb(209, 223, 242);" />
						</div>
					</div>
				`,
				answer: ['All in all, ...'],
			},
		],
	},
	6: {
		unit: 'Science',
		id: 'FG10-S-P103-E6',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 420,
		maxLength: 46,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 800 },
		titleQuestion: [
			{
				num: '6',
				title: `
          Work in pairs. Read the task below. 
					Then plan and write the letter. Remember to use a phrase from exercise 5.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex;">
						<div style="flex: 1; padding: 10px 10px 10px 20px; border-radius: 10px; background-color: rgb(209, 223, 242);">
							During the lockdown time to prevent the spread of Covid-19, 
							students in your city are having lessons broadcast on the city’s TV channel. 
							Write a letter of complaint (120-150 words) about the problem(s) you have had. 
							Think about the ideas you discussed in exercise 1.
						</div>

						<div style="margin-left: 20px; flex: 1; padding-left: 20px; border-left: 3px solid rgb(34, 132, 198);">
								<div style="color: rgb(34, 132, 198);">Summing up</div>
								<div>On balance, I think ...</div>
								<div>Overall, ... in my opinion.</div>
								<div>All in all, ...</div>
								<div>For these reasons, I strongly believe that ...</div>
							</div>
					</div>

					<div style="display: flex; justify-content: center; align-items: center;"><textarea id="0" rows="10"></textarea></div>
				`,
				answer: ['All in all, ...'],
			},
		],
	},
};

export default json;
