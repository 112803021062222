import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P31-E1',
    audio: '',
    video: '',
    component: T6,

    maxLength: 12,
    stylesTextInput: { borderBottom: 'dash' },
    textareaStyle: { width: 700 },
    inputSize: 220,
    // isHiddenCheck: true,
    titleImage: '',
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page31/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: 'Match two of the words below with the photos. Then describe the photos.',
        color: '#8e258c',
        prefix: { icon: [''], text: 'VOCABULARY' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        
        <div style='color:rgb(21 110 139); width:20cm'>
          <b >Outdoor activities</b>&emsp;&emsp;abseiling&emsp;&emsp;jet-skiing&emsp;&emsp;
kayaking&emsp;&emsp;mountain biking&emsp;&emsp;orienteering&emsp;&emsp;
paintballing&emsp;&emsp; quad biking&emsp;&emsp;rock climbing&emsp;&emsp;
kite surfing
        </div>
           <img src='img/FriendsPlus/Page31/E1/1.jpg'  />  

        <div style='display:flex; justify-content: space-around; margin-top:20px;'>
          <span>A#</span>        
          <span>B#</span>        
        </div>

            
          `,
        answer: ['orienteering', 'quad biking'],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P31-E2',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    titleQuestion: [{ num: '2', title: 'Answer the questions about the activities inexercise 1.', color: '#445B9F' }],
    questionImage: [
      // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page31/E2/1.jpg' },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P31-E3',
    audio: '',
    video: '',
    component: T6,

    // maxLength: 12,
    stylesTextInput: { borderBottom: 'dash' },
    textareaStyle: { width: 400 },
    inputSize: 400,
    // isHiddenCheck: true,
    titleImage: '',
    // textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page31/E3/Key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: 'Read the invitation and reply. Then answer the questions.',
        color: '#8e258c',
        prefix: { icon: [''], text: 'VOCABULARY' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
           <img src='img/FriendsPlus/Page31/E3/1.jpg'  />  
           <div>
              1&emsp;What time does the party begin?<br>#<br> 
              2&emsp;What are the first activities?<br>#<br> 
              3&emsp;What does Lisa suggest?<br><textarea id='2' row='3'></textarea> 
           </div>
          


            
          `,
        answer: ['3 p.m.', 'Jet skiing and kite-surfing', 'Going to the cinema when she returns home from holiday.'],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P31-E4',
    audio: '',
    video: '',
    component: T6,
    inputSize: 520,
    // titleImage: 'img/FriendsPlus/Page31/E4/1.jpg',
    exerciseKey: 'img/FriendsPlus/Page31/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: 'Look at the phrases below. Find two more in the reply in exercise 3.',
        color: '#8e258c',
        prefix: { icon: [], text: 'KEY PHRASE' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='line-height:50px'>
          <b style='font-size:28px;color:rgb(21 110 139)'>Making suggestions</b><br>
           Shall we (doing something)?<br>
           Let’s (doing something)<br>
           Do you fancy (doing something)?<br>
           How / What about (doing something)?<br>
           We could always (doing something)?<br>
          <sup>1</sup>#<br>
          <sup>2</sup>#<br>
        </div>
          <div style='margin:20px 0px; background:rgb(234 238 247);border: 6px dotted rgb(21 110 139);width:22cm ;border-radius:18px; padding:5px 20px'>
            <b style='font-size:28px;color:rgb(21 110 139)'>Writing Strategy</b>
            <div>
              In an informal text, you can use common abbreviations
              like e.g. and etc. You should use short forms like I’m and
              don’t, not full forms (I am and do not).
            </div>
          </div>
         
          `,
        answer: ['It would be nice to meet up some time.', 'Why don’t we go to the cinema?'],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P31-E5',
    audio: '',
    video: '',
    component: T6,
    inputSize: 520,
    // titleImage: 'img/FriendsPlus/Page31/E4/1.jpg',
    exerciseKey: 'img/FriendsPlus/Page31/E5/Key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title:
          'Read the <span style="font-size:28px;color:rgb(21 110 139)">Writing Strategy</span>. Find these abbreviations in the invitations in exercise 3. What do they mean? Do you know any other common abbreviations?',
        color: '#8e258c',
        prefix: { icon: [], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='line-height:50px'>
            a.m: #<br>
            BBQ: #<br>
            pm: #<br>
            e.g: #<br>
            etc: #<br>
          </div>
          <img src='img/FriendsPlus/Page31/E3/1.jpg'>
         
          `,
        answer: [
          'morning (ante meridiem, before noon)',
          'barbecue',
          'afternoon (post meridiem, after noon)',
          'for example (exempli gratia)',
          'et cetera (to show there are more in the list)',
        ],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P31-E6',
    audio: '',
    video: '',
    component: T6,
    inputSize: 520,
    textareaStyle: { width: 725 },
    // titleImage: 'img/FriendsPlus/Page31/E4/1.jpg',
    // exerciseKey: 'img/FriendsPlus/Page31/E6/Key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: 'Write an invitation (100– 120 words) to a party that includes outdoor activities. Remember to include:',
        color: '#8e258c',
        prefix: { icon: [], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='line-height:45px'>
            <div style='display:flex'>
              <b style='font-size:50px; margin-top:-14px'>.</b>
              <div>
                the reason for the party (birthday, end of exams,
                etc.).
              </div>
            </div>
            <div style='display:flex'>
              <b style='font-size:50px; margin-top:-14px'>.</b>
              <div>
                the date, time and place of the party.
              </div>
            </div>
            <div style='display:flex'>
              <b style='font-size:50px; margin-top:-14px'>.</b>
              <div>
                 any instructions (what the person should bring, wear, etc.).
              </div>
            </div>
          </div>
          <div style=' position: relative; '>
            <div><img src='img/FriendsPlus/Page31/E6/1.jpg'  /></div>
            <div style=" position: absolute; top: 105px; left: 25px;"><textarea id='0' rows='10'></textarea></div>
					</div>
        
          `,
        answer: [''],
      },
    ],
  },
  7: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P31-E7',
    audio: '',
    video: '',
    component: T6,
    inputSize: 520,
    textareaStyle: { width: 725 },
    // titleImage: 'img/FriendsPlus/Page31/E4/1.jpg',
    // exerciseKey: 'img/FriendsPlus/Page31/E6/Key/answerKey.png',
    titleQuestion: [
      {
        num: '7',
        title:
          'Work in pairs. Swap your invitations from exercise 6. Then write a reply (100– 120 words). In your reply, you should:',
        color: '#8e258c',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display:flex'>
          <div style='line-height:45px'>
            <div style='display:flex'>
              <b style='font-size:50px; margin-top:-14px'>.</b>
              <div>
                thank your partner for the invitation.
              </div>
            </div>
            <div style='display:flex'>
              <b style='font-size:50px; margin-top:-14px'>.</b>
              <div>
                say why you cannot come to the party
              </div>
            </div>
            <div style='display:flex'>
              <b style='font-size:50px; margin-top:-14px'>.</b>
              <div>
                 suggest doing something another time.
              </div>
            </div>
          </div>
          <img src='img/FriendsPlus/Page31/E6/2.jpg'  />
        </div>
          <div style=' position: relative; '>
            <div><img src='img/FriendsPlus/Page31/E6/1.jpg'  /></div>
            <div style=" position: absolute; top: 105px; left: 25px;"><textarea id='0' rows='10'></textarea></div>
					</div>
        
          `,
        answer: [''],
      },
    ],
  },
};

export default json;
