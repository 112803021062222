import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Ambition',
		id: 'FG10-A-P68-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page68/E1/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. Look at the photo. 
          Are there Body Shop stores in your country? What do they sell?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="margin-top: 20px; display: flex; justify-content: center; align-items: center;">
            <img src="img/FriendsPlus/Page68/E1/1.jpg" style="max-width: 100%;" />
          </div>
				`,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Ambition',
		id: 'FG10-A-P68-E2',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_22.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page68/E2/Key/answerKey.png',
		component: T6,
		inputSize: 65,
		maxLength: 5,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '2',
				title: `
          <audioimage name="2.22"></audioimage> 
          Read the text and fill in the gaps with the words below.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="width: 400px; display: flex; justify-content: space-between; color: rgb(0, 111, 147);">
            <span>as</span> <span>got</span> <span>in</span> <span>later</span> <span>of (x2)</span> <span>that</span> <span>to (x3)</span>
          </div>

          <div style="margin-top: 10px; width: 900px; background-color: rgb(219, 234, 228); padding: 20px 20px 20px 80px; box-shadow: 5px 5px 15px rgba(0,0,0,0.4); font-size: 20px;">
            <div style="font-size: 50px; text-shadow: 5px -1px rgb(255,255,255);">Anita Roddick</div>
            <div>
              Anita Perilli was born in 1942 in Littlehampton, a town <sup>1</sup># the south of England. 
              After leaving school, she trained <sup>2</sup># an English teacher, 
              but before she found a job, she decided to travel around the world. 
              When Anita returned <sup>3</sup># Britain, she met a Scottish man called Gordon Roddick. 
              They fell in love and <sup>4</sup># married. They had two daughters and moved to Brighton. 
              It was there in 1976 that Anita Roddick opened the first Body Shop store. 
              She wanted <sup>5</sup># sell cosmetics that were natural and not tested on animals. 
              She also recycled the bottles <sup>6</sup># contained her products: customers could bring 
              them back to the shop and refill them. Six months <sup>7</sup># , she opened another shop, 
              and by 1991, there were seven hundred Body Shop stores. 
              By 2004, the Body Shop had over two thousand stores with 71 million customers in 51 countries. 
              Anita Roddick was passionate about social and environmental issues. She gave money <sup>8</sup># many 
              charities. She joined anti-globalisation protests and criticised big oil companies that 
              did not invest in renewable energy. Near the end <sup>9</sup># her life, she sold Body Shop to L’Oréal, 
              one of the world’s largest cosmetics companies. Many of her customers were angry, 
              but she gave most <sup>10</sup># her money to charities. She died in 2007 at the age of 65.
            </div>
          </div>
				`,
				answer: ['in', 'as', 'to', 'got', 'to', 'that', 'later', 'to', 'of', 'of'],
			},
		],
	},
	3: {
		unit: 'Ambition',
		id: 'FG10-A-P68-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page68/E3/Key/answerKey.png',
		component: T6,
		inputSize: 420,
		maxLength: 112,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		textareaStyle: { width: 430 },
		titleQuestion: [
			{
				num: '3',
				title: `
          Read the text again and answer the questions.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex; align-items: start;">
						<div style="flex: 2; background-color: rgb(219, 234, 228); padding: 20px 20px 20px 80px; box-shadow: 3px 3px 7px rgba(0,0,0,0.4); font-size: 20px; font-family: 'Brush Script MT', cursive;">
              <div style="font-size: 50px; text-shadow: 5px -1px rgb(255,255,255);">Anita Roddick</div>
              <div>
                Anita Perilli was born in 1942 in Littlehampton, a town <sup>1</sup>_____ the south of England. 
                After leaving school, she trained <sup>2</sup>_____ an English teacher, 
                but before she found a job, she decided to travel around the world. 
                When Anita returned <sup>3</sup>_____ Britain, she met a Scottish man called Gordon Roddick. 
                They fell in love and <sup>4</sup>_____ married. They had two daughters and moved to Brighton. 
                It was there in 1976 that Anita Roddick opened the first Body Shop store. 
                She wanted <sup>5</sup>_____ sell cosmetics that were natural and not tested on animals. 
                She also recycled the bottles <sup>6</sup>_____ contained her products: customers could bring 
                them back to the shop and refill them. Six months <sup>7</sup>_____ , she opened another shop, 
                and by 1991, there were seven hundred Body Shop stores. 
                By 2004, the Body Shop had over two thousand stores with 71 million customers in 51 countries. 
                Anita Roddick was passionate about social and environmental issues. She gave money <sup>8</sup>_____ many 
                charities. She joined anti-globalisation protests and criticised big oil companies that 
                did not invest in renewable energy. Near the end <sup>9</sup>_____ her life, she sold Body Shop to L’Oréal, 
                one of the world’s largest cosmetics companies. Many of her customers were angry, 
                but she gave most <sup>10</sup>_____ her money to charities. She died in 2007 at the age of 65.
              </div>
            </div>

            <div style="margin-left: 30px; font-size: 20px; border: 1px solid rgb(0,0,0); border-radius: 10px; padding: 10px; flex: 1;">
              <div style="display: flex;">
                <strong style="padding-right: 10px;">1</strong>
                <span>Where did Roddick go after she left school? <br/>#</span>				
              </div>
              <div style="display: flex;">
                <strong style="padding-right: 10px;">2</strong>
                <span>Where did she live when she opened her first Body Shop store? <br/>#</span>				
              </div>
              <div style="display: flex;">
                <strong style="padding-right: 10px;">3</strong>
                <span>What was different about the Body Shop products? <br/>#</span>				
              </div>
              <div style="display: flex;">
                <strong style="padding-right: 10px;">4</strong>
                <div>
                  <span>Why did some of her customers feel angry?</span>			
                  <div style="margin-top: -5px;"><textarea id="3" rows="3" font-size="20px"></textarea></div>
                </div>
              </div>
            </div>
          </div>
				`,
				answer: [
					'She travelled around the world.',
					'She lived in Brighton.',
					'They were natural and not tested on animals.',
					'Because she sold the Body Shop to a big cosmetic company that was less concerned about selling natural products.',
				],
			},
		],
	},
	4: {
		unit: 'Ambition',
		id: 'FG10-A-P68-E4',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_23.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page68/E4/Key/answerKey.png',
		component: T6,
		inputSize: 300,
		maxLength: 27,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '4',
				title: `
					<audioimage name="2.23"></audioimage> 
					Listen to a radio programme about the Tata family. 
					Put their business activities in the order they are mentioned.
  			`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px; display: flex; align-items: center;">
						<div style="width: 370px;">
							<div style="display: flex;">
								<strong style="padding-right: 10px;">1</strong>
								<span>Steel</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">2</strong>
								<span>Electricity</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">3</strong>
								<span>Indian Institute of Science</span>				
							</div>
						</div>

						<div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">4</strong>
								<span>Cotton</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">5</strong>
								<span>Tata Airlines</span>				
							</div>
						</div>

						<div style="margin-left: 70px; display: flex; justify-content: center; align-items: center;">
							<img src="img/FriendsPlus/Page68/E4/1.jpg" style="max-width: 100%;" />
						</div>
					</div>

					<div style="margin-top: 20px;">
						<i>Put their business activities in the order they are mentioned:</i><br/>
						<div style="padding-left: 10px; display: flex;">
							<div style="width: 370px;">
								<div style="display: flex;">
									<strong style="padding-right: 10px;">1</strong>
									<span>#</span>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">2</strong>
									<span>#</span>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">3</strong>
									<span>#</span>				
								</div>
							</div>
							<div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">4</strong>
									<span>#</span>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">5</strong>
									<span>#</span>				
								</div>
							</div>
						</div>
					</div>
				`,
				answer: ['Cotton', 'Indian Institute of Science', 'Steel', 'Electricity', 'Tata Airlines'],
			},
		],
	},
	5: {
		unit: 'Ambition',
		id: 'FG10-A-P68-E5',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_23.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page68/E5/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		titleQuestion: [
			{
				num: '5',
				title: `
					<audioimage name="2.23"></audioimage> 
					Listen again. Are the sentences true or false? Write T or F.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 900px; display: flex; align-items: start;">
						<div style="padding-left: 10px; flex: 3;">
							<div style="display: flex;">
								<strong style="padding-right: 10px;">1</strong>
								<span>Jamsetji Nusserwanji set up his own business as soon as he left school. #</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">2</strong>
								<span>Tata companies worked fast and created excellent products. #</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">3</strong>
								<span>Tata had one son who managed his steel companies. #</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">4</strong>
								<span>Tata Airlines became a new company with a new name. #</span>				
							</div>
						</div>

						<div style="display: flex; justify-content: center; align-items: center; flex: 2;">
							<img src="img/FriendsPlus/Page68/E4/1.jpg" style="max-width: 100%;" />
						</div>
					</div>
				`,
				answer: ['F', 'T', 'F', 'T'],
			},
		],
	},
	6: {
		unit: 'Ambition',
		id: 'FG10-A-P68-E1',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '6',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. Ask and answer the questions.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="padding-left: 10px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<div>
								<span>Where are the entrepreneurs below from? What are they famous for?</span>
								<div style="color: rgb(0, 111, 147);">
									<span>Coco Chanel</span> <br/>
									<span>Walt Disney</span> <br/>
									<span>Steve Jobs</span> <br/>
								</div>				
							</div>
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>Do you know any entrepreneurs from Viet Nam? What are they famous for?</span>				
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
};

export default json;
