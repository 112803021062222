import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P118 -E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title:
          "<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>6B</span>The second conditional</strong> <br /> <strong>1</strong> Match the two halves of the conditional sentences. Which sentences are true for you? <leftarrow name='6.1, 6.2, 6.3'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 50,
    maxLength: 7,
    exerciseKey: 'img/FriendsPlus/Page118/E1/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div>
            <strong>1</strong>
            <span style="margin-left: 20px">If I won the lottery, <input /></span>
          </div>
          <div>
            <strong>2</strong>
            <span style="margin-left: 20px">II’d feel bad <input /></span>
          </div>
          <div>
            <strong>3</strong>
            <span style="margin-left: 20px">If I found a spider in my bed <input /></span>
          </div>
          <div>
            <strong>4</strong>
            <span style="margin-left: 20px">I would download all of my favourite films <input /></span>
          </div>
          <div>
            <strong>5</strong>
            <span style="margin-left: 20px">If I went on holiday with my friends, <input /></span>
          </div>

          <div style="margin-top:10px">
            <strong>a</strong>
            <span style="margin-left: 20px">I’d be terrified.</span>
          </div>
          <div>
            <strong>b</strong>
            <span style="margin-left: 20px">if I didn't have to pay for them.</span>
          </div>
          <div>
            <strong>c</strong>
            <span style="margin-left: 20px">I’d buy a new house for my parents.</span>
          </div>
          <div>
            <strong>d</strong>
            <span style="margin-left: 20px">we’d go camping.</span>
          </div>
          <div>
            <strong>e</strong>
            <span style="margin-left: 20px">if my best friend was cross with me.</span>
          </div>
				`,
        answer: ['c', 'e', 'a', 'b', 'd'],
      },
    ],
  },
  2: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P118-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title:
          "Complete the second conditional sentences with the correct form of the verbs in brackets. <leftarrow name='6.1, 6.2, 6.3'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 200,
    maxLength: 200,
    exerciseKey: 'img/FriendsPlus/Page118/E2/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="display:flex">
            <strong>1</strong>
            <div style="margin-left:10px">I <input /> (do) more homework if I <input /> (spend) less time online</div>
          </div>
          <div style="display:flex">
            <strong>2</strong>
            <div style="margin-left:10px">If children <input /> (not watch) TV so much, they  <input /> (be) healthier</div>
          </div>
          <div style="display:flex">
            <strong>3</strong>
            <div style="margin-left:10px">If a big rock <input /> (hit) the Earth, it <input /> (cause) a huge explosion.</div>
          </div>
          <div style="display:flex">
            <strong>4</strong>
            <div style="margin-left:10px">Global warming <input /> (slow) down if people <input /> (change) their lifestyles.</div>
          </div>
          <div style="display:flex">
            <strong>5</strong>
            <div style="margin-left:10px">If you <input /> (apply) for a job at the hotel, I’m sure you <input /> (get) it</div>
          </div>
          <div style="display:flex">
            <strong>6</strong>
            <div style="margin-left:10px">If you <input /> (visit) Rome, you <input /> (can) see the Trevi Fountain.</div>
          </div>
          <div style="display:flex">
            <strong>7</strong>
            <div style="margin-left:10px">If I <input /> (visit) Rome, you(want) to become a millionaire, I <input /> (start) my own business.</div>
          </div>

				`,
        answer: [
          'would do',
          'spent',
          `didn't watch`,
          'would be',
          'hit',
          'would cause',
          'would slow',
          'changed ',
          'applied',
          'would get',
          'visited',
          'could',
          'wanted',
          'would start',
        ],
      },
    ],
  },
  3: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P118-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: "Rewrite these sentences using the second conditional. <leftarrow name='6.1, 6.2, 6.3'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 800,
    exerciseKey: 'img/FriendsPlus/Page118/E3/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              <div>My job is repetitive. That’s why I don’t enjoy it</div>
              <div style="color: rgb(4 84 121); font-style: oblique;">If my job wasn’t repetitive, I’d enjoy it.</div>
              <div>OR <span style="color: rgb(4 84 121); font-style: oblique;">I’d enjoy my job if it wasn’t repetitive.</span></div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              <div>She doesn’t like swimming. That’s why she doesn’t go to the pool.</div>
              <input/></br>
              OR <input/>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div>He doesn’t earn a lot of money. That’s why he doesn’t rent an apartment on his own.</div>
              <input/></br>
              OR <input/>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              <div>The Louvre Museum is crowded. That’s why I don’t like it.</div>
              <input/></br>
              OR <input/>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <div>We won’t go on holiday this year. We can’t afford it.</div>
              <input/></br>
              OR <input/>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              <div>I don’t understand my homework. That’s why I can’t finish it quickly.</div>
              <input/></br>
              OR <input/>
            </div>
          </div>
  			`,
        answer: [
          'If she liked swimming, she would go to the pool.',
          'She would go to the pool if she liked swimming.',
          'If he earned a lot of money, he would rent an apartment on his own.',
          'He would rent an apartment on his own if he earned a lot of money.',
          `If the Louvre Museum wasn't crowded,I would like it./If the Louvre Museum weren't crowded,I would like it. `,
          `I would like the Louvre Museum if it wasn't crowded./I would like the Louvre Museum if it weren't crowded.`,
          'We would go on holiday this year if we could afford it. ',
          'If we could afford it, we would go on holiday this year.',
          'If I understood my homework, I could finish it quickly. ',
          'I could finish my homework quickly if I understood it.',
        ],
      },
    ],
  },

  // 3: {
  //   unit: 'Grammar Builder and  Reference',
  //   id: 'SB10-GBAR-P118-E3',
  //   audio: '',
  //   video: '',
  //   component: T6,
  //   titleQuestion: [
  //     {
  //       num: '3',
  //       title: "Rewrite these sentences using the second conditional. <leftarrow name='6.1, 6.2, 6.3'></leftarrow>",
  //       color: 'black',
  //     },
  //   ],
  //   stylesTextInput: {
  //     fontSize: 20,
  //     textAlign: 'left',
  //     borderBottom: '2px solid',
  //     backgroundColor: 'transparent',
  //   },
  //   inputSize: 800,
  //   exerciseKey: 'img/FriendsPlus/Page118/E3/Key/answerKey.png',
  //   questionImage: [],
  //   isHiddenCheck: true,
  //   questions: [
  //     {
  //       title: /*html */ `
  //         <div style="display: flex; column-gap: 20px">
  //           <strong>1</strong>
  //           <div>
  //             <div>My job is repetitive. That’s why I don’t enjoy it</div>
  //             <div style="color: rgb(4 84 121); font-style: oblique;">If my job wasn’t repetitive, I’d enjoy it.</div>
  //             <div>OR <span style="color: rgb(4 84 121); font-style: oblique;">I’d enjoy my job if it wasn’t repetitive.</span></div>
  //           </div>
  //         </div>
  //         <div style="display: flex; column-gap: 20px">
  //           <strong>2</strong>
  //           <div>
  //             <div>She doesn’t like swimming. That’s why she doesn’t go to the pool.</div>
  //             <input/></br>
  //             OR <input/>
  //           </div>
  //         </div>
  //         <div style="display: flex; column-gap: 20px">
  //           <strong>3</strong>
  //           <div>
  //             <div>He doesn’t earn a lot of money. That’s why he doesn’t rent an apartment on his own.</div>
  //             <input/></br>
  //             OR <input/>
  //           </div>
  //         </div>
  //         <div style="display: flex; column-gap: 20px">
  //           <strong>4</strong>
  //           <div>
  //             <div>The Louvre Museum is crowded. That’s why I don’t like it.</div>
  //             <input/></br>
  //             OR <input/>
  //           </div>
  //         </div>
  //         <div style="display: flex; column-gap: 20px">
  //           <strong>5</strong>
  //           <div>
  //             <div>We won’t go on holiday this year. We can’t afford it.</div>
  //             <input/></br>
  //             OR <input/>
  //           </div>
  //         </div>
  //         <div style="display: flex; column-gap: 20px">
  //           <strong>6</strong>
  //           <div>
  //             <div>I don’t understand my homework. That’s why I can’t finish it quickly.</div>
  //             <input/></br>
  //             OR <input/>
  //           </div>
  //         </div>
  // 			`,
  //       answer: [
  //         'If she liked swimming, she would go to the pool. / She would go to the pool if she liked swimming.',
  //         'If she liked swimming, she would go to the pool. / She would go to the pool if she liked swimming.',
  //         'If he earned a lot of money, he would rent an apartment on his own. / He would rent an apartment on his own if he earned a lot of money.',
  //         'If he earned a lot of money, he would rent an apartment on his own. / He would rent an apartment on his own if he earned a lot of money.',
  //         `If the Louvre Museum wasn't. / weren't crowded,I would like it. / I would like the Louvre Museum if it wasn't. / weren't crowded.`,
  //         `If the Louvre Museum wasn't. / weren't crowded,I would like it. / I would like the Louvre Museum if it wasn't. / weren't crowded.`,
  //         `If the Louvre Museum wasn't. / weren't crowded,I would like it. / I would like the Louvre Museum if it wasn't. / weren't crowded.`,
  //         `If the Louvre Museum wasn't. / weren't crowded,I would like it. / I would like the Louvre Museum if it wasn't. / weren't crowded.`,
  //         'We would go on holiday this year if we could afford it. / If we could afford it, we would go on holiday this year.',
  //         'We would go on holiday this year if we could afford it. / If we could afford it, we would go on holiday this year.',
  //         'If I understood my homework, I could finish it quickly. / I could finish my homework quickly if I understood it.',
  //         'If I understood my homework, I could finish it quickly. / I could finish my homework quickly if I understood it.',
  //       ],
  //     },
  //   ],
  // },
  4: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P118-E4',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title:
          "Complete the second conditional questions with the correct form of the verbs in brackets. <leftarrow name='6.1, 6.2, 6.3'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    inputSize: 200,
    maxLength: 100,
    exerciseKey: 'img/FriendsPlus/Page118/E4/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <span>What <input /> (you / do) if you <input /> (be) outside in a thunderstorm?</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <span>If you <input /> (can) meet one film star, who <input /> (you / choose)?</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <span><input /> (you / behave) well on holiday if your parents <input /> (not be) there?</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <span>How <input /> (you / feel) if you <input /> (win) the lottery?</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <span>If you <input /> (can) visit any country, where <input /> (you / go)?</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <span>If you <input /> (have to) spend a month alone on a desert island, what <input /> (you / take) with you?</span>
          </div>
				`,
        answer: [
          'would you do',
          'were',
          'could',
          'would you choose',
          'Would you behave',
          `weren't `,
          'would you feel',
          'won',
          'could',
          ' would you go',
          'had to',
          'would you take',
        ],
      },
    ],
  },
  5: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P118 -E5',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '5',
        title:
          "<strong style='color:rgb(0 147 69); '>SPEAKING</strong> In pairs, ask and answer the questions in exercise 4. <leftarrow name='6.1, 6.2, 6.3'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: 'none',
      backgroundColor: 'transparent',
    },
    inputSize: 100,
    maxLength: 100,
    exerciseKey: 'img/FriendsPlus/Page6/E1/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
				`,
        answer: [],
      },
    ],
  },
  6: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P118 -E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title:
          "<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>6D</span>Past perfect <br/> <strong>6</strong> Complete the sentences with the past perfect form of the verbs in brackets. <leftarrow name='6.4, 6.5'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: '2px solid ',
      backgroundColor: 'transparent',
    },
    inputSize: 200,
    maxLength: 100,
    exerciseKey: 'img/FriendsPlus/Page118/E6/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              I didn’t have any chocolate because my sister <input /> (eat) it.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              I couldn’t buy the magazine because I <input /> (forget) to bring my wallet with me.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              I didn’t recognise my cousin. He <input /> (grow) a beard.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              We couldn’t get home because we <input /> (miss) the last bus.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              After I <input /> (write) the answer, I realised I <input /> (make) a mistake.
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              I didn’t watch the programme because I <input /> (see) it before.
            </div>
          </div>
				`,
        answer: ['had eaten', 'had forgotten', 'had grown', 'had missed', 'had written', ' had made', 'had seen'],
      },
    ],
  },
  7: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P118-E7',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '7',
        title:
          "Complete the sentences. Use the past simple or past perfect form of the verbs in brackets. <leftarrow name='6.4, 6.5'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'center',
      borderBottom: '2px solid ',
      backgroundColor: 'transparent',
    },
    inputSize: 200,
    maxLength: 100,
    exerciseKey: 'img/FriendsPlus/Page118/E7/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>I <input /> (can’t) remember where I <input /> (leave) my keys.</div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>Last Saturday, <input /> (eat) at a Japanese restaurant. I <input /> (never eat) Japanese food before that.</div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>I <input /> (find) a £10 note that I <input /> (lose) last year.</div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>Last weekend I <input /> (meet) a girl who <input />  (be born) in the same hospital as me.</div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>I <input /> (not play) volleyball because I <input /> (hurt) my hand.</div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>The pavement <input /> (be) wet because it <input /> (rain).</div>
          </div>
				`,
        answer: [
          `couldn't`,
          'had left',
          'ate',
          'had never eaten',
          'found',
          'had lost',
          'met',
          'had been born',
          `didn't play`,
          'had hurt',
          'was',
          'had rained',
        ],
      },
    ],
  },
  8: {
    unit: 'Grammar Builder and  Reference',
    id: 'SB10-GBAR-P118-E8',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '8',
        title:
          "Rewrite each pair of sentences as one sentence using the past simple and the past perfect. Start with the word given. <leftarrow name='6.4, 6.5'></leftarrow>",
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      textAlign: 'left',
      borderBottom: '2px solid ',
      backgroundColor: 'transparent',
    },
    inputSize: 600,
    maxLength: 200,
    exerciseKey: 'img/FriendsPlus/Page118/E8/Key/answerKey.png',
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              <div>I had dinner. I watched TV</div>
              <div>After <span style="color: rgb(4 84 121); font-style: oblique;">I’d had dinner, I watched TV.</span></div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              <div>We bought a newspaper. We had a coffee.</div>
              <div>After <input /></div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div>We played tennis. We went home.</div>
              <div>After <input /></div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              <div>I went to sleep. My cousin phoned.</div>
              <div>When <input /></div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <div>Messi scored two goals. We arrived at the match.</div>
              <div>When <input /></div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              <div>The children did the housework. Their mother got home.</div>
              <div>When <input /></div>
            </div>
          </div>
				`,
        answer: [
          `we'd bought a newspaper, we had a coffee.`,
          `we'd played tennis, we went home.`,
          `my cousin phoned, I'd gone to sleep.`,
          `we arrived at the match, Messi had scored two goals.`,
          'their mother got home, the children had done the housework.',
        ],
      },
    ],
  },
};

export default json;
