import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Science',
		id: 'FG10-S-P105-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page105/E1/Key/answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: 'Reading',
				title: `
					Read the texts. Circle the correct option (a–d).
        `,
				color: 'rgb(0, 111, 147)',
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					margin: '5px 0',
					border: 'none',
					borderColor: 'transparent',
					borderRadius: '50%',
					fontWeight: 'bold',
				},
				selectWordStyle: {
					border: 'solid 2px',
					borderColor: '#00a8ec',
					margin: '-2px',
					padding: '5px',
				},
				limitSelect: 1,
				listWords: ['a<br> b<br> c<br> d'],
				answers: ['0-0'],
				initialValue: [],
			},
			Layout: `
				<div style="display: flex; align-items: start;">
					<div style="flex: 1; padding: 10px 20px; border-radius: 10px; background-color: rgb(229, 240, 203);">
						<strong>TV opportunity!</strong>
						<div>
							<i>Great Inventions</i> is a new TV show and we are looking for young people to take part. 
							Interested? We invite you to make a two-minute video that shows your idea for a new invention. 
							Make sure you include:
						</div>
						<ul style="margin-top: 10px; margin-bottom: 10px;">
							<li>personal details: name, age, and address.</li>
							<li>the name of your invention, how you got the idea and how it works.</li>
						</ul>
						<div>
							You can email us your video or send it to the address shown on our website. 
							We’ll choose the ones we think are most interesting. Your prize? 
							The chance to appear on our TV show!
						</div>
					</div>

					<div style="margin-left: 20px; flex: 1; padding: 10px 20px; border-radius: 10px; border: 1px solid rgb(209, 223, 242);">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>What do the programme makers want?</span>				
						</div>
						<div style="margin-left: 30px; display: flex;">
							<div>
								<input id='0' type='Circle' />
							</div>
							<div style="margin-left: 10px;">
								<div>new inventors</div>
								<div>experienced film makers</div>
								<div>young TV presenters</div>
								<div>young actors</div>
							</div>
						</div>
					</div>
				</div>
      `,
		},
	},
	2: {
		unit: 'Science',
		id: 'FG10-S-P105-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page105/E2/Key/answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: 'Reading',
				title: `
					Read the texts. Circle the correct option (a–d).
        `,
				color: 'rgb(0, 111, 147)',
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					margin: '5px 0',
					border: 'none',
					borderColor: 'transparent',
					borderRadius: '50%',
					fontWeight: 'bold',
				},
				selectWordStyle: {
					border: 'solid 2px',
					borderColor: '#00a8ec',
					margin: '-2px',
					padding: '5px',
				},
				limitSelect: 1,
				listWords: ['a<br> b<br> c<br> d'],
				answers: ['0-4'],
				initialValue: [],
			},
			Layout: `
				<div style="display: flex; align-items: start;">
					<div style="flex: 1; padding: 10px 20px; border-radius: 10px; background-color: rgb(229, 240, 203);">
						<strong>20th-century inventions</strong>
						<div>
							The most important modern invention has to be the mobile phone. 
							It was only invented in the 1970s, but look at it now! Everyone has a phone. 
							Originally, of course, phones was large and were only used to make phone calls. 
							Now those phones are in museums! Modern mobile phones are mini-computers - 
							you can do so much with them. You can buy very expensive phones, 
							but you can also buy cheap models. That's why, in my view, it's the best 20th-century invention.
						</div>
					</div>

					<div style="margin-left: 20px; flex: 1; padding: 10px 20px; border-radius: 10px; border: 1px solid rgb(209, 223, 242);">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>What is the purpose of the text?</span>				
						</div>
						<div style="margin-left: 30px; display: flex;">
							<div>
								<input id='0' type='Circle' />
							</div>
							<div style="margin-left: 10px;">
								<div>to advertise mobile phones</div>
								<div>to invite people to a museum exhibition</div>
								<div>to give an opinion about an invention</div>
								<div>to advise people to avoid cheap phones</div>
							</div>
						</div>
					</div>
				</div>
      `,
		},
	},
	3: {
		unit: 'Science',
		id: 'FG10-S-P105-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page105/E3/Key/answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: 'Reading',
				title: `
					Read the texts. Circle the correct option (a–d).
        `,
				color: 'rgb(0, 111, 147)',
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					margin: '5px 0',
					border: 'none',
					borderColor: 'transparent',
					borderRadius: '50%',
					fontWeight: 'bold',
				},
				selectWordStyle: {
					border: 'solid 2px',
					borderColor: '#00a8ec',
					margin: '-2px',
					padding: '5px',
				},
				limitSelect: 1,
				listWords: ['a<br> b<br> c<br> d'],
				answers: ['0-2'],
				initialValue: [],
			},
			Layout: `
				<div style="display: flex; align-items: start;">
					<div style="flex: 1; padding: 10px 20px; border-radius: 10px; background-color: rgb(229, 240, 203);">
						<strong>3D printers</strong>
						<div>
							3D printers are a new kind of machine that can make everyday objects. 
							They're amazing because they can produce different things in different materials, 
							from cups and plates to plasic toys and even chocolate cakes! So how do they work? 
							First you design a 3D object on your computer, then you connect it to a 3D printer. 
							Press 'print' and watch it work. The printer makes the object in very thin layers, 
							like the pages of a book - one on top of the other until you have a complete object.
						</div>
					</div>

					<div style="margin-left: 20px; flex: 1; padding: 10px 20px; border-radius: 10px; border: 1px solid rgb(209, 223, 242);">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span>What is the writer of the text doing?</span>				
						</div>
						<div style="margin-left: 30px; display: flex;">
							<div>
								<input id='0' type='Circle' />
							</div>
							<div style="margin-left: 10px;">
								<div>giving an idea about the future of printers</div>
								<div>describing an invention</div>
								<div>giving a short history of an object</div>
								<div>explaining different inventions</div>
							</div>
						</div>
					</div>
				</div>
      `,
		},
	},
	// Thiếu file audio: 3.23
	4: {
		unit: 'Science',
		id: 'FG10-S-P105-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page105/E4/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		titleQuestion: [
			{
				num: 'Listening',
				title: `
					<audioimage name="3.23"></audioimage> 
					Listen to the text. Are the sentences true or false? Write T or F.
        `,
				color: 'rgb(0, 111, 147)',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px;">
						<div style="display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">1</span>
							<span>Galileo invented the telescope. #</span>				
						</div>
						<div style="display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">2</span>
							<span>Galileo immediately used the telescope to study space. #</span>				
						</div>
						<div style="display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">3</span>
							<span>The United States sent the first person into space. #</span>				
						</div>
						<div style="display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">4</span>
							<span>There was an astronaut in Vostok 1. #</span>				
						</div>
						<div style="display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">5</span>
							<span>The speaker thinks it’s useful to land on Mars. #</span>				
						</div>
						<div style="display: flex;">
							<span style="padding-right: 10px; font-weight: bold;">6</span>
							<span>The speaker is mainly describing the advantages of space exploration. #</span>				
						</div>
					</div>
				`,
				answer: ['F', 'T', 'F', 'T', 'T', 'F'],
			},
		],
	},
	5: {
		unit: 'Science',
		id: 'FG10-S-P105-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page105/E5/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1000,
		textareaStyle: { width: 400 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: 'Speaking',
				title: `
					Work in pairs and do the task below.
        `,
				color: 'rgb(0, 111, 147)',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex;">
						<div style="flex: 3; padding: 10px 20px; border-radius: 10px; background-color: rgb(229, 240, 203);">
							You are returning a mobile phone to a shop because something has gone wrong with it.<br/> 
							Discuss these four issues with the shop assistant:
							<div style="margin-top: 7px; padding-left: 40px;">
								when you bought it <br/>
								what the problem is <br/>
								what you want to happen next <br/>
								what you will do if that isn’t possible. <br/>							 
							</div>
						</div>

						<div style="margin-left: 20px; flex: 2; padding: 10px 20px; border-radius: 10px; border: 1px solid rgb(209, 223, 242);">
							<textarea id="0" rows="7"></textarea>
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
	6: {
		unit: 'Science',
		id: 'FG10-S-P105-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page105/E6/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1000,
		textareaStyle: { width: 700 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: 'Writing',
				title: `
					Read the task below and write the letter.
        `,
				color: 'rgb(0, 111, 147)',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex;">
						<div style="flex: 1; padding: 10px 20px; border-radius: 10px; background-color: rgb(229, 240, 203);">
							You ordered a tablet online 3 weeks ago and it arrived yesterday. 
							Unfortunately, you were not satisfied with the product. 
							Write a letter of complaint (120—150 words).
						</div>

						<div style="margin-left: 20px; flex: 2; padding: 10px 20px; border-radius: 10px; border: 1px solid rgb(209, 223, 242);">
							<textarea id="0" rows="7"></textarea>
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
};

export default json;
