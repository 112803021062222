import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Vocabulary Builder',
    id: 'SB10-V-124-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page124/E1/Key/answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: `
          <div style="font-size: 30px;"><span style="color: rgb(146, 39, 144);">IA</span> Sports abd hobbies</div>
          <div><span style="padding-right: 10px;">1</span> Match the icons with eight of the sports and hobbies below.</div>
        `,
        color: 'black',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      // fontSize: 21,
    },
    inputSize: 210,
    maxLength: 18,
    // isHiddenCheck: true,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
          <div style="max-width: 1000px; font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>basketball</span><span>board games</span><span>bowling</span>
            <span>chess</span><span>cycling</span><span>dancing</span>
            <span>drama</span><span>drawing</span><span>football</span>
            <span>gymnastics</span><span>ice hockey</span><span>ice skating</span>
            <span>listening to music</span><span>skateboarding</span><span>swimming</span>
            <span>video games</span><span>volleyball</span><span>watching films</span>
          </div>

          <div style="margin-top: 20px;"><img src="img/FriendsPlus/Page124/E1/1.jpg" style="max-width: 100%;" /></div>

					<div style="margin-top: 20px; display: flex; gap: 50px;">
						<div>
							<div style="display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">1</div>
								<div>#</div>
							</div>
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">2</div>
								<div>#</div>
							</div>	
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">3</div>
								<div>#</div>
							</div>	
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">4</div>
								<div>#</div>
							</div>	
						</div>

						<div>
							<div style="display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">5</div>
								<div>#</div>
							</div>
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">6</div>
								<div>#</div>
							</div>	
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">7</div>
								<div>#</div>
							</div>	
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="font-weight: bold; text-align: right;">8</div>
								<div>#</div>
							</div>	
						</div>
					</div>
				`,
        answer: [
          'basketball',
          'chess',
          'ice hockey',
          'video games',
          'ice skating',
          'listening to music',
          'cycling ',
          'drama',
        ],
      },
    ],
  },
  2: {
    unit: 'Vocabulary Builder',
    id: 'SB10-V-124-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page124/E2/Key/answerKey.png',
    component: T6,
    stylesTextInput: {
      textAlign: 'center',
    },
    inputSize: 250,
    maxLength: 200,
    titleQuestion: [
      {
        num: '2',
        title: `
          Which sports and hobbies from exercise 1 can you do …
        `,
        color: 'black',
      },
    ],
    // isHiddenCheck: true,

    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
          <div style="max-width: 1000px; font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>basketball</span><span>board games</span><span>bowling</span>
            <span>chess</span><span>cycling</span><span>dancing</span>
            <span>drama</span><span>drawing</span><span>football</span>
            <span>gymnastics</span><span>ice hockey</span><span>ice skating</span>
            <span>listening to music</span><span>skateboarding</span><span>swimming</span>
            <span>video games</span><span>volleyball</span><span>watching films</span>
          </div>

					<div style="margin-top: 20px;">
						<div style="display: flex; gap: 20px;">
							<div style="width: 870px; display: flex; gap: 20px;">
								<strong>a</strong><span>on your own?</span>
							</div>
							<div>#, #, #, #, #, #,#,#,#,#,#</div>
						</div>
						<div style="display: flex; gap: 20px;">
							<div style="width: 870px; display: flex; gap: 20px;">
								<strong>b</strong><span>at home?</span>
							</div>
							<div>#, #, #, #, #, #,#,#,#,#,#</div>
						</div>
						<div style="display: flex; gap: 20px;">
							<div style="width: 870px; display: flex; gap: 20px;">
								<strong>c</strong><span>in a team?</span>
							</div>
							<div>#, #, #, #, #, #,#,#,#,#,#</div>
						</div>
					</div>
				`,
        answer: [
          'bowling / cycling / dancing / drawing / gymnastics /ice skating / listening to music / skateboarding /swimming / video games / watching films',
          'bowling / cycling / dancing / drawing / gymnastics /ice skating / listening to music / skateboarding /swimming / video games / watching films',
          'bowling / cycling / dancing / drawing / gymnastics /ice skating / listening to music / skateboarding /swimming / video games / watching films',
          'bowling / cycling / dancing / drawing / gymnastics /ice skating / listening to music / skateboarding /swimming / video games / watching films',
          'bowling / cycling / dancing / drawing / gymnastics /ice skating / listening to music / skateboarding /swimming / video games / watching films',
          'bowling / cycling / dancing / drawing / gymnastics /ice skating / listening to music / skateboarding /swimming / video games / watching films',
          'bowling / cycling / dancing / drawing / gymnastics /ice skating / listening to music / skateboarding /swimming / video games / watching films',
          'bowling / cycling / dancing / drawing / gymnastics /ice skating / listening to music / skateboarding /swimming / video games / watching films',
          'bowling / cycling / dancing / drawing / gymnastics /ice skating / listening to music / skateboarding /swimming / video games / watching films',
          'bowling / cycling / dancing / drawing / gymnastics /ice skating / listening to music / skateboarding /swimming / video games / watching films',
          'bowling / cycling / dancing / drawing / gymnastics /ice skating / listening to music / skateboarding /swimming / video games / watching films',
          'board games / chess / drawing / listening to music /video games / watching films',
          'board games / chess / drawing / listening to music /video games / watching films',
          'board games / chess / drawing / listening to music /video games / watching films',
          'board games / chess / drawing / listening to music /video games / watching films',
          'board games / chess / drawing / listening to music /video games / watching films',
          'board games / chess / drawing / listening to music /video games / watching films',
          'none',
          'none',
          'none',
          'none',
          'none',
          'basketball / bowling / football / ice hockey / volleyball',
          'basketball / bowling / football / ice hockey / volleyball',
          'basketball / bowling / football / ice hockey / volleyball',
          'basketball / bowling / football / ice hockey / volleyball',
          'basketball / bowling / football / ice hockey / volleyball',
          'none',
          'none',
          'none',
          'none',
          'none',
          'none',
        ],
      },
    ],
  },
  3: {
    unit: 'Vocabulary Builder',
    id: 'SB10-V-124-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page124/E3/answerKey.png',
    component: T6,
    stylesTextInput: {
      fontSize: 21,
    },
    inputSize: 120,
    maxLength: 10,
    titleQuestion: [
      {
        num: '',
        title: `
          <div style="font-size: 30px;"><span style="color: rgb(146, 39, 144);">IC</span> Clothes</div>
          <div><span style="padding-right: 10px;">3</span> Look at the pictures and complete the descriptions. Use the words below.</div>
        `,
        color: 'black',
      },
    ],
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
          <div style="max-width: 1000px;font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>boots</span><span>cardian</span><span>coat</span><span>dress</span>
            <span>gloves</span><span>hat</span><span>hoodie</span><span>jacket</span>
            <span>jeans</span><span>leggings</span><span>scarf</span><span>shirt</span>
            <span>shoes</span><span>shorts</span><span>skirt</span><span>socks</span>
            <span>sweater</span><span>sweatshirt</span><span>T-shirt</span><span>tie</span>
            <span>top</span><span>tracksuit</span><span>trainers</span><span>trousers</span>
          </div>

					<div style="margin-top: 20px; display: flex; flex-wrap: wrap; gap: 150px;">
						<div style="display: flex; flex-direction: column; align-items: center;">
							<img src="img/FriendsPlus/Page124/E2/1.jpg" style="max-width: 100%;" />
							<div style="font-size: 21px;"><span style="font-weight: bold; padding-right: 5px">A</span>Mia is wearing ####</div>
						</div>
						<div style="display: flex; flex-direction: column; align-items: center;">
							<img src="img/FriendsPlus/Page124/E2/2.jpg" style="max-width: 100%;" />
							<div style="font-size: 21px;"><span style="font-weight: bold; padding-right: 5px">B</span>Luke is wearing ####</div>
						</div>
					</div>

					<div style="margin-top: 20px; display: flex; flex-wrap: wrap; gap: 150px;">
						<div style="display: flex; flex-direction: column; align-items: center;">
							<img src="img/FriendsPlus/Page124/E2/3.jpg" style="max-width: 100%;" />
							<div style="font-size: 21px;"><span style="font-weight: bold; padding-right: 5px">C</span>Georgia is wearing #####</div>
						</div>
						<div style="display: flex; flex-direction: column; align-items: center;">
							<img src="img/FriendsPlus/Page124/E2/4.jpg" style="max-width: 100%;" />
							<div style="font-size: 21px;"><span style="font-weight: bold; padding-right: 5px">D</span>Ben is wearing ####</div>
						</div>
					</div>
        `,
        answer: [
          'a dress / a coat / a hat / gloves ',
          'a dress / a coat / a hat / gloves ',
          'a dress / a coat / a hat / gloves ',
          'a dress / a coat / a hat / gloves ',
          'a shirt / a tie / a jacket / trousers',
          'a shirt / a tie / a jacket / trousers',
          'a shirt / a tie / a jacket / trousers',
          'a shirt / a tie / a jacket / trousers',
          'a sweater / T-shirt / a cardigan / a skirt / a scarf / boots',
          'a sweater / T-shirt / a cardigan / a skirt / a scarf / boots',
          'a sweater / T-shirt / a cardigan / a skirt / a scarf / boots',
          'a sweater / T-shirt / a cardigan / a skirt / a scarf / boots',
          'a sweater / T-shirt / a cardigan / a skirt / a scarf / boots',
          'a T-shirt / a hoodie / shorts / trainers',
          'a T-shirt / a hoodie / shorts / trainers',
          'a T-shirt / a hoodie / shorts / trainers',
          'a T-shirt / a hoodie / shorts / trainers',
        ],
      },
    ],
  },
  4: {
    unit: 'Vocabulary Builder',
    id: 'SB10-V-124-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page124/E4/Key/answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title: `
					Write which clothes from exercise 3 you are wearing now.
        `,
        color: 'black',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 21,
    },
    inputSize: 280,
    maxLength: 23,
    isHiddenCheck: true,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    textareaStyle: { width: 600 },
    questions: [
      {
        title: `
          <div style="max-width: 1000px;font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>boots</span><span>cardian</span><span>coat</span><span>dress</span>
            <span>gloves</span><span>hat</span><span>hoodie</span><span>jacket</span>
            <span>jeans</span><span>leggings</span><span>scarf</span><span>shirt</span>
            <span>shoes</span><span>shorts</span><span>skirt</span><span>socks</span>
            <span>sweater</span><span>sweatshirt</span><span>T-shirt</span><span>tie</span>
            <span>top</span><span>tracksuit</span><span>trainers</span><span>trousers</span>
          </div>

					<textarea id="0" rows="10"></textarea>
				`,
        answer: [],
      },
    ],
  },
  5: {
    unit: 'Vocabulary Builder',
    id: 'SB10-V-124-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page124/E5/Key/answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: `
          <div style="font-size: 30px;"><span style="color: rgb(146, 39, 144);">1G</span> <i>get</i></div>
          <div>
  					<span style="padding-right: 10px;">1</span>
  					Complete the sentences with the correct form of get.
  					In your notebook, match the sentences to the correct basic meaning (a–e) in the <span style="color: rgb(146, 39, 144);">Learn this!</span> box.</div>
        `,
        color: 'black',
      },
    ],
    stylesTextInput: {
      fontSize: 22,
      textAlign: 'center',
    },
    inputSize: 150,
    maxLength: 8,
    // isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    textareaStyle: { width: 600 },
    questions: [
      {
        title: `
         	<div style="margin-top: 20px; width: 630px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(146, 39, 144); background-image: radial-gradient(rgb(250 244 244), rgb(219, 196, 223)); font-size: 22px;">
            <div>
              <span style="color: rgb(146, 39, 144); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LEARN THIS!</span>
  						<strong>Basic meanings of <i>get</i></strong>
            </div>
  					<div>The verb <i>get</i> has several basic meanings.</div>
            <div>
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">a</div>
                <div>to receive - <span style="color: rgb(10, 111, 143);">What did you get for your birthday?</span></div>
              </div>
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">b</div>
                <div>to buy or collect - <span style="color: rgb(10, 111, 143);">He went out to get a newspaper</span></div>
              </div>
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">c</div>
                <div>to catch or take (transport) - <span style="color: rgb(10, 111, 143);">Which train did she get?</span></div>
              </div>
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">d</div>
                <div>to arrive - <span style="color: rgb(10, 111, 143);">We got home at midnight.</span></div>
              </div>
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">e</div>
                <div>to become - <span style="color: rgb(10, 111, 143);">I’m getting hungry.</span></div>
              </div>
            </div>
          </div>

  				<div style="margin-top: 20px;">
  					<div style="display: flex; gap: 20px;">
  						<div style="font-weight: bold; text-align: right;">1</div>
  						<div>It was dark when we # to the hotel.</div>
  					</div>
  					<div style="margin-top: 7px; display: flex; gap: 20px;">
  						<div style="font-weight: bold; text-align: right;">2</div>
  						<div>It was too far to walk so we decided to # a taxi.</div>
  					</div>
  					<div style="margin-top: 7px; display: flex; gap: 20px;">
  						<div style="font-weight: bold; text-align: right;">3</div>
  						<div>We live on the equator, so it # dark at the same time every evening.</div>
  					</div>
  					<div style="margin-top: 7px; display: flex; gap: 20px;">
  						<div style="font-weight: bold; text-align: right;">4</div>
  						<div>On the way to the hospital yesterday, we # some flowers for my aunt.</div>
  					</div>
  					<div style="margin-top: 7px; display: flex; gap: 20px;">
  						<div style="font-weight: bold; text-align: right;">5</div>
  						<div>My brother is really excited because he # a new phone for his birthday.</div>
  					</div>
  				</div>

          <div style="margin-top: 20px;">
            <b>ANSWER:</b>
            <div style="display:flex;margin-left:4px">
                <div><b>1</b><input width='50px'/> </div>
                <div><b style="margin-left:30px">2</b><input width='50px'/> </div>
                <div><b style="margin-left:30px">3</b><input width='50px'/> </div>
                <div><b style="margin-left:30px">4</b><input width='50px'/> </div>
                <div><b style="margin-left:30px">5</b><input width='50px'/> </div>
            </div>
          </div>
        `,
        answer: ['got', 'get', 'gets', 'got', ' is getting', 'd', 'c', 'e', 'b', 'a'],
      },
    ],
  },
  // todo

  6: {
    unit: 'Vocabulary Builder',
    id: 'SB10-V-124-E6',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page124/E6/Key/answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: `
					Write one more example for each basic meaning (a–e) of get.
        `,
        color: 'black',
      },
    ],
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 22,
    },
    inputSize: 220,
    maxLength: 18,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    textareaStyle: { width: 600 },
    questions: [
      {
        title: `
					<div style="margin-top: 20px; width: 630px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(146, 39, 144); background-image: radial-gradient(rgb(250 244 244), rgb(219, 196, 223)); font-size: 22px;">
            <div>
              <span style="color: rgb(146, 39, 144); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LEARN THIS!</span> 
							<strong>Basic meanings of <i>get</i></strong>
            </div>
						<div>The verb <i>get</i> has several basic meanings.</div>
            <div>
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">a</div>
                <div>to receive - <span style="color: rgb(10, 111, 143);">What did you get for your birthday?</span></div>
              </div>
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">b</div>
                <div>to buy or collect - <span style="color: rgb(10, 111, 143);">He went out to get a newspaper</span></div>
              </div>
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">c</div>
                <div>to catch or take (transport) - <span style="color: rgb(10, 111, 143);">Which train did she get?</span></div>
              </div>
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">d</div>
                <div>to arrive - <span style="color: rgb(10, 111, 143);">We got home at midnight.</span></div>
              </div>
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">e</div>
                <div>to become - <span style="color: rgb(10, 111, 143);">I’m getting hungry.</span></div>
              </div>
            </div>
          </div>

					<textarea id="0" rows="10"></textarea>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
