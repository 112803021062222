import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Ambition',
		id: 'FG10-A-P67-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page67/E1/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		titleQuestion: [
			{
				num: '1',
				title: `
          Read the formal email. Match elements a–g with parts 1–7 of the email.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="padding-left: 10px;">
            <div style="display: flex;">
              <strong style="padding-right: 10px;">a</strong>
              <span>the email address the email is going to #</span>
            </div>
            <div style="display: flex;">
              <strong style="padding-right: 10px;">b</strong>
              <span>the date #</span>
            </div>
            <div style="display: flex;">
              <strong style="padding-right: 10px;">c</strong>
              <span>the writer’s contact address #</span>
            </div>
            <div style="display: flex;">
              <strong style="padding-right: 10px;">d</strong>
              <span>the writer’s name / signature #</span>
            </div>
            <div style="display: flex;">
              <strong style="padding-right: 10px;">e</strong>
              <span>the person the letter is going to #</span>
            </div>
            <div style="display: flex;">
              <strong style="padding-right: 10px;">f</strong>
              <span>the subject line #</span>
            </div>
            <div style="display: flex;">
              <strong style="padding-right: 10px;">g</strong>
              <span>the writer’s contact telephone number #</span>
            </div>
          </div>

          <div style="margin-top: 20px; width: 800px; font-size: 20px; font-size: 20px; box-shadow: 5px 5px 10px rgba(0,0,0,0.4); padding: 10px 20px;">
            <div style="border-bottom: 1px solid rgb(0,0,0); padding-bottom: 8px;">
              <div><strong>From:</strong> Grace Browning &lt;gracebrowning@hotmail.com&gt;</div>
              <div><strong>Sent:</strong> <sup>1</sup>Saturday, November 16, 10:06 am</div>
              <div><strong><sup>2</sup>To:</strong> Mbellis@brightschool.org</div>
              <div><strong><sup>3</sup>Subject:</strong>Application for the role of starting-line steward</div>
            </div>

            <div>
              <div style="margin-top: 10px;"><sup>4</sup>Dear Mr Bellis,</div>

              <div style="margin-top: 10px; display: flex; align-items: start;">
                <div style="margin-right: 20px; text-align: center; flex: 1; border: 2px solid rgb(34, 132, 198); border-radius: 5px; font-weight: bold;">A</div>
                <div style="flex: 22;">
                  I noticed your advertisement in the school canteen for
                  a starting-line steward at the school charity run,
                  and I am writing to apply for the role.
                </div>
              </div>
              <div style="margin-top: 10px; display: flex; align-items: start;">
                <div style="margin-right: 20px; text-align: center; flex: 1; border: 2px solid rgb(34, 132, 198); border-radius: 5px; font-weight: bold;">B</div>
                <div style="flex: 22;">
                  I was planning to take part in the charity run,
                  but I find it much more rewarding if I volunteer as part of the event.
                  I am keen to help as a steward because I am very sociable and organised.
                </div>
              </div>
              <div style="margin-top: 10px; display: flex; align-items: start;">
                <div style="margin-right: 20px; text-align: center; flex: 1; border: 2px solid rgb(34, 132, 198); border-radius: 5px; font-weight: bold;">C</div>
                <div style="flex: 22;">
                  I have experience of volunteering.
                  Last year, I helped at the village fair.
                  I was in charge of the paints and materials.
                  I believe I possess all the necessary personal qualities for the role.
                  I am positive, energetic and reliable.
                </div>
              </div>
              <div style="margin-top: 10px; display: flex; align-items: start;">
                <div style="margin-right: 20px; text-align: center; flex: 1; border: 2px solid rgb(34, 132, 198); border-radius: 5px; font-weight: bold;">D</div>
                <div style="flex: 22;">
                  I am available for the charity run meeting after school next Monday.
                  Moreover, my parents can take me to the event.
                </div>
              </div>
              <div style="margin-top: 10px; display: flex; align-items: start;">
                <div style="margin-right: 20px; text-align: center; flex: 1; border: 2px solid rgb(34, 132, 198); border-radius: 5px; font-weight: bold;">E</div>
                <div style="flex: 22;">
                  I look forward to hearing from you soon.
                </div>
              </div>

              <div style="margin-top: 10px;">
                <div>Yours sincerely,</div>
                <div style="margin-top: 10px;">
                <sup>5</sup>Grace Browning <br/>
                <sup>6</sup>14 Langley Drive <br/>
                London <br/>
                <sup>7</sup>07997 426589
                </div>
              </div>
            </div>
          </div>
				`,
				answer: ['2', '1', '6', '5', '4', '3', '7'],
			},
		],
	},
	2: {
		unit: 'Ambition',
		id: 'FG10-A-P67-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page67/E2/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '2',
				title: `
          Complete the paragraph plan by matching paragraphs A–E 
          of the email with descriptions 1–5 below.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="padding-left: 10px;">
            <div style="display: flex;">
              <strong style="padding-right: 10px;">1 Paragraph</strong> 
              <span><span style="color: rgb(0, 153, 219);">D</span>: when you are available</span>				
            </div>
            <div style="display: flex;">
              <strong style="padding-right: 10px;">2 Paragraph</strong> 
              <span>#: what you would like to happen next.</span>				
            </div>
            <div style="display: flex;">
              <strong style="padding-right: 10px;">3 Paragraph</strong> 
              <span>#: why you are interested in the role.</span>				
            </div>
            <div style="display: flex;">
              <strong style="padding-right: 10px;">4 Paragraph</strong> 
              <span>#: why you are the right person for the role.</span>				
            </div>
            <div style="display: flex;">
              <strong style="padding-right: 10px;">5 Paragraph</strong> 
              <span>#: what the role is and how you found out about it</span>				
            </div>
          </div>

          <div style="margin-top: 20px; width: 900px; font-size: 20px; box-shadow: 5px 5px 10px rgba(0,0,0,0.4); padding: 10px 20px;">
            <div style="border-bottom: 1px solid rgb(0,0,0); padding-bottom: 8px;">
              <div><strong>From:</strong> Grace Browning &lt;gracebrowning@hotmail.com&gt;</div>
              <div><strong>Sent:</strong> <sup>1</sup>Saturday, November 16, 10:06 am</div>
              <div><strong><sup>2</sup>To:</strong> Mbellis@brightschool.org</div>
              <div><strong><sup>3</sup>Subject:</strong>Application for the role of starting-line steward</div>
            </div>

            <div>
              <div style="margin-top: 10px;"><sup>4</sup>Dear Mr Bellis,</div>        

              <div style="margin-top: 10px; display: flex; align-items: start;">
              <div style="margin-right: 20px; text-align: center; flex: 1; border: 2px solid rgb(34, 132, 198); border-radius: 5px; font-weight: bold;">A</div>
              <div style="flex: 22;">
                I noticed your advertisement in the school canteen for 
                a starting-line steward at the school charity run, 
                and I am writing to apply for the role.
              </div>
              </div>
              <div style="margin-top: 10px; display: flex; align-items: start;">
              <div style="margin-right: 20px; text-align: center; flex: 1; border: 2px solid rgb(34, 132, 198); border-radius: 5px; font-weight: bold;">B</div>
              <div style="flex: 22;">
                I was planning to take part in the charity run, 
                but I find it much more rewarding if I volunteer as part of the event. 
                I am keen to help as a steward because I am very sociable and organised.
              </div>
              </div>
              <div style="margin-top: 10px; display: flex; align-items: start;">
              <div style="margin-right: 20px; text-align: center; flex: 1; border: 2px solid rgb(34, 132, 198); border-radius: 5px; font-weight: bold;">C</div>
              <div style="flex: 22;">
                I have experience of volunteering. 
                Last year, I helped at the village fair. 
                I was in charge of the paints and materials. 
                I believe I possess all the necessary personal qualities for the role. 
                I am positive, energetic and reliable.
              </div>
              </div>
              <div style="margin-top: 10px; display: flex; align-items: start;">
              <div style="margin-right: 20px; text-align: center; flex: 1; border: 2px solid rgb(34, 132, 198); border-radius: 5px; font-weight: bold;">D</div>
              <div style="flex: 22;">
                I am available for the charity run meeting after school next Monday. 
                Moreover, my parents can take me to the event.
              </div>
              </div>
              <div style="margin-top: 10px; display: flex; align-items: start;">
              <div style="margin-right: 20px; text-align: center; flex: 1; border: 2px solid rgb(34, 132, 198); border-radius: 5px; font-weight: bold;">E</div>
              <div style="flex: 22;">
                I look forward to hearing from you soon.
              </div>
              </div>

              <div style="margin-top: 10px;">
              <div>Yours sincerely,</div>
              <div style="margin-top: 10px;">
              <sup>5</sup>Grace Browning <br/>
              <sup>6</sup>14 Langley Drive <br/>
              London <br/>
              <sup>7</sup>07997 426589
              </div>
              </div>
            </div>
          </div>
				`,
				answer: ['E', 'B', 'C', 'A'],
			},
		],
	},
	3: {
		unit: 'Ambition',
		id: 'FG10-A-P67-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page67/E3/Key/answerKey.png',
		component: T6,
		inputSize: 600,
		maxLength: 102,
		textareaStyle: { width: 600 },
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
          In which paragraph does Grace mention …
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div style="padding-left: 10px;">
            <div style="display: flex;">
              <strong style="padding-right: 10px;">1</strong>
              <div>
                <span>her reasons for applying for the role? </span>
                <textarea id="2" rows="2"></textarea>
              </div>				
            </div>
            <div style="margin-top: -37px; display: flex;">
              <strong style="padding-right: 10px;">2</strong>
              <span>her experience? <br/>#</span>				
            </div>
            <div style="display: flex;">
              <strong style="padding-right: 10px;">3</strong>
              <span>her personal qualities? <br/>#</span>				
            </div>
          </div>

          <div style="margin-top: 20px; width: 900px; font-size: 20px; box-shadow: 5px 5px 10px rgba(0,0,0,0.4); padding: 10px 20px;">
            <div style="border-bottom: 1px solid rgb(0,0,0); padding-bottom: 8px;">
              <div><strong>From:</strong> Grace Browning &lt;gracebrowning@hotmail.com&gt;</div>
              <div><strong>Sent:</strong> <sup>1</sup>Saturday, November 16, 10:06 am</div>
              <div><strong><sup>2</sup>To:</strong> Mbellis@brightschool.org</div>
              <div><strong><sup>3</sup>Subject:</strong>Application for the role of starting-line steward</div>
            </div>

            <div>
              <div style="margin-top: 10px;"><sup>4</sup>Dear Mr Bellis,</div>        

              <div style="margin-top: 10px; display: flex; align-items: start;">
              <div style="margin-right: 20px; text-align: center; flex: 1; border: 2px solid rgb(34, 132, 198); border-radius: 5px; font-weight: bold;">A</div>
              <div style="flex: 22;">
                I noticed your advertisement in the school canteen for 
                a starting-line steward at the school charity run, 
                and I am writing to apply for the role.
              </div>
              </div>
              <div style="margin-top: 10px; display: flex; align-items: start;">
              <div style="margin-right: 20px; text-align: center; flex: 1; border: 2px solid rgb(34, 132, 198); border-radius: 5px; font-weight: bold;">B</div>
              <div style="flex: 22;">
                I was planning to take part in the charity run, 
                but I find it much more rewarding if I volunteer as part of the event. 
                I am keen to help as a steward because I am very sociable and organised.
              </div>
              </div>
              <div style="margin-top: 10px; display: flex; align-items: start;">
              <div style="margin-right: 20px; text-align: center; flex: 1; border: 2px solid rgb(34, 132, 198); border-radius: 5px; font-weight: bold;">C</div>
              <div style="flex: 22;">
                I have experience of volunteering. 
                Last year, I helped at the village fair. 
                I was in charge of the paints and materials. 
                I believe I possess all the necessary personal qualities for the role. 
                I am positive, energetic and reliable.
              </div>
              </div>
              <div style="margin-top: 10px; display: flex; align-items: start;">
              <div style="margin-right: 20px; text-align: center; flex: 1; border: 2px solid rgb(34, 132, 198); border-radius: 5px; font-weight: bold;">D</div>
              <div style="flex: 22;">
                I am available for the charity run meeting after school next Monday. 
                Moreover, my parents can take me to the event.
              </div>
              </div>
              <div style="margin-top: 10px; display: flex; align-items: start;">
              <div style="margin-right: 20px; text-align: center; flex: 1; border: 2px solid rgb(34, 132, 198); border-radius: 5px; font-weight: bold;">E</div>
              <div style="flex: 22;">
                I look forward to hearing from you soon.
              </div>
              </div>

              <div style="margin-top: 10px;">
              <div>Yours sincerely,</div>
              <div style="margin-top: 10px;">
              <sup>5</sup>Grace Browning <br/>
              <sup>6</sup>14 Langley Drive <br/>
              London <br/>
              <sup>7</sup>07997 426589
              </div>
              </div>
            </div>
          </div>
				`,
				answer: [
					'Paragraph C. She has experience of volunteering at a village fair.',
					'Paragraph C. She is positive, energetic and reliable.',
					'Paragraph B. She was going to take part herself, but would find it more satisfiying to be a volunteer.',
				],
			},
		],
	},
	4: {
		unit: 'Ambition',
		id: 'FG10-A-P67-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page67/E4/Key/answerKey.png',
		component: T6,
		inputSize: 210,
		maxLength: 49,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '4',
				title: `
          Read the <span style="color: rgb(34, 132, 198);">Writing Strategy</span>. Answer the questions.
  			`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				  <div style="display: flex;">
				    <div style="font-size: 20px; margin-left: 20px; width: 400px; padding: 10px; border-radius: 10px; border: 4px dotted rgb(34, 132, 198); background-color: rgb(232, 237, 243); box-shadow: 3px 3px 5px rgba(0,0,0,0.4);">
				      <span style="font-weight: bold; color: rgb(34, 132, 198);">Writing Strategy</span>
				      <div>
				        When you write a formal letter / email:
				        <ul>
				          <li>Start with: <i>Dear (Mrs White)</i>, if you know the name of the person, or <i>Dear Sir or Madam</i>, if you do not.</li>
				          <li>Include the topic of the email in the subject line.</li>
				          <li>Write in paragraphs. One-sentence paragraphs are fine for opening or closing an application letter.</li>
				          <li>Do not use colloquial language or short forms (<i>I’m, there’s</i>, etc.).</li>
				          <li>Finish with <i>Yours sincerely</i>, if you used the person’s name at the start, or <i>Yours faithfully</i>, if you did not.</li>
				        </ul>
				      </div>
				    </div>

            <div style=" margin-left: 20px;">
              <div style="padding: 10px; font-size: 20px; border: 1px solid rgba(0,0,0, 0.4); border-radius: 10px; box-shadow: 3px 3px 5px rgba(0,0,0,0.4);">
                <span style="font-weight: bold; font-style: italic;">Answer:</span>
                <div style="display: flex;">
                  <strong style="padding-right: 10px;">1</strong>
                  <span>What is the subject line in Grace’s letter? <br/><input id='0' width='440px' /></span>
                </div>
                <div style="display: flex;">
                  <strong style="padding-right: 10px;">2</strong>
                  <span>Does Grace include any one-sentence paragraphs? <br/><input id='1' width='440px' /></span>
                </div>

                <div>
                  <div style="display: flex;">
                    <strong style="padding-right: 10px;">3</strong>
                    <span>How does Grace express these things in a more formal way?</span>
                  </div>
                  <div style="padding-left: 20px;">
                    <div style="display: flex;">
                      <strong style="padding-right: 10px;">a</strong>
                      <span>I really want to ... (paragraph B) <br/>#</span>
                    </div>
                    <div style="display: flex;">
                      <strong style="padding-right: 10px;">b</strong>
                      <span>I think I’ve got ... (paragraph C) <br/>#</span>
                    </div>
                    <div style="display: flex;">
                      <strong style="padding-right: 10px;">c</strong>
                      <span>I can come to ... (paragraph D) <br/>#</span>
                    </div>
                    <div style="display: flex;">
                      <strong style="padding-right: 10px;">d</strong>
                      <span>Best wishes from <br/>#</span>
                    </div>
                  </div>
                </div>
              </div>

              <div style="margin-top: 20px; padding: 0 10px; border-radius: 10px; box-shadow: 0 0 10px rgb(34, 132, 198); display: flex; justify-content: center; align-items: center;">
                <img src="img/FriendsPlus/Page60/E3/1.jpg" style="max-width: 100%;" />
                <span style="margin-left: 10px; color: rgb(34, 132, 198); font-size: 20px;"><span style="font-weight: bold; padding-right: 20px;">Vocabulary Builder 5H</span> Formal language: page 126</span>
              </div>
            </div>
				  </div>
				`,
				answer: [
					'Application for the role of starting-line steward',
					'yes (paragraphs A and E)',
					'I am keen to ...',
					'I believe I possess ...',
					'I am available for',
					'Yours sincerely',
				],
			},
		],
	},
	5: {
		unit: 'Ambition',
		id: 'FG10-A-P67-E5',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 40,
		maxLength: 150,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 1060 },
		titleQuestion: [
			{
				num: '5',
				title: `
					Read the volunteer advert below and plan an application email. 
          Follow the paragraph plan you completed in exercise 2. 
          Then write your email (120-150 words).
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				  <div style="display: flex; align-items: end;">
				    <div style="width: 600px; display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center; padding: 10px 40px; background-color: rgb(255, 240, 170); transform: rotate(-1deg); margin-top: 20px; box-shadow: 5px 5px 10px rgba(0,0,0,0.4); font-family: 'Brush Script MT', cursive; font-size: 20px;">
				      <div style="font-size: 35px; font-weight: bold; color: rgb(0, 153, 219);">Volunteers needed</div>
				      <div>The school music group are looking for volunteers to support our school concert.</div>
				      <div style="margin-top: 10px;">We need help with organising the event, carrying musical instruments and decorating the hall.</div>
				      <div style="margin-top: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
				        <div><strong>When?:</strong> Saturday 14<sup>th</sup> April</div>
				        <div><strong>What time?:</strong> 2PM to 6PM</div>
				        <div><strong>Where?:</strong> Lourd School Hall</div>
				      </div>
				      <div style="margin-top: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
				        <div>If you’re interested, please apply to Claire Veneer</div>
				        <div><strong>c.veneer@Lourdsch.com</strong></div>
				      </div>
				    </div>

				    <div style="margin-left: 40px; margin-top: 20px; width: 430px; display: flex; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(34, 132, 198);; background-image: radial-gradient(rgb(244, 247, 252), rgb(202, 216, 239)); font-size: 20px;">
				      <div style="margin-right: 10px; margin-top: 6px; font-weight: bold; font-size: 50px; color: rgb(34, 132, 198);">
				        <img src="img/FriendsPlus/Page67/E5/1.jpg" style="max-width: 100%;" />
				      </div>
				      <div>
				        <span style="color: rgb(34, 132, 198); font-weight: bold; text-transform: uppercase; margin-right: 10px;">CHECK YOUR WORK</span>
				        <div>
				          <span>Have you ...</span>
				          <ul>
				            <li>followed your paragraph plan?</li>
				            <li>used appropriately formal language?</li>
				            <li>checked the spelling and grammar?</li>
				          </ul>
				        </div>
				      </div>
				    </div>
				  </div>

				  <div style="margin-top: 20px;"><textarea id="0" rows="5"></textarea></div>
				`,
				// title: ` // NO AN EYE SIGN
				//   <div style="display: flex; align-items: end;">
				//     <div style="width: 600px; display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center; padding: 10px 40px; background-color: rgb(255, 240, 170); transform: rotate(-1deg); margin-top: 20px; box-shadow: 5px 5px 10px rgba(0,0,0,0.4); font-family: 'Brush Script MT', cursive; font-size: 20px;">
				//       <div style="font-size: 35px; font-weight: bold; color: rgb(0, 153, 219);">Volunteers needed</div>
				//       <div>The school music group are looking for volunteers to support our school concert.</div>
				//       <div style="margin-top: 10px;">We need help with organising the event, carrying musical instruments and decorating the hall.</div>
				//       <div style="margin-top: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
				//         <div><strong>When?:</strong> Saturday 14<sup>th</sup> April</div>
				//         <div><strong>What time?:</strong> 2PM to 6PM</div>
				//         <div><strong>Where?:</strong> Lourd School Hall</div>
				//       </div>
				//       <div style="margin-top: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
				//         <div>If you’re interested, please apply to Claire Veneer</div>
				//         <div><strong>c.veneer@Lourdsch.com</strong></div>
				//       </div>
				//     </div>

				//     <div style="margin-left: 40px; margin-top: 20px; width: 410px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(34, 132, 198); background-image: radial-gradient(rgb(244, 247, 252), rgb(202, 216, 239)); font-size: 20px;">
				//       <div>
				//         <span style="color: rgb(34, 132, 198); font-weight: bold; text-transform: uppercase; margin-right: 10px;">CHECK YOUR WORK</span>
				//       </div>
				//       <div>
				//         <span>Have you ...</span>
				//         <ul>
				//           <li>followed your paragraph plan?</li>
				//           <li>used appropriately formal language?</li>
				//           <li>checked the spelling and grammar?</li>
				//         </ul>
				//       </div>
				//     </div>
				//   </div>

				//   <div style="margin-top: 20px;"><textarea id="0" rows="5"></textarea></div>
				// `,
				answer: [],
			},
		],
	},
};

export default json;
