import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  5: {
    unit: 'Unit 6',
    id: 'SB10-U6-P71-E5',
    audio: 'Audios/Friends Global Grade 10 SB_CD2_27.mp3',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '5',
        title: `
          <headphone1 name='2.27'></headphone1> Listen and repeat all of the shops and
services words from exercise 4.
        <div style="color: rgb(146,39,143);margin:20px 0px;width:26cm">
             <b>Shops and services</b> baker’s bank butcher’s charity shop chemist’s coffee shop clothes shop cosmetics store deli (delicatessen) DIY store
             estate agent’s florist’s garden centre greengrocer’s jeweller’s
             aunderette newsagent’s optician’s post office
             shoe shop stationer’s takeaway
          </div>
        `,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [],
  },
  6: {
    unit: 'Unit 6',
    id: 'SB10-U6-P71-E6',
    exerciseKey: '/img/FriendsPlus/Page71/E6/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_28.mp3',
    video: '',
    component: T6,
    inputSize: 150,
    maxLength: 81,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      // color: 'rgb(39,131,197)',
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '6',
        title: `
          <headphone1 name="2.28"></headphone1>
          Listen to four dialogues. Match them with four of the shops and services from exercise 4
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div style="color: rgb(146,39,143);">
             <b>Shops and services</b> baker's bank butcher's charity shop chemist's coffee shop clothes shop cosmetics store delicatessen DIY store
             estate agent's florist's garden centre greengrocer's jeweller's 
             aunderette newsagent's optician's post office
             shoe shop stationer's takeaway
          </div>
          <div style="display: flex;margin-top: 20px;gap: 80px;">
            <div style="margin-right: 20px;">
              <b style="margin-right: 15px;">1</b>
              <span style="color: rgb(39 131 197)"> DIY store</span> <br>
              <b>2</b>
              <span> #</span>
            </div>
            <div>
              <b>3</b>
              <span> #</span> <br>
              <b>4</b>
              <span> #</span>
            </div>
          </div>
        `,
        answer: [`newsagent's`, `clothes shop`, `jeweller's`],
      },
    ],
  },
  7: {
    unit: 'Unit 6',
    id: 'SB10-U6-P71-E7',
    exerciseKey: '/img/FriendsPlus/Page71/E7/Key/answerKey.png',
    audio: '',
    video: '',
    textAlign: 'center',
    component: T6,
    inputSize: 170,
    maxLength: 13,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      // fontWeight: 'bold',
      // fontSize: 23,
      // color: 'rgb(146,39,143)',
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: '7',
        title: `
            <span style="color: rgb(146,39,143);">VOCABULARY</span>
            <span> Complete definitions 1–8 with the words and phrases below.</span>
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div style="color: rgb(146,39,143);">
            <div>
              <b>Shopping</b> 
              <span>&nbsp bargain &nbsp coupons &nbsp discount</span> <br>
              <span> price &nbsp tag &nbsp receipt &nbsp refund &nbsp sale &nbsp special offer</span>
            </div>
          </div>
          
          <div style="margin-top: 20px; line-height:50px">
            <div>
              <b>1</b> 
              <span> “Buy one, get one free” is a common example of a</span>
              <span>#</span>
            </div>
            <div>
              <b>2</b> 
              <span> If you think something is a</span>
              <span><input type="text" width="110px"></span>
              <span> , you think it’s a good price for what you are getting.</span>
            </div>
            <div>
              <b>3</b> 
              <span> The</span>
              <span><input type="text" width="130px"></span>
              <span> tells you how much something
                costs in a shop.  </span>
            </div>
            <div>
              <b>4</b> 
              <span> When a shop has a</span>
              <span><input type="text" width="80px"></span>
              <span> , it sells some of its items at lower prices for a few days or weeks  </span>
            </div>
            <div>
              <b>5</b> 
              <span> If a shop gives you a</span>
              <span><input type="text" width="130px"></span>
              <span> , you pay less than the usual price. </span>
            </div>
            <div>
              <b>6</b> 
              <span> Magazines sometimes have</span>
              <span><input type="text" width="130px"></span>
              <span> that give you money off in a shop </span>
            </div>
            <div>
              <b>7</b> 
              <span> If you ask for a </span>
              <span><input type="text" width="110px"></span>
              <span> in a shop, you ask for
                your money back. </span>
            </div>
            <div>
              <b>8</b> 
              <span> A </span>
              <span><input type="text" width="110px"></span>
              <span> is a small piece of paper that you get when you pay for something. </span>
            </div>
          </div>
        `,
        answer: ['special offer', 'bargain', 'price tag', 'sale', 'discount', 'coupons', 'refund', 'receipt'],
      },
    ],
  },
  8: {
    unit: 'Unit 6',
    id: 'SB10-U6-P71-E8',
    exerciseKey: '/img/FriendsPlus/Page71/E8/Key/answerKey.png',
    audio: '',
    video: '',
    component: D1,
    fontSize: 23,
    maxLength: 15,
    // checkDuplicated: true,
    textAlign: 'center',
    // stylesTextInput: {
    //   borderBottom: 'dash',
    //   background: 'red',
    //   height: 28,
    // },
    // textareaStyle: {},
    titleQuestion: [
      {
        num: '8',
        title: `
              <b style="color: rgb(45,206,137);">SPEAKING</b>
              Read the 
              <span style="color: rgb(146,39,143);">Recycle!</span>
              box. Look at the
                questionnaire below and complete the questions
                with the present perfect form of the verbs in brackets
                and ever. Then do the questionnaire in pairs.
        `,
        color: '#860052',
      },
    ],

    questionImage: [
      // Row
      [{ url: 'img/FriendsPlus/Page71/E8/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page71/E8/2.jpg' },
        { url: 'img/FriendsPlus/Page71/E8/3.jpg', input: 'true', answer: 'Have you / Have' },
        { url: 'img/FriendsPlus/Page71/E8/4.jpg' },
        { url: 'img/FriendsPlus/Page71/E8/5.jpg', input: 'true', answer: 'Have you / Have' },
        { url: 'img/FriendsPlus/Page71/E8/6.jpg' },
        { url: 'img/FriendsPlus/Page71/E8/7.jpg', input: 'true', answer: 'Have you' },
        { url: 'img/FriendsPlus/Page71/E8/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page71/E8/9.jpg' },
        { url: 'img/FriendsPlus/Page71/E8/10.jpg', input: 'true', answer: 'ever asked / you ever asked' },
        { url: 'img/FriendsPlus/Page71/E8/11.jpg' },
        { url: 'img/FriendsPlus/Page71/E8/12.jpg', input: 'true', answer: 'ever used / you ever used' },
        { url: 'img/FriendsPlus/Page71/E8/13.jpg' },
        { url: 'img/FriendsPlus/Page71/E8/14.jpg', input: 'true', answer: 'ever compared' },
        { url: 'img/FriendsPlus/Page71/E8/15.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page71/E8/16.jpg' }],
      [
        { url: 'img/FriendsPlus/Page71/E8/17.jpg' },
        { url: 'img/FriendsPlus/Page71/E8/18.jpg', input: 'true', answer: 'Have you / Have' },
        { url: 'img/FriendsPlus/Page71/E8/19.jpg' },
        { url: 'img/FriendsPlus/Page71/E8/20.jpg', input: 'true', answer: 'Have you / Have' },
        { url: 'img/FriendsPlus/Page71/E8/21.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page71/E8/22.jpg' },
        { url: 'img/FriendsPlus/Page71/E8/23.jpg', input: 'true', answer: 'ever waited / you ever waited' },
        { url: 'img/FriendsPlus/Page71/E8/24.jpg' },
        { url: 'img/FriendsPlus/Page71/E8/25.jpg', input: 'true', answer: 'ever seen / you ever seen' },
        { url: 'img/FriendsPlus/Page71/E8/26.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page71/E8/27.jpg' }],
    ],
  },
};

export default json;
