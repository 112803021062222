import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: {
    unit: 'Unit 3',
    id: 'SB10-U3-P44-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '1',
        title: `
        <b style='color:rgb(2, 146, 97);'>SPEAKING</b> Look at the photo. Do you know this TV series? What type of programme do you think it is? Choose from the words below. 
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    textareaStyle: { width: 600 },
    hideBtnFooter: true,
    recorder: true,
    hintBox: [
      {
        src: ['detective drama', 'talent show', 'comedy', 'science fiction'],
        // arrow: true,
        width: 600,
        borderColor: '#2284c8',
      },
    ],
    questions: [
      {
        title: `
        <div style='display: flex; gap:30px; margin-left: 0px;'>
            <div style='padding: 5px 5px 25px 5px;' >
				      <div style='margin: 20px 0px 10px 0px;'><hintBox id='0' ></hintBox></div>
					    <div style="margin-top: 40px;"><textarea id="0" rows="5"></textarea></div>
            </div>
				    <img src='img/FriendsPlus/Page44/E1/1.jpg' style='width: 350px;' />
        </div>
			  `,
        answer: [],
      },
    ],
  },
  2: {
    unit: 'Unit 3',
    id: 'SB10-U3-P44-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page44/E2/key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `
        Read the text.  
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 260,
    textAlign: 'center',
    maxLength: 20,

    hintBox: [
      {
        src: ['detective drama', 'talent show', 'comedy', 'science fiction'],
        // arrow: true,
        width: 500,
        borderColor: '#2284c8',
      },
    ],
    questions: [
      {
        title: `
        <div style='display: flex; gap:30px; margin-left: 0px;'>
            <div style='padding: 5px 5px 25px 5px;' >
				        <div style='margin: 20px 0px 10px 0px;'><hintBox id='0' ></hintBox></div>
                <div style='margin-right: 10px;'><b> A </b> Check your answer to exercise 1.</div>
                <div>#</div>
                <div style='margin-right: 10px;'><b> B </b> What type of programme is the other series mentioned in the text?</div>
                <div>#</div>
            </div>
				    <img src='img/FriendsPlus/Page44/E2/1.jpg' style='width: 500px;' />
        </div>
			  `,
        answer: ['detective drama', 'comedy'],
      },
    ],
  },
  3: {
    unit: 'Unit 3',
    id: 'SB10-U3-P44-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page44/E3/Key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `
				Are the sentences true or false? Write T or F.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 60,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: ['T', 'F'],
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 &emsp;</b></div>
          <div> Online series are mainly popular with young people. </div>
          <b> <select id='0'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 &emsp;</b></div>
          <div> <i>The Inbestigators</i> is about four young detectives.</div>
          <b> <select id='1'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 &emsp;</b></div>
          <div> In <i>The Thundermans</i>, Max and Phoebe have similar ideas about what they want to do with their superpowers.</div>
          <b> <select id='2'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 &emsp;</b></div>
          <div> The <i>Inbestigators</i> started before <i>The Thundermans</i>. </div>
          <b> <select id='3'></select>  </b>
        </div>
			  `,
        answer: ['F', 'T', 'F', 'F'],
      },
    ],
  },
  4: {
    unit: 'Unit 3',
    id: 'SB10-U3-P44-E4',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_41.mp3',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '4',
        title: `
          <headphone1 name='1.41' src='' ></headphone1> Listen to the information about <i>You</i> vs <i>Wild</i>.
        `,
        color: '#860052',
      },
    ],
    exerciseKey: '/img/FriendsPlus/Page44/E4/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          margin: '-2px',
        },
        limitSelect: 1,
        listWords: ['1 <br> 2 <br> 3'],
        answers: ['0-8'],
        initialValue: [],
      },
      Layout: `
        <div>
            <div><b> What makes it different from other series?</b> </div>
            <div style="margin-left: 15px; display: flex;">
              <div><b><input type="Circle" id="0" /></b> </div>
              <div style="margin-left: 10px;">
                It is an action and adventure series.<br />
                Bear Grylls faces lots of dangerous situations. <br />
                The viewer chooses what happens in each episode.<br />
              </div>
            </div>
        </div>
        
      `,
    },
  },
  5: {
    unit: 'Unit 3',
    id: 'SB10-U3-P44-E5',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_41.mp3',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '5',
        title: `
          <headphone1 name='1.41' src='' ></headphone1> Listen again. Circle the correct answers.
        `,
        color: '#860052',
      },
    ],
    exerciseKey: '/img/FriendsPlus/Page44/E5/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          margin: '-2px',
        },
        limitSelect: 1,
        listWords: ['2018 / 2019', 'pilot / doctor', 'torch / hook', 'the_same_country / different_parts_of_the_world'],
        answers: ['2-4', '3-4', '0-4', '1-4'],
        initialValue: [],
      },
      Layout: `
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div> The first episode of You vs Wild was on TV in <b><input type="Circle" id="0" /></b>. </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div> In the first episode, Grylls is searching for a <b><input type="Circle" id="1" /></b>. </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div> Viewers have to choose between a slingshot and a <b><input type="Circle" id="2" /></b>. </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div> All the episodes take place in <b><input type="Circle" id="3" /></b>. </div>
        </div>

        
      `,
    },
  },
  6: {
    unit: 'Unit 3',
    id: 'SB10-U3-P44-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    hideBtnFooter: true,
    recorder: true,
    titleQuestion: [
      {
        num: '6',
        title: `
        <b style='color:rgb(2, 146, 97);'>SPEAKING</b> Ask and answer the questions in pairs.
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `

          <div style='display: flex; gap:30px; margin-left: 0px;'>
            <div style='padding: 5px 5px 25px 5px;' >
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 1 </b></div>
                <div> Do you watch any of the shows mentioned in the text in exercise 2? What do you think of them?</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> 1 </b></div>
                <div> What are your favourite TV series? Do you watch them online?</div>
              </div>
            </div>
				    <img src='img/FriendsPlus/Page44/E6/1.jpg' style='width: 550px;' />
         </div>

			  `,
        answer: [],
      },
    ],
  },
};

export default json;
