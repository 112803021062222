import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2';

const json = {
  1: {
    unit: 'Unit 1',
    id: 'FG10-U1-P10-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page10/E1/Key/answerKey.png',
    component: T6,
    padding: 0,
    inputSize: 150,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: '1',
        title: "<span style='color: rgb(25, 158, 86)'>SPEAKING</span> Look at the photos and answer the questions.",
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <div ><img src="img/FriendsPlus/Page10/E1/1.jpg" alt="" style="width: 900px;"/></div>
        <div><b>1. </b>How do you think these people are feeling?</div>
        <div style="display: flex; gap: 70px;">
          <div><b>A. </b>#</div>
          <div><b>B. </b>#</div>
          <div><b>C. </b>#</div>
        </div>
        <div><b>2. </b>What do you think is making them feel that way?</div>
        <div><i style="color: rgb(154, 201, 243);">The girls in B are feeling happy because it is their graduation day.</i></div>
          <div style="display: flex;"><b>A. </b><textarea id="3" rows="2"></textarea></div>
          <div style="display: flex;"><b>B. </b><textarea id="4" rows="1"></textarea></div>
          <div style="display: flex;"><b>C. </b><textarea id="5" rows="1"></textarea></div>
        `,
        answer: [
          `worried`,
          `happy/excited `,
          `bored`,
          `The man is worried because the people are standing too close to each other and they are not wearing masks.`,
          `The girls are happy because it is their graduation day.`,
          `The girl's schoolwork is difficult and boring.`,
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 1',
    id: 'FG10-U1-P10-E2',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_04.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page10/E2/Key/answerKey.png',
    component: T6,
    padding: 0,
    inputSize: 150,
    titleQuestion: [
      {
        num: '2',
        title: `<audioimage name="1.04"></audioimage><span style='color: #92278f'> VOCABULARY</span> Check the meaning of all the adjectives below. Can you match any of them with the photos in exercise 1?`,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <div style='color:rgb(146, 39, 143)'><b>Adjectives to describe feelings</b>  
         &emsp; anxious &emsp; ashamed &emsp; bored &emsp; confused &emsp; cross &emsp; delighted &emsp; disappointed &emsp; embarrassed &emsp; envious &emsp; excited &emsp; frightened &emsp; proud &emsp; relieved &emsp; shocked &emsp; suspicious &emsp; upset
        </div>
        <div><i style="color: rgb(154, 201, 243);">The people in photo A look ...</i></div>
          <div><b>A. </b>#,#.</div>
          <div><b>B. </b>#,#,#.</div>
          <div><b>C. </b>#.</div>
        `,
        answer: [
          `worried/confused`,
          `worried/confused`,
          `delighted/excited/proud`,
          `delighted/excited/proud`,
          `delighted/excited/proud`,
          `bored`,
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 1',
    id: 'FG10-U-P10-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page10/E3/Key/answerKey.png',
    padding: 0,
    inputSize: 170,
    titleQuestion: [
      {
        num: '3',
        title: `
          Work in pairs. Put the adjectives in exercise 2 into categories below. Do you know any other adjectives you could add to the categories?
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style='color:rgb(146, 39, 143)'><b>Adjectives to describe feelings</b>  
         &emsp; anxious &emsp; ashamed &emsp; bored &emsp; confused &emsp; cross &emsp; delighted &emsp; disappointed &emsp; embarrassed &emsp; envious &emsp; excited &emsp; frightened &emsp; proud &emsp; relieved &emsp; shocked &emsp; suspicious &emsp; upset
        </div>

           <div style='width:1000px;'><table style='border: 1px solid rgb(203, 121, 231);'>
            <tr style='background-color: rgb(146,39,143); color: white;'>
              <th style='border: 1px solid rgb(203, 121, 231);'>&emsp; Positive feelings</th>
              <th style='border: 1px solid rgb(203, 121, 231);'>&emsp; Negative feelings</th>
            </tr>
            <tr>
              <td style='border: 1px solid rgb(203, 121, 231);'>
                <div><i style="color: rgb(154, 201, 243);">&ensp; delighted</i>, #,#,#.</div>  
                <div></div>
              </td>
              <td style='border: 1px solid rgb(203, 121, 231);'>
                <div><i style="color: rgb(154, 201, 243);">&ensp; anxious</i>, #,#,#,#,#,#,#,#,#,#,#.</div>  
              </td>
            </tr>
          </table></div>
        `,
        answer: [
          `excited / proud / relieved`,
          `excited / proud / relieved`,
          `excited / proud / relieved`,
          `ashamed / bored / confused / disappointed / embarrassed / envious / frightened / shocked / suspicious / cross / upset`,
          `ashamed / bored / confused / disappointed / embarrassed / envious / frightened / shocked / suspicious / cross / upset`,
          `ashamed / bored / confused / disappointed / embarrassed / envious / frightened / shocked / suspicious / cross / upset`,
          `ashamed / bored / confused / disappointed / embarrassed / envious / frightened / shocked / suspicious / cross / upset`,
          `ashamed / bored / confused / disappointed / embarrassed / envious / frightened / shocked / suspicious / cross / upset`,
          `ashamed / bored / confused / disappointed / embarrassed / envious / frightened / shocked / suspicious / cross / upset`,
          `ashamed / bored / confused / disappointed / embarrassed / envious / frightened / shocked / suspicious / cross / upset`,
          `ashamed / bored / confused / disappointed / embarrassed / envious / frightened / shocked / suspicious / cross / upset`,
          `ashamed / bored / confused / disappointed / embarrassed / envious / frightened / shocked / suspicious / cross / upset`,
          `ashamed / bored / confused / disappointed / embarrassed / envious / frightened / shocked / suspicious / cross / upset`,
          `ashamed / bored / confused / disappointed / embarrassed / envious / frightened / shocked / suspicious / cross / upset`,
        ],
        // answer: [
        // 	`excited`,
        // 	`proud`,
        // 	`relieved`,
        // 	`ashamed`,
        // 	`bored`,
        // 	`confused`,
        // 	`disappointed`,
        // 	`embarrassed`,
        // 	`envious`,
        // 	`frightened`,
        // 	`shocked`,
        // 	`suspicious`,
        // 	`cross`,
        // 	`upset`,
        // ],
      },
    ],
  },
  4: {
    unit: 'Unit 1',
    id: 'FG10-U-P10-E4',
    exerciseKey: '/img/FriendsPlus/Page10/E4/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD1_05.mp3',
    video: '',
    component: T6v2,
    selectStyle: {
      color: '#000',
      width: 100,
      textAlign: 'center',
    },
    selectOptionValues: [`/id/`, `/d/`, `/t/`],
    titleQuestion: [
      {
        num: '4',
        title: `
        <audioimage name="1.05"></audioimage>
            <b style="color: rgb(1,102,179);"> PRONUNCIATION</b> Listen and repeat. Pay attention to the ending sounds.
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `

           <div style="color: rgb(146,39,143);margin-left:100px;">
            <b>shame &nbsp bore &nbsp confuse &nbsp delight &nbsp embarrass </b><br>
            <b>excite &nbsp frighten &nbsp relieve &nbsp shock </b>
          </div>
          <b>Say the words. Decide if the endings are pronounced /id/, /d/ or /t/. Listen
            again and check.
          </b>
          

          <div style='display: flex;gap: 70px;'>
            <div>
              <div style='display: flex;'>
                <div style='flex: 1;'>shamed</div><select id='0'></select>
              </div>
              <div style='display: flex;'>
                <div style='flex: 1;'>bored</div><select id='1'></select>
              </div>
              <div style='display: flex;'>
                <div style='flex: 1;'>confused</div><select id='2'></select>
              </div>
            </div>
            <div>
              <div style='display: flex;'>
                <div style='flex: 1;'>delighted</div><select id='3'></select>
              </div>
              <div style='display: flex;'>
                <div style='flex: 1;'>embarrassed</div><select id='4'></select>
              </div>
              <div style='display: flex;'>
                <div style='flex: 1;'>excited</div><select id='5'></select>
              </div>
            </div>
            <div>
              <div style='display: flex;'>
                <div style='flex: 1;'>frightened</div><select id='6'></select>
              </div>
              <div style='display: flex;'>
                <div style='flex: 1;'>relieved</div><select id='7'></select>
              </div>
              <div style='display: flex;'>
                <div style='flex: 1;'>shocked</div><select id='8'></select>
              </div>
            </div>
          </div>
        `,
        answer: [`/d/`, `/d/`, `/d/`, `/id/`, `/t/`, `/id/`, `/d/`, `/d/`, `/t/`],
      },
    ],
  },
};

export default json;
