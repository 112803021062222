import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  3: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P83-E3',
    audio: 'Audios/Friends Global Grade 10 SB_CD3_02.mp3',
    video: '',
    component: T6,
    exerciseKey: '',
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '3',
        title: `
				<headphone1 name='3.02'></headphone1> Listen to the words in exercise 2 and check your answers to exercises 1 and 2.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    hintBox: [
      {
        src: [
          'aquarium',
          'botanical gardens',
          'castle',
          'cathedral',
          'fountain',
          'bay',
          'market',
          'mosque',
          'museum',
          'national park',
          'palace',
          'ruins',
          'statue',
          'temple',
          'theme park',
          'tower',
          'water park',
        ],
        // arrow: true,
        width: 500,
        borderColor: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <div style='display: flex;'>
				  <div>
            <span style='color:rgb(153, 34, 136);'><b >Tourist attractions (1)</b></span>
            <hintBox id='0' ></hintBox>
          </div>
          <div><img src= 'img/FriendsPlus/Page83/E3/1.jpg'/></div>
        </div>
			  `,
        answer: [],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P83-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '4',
        title: `
				<span style='color:#92278f;'>VOCABULARY</span> Check the meaning of the adjectives below. Then look at the photos again. Can you match any of the adjectives to the photos?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    hintBox: [
      {
        src: [
          'atmospheric',
          'beautiful',
          'boring',
          'busy',
          'cheap',
          'crowded',
          'disappointing',
          'expensive',
          'historic',
          'impressive',
          'peaceful',
          'remote',
          'romantic',
          'spectacular',
          'touristy',
        ],
        // arrow: true,
        width: 500,
        borderColor: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        <div style='display: flex;'>
				  <div>
          <span style='color:rgb(153, 34, 136);'><b >Describing tourist attractions</b></span>
            <hintBox id='0' ></hintBox>
          </div>
          <div><img src= 'img/FriendsPlus/Page83/E4/1.jpg'/></div>
        </div>
			  `,
        answer: [],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P83-E5',
    audio: 'Audios/Friends Global Grade 10 SB_CD3_03.mp3',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page83/E5/key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `
				<headphone1 name='3.03'></headphone1> Listen to a tour guide talking to tourists on a coach. What countries are they going to visit? Write them in order. Choose from the countries below.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 270,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: [
      'Croatia',
      'the Czech Republic',
      'Germany',
      'Hungary',
      'Italy',
      'the Netherlands',
      'Poland',
      'Slovakia',
      'Turkey',
    ],

    // checkDuplicated: true,
    hintBox: [
      {
        src: [
          'Croatia',
          'the Czech Republic',
          'Germany',
          'Hungary',
          'Italy',
          'the Netherlands',
          'Poland',
          'Slovakia',
          'Turkey',
        ],
        // arrow: true,
        width: 1000,
        borderColor: '#92278f',
      },
    ],
    questions: [
      {
        title: `
          <hintBox id='0' ></hintBox>
          <span style='color:rgb(34, 132, 200);'><i ><b>1. </b>Germany, …</i></span>
          <div style='display: flex; justify-content: space-around; margin: 20px 0;'>
              <div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> 2. </b></div>
                  <b> <select id='0'></select>  </b>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> 3. </b></div>
                  <b> <select id='1'></select>  </b>
                </div>
              </div>
              <div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> 4. </b></div>
                  <b> <select id='2'></select>  </b>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> 5. </b></div>
                  <b> <select id='3'></select>  </b>
                </div>
              </div>
                
          </div>

			  `,
        answer: ['the Czech Republic', 'Slovakia / Hungary', 'Croatia', 'Italy'],
      },
    ],
  },
  6: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P83-E6',
    audio: 'Audios/Friends Global Grade 10 SB_CD3_03.mp3',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page83/E6/key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `
				<headphone1 name='3.03'></headphone1> Listen again. Match the tourist attractions they will visit with countries from exercise 5. Which adjectives from exercise 4 does the guide use to describe each attraction?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 170,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: [
      'Croatia',
      'Hungary',
      'Italy',
      'Poland',
      'Slovakia',
      'Turkey',
      'atmospheric',
      'beautiful',
      'boring',
      'busy',
      'cheap',
      'crowded',
      'disappointing',
      'expensive',
      'historic',
      'impressive',
      'peaceful',
      'remote',
      'romantic',
      'spectacular',
      'touristy',
    ],

    // checkDuplicated: true,
    hintBox: [
      {
        src: ['Germany ', 'Croatia', 'Hungary', 'Italy', 'Poland', 'Slovakia', 'Turkey'],
        // arrow: true,
        width: 1000,
        borderColor: '#92278f',
      },
      {
        src: [
          'atmospheric',
          'beautiful',
          'boring',
          'busy',
          'cheap',
          'crowded',
          'disappointing',
          'expensive',
          'historic',
          'impressive',
          'peaceful',
          'remote',
          'romantic',
          'spectacular',
          'touristy',
        ],
        // arrow: true,
        width: 1000,
        borderColor: '#92278f',
      },
    ],
    questions: [
      {
        title: `
          <div>
          <span style='color:rgb(153, 34, 136);'><b >The countries</b></span>
            <hintBox id='0' ></hintBox>
          <span style='color:rgb(153, 34, 136);'><b >Describing tourist attractions</b></span>
            <hintBox id='1' ></hintBox>
          </div>
          
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> 1. </b></div>
                  <div> Havel’s Market: <i style='color:rgb(34, 132, 200);'> Germany - touristy </i>  </div>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> 2. </b> The Liberty Statue: </div>
                  <select id='0'></select> - <select id='1'></select>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> 3. </b> The Old Harbour: </div>
                  <select id='2'></select> - <select id='3'></select>
                </div>
                <div style='display: flex;'>
                  <div style='margin-right: 10px;'><b> 4. </b> The Trevi Fountain: </div>
                  <select id='4'></select> - <select id='5'></select>
                </div>
                

			  `,
        answer: ['Hungary', 'impressive', 'Croatia', 'romantic', 'Italy', 'historic'],
      },
    ],
  },
  7: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P83-E7',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '7',
        title: `
				<span style='color:rgb(0,147,70);'>SPEAKING</span> Work in pairs. Describe three places in Viet Nam using adjectives from exercise4.
			  `,
        color: '#92278f',
      },
    ],
    hintBox: [
      {
        src: ['The old market in … is quite cheap. '],
        arrow: true,
        position: 1,
        width: 500,
        borderColor: '#a85faa',
      },
      {
        src: ['I think … is a very peaceful place.'],
        arrow: true,
        position: 2,
        width: 500,
        borderColor: '#a85faa',
      },
      {
        src: ['In my opinion, … is touristy and expensive.'],
        arrow: true,
        position: 1,
        width: 600,
        borderColor: '#a85faa',
      },
    ],
    questions: [
      {
        title: `
				<div style='margin: 20px 100px 10px 100px;'><hintBox id='0' ></hintBox></div>
				<div style='margin: 20px 0 50px 450px;'><hintBox id='1' ></hintBox></div>
				<div style='margin: 20px 100px 10px 100px;'><hintBox id='2' ></hintBox></div>

			  `,
        answer: [],
      },
    ],
  },
};

export default json;
