import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Vocabulary Builder',
    id: 'SB10-V-P125-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page125/E1/Key/answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: `
          <div style="font-size: 30px;"><span style="color: rgb(146, 39, 144);">2G</span> Sports clothing and equipment</div>
          <div><span style="padding-right: 10px;">1</span> Match the items (1–12) in the photos with the words below. Check the meaning of all the words.</div>
        `,
        color: 'black',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 21,
    },
    inputSize: 280,
    maxLength: 23,
    isHiddenCheck: true,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
          <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>ball</span><span>bat</span><span>boots</span><span>gloves</span><span>goal</span>
            <span>goggles</span><span>helmet</span><span>hoop</span><span>mask</span><span>net</span>
            <span>puck</span><span>racket</span><span>rope</span><span>running shoes</span><span>safety harness</span>
            <span>shirt</span><span>shorts</span><span>skates</span><span>socks</span><span>stick</span>
            <span>surfboard</span><span>swimming trunks(men) / swimming costume(women)</span><span>vest</span><span>wetsuit</span>
          </div>

          <div style="margin-top: 20px; display: flex; gap: 20px;">
            <div><img src="img/FriendsPlus/Page125/E1/1.jpg" style="max-width: 100%;" /></div>
            <div><img src="img/FriendsPlus/Page125/E1/2.jpg" style="max-width: 100%;" /></div>
          </div>

          <div style="margin-top: 20px">
            <div style=":font-size 21; font-weight: bold; font-style: italic">Answer:</div>
            <div><span style="font-weight: bold; padding: 20px">1</span>#, <span style="font-weight: bold; padding: 20px">2</span>#, <span style="font-weight: bold; padding: 20px">3</span># </div>
            <div><span style="font-weight: bold; padding: 20px">4</span>#, <span style="font-weight: bold; padding: 20px">5</span>#, <span style="font-weight: bold; padding: 20px">6</span># </div>
            <div><span style="font-weight: bold; padding: 20px">7</span>#, <span style="font-weight: bold; padding: 20px">8</span>#, <span style="font-weight: bold; padding: 20px">9</span># </div>
            <div><span style="font-weight: bold; padding: 20px">10</span>#, <span style="font-weight: bold; padding: 20px">11</span>#, <span style="font-weight: bold; padding: 20px">12</span># </div>
          </div>
				`,

        answer: [],
      },
    ],
  },
  2: {
    unit: 'Vocabulary Builder',
    id: 'SB10-V-P125-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page125/E2/Key/answerKey.png',
    component: T6,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 21,
    },
    inputSize: 280,
    maxLength: 23,
    titleQuestion: [
      {
        num: '2',
        title: `
          Put the words in exercise 1 into two groups: <i>clothing</i> and <i>equipment</i>.
        `,
        color: 'black',
      },
    ],
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
          <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>ball</span><span>bat</span><span>boots</span><span>gloves</span><span>goal</span>
            <span>goggles</span><span>helmet</span><span>hoop</span><span>mask</span><span>net</span>
            <span>puck</span><span>racket</span><span>rope</span><span>running shoes</span><span>safety harness</span>
            <span>shirt</span><span>shorts</span><span>skates</span><span>socks</span><span>stick</span>
            <span>surfboard</span><span>swimming trunks(men) / swimming costume(women)</span><span>vest</span><span>wetsuit</span>
          </div>

          <div style="margin-top: 20px; color: rgb(10, 111, 143);">
            <div style="display: flex; gap: 40px;">
              <div style="font-weight: bold; text-align: right;">Clothing:</div>
              <div> gloves, #, #, #, #, #</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">Equipment:</div>
              <div> rope, #, #, #, #, #</div>
            </div>
          </div>
        `,
        answer: [],
      },
    ],
  },
  3: {
    unit: 'Vocabulary Builder',
    id: 'SB10-V-P125-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page125/E2/Key/answerKey.png',
    component: T6,
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 21,
    },
    inputSize: 280,
    maxLength: 23,
    titleQuestion: [
      {
        num: '3',
        title: `
          Put the words in exercise 1 into two groups: <i>clothing</i> and <i>equipment</i>.
        `,
        color: 'black',
      },
    ],
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
          <div style="padding: 10px; border-radius: 10px; border: 1px solid rgb(146, 39, 144); color: rgb(146, 39, 144);">
            <div style="font-weight: bold; font-style: italic;">Clothing and equipment</div>
            <div style="font-weight: bold; display: flex; gap: 10px 30px; flex-wrap: wrap;">
              <span>ball</span><span>bat</span><span>boots</span><span>gloves</span><span>goal</span>
              <span>goggles</span><span>helmet</span><span>hoop</span><span>mask</span><span>net</span>
              <span>puck</span><span>racket</span><span>rope</span><span>running shoes</span><span>safety harness</span>
              <span>shirt</span><span>shorts</span><span>skates</span><span>socks</span><span>stick</span>
              <span>surfboard</span><span>swimming trunks(men) / swimming costume(women)</span><span>vest</span><span>wetsuit</span>
            </div>
          </div>

          <div style="margin-top: 20px; width: fit-content; padding: 10px; border-radius: 10px; border: 1px solid rgb(226 87 224); color: rgb(226 87 224);">
            <div style="font-weight: bold; font-style: italic;">Sports</div>
            <div style="font-weight: bold; display: flex; gap: 10px 30px; flex-wrap: wrap;">
              <span>basketball</span><span>climbing</span><span>football</span><span>surfing</span>
            </div>
          </div>

          <div style="margin-top: 20px; color: rgb(10, 111, 143);">
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">basketball:</div>
              <div>shorts, #, #</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">climbing:</div>
              <div>#, #, #</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">football:</div>
              <div>#, #, #</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">surfing:</div>
              <div>#, #, #</div>
            </div>
          </div>
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: 'Vocabulary Builder',
    id: 'SB10-V-P125-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page125/E4/Key/answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: `
          <div style="font-size: 30px;"><span style="color: rgb(146, 39, 144);">2H</span> Outdoor activities</div>
          <div style="display: flex;">
            <span style="padding-right: 10px;">4</span> 
            <span>Which of the activities below usually take place a) in water, b) in the air, c) in the street and d) in the mountains?</span>
          </div>
        `,
        color: 'black',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 21,
    },
    inputSize: 280,
    maxLength: 23,
    isHiddenCheck: true,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
          <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>bungee jumping</span><span>camping</span><span>canoeing</span><span>cycling</span>
            <span>hang-gliding</span><span>hiking</span><span>karting</span><span>paddleboarding</span>
            <span>riding</span><span>rollerblading</span><span>snorkelling</span><span>walking</span><span>windsurfing</span>
          </div>

          <div style="margin-top: 20px">
            <div style="font-size: 21; font-weight: bold; font-style: italic">Answer:</div>
            <div>
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold;">a) in water</div>
                <div>#, #, #</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold;">b) in the air</div>
                <div>#, #, #</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold;">c) in the street</div>
                <div>#, #, #</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 295px; font-weight: bold;">d) in the mountains</div>
                <div>#, #, #, #</div>
              </div>	
            </div>
          </div>
				`,

        answer: [],
      },
    ],
  },
  5: {
    unit: 'Vocabulary Builder',
    id: 'SB10-V-P125-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page125/E5/Key/answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '5',
        title: `
          Decide which activities in exercise 4 you would like to do. 
          Which would you not like to do? Why?
        `,
        color: 'black',
      },
    ],
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 22,
    },
    inputSize: 220,
    maxLength: 18,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    textareaStyle: { width: 600 },
    questions: [
      {
        title: `
          <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>bungee jumping</span><span>camping</span><span>canoeing</span><span>cycling</span>
            <span>hang-gliding</span><span>hiking</span><span>karting</span><span>paddleboarding</span>
            <span>riding</span><span>rollerblading</span><span>snorkelling</span><span>walking</span><span>windsurfing</span>
          </div>

          <div style="margin-top: 20px; color: rgb(10, 111, 143);">I wouldn’t like to do bungee jumping. I think it’s scary.</div>

          <div><textarea id="0" rows="10"></textarea></div>
        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: 'Vocabulary Builder',
    id: 'SB10-V-P125-E6',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page125/E6/Key/answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: `
          <div style="font-size: 30px;"><span style="color: rgb(146, 39, 144);">3G</span> Expressing likes and dislikes</div>
          <div style="display: flex;">
            <span style="padding-right: 10px;">1</span> 
            <span>Complete the sentences using the prepositions below. One sentence does not need a preposition.</span>
          </div>
        `,
        color: 'black',
      },
    ],
    inputSize: 80,
    maxLength: 5,
    stylesTextInput: {
      textAlign: 'center',
    },
    // isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
          <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>about</span><span>for</span><span>for</span>
            <span>in</span><span>of</span><span>on</span><span>about</span>
          </div>

          <div style="margin-top: 20px;">
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">1</div>
              <div>My sister isn’t <strong>keen</strong> # video games.</div>
            </div>
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">2</div>
              <div>When I was young, I wasn’t <strong>interested</strong> # sport.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">3</div>
              <div>Lucy isn’t a big <strong>fan</strong> # horror films.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">4</div>
              <div>My parents <strong>can’t stand</strong> # loud music.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">5</div>
              <div>I like comedy but I can’t get <strong>excited</strong> # sitcoms.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">6</div>
              <div>Hailey doesn’t <strong>go</strong> # combat games, but she likes driving games.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">7</div>
              <div>My cousin is <strong>mad</strong> # vampire films.</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="font-weight: bold; text-align: right;">8</div>
              <div>My best friend is always <strong>up</strong> # a trip to the cinema.</div>
            </div>	
          </div>
        `,
        answer: ['on', 'in', 'of', 'none', 'about', 'for', 'about', 'for'],
      },
    ],
  },
  7: {
    unit: 'Vocabulary Builder',
    id: 'SB10-V-P125-E7',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page125/E7/Key/answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: `
          Use the bold phrases in exercise 1 (including the correct prepositions) to write your own opinions about the things below.
        `,
        color: 'black',
      },
    ],
    inputSize: 190,
    maxLength: 21,
    textareaStyle: { width: 800 },
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
          <div style="font-weight: bold; color: rgb(146, 39, 144); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>going bowling</span><span>watching horror films</span><span>going out for dinner</span><span>playing board games</span><span>cooking</span>
          </div>

          <div><textarea id="0" rows="10"></textarea></div>
        `,
        answer: [''],
      },
    ],
  },
  8: {
    unit: 'Vocabulary Builder',
    id: 'SB10-V-P125-E8',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page125/E8/Key/answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: `
          <div style="font-size: 30px;"><span style="color: rgb(146, 39, 144);">3H</span> Social activities</div>
          <div style="display: flex;">
            <span style="padding-right: 10px;">3</span> 
            <span>Study the <span style="color: rgb(146, 39, 144);">Learn this!</span> box. Then use the underlined verbs to complete the phrases below. Use each verb once.</span>
            </div>

        `,
        color: 'black',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
    },
    inputSize: 130,
    maxLength: 10,
    // isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
          <div style="margin-top: 20px; width: 630px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(146, 39, 144); background-image: radial-gradient(rgb(250 244 244), rgb(219, 196, 223)); font-size: 22px;">
            <div>
              <span style="color: rgb(146, 39, 144); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LEARN THIS!</span> 
            </div>
            <div>
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">a</div>
                <div><u>go to</u> the park / the cinema / the shopping centre / a café / a restaurant / a friend’s house</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">b</div>
                <div><u>go for</u> a walk / a run / a bike ride</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">c</div>
                <div><u>go out for</u> lunch / dinner / a coffee / the day / the evening</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">d</div>
                <div><u>go</u> dancing / skating / skateboarding / rollerblading</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">e</div>
                <div><u>watch</u> TV / a DVD / a film</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">f</div>
                <div><u>play</u> video games / volleyball / tennis</div>
              </div>	
            </div>
          </div>

          <div style="margin-top: 30px; display: flex; gap: 100px;">
            <div>
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">1</div>
                <div># a meal</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">2</div>
                <div># swimming</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">3</div>
                <div># the beach</div>
              </div>	
            </div>
            <div>
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">4</div>
                <div># a talent show</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">5</div>
                <div># board games</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">6</div>
                <div># a jog</div>
              </div>	
            </div>
          </div>
        `,
        answer: ['go out for', 'go', 'go to', 'watch', 'play', 'go for'],
      },
    ],
  },
  9: {
    unit: 'Vocabulary Builder',
    id: 'SB10-V-P125-E9',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page125/E9/Key/answerKey.png',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. Find out what your partner does at weekends. 
          Ask and answer about the activities in the <span style="color: rgb(146, 39, 144);">Learn this!</span> box and exercise 3.
        `,
        color: 'black',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
    },
    inputSize: 130,
    maxLength: 10,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    hintBox: [
      {
        src: ['Do you ever go to the cinema at weekends?'],
        borderColor: 'rgb(146, 39, 144)',
        arrow: true,
        position: 2,
        width: 525,
      },
      {
        src: ['Yes, I do. / No, I don’t. / Not very often.'],
        borderColor: 'rgb(146, 39, 144)',
        arrow: true,
        position: 1,
        width: 470,
      },
    ],
    questions: [
      {
        title: `
          <div style="margin-top: 20px; width: 630px; padding: 5px 10px; border-radius: 10px; border: 2px solid rgb(146, 39, 144); background-image: radial-gradient(rgb(250 244 244), rgb(219, 196, 223)); font-size: 22px;">
            <div>
              <span style="color: rgb(146, 39, 144); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LEARN THIS!</span> 
            </div>
            <div>
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">a</div>
                <div><u>go to</u> the park / the cinema / the shopping centre / a café / a restaurant / a friend’s house</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">b</div>
                <div><u>go for</u> a walk / a run / a bike ride</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">c</div>
                <div><u>go out for</u> lunch / dinner / a coffee / the day / the evening</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">d</div>
                <div><u>go</u> dancing / skating / skateboarding / rollerblading</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">e</div>
                <div><u>watch</u> TV / a DVD / a film</div>
              </div>	
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">f</div>
                <div><u>play</u> video games / volleyball / tennis</div>
              </div>	
            </div>
          </div>

          <div style="margin-top: 30px; display: flex; gap: 30px;">
            <hintbox id='0'></hintbox>
            <hintbox id='1'></hintbox>
          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
