import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: {
    unit: 'Weather',
    id: 'FG10-W-P57-E1',
    exerciseKey: '/img/FriendsPlus/Page46/E1/Key/answerKey.png',
    audio: '',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '1',
        title: `Do the weather quiz in pairs.`,
        color: '#860052',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 3px', borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          'a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp; c',
        ],
        listWords: [
          'a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp; c',
          'a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp; c',
          'a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp; c',
          'a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp; c',
          'a <br/> b <br/> c',
          'a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp; b',
          'a <br/> b <br/> c',
          'a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp; c',
        ],
        answers: ['0-0', '1-8', '2-0', '3-0', '4-4', '5-4', '6-8', '7-4'],
        initialValue: [],
      },
      Layout: `
          <div style='position: relative; min-width: 900px;'>
              <div style="display: flex; gap: 20px;">
                <strong>1</strong>
                <div>
                  <div style='z-index: 50; position: absolute; top: 38px; left: 41px;'><input id='0' type='Circle' /> </div>
                  <div>What shape is a <span style='background: rgb(198, 158, 210);'>raindrop</span> before it starts falling?</div>
                  <div>
                    <div style='position: absolute; top: 57px; left: 38px;'><img src="img/FriendsPlus/Page46/E1/shape-1.jpg" style="max-width: 50%;" /></div>
                    <div style='position: absolute; top: 57px; left: 290px;'><img src="img/FriendsPlus/Page46/E1/shape-2.jpg" style="max-width: 50%;" /></div>
                    <div style='position: absolute; top: 57px; left: 565px;'><img src="img/FriendsPlus/Page46/E1/shape-3.jpg" style="max-width: 50%;" /></div>
                  </div>
                </div>
              </div>

              <div style="margin-top: 130px; display: flex; gap: 20px;">
                <strong>2</strong>
                <div>
                  <div style='z-index: 50; position: absolute; top: 205px; left: 41px;'><input id='1' type='Circle' /> </div>
                  <div>What makes the sound of <span style='background: rgb(198, 158, 210);'>thunder</span>?</div>
                  <div style="display: flex;">
                    <div style='position: absolute; top: 205px; left: 74px;'>a <span style='background: rgb(198, 158, 210);'>cloud</span></div>
                    <div style='position: absolute; top: 205px; left: 329px;'><span style='background: rgb(198, 158, 210);'>rain</span></div>
                    <div style='position: absolute; top: 205px; left: 611px;'><span style='background: rgb(198, 158, 210);'>lightning</span></div>
                  </div>
                </div>
              </div>

              <div style="margin-top: 67px; display: flex; gap: 20px;">
                <strong>3</strong>
                <div>
                  <div style='z-index: 50; position: absolute; top: 350px; left: 41px;'><input id='2' type='Circle' /> </div>
                  <div>You see lightning and then hear thunder six seconds later. <br />How far away is the <span style='background: rgb(198, 158, 210);'>storm</span>?</div>
                  <div style="display: flex;">
                    <div style='position: absolute; top: 350px; left: 74px;'>2 km</div>
                    <div style='position: absolute; top: 350px; left: 329px;'>3 km</div>
                    <div style='position: absolute; top: 350px; left: 611px;'>6 km</div>
                  </div>
                </div>
              </div>

              <div style="margin-top: 67px; display: flex; gap: 20px;">
                <strong>4</strong>
                <div>
                  <div style='z-index: 50; position: absolute; top: 450px; left: 41px;'><input id='3' type='Circle' /> </div>
                  <div>You only get <span style='background: rgb(198, 158, 210);'>foggy</span> weather when</div>
                  <div style="display: flex;">
                    <div style='position: absolute; top: 450px; left: 74px;'>the air is cold.</div>
                    <div style='position: absolute; top: 450px; left: 329px;'>it is <span style='background: rgb(198, 158, 210);'>rainy</span>.</div>
                    <div style='position: absolute; top: 450px; left: 611px;'>you are near the ocean.</div>
                  </div>
                </div>
              </div>
              
              <div style="margin-top: 67px; display: flex; gap: 20px;">
                <strong>5</strong>
                <div>
                  <div style='z-index: 50; position: absolute; top: 553px; left: 41px;'><input id='4' type='Circle' /> </div>
                  <div>You only get <span style='background: rgb(198, 158, 210);'>hail</span> when</div>
                  <div style="display: flex;">
                    <div style='position: absolute; top: 553px; left: 74px;'>it is <span style='background: rgb(198, 158, 210);'>windy</span>.</div>
                    <div style='z-index: 50; position: absolute; top: 591px; left: 72px;'>there are <span style='background: rgb(198, 158, 210);'>storm clouds</span>.</div>
                    <div style='z-index: 50; position: absolute; top: 628px; left: 72px;'>there is no <span style='background: rgb(198, 158, 210);'>sunshine</span>.</div>
                  </div>
                </div>
              </div>

              <div style="margin-top: 112px; display: flex; gap: 20px;">
                <strong>6</strong>
                <div>
                  <div style='z-index: 50; position: absolute; top: 705px; left: 41px;'><input id='5' type='Circle' /> </div>
                  <div>In what direction does an 'east <span style='background: rgb(198, 158, 210);'>wind</span>' blow?</div>
                  <div style="display: flex;">
                    <div style='position: absolute; top: 705px; left: 74px;'>towards the east</div>
                    <div style='position: absolute; top: 705px; left: 329px;'>towards the west</div>
                  </div>
                </div>
              </div>

              <div style="margin-top: 51px; display: flex; gap: 20px;">
                <strong>7</strong>
                <div>
                  <div style='z-index: 50; position: absolute; top: 793px; left: 41px;'><input id='6' type='Circle' /> </div>
                  <div>What is the maximum time a <span style='background: rgb(198, 158, 210);'>snowflake</span> takes to reach the ground?</div>
                  <div style="display: flex;">
                    <div style='position: absolute; top: 793px; left: 74px;'>a minute</div>
                    <div style='z-index: 50; position: absolute; top: 831px; left: 72px;'>five minutes</div>
                    <div style='z-index: 50; position: absolute; top: 868px; left: 72px;'>an hour</div>
                  </div>
                </div>
              </div>

              <div style="margin-top: 127px; display: flex; gap: 20px;">
                <strong>8</strong>
                <div>
                  <div style='z-index: 50; position: absolute; top: 961px; left: 41px;'><input id='7' type='Circle' /> </div>
                  <div>When you facing a rainbow, the <span style='background: rgb(198, 158, 210);'>sun</span> is always</div>
                  <div style="display: flex;">
                    <div style='position: absolute; top: 961px; left: 74px;'>in front of you.</div>
                    <div style='position: absolute; top: 961px; left: 329px;'>behind you.</div>
                    <div style='position: absolute; top: 961px; left: 611px;'>to one side.</div>
                  </div>
                </div>
              </div>
              
          </div>
      `,
    },
  },
};

export default json;
