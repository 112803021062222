import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 3',
    id: 'SB10-U3-P45-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page45/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: 'Reading',
        title: `
        <br/>
				<div style="margin-left: -100px">Read the following passage and circle True (T), False (F) or Doesn’t say (DS).</div>
			  `,
        color: '#2284c8',
      },
    ],
    question: {
      Write: {
        inputStyle: {
          borderBottom: '',
          color: '#000',
          textAlign: 'center',
        },
        answers: [],
        initialValue: [],

        textAlign: 'center',
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: '#000',
          fontWeight: 700,
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: ['T / F / DS', 'T / F / DS', 'T / F / DS', 'T / F / DS', 'T / F / DS', 'T / F / DS'],
        answers: ['0-4', '1-8', '2-0', '3-8', '4-0', '5-0'],
        initialValue: [],
      },
      Layout: `
            <div style='background-color: rgb(210, 222, 244); margin-bottom: 20px ; border-radius: 15px; '>
              <div style='padding: 15px 25px 25px 25px;' >
                <div><b>The Avengers</b></div>
                <div>Superheroes are very popular: people love films like <i>The Amazing Spider Man, Batman Begins, Thor and Captain America</i>. They are all great, but in my opinion <i>The Avengers</i> is the best superhero film because it’s different. Superheroes are usually lonely people – they save the world on their own. This isn’t how things work in <i>The Avengers</i>.</div>
                <div>Loki is the bad character in the film, and he’s also Thor’s brother. The film begins when Loki and his powerful army steal the Tesseract. This is a source of energy, and Loki can use it to destroy the Earth. Nick Fury is the good character, and he’s also the leader of an organisation called S.H.I.E.L.D. Nick knows that he isn’t strong enough to fight against Loki – so he creates a team of superheroes. </div>
                <div>There are a lot of great characters in <i>The Avengers</i>. The final battle scene between the superhero team and Loki’s army is the most exciting scene in the film. The script is very clever too, and there are some funny lines for all the characters. </div>
                <div>The idea of putting superheroes together was amazing, and the audience loved it. </div>
              </div>
            </div>
            <div style=' padding: 5px 5px 25px 5px;' >
              <div style='display: flex;'>
                <div><b> 1 &emsp;</b></div>
                <div> What makes <i>The Avengers</i> different from other superhero films is its clever script.<b><input id='0' type='Circle' /></b></div>
              </div>
              <div style='display: flex;'>
                <div><b> 2 &emsp;</b></div>
                <div> The superheroes in <i>The Avengers</i> are the most powerful. <b><input id='1' type='Circle' /></b></div>
              </div>
              <div style='display: flex;'>
                <div><b> 3 &emsp;</b></div>
                <div> Tesseract, a weapon stolen by Loki’s team, can be used to destroy the Earth. <b><input id='2' type='Circle' /></b></div>
              </div>
              <div style='display: flex;'>
                <div><b> 4 &emsp;</b></div>
                <div> There are a lot of battle scenes in <i>The Avengers</i>. <b><input id='3' type='Circle' /></b></div>
              </div>
              <div style='display: flex;'>
                <div><b> 5 &emsp;</b></div>
                <div> Some lines in the script make the audience laugh. <b><input id='4' type='Circle' /></b></div>
              </div>
              <div style='display: flex;'>
                <div><b> 6 &emsp;</b></div>
                <div> The audience enjoys watching superheroes cooperate in an effort to save the world. <b><input id='5' type='Circle' /></b></div>
              </div>
            </div>


      `,
    },
  },
  2: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P45-E2',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_42.mp3',
    video: '',
    component: MatchDots,
    exerciseKey: 'img/FriendsPlus/Page45/E2/key/answerKey.png',
    titleQuestion: [
      {
        num: 'Listening',
        title: `
        <br/>
				<div style="margin-left: -100px"><headphone1 name='1.42'></headphone1> You will hear four speakers talk about entertainment. Match sentences A– E with recordings 1– 4. There is one extra sentence.</div>
			  `,
        color: '#2284c8',
      },
    ],
    stylesTextInput: { borderBottom: '10px dashed', textAlign: 'center' },
    inputSize: 50,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '17px',
              left: '122px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // 1
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '55px',
              left: '122px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // 2
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '92px',
              left: '122px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // 3
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '134px',
              left: '122px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // A
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '17px',
              left: '245px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // B
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '55px',
              left: '245px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // C
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '92px',
              left: '245px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // D
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '134px',
              left: '245px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // D
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '173px',
              left: '245px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #dc2c39',
              background: 'white',
            },
          }, // D
        ],
        answers: ['0-5', '1-4', '2-7', '3-6'],
        initialValue: [],
      },
      Layout: `
          <div style='display: flex; justify-content: space-around; width:1000px; font-size: 26px;'>
            <div >
                <div><b> 1  </b></div>
                <div><b> 2  </b></div>
                <div><b> 3  </b></div>
                <div><b> 4  </b></div>
            </div>    
            <div >
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> A </b></div>
                <div>  We learn about somebody’s life. </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> B </b></div>
                <div> The speaker is making an appointment to competitors.</div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> C </b></div>
                <div> The speaker is still studying at school. </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> D </b></div>
                <div> We learn about the history of some popular stories. </div>
              </div>
              <div style='display: flex;'>
                <div style='margin-right: 10px;'><b> E </b></div>
                <div> The speaker is describing a festival. </div>
              </div>
            </div>     
          </div>     
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
      `,
    },
  },
  3: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P45-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: 'Speaking',
        title: ` <br/>
				<div style="margin-left: -100px">Work in pairs. Read the role-play information for Student A and Student B. Make a list of ideas for your own role and think about ways of suggesting them. Then do the role-play.</div>
			  `,
        color: '#2284c8',
      },
    ],
    hideBtnFooter: true,
    titleImage: '',
    questionImage: [],
    questions: [
      {
        title: `
        <div ><b> Student A </b></div>
        <div style='margin-left: 10px;'> You and a friend want to go to a music event together. Discuss which event to go to with your partner. Suggest asking someone in your family to drive you there.</div>
        <div ><b> Student B </b></div>
        <div style='margin-left: 10px;'> You and a friend want to go to a music event together. Discuss which event to go to with your partner. Suggest taking public transport.</div>

			  `,
        answer: [],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P45-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: 'Writing',
        title: ` <br/>
				<div style="margin-left: -100px">Imagine you went to the cinema last night and are writing to your English friend. Write an informal letter (120– 150 words) to them in which you:</div>
			  `,
        color: '#2284c8',
      },
    ],
    hideBtnFooter: true,
    textareaStyle: { width: 1000 },
    questions: [
      {
        title: `
        <ul>
          <li>describe the film.</li>
          <li>give your opinion of the film.</li>
          <li>make a suggestion to your friend.</li>
        </ul>
        <div><textarea id="0" rows="6"></textarea></div>
			  `,
        answer: [],
      },
    ],
  },
};

export default json;
