import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    unit: 'Weather',
    id: 'FG10-W-P56-E1',
    exerciseKey: '/img/FriendsPlus/Page56/E1/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    inputSize: 520,
    maxLength: 39,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 23,
    },
    textareaStyle: { width: 650, fontWeight: 'bold' },
    titleQuestion: [
      {
        num: '1',
        title: `
         Read the webpage about El Niño. What is it and 
what effects can it have? 
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
					<div><img src="/img/FriendsPlus/Page56/E1/1.jpg" alt="" style="width:900px;" ></div>
					<div>
        <div style="display: flex; margin-top: 20px">
        <b>Answers:</b>
        <span style="margin-top:-10px"><textarea id="0" rows="4"></textarea></span>
      </div>
        `,
        answer: [
          'El Nino is a weather phenomenon, which can cause flooding or very dry conditions, forest fires, droughts, typhoons and hurricanes. It can also affect marine ecosystems.',
        ],
      },
    ],
  },
  2: {
    unit: 'Weather',
    id: 'FG10-W-P56-E2',
    exerciseKey: '/img/FriendsPlus/Page56/E2/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    isHiddenCheck: true,
    inputSize: 790,
    // maxLength: 1,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      // fontWeight: 'bold',
    },
    titleQuestion: [
      {
        num: '2',
        title: `
         True or False? Write T or F. Correct the false sentences
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
				<div>
        <div style="display: flex;">
          <b>1</b> 
          <div>
            <span> &nbsp ENSO occurs in the Mediterranean.</span> 
            <span style="margin-left:10px"><input id='0' width='50px' /></span>
            <div><i>If the sentence is right, write "T" again. Otherwise, correct it.</i><input id='1' /></div>
          </div>
        </div>

        <div style="display: flex;">
          <b>2</b> 
          <div>
            <span> &nbsp ENSO happens when the ocean temperature  becomes unusually cold.</span> 
            <span style="margin-left:10px"><input id='2' width='50px' /></span>
            <div><i>If the sentence is right, write "T" again. Otherwise, correct it.</i><br /><input id='3' /></div>
          </div>
        </div>

        <div style="display: flex;">
          <b>3</b> 
          <div>
            <span> &nbsp ENSO lasts between 2 and 7 years.</span> 
            <span style="margin-left:10px"><input id='4' width='50px' /></span>
            <div><i>If the sentence is right, write "T" again. Otherwise, correct it.</i><input id='5' /></div>
          </div>
        </div>

        <div style="display: flex;">
          <b>4</b> 
          <div>
            <span> &nbsp Volcanic activity is a possible cause of El Niño.</span> 
            <span style="margin-left:10px"><input id='6' width='50px' /></span>
            <div><i>If the sentence is right, write "T" again. Otherwise, correct it.</i><input id='7' /></div>
          </div>
        </div>

        <div style="display: flex;">
          <b>5</b> 
          <div>
            <span> &nbsp El Niño only affects the weather in the Pacific.</span> 
            <span style="margin-left:10px"><input id='8' width='50px' /></span>
            <div><i>If the sentence is right, write "T" again. Otherwise, correct it.</i><input id='9' /></div>
          </div>
        </div>

        <div style="display: flex;">
          <b>6</b> 
          <div>
            <span> &nbsp ENSO is problematic for Pacific fish populations.</span> 
            <span style="margin-left:10px"><input id='10' width='50px' /></span>
            <div><i>If the sentence is right, write "T" again. Otherwise, correct it.</i><input id='11' /></div>
          </div>
        </div>
        `,
        answer: [
          'F',
          'ENSO has a big impact on the Pacific region. / El Niño has a big impact on the Pacific region. ',
          'F',
          'ENSO happens when the ocean temperature becomes unusually warm. / El Niño happens when the ocean temperature becomes unusually warm.',
          'F',
          'ENSO lasts between 9 months and 2 years. / El Niño lasts between 9 months and 2 years.',
          'T',
          'T',
          'F',
          'ENSO affects the weather all over the world. / El Niño affects the weather all over the world.',
          'T',
          'T',
        ],
      },
    ],
  },
  // 2: {
  // 	unit: 'Weather',
  // 	id: 'FG10-W-P56-E2',
  // 	exerciseKey: '/img/FriendsPlus/Page56/E2/Key/answerKey.png',
  // 	audio: '',
  // 	video: '',
  // 	component: T6,
  // 	inputSize: 50,
  // 	maxLength: 1,
  // 	// checkDuplicated: true,
  // 	stylesTextInput: {
  // 		textAlign: 'center',
  // 		fontWeight: 'bold',
  // 	},
  // 	titleQuestion: [
  // 		{
  // 			num: '2',
  // 			title: `
  //        True or False? Write T or F. Correct the false sentences
  //       `,
  // 			color: '#860052',
  // 		},
  // 	],

  // 	questions: [
  // 		{
  // 			title: `
  // 			<div>
  //       <div style="display: flex;">
  //         <b>1</b>
  //         <span> &nbsp ENSO occurs in the Mediterranean.</span>
  //         <span style="margin-left:10px">#</span>
  //       </div>

  //       <div style="display: flex;">
  //         <b>2</b>
  //         <span> &nbsp ENSO happens when the ocean temperature  becomes unusually cold.</span>
  //         <span style="margin-left:10px">#</span>
  //       </div>
  //       <div style="display: flex;">
  //         <b>3</b>
  //         <span> &nbsp ENSO lasts between 2 and 7 years.</span>
  //         <span style="margin-left:10px">#</span>
  //       </div>
  //       <div style="display: flex;">
  //         <b>4</b>
  //         <span> &nbsp Volcanic activity is a possible cause of El Niño.</span>
  //         <span style="margin-left:10px">#</span>
  //       </div>
  //       <div style="display: flex;">
  //         <b>5</b>
  //         <span> &nbsp El Niño only affects the weather in the Pacific.</span>
  //         <span style="margin-left:10px">#</span>
  //       </div>
  //       <div style="display: flex;">
  //         <b>6</b>
  //         <span> &nbsp ENSO is problematic for Pacific fish populations.</span>
  //         <span style="margin-left:10px">#</span>
  //       </div>
  //     </div>

  //       `,
  // 			answer: ['F', 'F', 'F', 'T', 'F', 'T'],
  // 		},
  // 	],
  // },
  3: {
    unit: 'Weather',
    id: 'FG10-W-P56-E3',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_11.mp3',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '3',
        title: `
        <audioimage name="2.11"></audioimage>
						Listen to an interview about El Niño.
						Choose the correct words to complete the
						statements. 
        `,
        color: '#860052',
      },
    ],
    exerciseKey: '/img/FriendsPlus/Page56/E3/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          margin: '-2px',
        },
        limitSelect: 1,
        listWords: ['more / less', 'likely / unlikely ', 'may / won’t ', 'can / can’t'],
        answers: ['0-0', '1-0', '2-0', '3-0'],
        initialValue: [],
      },
      Layout: `
         <div>
        <b>1 </b>
        <span>El Niño events are getting <b><input type="Circle" id="0" ></b>  severe. </span><br>
        <b>2 </b>
        <span>It’s <b><input type="Circle" id="1" ></b> that global warming will have an impact on future El Niño events.</span><br>
        <b>3 </b>
        <span>Scientists think that El Niño events <b><input type="Circle" id="2" ></b> get more frequent.</span><br>
        <b>4 </b>
        <span>Scientists <b><input type="Circle" id="3" ></b> predict when an El Niño event is going to happen.</span><br>
      </div> 
      `,
    },
  },
  4: {
    unit: 'Weather',
    id: 'FG10-W-P56-E4',
    exerciseKey: '/img/FriendsPlus/Page56/E4/Key/answerKey.png',
    audio: '/Audios/Friends Global Grade 10 SB_CD2_11.mp3',
    video: '',
    component: T6,
    isHiddenCheck: true,
    inputSize: 80,
    maxLength: 4,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: 'center',
    },
    titleQuestion: [
      {
        num: '4',
        title: `
				<audioimage name ="2.11"></audioimage>
          Listen again. Use the following numbers to complete the paragraph.
					
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
					<b style="color: rgb(39,131,197);">60 &nbsp 2015 &nbsp 16 &nbsp 2016 &nbsp 25</b>
          <div>
            <span>
              El Niño events have been getting more severe over
              the last <b><sup>1</sup>#</b> years.<br>
            </span>
            <span>In <b><sup>2</sup>#</b> there</span> 
            <span> were <b><sup>3</sup>#</b> typhoons </span>
            <span>in the Central Pacific. </span>
            <span>It’s possible that more than <b><sup>4</sup>#</b> </span>
            <span>million people suffered from hunger and malnutrition </span>
            <span>in <b><sup>5</sup>#</b> as a consequence. </span>
          </div>
        `,
        answer: ['25 ', '2015', '16', '60', '2016'],
      },
    ],
  },
  5: {
    unit: 'Weather',
    id: 'FG10-W-P56-E5',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '5',
        title: `
          <span style="color: rgb(45,206,137);">SPEAKING</span> Work in groups. Ask and answer the
              questions about extreme weather in Viet Nam.
        `,
        color: '#92278f',
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <div>
            <b>1</b> <span>What type of extreme weather is common?</span><br>
            <b>2</b> <span>When was the last typhoon?</span><br>
            <b>3</b> <span>In which region do typhoons normally occur?</span><br>
            <b>4</b> <span>What do the people usually do to prepare for typhoons?</span><br>
          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
