import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  7: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P35-E7',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    textareaStyle: { width: 1100 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '7',
        title: `
				<b style='color:rgb(0,147,70);'>SPEAKING</b> Work in pairs. Tell your partner your opinion of a film you saw recently. Use the language in exercise 6 to help you.
			  `,
        color: '#92278f',
      },
    ],
    hintBox: [
      {
        src: ['acting', 'ending', 'plot', 'scenes', 'script', 'soundtrack', 'special effects'],
        width: 800,
        borderColor: '#92278f',
      },
    ],
    questions: [
      {
        title: `
				<div style='margin: 20px 100px 10px 20px;'><hintBox id='0' ></hintBox></div>
        <i style='color:rgb(64, 189, 206);'>I recently saw a really exciting action film. The special effects were amazing!</i>
			  <textarea id="0" rows="4">
        `,
        answer: [],
      },
    ],
  },
  8: {
    unit: 'Unit 3',
    id: 'SB10-U3-P35-E8',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page35/E8/Key/answerKey.png',
    titleQuestion: [
      {
        num: '8',
        title: `
				<b style='color:rgb(0,147,70);'>SPEAKING</b> Complete the WebQuest quiz below with words from exercise 2 and 6. Search the internet to do the quiz in groups. Check your answers with your teacher.
			  `,
        color: '#92278f',
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: '',
          color: '#000',
          textAlign: 'center',
          maxLength: 1,
        },
        answers: ['e', 'r', 'i', 'o', 'r', 'a', 'm', 'e', 'a', 'l', 'i', 't', 'o', 'a', 'l', 'e', 'n', 'o', 'w'],
        initialValue: [],

        textAlign: 'left',
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: '#000',
          fontWeight: 700,
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: ['True / False', 'True / False', 'True / False'],
        answers: ['0-0', '1-0', '2-0'],
        initialValue: [],
      },
      Layout: `
        <div style='margin-left: 100px'><img src='img/FriendsPlus/Page35/E8/1.jpg' alt=''/></div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div><i>Dat Phuong Nam</i> is the first Vietnamese &emsp; p<input id='0' width='30px'/> <input id='1' width='30px'/> <input id='2' width='30px'/> <input id='3' width='30px'/> d &emsp;   d<input id='4' width='30px'/> <input id='5' width='30px'/> <input id='6' width='30px'/> a  &emsp; to be exported to the USA. <input id='0' type='Circle' ></input></div>
			    
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>The TV &emsp; r<input id='7' width='30px'/> <input id='8' width='30px'/> <input id='9' width='30px'/> <input id='10' width='30px'/> <input id='11' width='30px'/> y &emsp;   sh<input id='12' width='30px'/> w &emsp;<i>Thieu nien noi</i> gives teenagers an opportunity to show their talents. <input id='1' type='Circle' ></input></div>
			    
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>Pop groups Little Mix, Girls Aloud and One Direction all became famous after being on &emsp;t<input id='13' width='30px'/> <input id='14' width='30px'/> <input id='15' width='30px'/> <input id='16' width='30px'/> t &emsp;   sh<input id='17' width='30px'/> <input id='18' width='30px'/> s. <input id='2' type='Circle' ></input></div>
			    
        </div>


      `,
    },
  },
  9: {
    unit: 'Unit 3',
    id: 'SB10-U3-P35-E9',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page35/E9/key/answerKey.png',
    titleQuestion: [
      {
        num: '8',
        title: `
				<b style='color:rgb(0,147,70);'>SPEAKING</b> Complete the WebQuest quiz below with words from exercise 2 and 6. <br/>Search the internet to do the quiz in groups. Check your answers with your teacher.
			  `,
        color: '#92278f',
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: '',
          color: '#000',
          textAlign: 'left',
          // maxLength: 1,
        },
        answers: [
          'a',
          'r',
          'a',
          'c',
          't',
          'e',
          'the character has never been played by Johnny Depp',
          'a',
          'n',
          't',
          'a',
          's',
          'i',
          'l',
          'm',
          "it's part of the Hunger Games series, not the Twilight series",
          'n',
          'i',
          'm',
          'a',
          't',
          'i',
          'o',
        ],
        initialValue: [],

        textAlign: 'left',
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: '#000',
          fontWeight: 700,
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: ['a <br> b <br> c <br> d ', 'a <br> b <br> c <br> d ', 'a <br> b <br> c <br> d '],
        answers: ['0-8', '1-4', '2-12'],
        initialValue: [],
      },
      Layout: `
      <div style='margin-left: 100px'><img src='img/FriendsPlus/Page35/E9/1.jpg' alt=''/></div>
      <div>
        
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div>
            <div> Which of these &emsp; ch<input id='0' width='30px'/> <input id='1' width='30px'/> <input id='2' width='30px'/> <input id='3' width='30px'/> <input id='4' width='30px'/> <input id='5' width='30px'/>rs &emsp; is the odd one out? </div>
            <div style='display: flex; gap:10px;'>
                <div><b> <input id='0' type='Circle' /> </b></div>
              <div>
                <div> Willy Wonka </div>
                <div> the Mad Hatter </div>
                <div> Bilbo Baggins </div>
                <div> Jack Sparrow</div>
              </div>
            </div>
            <div><b>Why? : </b> <input id='6' width='800px' /></div>
          </div>
        </div>
        
      </div>
      <div>
        
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>
            <div> Which of these &emsp; f<input id='7' width='30px'/> <input id='8' width='30px'/> <input id='9' width='30px'/> <input id='10' width='30px'/> <input id='11' width='30px'/>y &emsp; f<input id='12' width='30px'/> <input id='13' width='30px'/> <input id='14' width='30px'/>s &emsp; is the odd one out? </div>
            <div style='display: flex; gap:10px;'>
              <div><b> <input id='1' type='Circle' /> </b></div>
              <div>
                <div> New Moon </div>
                <div> Catching Fire </div>
                <div> Breaking Dawn </div>
                <div> Eclipse</div>
              </div>
            </div>
            <div><b>Why? : </b> <input id='15' width='800px'/></div>
          </div>
        </div>
        
      </div>
      <div style=''>
        
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>
            <div> Which of these is not an &emsp; a<input id='16' width='30px'/> <input id='17' width='30px'/> <input id='18' width='30px'/> <input id='19' width='30px'/> <input id='20' width='30px'/> <input id='21' width='30px'/> <input id='22' width='30px'/>n? </div>
            <div style='display: flex; gap:10px;'>
              <div><b> <input id='2' type='Circle' /> </b></div>
              <div>
                <div> Turbo </div>
                <div> Shrek </div>
                <div> Rango </div>
                <div> Belle</div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
        

      `,
    },
  },
  10: {
    unit: 'Unit 3',
    id: 'SB10-U3-P35-E10',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page35/E10/Key/answerKey.png',
    titleQuestion: [
      {
        num: '8',
        title: `
				<b style='color:rgb(0,147,70);'>SPEAKING</b> Complete the WebQuest quiz below with words from exercise 2 and 6. Search the internet to do the quiz in groups. Check your answers with your teacher.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 39,
    stylesTextInput: [],
    maxLength: 1,
    textAlign: 'center',

    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style='margin-left: 100px'><img src='img/FriendsPlus/Page35/E10/1.jpg' alt=''/></div>
      <div>
        
        <div style='display: flex;margin-top: 30px;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div>
            <div style='width:1100px;'> Match the films with the genres. </div>
            <div style='display: flex; justify-content: space-around;'>
              <div>
                <div><b style='margin-right: 20px;'> 1 </b> <i>The Woman in Black</i> </div>
                <div><b style='margin-right: 20px;'> 2 </b> <i>Wall-E</i> </div>
                <div><b style='margin-right: 20px;'> 3 </b> <i>Gravity</i> </div>
                <div><b style='margin-right: 20px;'> 4 </b> <i> Kinh van hoa</i> </div>
              </div>
              <div>
                <div><b style='margin-right: 20px;'> a </b> a#######n</div>
                <div><b style='margin-right: 20px;'> b </b> s##p &emsp; o###a</div>
                <div><b style='margin-right: 20px;'> c </b> h####r &emsp; f##m</div>
                <div><b style='margin-right: 20px;'> d </b> t######r</div>
              </div>
            </div>
            <div style='display: flex; justify-content: space-around;'>
                <b>Match <i>a/b/c/d</i> : </b> 
                <div><b> 1 - # </b> </div>
                <div><b> 2 - # </b> </div>
                <div><b> 3 - # </b> </div>
                <div><b> 4 - # </b> </div>
            </div>
          </div>
        </div>
        
      </div>
      
      <div>
        
        <div style='display: flex;margin-top: 30px;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>
            <div style='width:1100px;'> Match the actors with the characters they play in these &emsp; a####n &emsp; f###s.</div>
            <div style='display: flex; justify-content: space-around;'>
              <div>
                <div><b style='margin-right: 20px;'> 1 </b> Robert Downey Jr </div>
                <div><b style='margin-right: 20px;'> 2 </b> Ben Affleck </div>
                <div><b style='margin-right: 20px;'> 3 </b> Andrew Garfield </div>
                <div><b style='margin-right: 20px;'> 4 </b> Henry Cavill </div>
              </div>
              <div>
                <div><b style='margin-right: 20px;'> a </b> Batman</div>
                <div><b style='margin-right: 20px;'> b </b> Iron Man</div>
                <div><b style='margin-right: 20px;'> c </b> Superman</div>
                <div><b style='margin-right: 20px;'> d </b> Spider-Man</div>
              </div>
            </div>
            <div style='display: flex; justify-content: space-around;'>
                <b>Match <i>a/b/c/d</i> : </b> 
                <div><b> 1 - # </b> </div>
                <div><b> 2 - # </b> </div>
                <div><b> 3 - # </b> </div>
                <div><b> 4 - # </b> </div>
            </div>
          </div>
        </div>
        
      </div>

      <div>
        
        <div style='display: flex;margin-top: 30px;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>
            <div style='width:1100px;'> Match the two halves to make titles of &emsp; r#####ic &emsp; c####ies.</div>
            <div style='display: flex; justify-content: space-around;'>
              <div>
                <div><b style='margin-right: 20px;'> 1 </b> Mr & Mrs </div>
                <div><b style='margin-right: 20px;'> 2 </b> When Harry Met </div>
                <div><b style='margin-right: 20px;'> 3 </b> Pretty </div>
                <div><b style='margin-right: 20px;'> 4 </b> Along Came </div>
              </div>
              <div>
                <div><b style='margin-right: 20px;'> a </b> Woman</div>
                <div><b style='margin-right: 20px;'> b </b>  Smith</div>
                <div><b style='margin-right: 20px;'> c </b> Polly</div>
                <div><b style='margin-right: 20px;'> d </b> Sally</div>
              </div>
            </div>
            <div style='display: flex; justify-content: space-around;'>
                <b>Match <i>a/b/c/d</i> : </b> 
                <div><b> 1 - # </b> </div>
                <div><b> 2 - # </b> </div>
                <div><b> 3 - # </b> </div>
                <div><b> 4 - # </b> </div>
            </div>
          </div>
        </div>
        
      </div>
      
			  `,
        answer: [
          'n',
          'i',
          'm',
          'a',
          't',
          'i',
          'o',
          'o',
          'a',
          'p',
          'e',
          'r',
          'o',
          'r',
          'r',
          'o',
          'i',
          'l',
          'h',
          'r',
          'i',
          'l',
          'l',
          'e',
          'c',
          'a',
          'd',
          'b',

          'c',
          't',
          'i',
          'o',
          'i',
          'l',
          'm',
          'b',
          'a',
          'd',
          'c',

          'o',
          'm',
          'a',
          'n',
          't',
          'o',
          'm',
          'e',
          'd',
          'b',
          'd',
          'a',
          'c',
        ],
      },
    ],
  },
};

export default json;
