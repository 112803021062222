import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

const json = {
	1: {
		unit: 'Unit 1',
		id: 'SB10-U1-P18-E1',
		audio: '',
		video: '',
		component: UI,
		titleQuestion: [
			{
				num: '1',
				title: `
          Look at the photo. Do you know this sport? How do you think it feels to do it?
        `,
			},
		],
		recorder: true,
		questionImage: [
			[
				// Column1
				{ url: 'img/FriendsPlus/Page18/E1/1.jpg' },
			],
		],
	},
	2: {
		unit: 'Unit 1',
		id: 'SB10-U1-P18-E2',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page18/E2/Key/answerKey.png',
		component: T6v2,
		inputSize: 200,
		maxLength: 12,
		stylesTextInput: {
			textAlign: 'center',
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '2',
				title: `
	        Complete the dialogue with the correct affirmative or
	        negative past simple form of the verbs below.
	      `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
	        <div style="margin-left:100px;font-weight: bold; color: rgb(0, 147, 69); display: flex; gap: 10px 30px; flex-wrap: wrap;">
	          <span>be</span><span>get</span><span>learn</span><span>leave</span><span>love</span><span>spend</span><span>watch</span>
	        </div>

	        <div style="margin-top: 20px;">
	          <div style="display: flex; gap: 20px;">
	            <div style="font-weight: bold; text-align: right;">Kirstie</div>
	            <div>Hi, Laurie. How are you? Tell me about your summer holiday!</div>
	          </div>
	          <div style="margin-top: 7px; display: flex; gap: 20px;">
	            <div style="font-weight: bold; text-align: right;">Laurie</div>
	            <div>Well, for the first three weeks, I was at a summer camp in Cornwall.</div>
	          </div>
	          <div style="display: flex; gap: 20px;">
	            <div style="font-weight: bold; text-align: right;">Kirstie</div>
	            <div>Really? That sounds like fun!</div>
	          </div>
	          <div style="margin-top: 7px; display: flex; gap: 20px;">
	            <div style="font-weight: bold; text-align: right;">Laurie</div>
	            <div>Yes, it was. I <sup>1</sup># a new sport – bodyboarding.</div>
	          </div>
	          <div style="display: flex; gap: 20px;">
	            <div style="font-weight: bold; text-align: right;">Kirstie</div>
	            <div>Wow! That sounds great!</div>
	          </div>
	          <div style="margin-top: 7px; display: flex; gap: 20px;">
	            <div style="font-weight: bold; text-align: right;">Laurie</div>
	            <div>Yes, I <sup>2</sup># it. It was really exciting – and a bit frightening too!</div>
	          </div>
	          <div style="display: flex; gap: 20px;">
	            <div style="font-weight: bold; text-align: right;">Kirstie</div>
	            <div>I bet! What else did you get up to over the summer?</div>
	          </div>
	          <div style="margin-top: 7px; display: flex; gap: 20px;">
	            <div style="font-weight: bold; text-align: right;">Laurie</div>
	            <div>Well, the second half of the holiday <sup>3</sup># so good. I <sup>4</sup># a stomach bug and <sup>5</sup># nearly a week on the sofa.</div>
	          </div>
	          <div style="display: flex; gap: 20px;">
	            <div style="font-weight: bold; text-align: right;">Kirstie</div>
	            <div>Oh dear! How awful!</div>
	          </div>
	          <div style="margin-top: 7px; display: flex; gap: 20px;">
	            <div style="font-weight: bold; text-align: right;">Laurie</div>
	            <div>I <sup>6</sup>#	the house for days. I just <sup>7</sup># DVDs. I was so bored!</div>
	          </div>
	        </div>
	      `,
				answer: ['learned / learnt', 'loved', "wasn't", 'got', 'spent', "didn't leave", 'watched'],
			},
		],
	},
	3: {
		unit: 'Unit 1',
		id: 'SB10-U1-P18-E3',
		audio: 'Audios/Friends Global Grade 10 SB_CD1_14.mp3',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page18/E3/Key/answerKey.png',
		component: T6,
		inputSize: 800,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: '20px',
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
          <audioimage name="1.14"></audioimage> 
          Listen and check your answers to exercise 2. 
          Does the photo go with the first or second half of the dialogue? 
          How do you know?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
          <div>
            <span style="font-size: 20px; font-weight: bold; font-style: italic;">Answer: #</span>
          </div>

          <div style="font-weight: bold; color: rgb(0, 147, 69); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>be</span><span>get</span><span>learn</span><span>leave</span><span>love</span><span>spend</span><span>watch</span>           
          </div>

          <div style="margin-top: 20px; display: flex; gap: 30px; align-items: center;">
            <div style="flex: 1;"><img src="img/FriendsPlus/Page18/E1/1.jpg" style="max-width: 100%;" /></div>

            <div style="flex: 1; font-size: 20px;">
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">Kirstie</div>
                <div>Hi, Laurie. How are you? Tell me about your summer holiday!</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">Laurie</div>
                <div>Well, for the first three weeks, I was at a summer camp in Cornwall.</div>
              </div>	
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">Kirstie</div>
                <div>Really? That sounds like fun!</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">Laurie</div>
                <div>Yes, it was. I <sup>1</sup>__________ a new sport – bodyboarding.</div>
              </div>	
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">Kirstie</div>
                <div>Wow! That sounds great!</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">Laurie</div>
                <div>Yes, I <sup>2</sup>__________ it. It was really exciting – and a bit frightening too!</div>
              </div>	
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">Kirstie</div>
                <div>I bet! What else did you get up to over the summer?</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">Laurie</div>
                <div>Well, the second half of the holiday <sup>3</sup>__________ so good. I <sup>4</sup>__________ a stomach bug and <sup>5</sup>__________ nearly a week on the sofa.</div>
              </div>	
              <div style="display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">Kirstie</div>
                <div>Oh dear! How awful!</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="font-weight: bold; text-align: right;">Laurie</div>
                <div>I <sup>6</sup>__________	the house for days. I just <sup>7</sup>__________ DVDs. I was so bored!</div>
              </div>	
            </div>
          </div>
        `,
				answer: ['The photo goes with the first half of the dialogue because it shows Laurie bodyboarding.'],
			},
		],
	},
	4: {
		unit: 'Unit 1',
		id: 'SB10-U1-P18-E4',
		audio: 'Audios/Friends Global Grade 10 SB_CD1_15.mp3',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page18/E4/Key/answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '4',
				title: `
          <audioimage name="1.15"></audioimage> 
          Listen to three girls talking about their summer holidays. 
          Match each speaker (1–3) with an event (a–c) and then 
          circle the correct adjective to describe how she felt about it.
        `,
				color: '#860052',
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: ['2', '1', '3'],
				initialValue: [],
			},
			Circle: {
				// initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
				initialWordStyle: {
					padding: '5px',
					margin: '5px 0',
					border: 'none',
					borderColor: 'transparent',
					borderRadius: '50%',
				},
				selectWordStyle: {
					padding: '5px',
					border: 'solid 2px',
					borderColor: '#00a8ec',
					margin: '-2px',
				},
				limitSelect: 1,
				listWords: [
					'depressed / embarrassed / worried',
					'exhausted / proud / surprised',
					'envious / interested / shocked',
				],
				answers: ['0-8', '1-8', '2-0'],
				initialValue: [],
			},
			Layout: `
        <div>
          <div style="display: flex; gap: 20px;">
            <div style="font-weight: bold; text-align: right;">a</div>
            <div>
              Speaker <input type="text" id="0" width="30px"> got sunburned.<br />
              She felt <strong><input type="Circle" id="0"></strong>
            </div>
          </div>
          <div style="margin-top: 7px; display: flex; gap: 20px;">
            <div style="font-weight: bold; text-align: right;">b</div>
            <div>
              Speaker <input type="text" id="1" width="30px"> ran a half marathon.<br />
              She felt <strong><input type="Circle" id="1"></strong>
            </div>
          </div>	
          <div style="margin-top: 7px; display: flex; gap: 20px;">
            <div style="font-weight: bold; text-align: right;">c</div>
            <div>
              Speaker <input type="text" id="2" width="30px"> visited her friend’s new house.<br />
              She felt <strong><input type="Circle" id="2"></strong>
            </div>
          </div>	
        </div>
      `,
			// Layout: `
			//   <div>
			//   <b>a</b> <span>Speaker <input type="text" id="0" width="30px"></span> got sunburned.She felt <b><input type="Circle" id="0"></b><br>
			//   <b>b</b> <span>Speaker <input type="text" id="1" width="30px"></span> ran a half marathon. She felt <b><input type="Circle" id="1"></b><br>
			//   <b>c</b> <span>Speaker <input type="text" id="2" width="30px"></span> visited her friend’s new house. She felt <b><input type="Circle" id="2"></b><br>
			// </div>
			// `,
		},
	},
	5: {
		unit: 'Unit 1',
		id: 'SB10-U1-P18-E5',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page18/E5/Key/answerKey.png',
		component: T6,
		inputSize: 130,
		maxLength: 10,
		checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '5',
				title: `
          <span style="color: rgb(146,39,143);">KEY PHRASES</span> 
          Read the phrases for reacting and showing interest. 
          Then find three more in the dialogue in exercise 2.
        `,
				color: '#860052',
			},
		],

		questions: [
			{
				title: `
          <div><span style="font-size: 20px; font-weight: bold; font-style: italic;">Answers: #, #, #</span></div>
          
          <div style="margin-top: 20px; display: flex; gap: 30px;">
            <div style="font-size: 20px; padding-left: 20px; border-left: 5px solid rgb(45,206,137); line-height: 40px;">
              <b><span style="color:rgb(45,206,137)">Reacting and showing interest</span></b><br>
              <span>You’re joking / kidding!</span><br>
              <span>How boring / funny / frustrating / exciting / upsetting!</span><br>
              <span>That’s amazing / exciting / worrying / shocking!</span><br>
              <span>That sounds great / terrible / annoying / terrifying!</span><br>
              <span>What a cool thing to do!</span><br>
              <span>Really? I’m so envious!</span><br>
              <span>Really? What a relief!</span><br>
              <span>That sounds like a nightmare!</span><br>
              <span>Oh no! What a disaster / shame!</span>
            </div>

            <div style="flex: 1; font-size: 20px; padding-left: 20px; border-left: 5px solid rgb(45,206,137);">
                <div style="font-weight: bold; margin-bottom: 10px;">Dialogue in exercise 2</div>
                <div style="display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Kirstie</div>
                  <div>Hi, Laurie. How are you? Tell me about your summer holiday!</div>
                </div>
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Laurie</div>
                  <div>Well, for the first three weeks, I was at a summer camp in Cornwall.</div>
                </div>	
                <div style="display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Kirstie</div>
                  <div>Really? That sounds like fun!</div>
                </div>
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Laurie</div>
                  <div>Yes, it was. I <sup>1</sup>__________ a new sport – bodyboarding.</div>
                </div>	
                <div style="display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Kirstie</div>
                  <div>Wow! That sounds great!</div>
                </div>
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Laurie</div>
                  <div>Yes, I <sup>2</sup>__________ it. It was really exciting – and a bit frightening too!</div>
                </div>	
                <div style="display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Kirstie</div>
                  <div>I bet! What else did you get up to over the summer?</div>
                </div>
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Laurie</div>
                  <div>Well, the second half of the holiday <sup>3</sup>__________ so good. I <sup>4</sup>__________ a stomach bug and <sup>5</sup>__________ nearly a week on the sofa.</div>
                </div>	
                <div style="display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Kirstie</div>
                  <div>Oh dear! How awful!</div>
                </div>
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Laurie</div>
                  <div>I <sup>6</sup>__________	the house for days. I just <sup>7</sup>__________ DVDs. I was so bored!</div>
                </div>	
              </div>
            </div>
        `,
				answer: ['Wow! / Oh dear! / How awful!', 'Wow! / Oh dear! / How awful!', 'Wow! / Oh dear! / How awful!'],
			},
		],
	},
	6: {
		unit: 'Unit 1',
		id: 'SB10-U1-P18-E6',
		audio: '/Audios/Friends Global Grade 10 SB_CD1_14.mp3',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page18/E6/Key/answerKey.png',
		component: T6,
		inputSize: 150,
		maxLength: 10,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '6',
				title: `
          <audioimage name="1.14"></audioimage> 
          <span style="color: rgb(1,102,179);">PRONUNCIATION</span> 
          Listen to the dialogue in exercise 2 again, 
          then practise saying the phrases above. Try to sound interested!
        `,
				color: '#860052',
			},
		],

		questions: [
			{
				title: `
          <div style="margin-top: 20px; display: flex; gap: 30px;">
            <div style="font-size: 20px; padding-left: 20px; border-left: 5px solid rgb(45,206,137); line-height: 40px;">
              <b><span style="color:rgb(45,206,137)">Reacting and showing interest</span></b><br>
              <span>You’re joking / kidding!</span><br>
              <span>How boring / funny / frustrating / exciting / upsetting!</span><br>
              <span>That’s amazing / exciting / worrying / shocking!</span><br>
              <span>That sounds great / terrible / annoying / terrifying!</span><br>
              <span>What a cool thing to do!</span><br>
              <span>Really? I’m so envious!</span><br>
              <span>Really? What a relief!</span><br>
              <span>That sounds like a nightmare!</span><br>
              <span>Oh no! What a disaster / shame!</span>
            </div>

            <div style="flex: 1; font-size: 20px; padding-left: 20px; border-left: 5px solid rgb(45,206,137);">
                <div style="font-weight: bold; margin-bottom: 10px;">Dialogue in exercise 2</div>
                <div style="display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Kirstie</div>
                  <div>Hi, Laurie. How are you? Tell me about your summer holiday!</div>
                </div>
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Laurie</div>
                  <div>Well, for the first three weeks, I was at a summer camp in Cornwall.</div>
                </div>	
                <div style="display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Kirstie</div>
                  <div>Really? That sounds like fun!</div>
                </div>
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Laurie</div>
                  <div>Yes, it was. I <sup>1</sup>__________ a new sport – bodyboarding.</div>
                </div>	
                <div style="display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Kirstie</div>
                  <div>Wow! That sounds great!</div>
                </div>
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Laurie</div>
                  <div>Yes, I <sup>2</sup>__________ it. It was really exciting – and a bit frightening too!</div>
                </div>	
                <div style="display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Kirstie</div>
                  <div>I bet! What else did you get up to over the summer?</div>
                </div>
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Laurie</div>
                  <div>Well, the second half of the holiday <sup>3</sup>__________ so good. I <sup>4</sup>__________ a stomach bug and <sup>5</sup>__________ nearly a week on the sofa.</div>
                </div>	
                <div style="display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Kirstie</div>
                  <div>Oh dear! How awful!</div>
                </div>
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">Laurie</div>
                  <div>I <sup>6</sup>__________	the house for days. I just <sup>7</sup>__________ DVDs. I was so bored!</div>
                </div>	
              </div>
            </div>
        `,
				answer: [],
			},
		],
	},
	7: {
		unit: 'Unit 1',
		id: 'SB10-U1-P18-E7',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page18/E7/Key/answerKey.png',
		component: T6,
		recorder: true,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '7',
				title: `
          Work in pairs. 
          Take turns to say a sentence from the list below 
          using the correct past simple form of the verbs in brackets. 
          Your partner reacts with a suitable phrase from exercise 5.
        `,
				color: '#860052',
			},
		],
		hintBox: [
			{
				src: ['I finally finished my science project.'],
				borderColor: 'rgb(196 44 206)',
				arrow: true,
				position: 2,
				width: 430,
			},
			{
				src: ['Really? What a relief!'],
				borderColor: 'rgb(196 44 206)',
				arrow: true,
				position: 1,
				width: 280,
			},
		],
		questions: [
			{
				title: `
          <div style="margin-top: 20px; display: flex; gap: 30px; align-items: start;">
            <div>
              <div style="padding: 10px; border-radius: 10px; border: 1px solid rgb(45,206,137);">
                <div style="display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">1</div>
                  <div>I finally (finish) my science project.</div>
                </div>
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">2</div>
                  <div>I (learn) to play a new song on the guitar.</div>
                </div>	
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">3</div>
                  <div>I (break) a bone in my foot.</div>
                </div>	
                <div style="margin-top: 7px; display: flex; gap: 20px;">
                  <div style="font-weight: bold; text-align: right;">4</div>
                  <div>I (drop) my dad’s laptop.</div>
                </div>	
              </div>
              <div style="margin-top: 30px;"><hintbox id='0'></hintbox></div>
              <div style="margin-top: 40px; margin-left: 230px;"><hintbox id='1'></hintbox></div>
            </div>

            <div style="padding-left: 20px; border-left: 5px solid rgb(45,206,137); line-height: 40px;">
              <b><span style="color:rgb(45,206,137)">Reacting and showing interest</span></b><br>
              <span>You’re joking / kidding!</span><br>
              <span>How boring / funny / frustrating / exciting / upsetting!</span><br>
              <span>That’s amazing / exciting / worrying / shocking!</span><br>
              <span>That sounds great / terrible / annoying / terrifying!</span><br>
              <span>What a cool thing to do!</span><br>
              <span>Really? I’m so envious!</span><br>
              <span>Really? What a relief!</span><br>
              <span>That sounds like a nightmare!</span><br>
              <span>Oh no! What a disaster / shame!</span>
            </div>
          </div>
        `,
				answer: [],
			},
		],
	},
	8: {
		unit: 'Unit 1',
		id: 'SB10-U1-P18-E8',
		audio: '',
		video: '',
		exerciseKey: '/img/FriendsPlus/Page18/E8/Key/answerKey.png',
		component: T6,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '8',
				title: `
          Read the <span style="color: rgb(45,206,137);">Speaking Strategy</span>. 
          Work in pairs. One student tells an event <br/>from his / her last summer, 
          the other reacts to the story.
        `,
				color: '#860052',
			},
		],
		recorder: true,
		questions: [
			{
				title: `
           <div style="margin-top: 20px; margin-left: 20px; width: 600px; padding: 10px; border-radius: 10px; border: 4px dotted rgb(45,206,137); background-color: rgb(229, 240, 228); box-shadow: 3px 3px 5px rgba(0,0,0,0.4)">
              <span style="font-weight: bold; color: rgb(0, 147, 69);">Speaking strategy</span>
              <div>
                Follow a simple structure for narrating events, for example:
                <div>
                  <div style="display: flex; gap: 20px;">
                    <div style="font-weight: bold; text-align: right;">1</div>
                    <div>set the scene (where? when? who?)</div>
                  </div>
                  <div style="margin-top: 7px; display: flex; gap: 20px;">
                    <div style="font-weight: bold; text-align: right;">2</div>
                    <div>say what happened</div>
                  </div>	
                  <div style="margin-top: 7px; display: flex; gap: 20px;">
                    <div style="font-weight: bold; text-align: right;">3</div>
                    <div>say how you (and/or others) felt about it</div>
                  </div>	
                </div>
              </div>
            </div>
        `,
				answer: [],
			},
		],
	},
}

export default json
