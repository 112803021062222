import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P128-E1',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '',
				title:
					"<strong><span style='color:rgb(144 38 143);'>8G</span>Gadgets</strong> <br /> <span>1</span> Match six of the gadgets below with pictures A–C. Check the meaning of all the words",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: 'none',
			backgroundColor: 'transparent',
		},
		inputSize: 110,
		maxLength: 7,
		exerciseKey: 'img/FriendsPlus/Page6/E1/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
       
          <div style="color: rgb(144 38 143); display: flex; column-gap: 20px; flex-wrap: wrap; width: 800px">
            <span>Blu-ray player</span>
            <span>camcorder</span>
            <span>digital camera</span>
            <span>digital radio</span>
            <span>digital photo frame</span>
            <span>DVD player</span>
            <span>e-book reader</span>
            <span>games console</span>
            <span>hard disk recorder</span>
            <span>headphones</span>
            <span>laptop</span>
            <span>mobile</span>
            <span>MP3 player</span>
            <span>satnav</span>
            <span>smart TV</span>
            <span>(solar-powered) battery charger</span>
            <span>tablet</span>
            <span>wireless speakers</span>
          </div>
          
          
          <div style="margin-top: 20px">
            <img src="img/FriendsPlus/Page128/E1/1.png" style="width: 800px"/>
          </div>
				`,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P128-E2',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '2',
				title: 'Read the sentences below. Which gadget is each person talking about? ',
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: 'none',
			backgroundColor: 'transparent',
		},
		inputSize: 110,
		maxLength: 7,
		exerciseKey: 'img/FriendsPlus/Page6/E1/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              “It connects to the internet so I can watch films and TV programmes online.”
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              “I can record my favourite TV programmes and watch them later. It can store about 100 programmes.”
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              “Dad has one – Mum gave it to him for Christmas. But he never uses it. He prefers to use maps.”
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              “I use them with my smartphone. They’re quite small so I can take them anywhere and the sound quality is really good.”
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              “I can store hundreds of books on it. Much easier than carrying them around with me!”
            </div>
          </div>
          
				`,
				answer: [],
			},
		],
	},
	3: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P128-E3',
		audio: '',
		video: '',
		component: Circle_Write,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '',
				title:
					"<strong><span style='color:rgb(144 38 143);'>8H</span> Conjunctions</strong> <br /> <span>3</span>  Choose the correct conjunction (a–c) to complete the sentences. Use a dictionary to help you if necessary.",
				color: 'black',
			},
		],
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				// initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
				initialWordStyle: {
					margin: '5px 0',
					border: 'none',
					borderColor: 'transparent',
					borderRadius: '50%',
				},
				selectWordStyle: {
					border: 'solid 2px',
					borderColor: '#00a8ec',
					margin: '-2px',
				},
				limitSelect: 1,
				listWords: [
					'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c',
					'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c',
					'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c',
					'a &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c',
				],
				answers: [],
				initialValue: [],
			},
			/*html */
			Layout: `
        <div style="margin-bottom: 20px">
            <img src="img/FriendsPlus/Page128/E2/1.png" style="width: 600px"/>
          </div>

        <div>
          <div>If there were no cars, lorries, vans etc. in the world, …</div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>1</strong>
          <div>
            <div>You couldn’t travel long distances you went by public tansport.</div>
            <div style="position: relative; padding: 20px 0">
                 <div style="z-index: 2; position: absolute; top: 0">
                <strong><input id="0" type="Circle" /></strong>
              </div> 
              <div style="position: absolute; top: 0; left: 30px;background-color: transparent">
                <span>rather than</span>
                <span style="margin-left: 100px">so</span>
                <span style="margin-left: 200px">unless</span>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>2</strong>
          <div>
            <div>You would probably shop locally going to a big supermarket.</div>
             <div style="position: relative; padding: 20px 0">
              <div style="z-index: 2; position: absolute; top: 0">
                <strong><input id="1" type="Circle" /></strong>
              
              </div> 
              <div style="position: absolute; top: 0; left: 30px; z-index: 1; ">
                <span>rather than</span>
                <span style="margin-left: 100px">although</span>
                <span style="margin-left: 140px">but</span>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>3</strong>
          <div>
            <div>The motorways would be empty you could cycle on them safely</div>
           <div style="position: relative; padding: 20px 0">
              <div style="z-index: 2; position: absolute; top: 0">
                <strong><input id="2" type="Circle" /></strong>
              
              </div> 
              <div style="position: absolute; top: 0; left: 30px">
                <span>as</span>
                <span style="margin-left: 200px">because</span>
                <span style="margin-left: 133px">unless</span>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>4</strong>
          <div>
            <div>We would eat more food from our local area it would be difficult to transport it from other places.</div>
             <div style="position: relative; padding: 20px 0">
              <div style="z-index: 2; position: absolute; top: 0">
                <strong><input id="3" type="Circle" /></strong>
              
              </div> 
              <div style="position: absolute; top: 0; left: 30px">
                <span>so</span>
                <span style="margin-left: 200px">since</span>
                <span style="margin-left: 170px">although</span>
              </div>
            </div>
          </div>
        </div>
				`,
			answer: [],
		},
	},
};

export default json;
