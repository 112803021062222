import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

const json = {
	1: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P114-E1',
		audio: '',
		video: '',
		component: T6v2,
		titleQuestion: [
			{
				num: '',
				title:
					"<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>4B</span>Comparison</strong> <br /> <strong>1</strong> Write the comparative form of the adjectives and quantifiers.<leftarrow name='4.1'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 24,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 200,
		exerciseKey: 'img/FriendsPlus/Page114/E1/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div style="width: 800px; display: flex; justify-content: space-between">
            <div>
              <div style=" display: flex; justify-content: space-between">
                <strong>1</strong>
                <span style="margin-left: 20px; width: 100px">small </span>
                <input />
              </div>
              <div style="display: flex; justify-content: space-between">
                <div>
                <strong>2</strong>
                  <span style="margin-left: 20px; width: 150px">large </span>
                </div>  
                <input />
              </div>
              <div style="display: flex; justify-content: space-between">
                <div>
                <strong>3</strong>
                  <span style="margin-left: 20px; width: 150px">early </span>
                </div>  
                <input />
              </div>
              <div style="display: flex; justify-content: space-between">
                <div>
                <strong>4</strong>
                  <span style="margin-left: 20px; width: 150px">powerful </span>
                </div>  
                <input />
              </div>
              <div style="display: flex; justify-content: space-between">
                <div>
                <strong>5</strong>
                  <span style="margin-left: 20px; width: 150px">much </span>
                </div>  
                <input />
              </div>
              <div style="display: flex; justify-content: space-between">
                <div>
                <strong>6</strong>
                  <span style="margin-left: 20px; width: 150px">bright </span>
                </div>  
                <input />
              </div>
            </div>
            <div>
              <div style="display: flex; justify-content: space-between">
                <div>
                <strong>7</strong>
                  <span style="margin-left: 20px; width: 150px">few </span>
                </div>  
                <input />
              </div>
              <div style="display: flex; justify-content: space-between">
                <div>
                <strong>8</strong>
                  <span style="margin-left: 20px; width: 150px">many </span>
                </div>  
                <input />
              </div>
              <div style="display: flex; justify-content: space-between">
                <div>
                <strong>9</strong>
                  <span style="margin-left: 20px; width: 150px">far </span>
                </div>  
                <input />
              </div>
              <div style="display: flex; justify-content: space-between">
                <div>
                <strong>10</strong>
                  <span style="margin-left: 20px; width: 150px">wet </span>
                </div>  
                <input />
              </div>
              <div style="display: flex; justify-content: space-between">
                <div>
                <strong>11</strong>
                  <span style="margin-left: 20px; width: 150px">pretty </span>
                </div>  
                <input />
              </div>
              <div style="display: flex; justify-content: space-between">
                <div>
                <strong>12</strong>
                  <span style="margin-left: 20px; width: 150px">frightening </span>
                </div>  
                <input />
              </div>
            </div>
          </div>

				`,
				answer: [
					'smaller',
					'larger',
					'earlier',
					'more powerful',
					'more',
					'brighter',
					'fewer',
					'more',
					'further / farther',
					'wetter',
					'prettier',
					'more frightening',
				],
			},
		],
	},
	2: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P114-E2',
		audio: '',
		video: '',
		component: T6v2,
		titleQuestion: [
			{
				num: '2',
				title:
					"Complete the email with the comparative form of theComplete the email with the comparative form of the adjectives and quantifiers in brackets.<leftarrow name='4.1'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 155,
		exerciseKey: 'img/FriendsPlus/Page114/E2/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
        <div style="position: relative">
          <img src="img/FriendsPlus/Page114/E2/1.png" style="width: 700px;"/>
          <div style="position: absolute; top: 30px; left: 20px; width: 650px;">
            <div style="padding: 10px 20px; font-size: 21px; color: rgb(0 68 98);">
              <div>Dear Aunt Polly,</div>
              <div>
                I hope you’re well. Jake seems <sup>1</sup><input />
                (happy) at his new school. It’s  <sup>2</sup><input />
                (small) and <sup>3</sup><input />
                (friendly) than his old school. His grades are <sup>4</sup><input />
                (good) than last year. He gets <sup>5</sup><input />
                homework but the lessons at school are <sup>6</sup><input />
                (difficult), he says. The only bad thing about his new school is that it’s <sup>7</sup><input />
                (far) from our house. At the moment he cycles there, but he’s planning to go by bus when the weather gets <sup>8</sup><input />
                (cold) and <sup>9</sup><input />
                (wet). 
              </div>
              <div>
              I’m working hard for my exams but I’m not enjoying it. It’s always  <sup>10</sup><input />
              (bad) at the weekend because I want to go out!
              </div>
              <div>Write soon.</div>
              <div>Eva</div>
            </div>
          
          </div>
        
        </div>
				`,
				answer: [
					'happier',
					'smaller',
					'friendlier',
					'better',
					'less',
					'more difficult',
					'further / farther',
					'colder',
					'wetter',
					'worse',
				],
			},
		],
	},
	3: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P114-E3',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '3',
				title:
					"Write sentences comparing a and b. Include the correct form of the words in brackets.<leftarrow name='4.1, 4.2'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 24,
			// textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 530,
		exerciseKey: 'img/FriendsPlus/Page114/E3/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
	        <div>
	          <div style="display: flex; column-gap: 20px">
	            <strong>1</strong>
	            <div>
	              <div style="display: flex;">
	                <div style="width: 250px;">
	                  <strong>a</strong>
	                  <span style="margin-left: 10px">Iceland</span>
	                </div>
	                <div>
	                  <strong>b</strong>
	                  <span style="margin-left: 10px">Egypt (far / cold)</span>
	                </div>
	              </div>
	              <div style="color: rgb(4 84 121); font-style: oblique;">Iceland is far colder than Egypt.</div>
	            </div>
	          </div>

	          <div style="display: flex; column-gap: 20px;">
	            <strong>2</strong>
	            <div>
	              <div style="display: flex;">
	                <div style="width: 250px;">
	                  <strong>a</strong>
	                  <span style="margin-left: 10px">the Earth</span>
	                </div>
	                <div>
	                  <strong>b</strong>
	                  <span style="margin-left: 10px"> the sun (much / small)</span>
	                </div>
	              </div>
	              <input />
	            </div>
	          </div>

	          <div style="display: flex; column-gap: 20px;">
	            <strong>3</strong>
	            <div>
	              <div style="display: flex;">
	                <div style="width: 250px;">
	                  <strong>a</strong>
	                  <span style="margin-left: 10px">gold</span>
	                </div>
	                <div>
	                  <strong>b</strong>
	                  <span style="margin-left: 10px">silver (heavy)</span>
	                </div>
	              </div>
	              <input />
	            </div>
	          </div>

	          <div style="display: flex; column-gap: 20px;">
	            <strong>4</strong>
	            <div>
	              <div style="display: flex;">
	                <div style="width: 250px;">
	                  <strong>a</strong>
	                  <span style="margin-left: 10px">rock-climbing</span>
	                </div>
	                <div>
	                  <strong>b</strong>
	                  <span style="margin-left: 10px">hiking (dangerous)</span>
	                </div>
	              </div>
	              <input />
	            </div>
	          </div>

	          <div style="display: flex; column-gap: 20px;">
	            <strong>5</strong>
	            <div>
	              <div style="display: flex;">
	                <div style="width: 250px;">
	                  <strong>a</strong>
	                  <span style="margin-left: 10px">cycling</span>
	                </div>
	                <div>
	                  <strong>b</strong>
	                  <span style="margin-left: 10px">skiing (much / easy)</span>
	                </div>
	              </div>
	              <input />
	            </div>
	          </div>

	          <div style="display: flex; column-gap: 20px;">
	            <strong>6</strong>
	            <div>
	              <div style="display: flex;">
	                <div style="width: 250px;">
	                  <strong>a</strong>
	                  <span style="margin-left: 10px">the USA</span>
	                </div>
	                <div>
	                  <strong>b</strong>
	                  <span style="margin-left: 10px">the UK (far / big)</span>
	                </div>
	              </div>
	              <input />
	            </div>
	          </div>

	          <div style="display: flex; column-gap: 20px;">
	            <strong>7</strong>
	            <div>
	              <div style="display: flex;">
	                <div style="width: 250px;">
	                  <strong>a</strong>
	                  <span style="margin-left: 10px">Porsches</span>
	                </div>
	                <div>
	                  <strong>b</strong>
	                  <span style="margin-left: 10px">Skodas (expensive)</span>
	                </div>
	              </div>
	              <input />
	            </div>
	          </div>

	          <div style="display: flex; column-gap: 20px;">
	            <strong>8</strong>
	            <div>
	              <div style="display: flex;">
	                <div style="width: 250px;">
	                  <strong>a</strong>
	                  <span style="margin-left: 10px">ice storms</span>
	                </div>
	                <div>
	                  <strong>b</strong>
	                  <span style="margin-left: 10px">thunderstorms (far / rare)</span>
	                </div>
	              </div>
	              <input />
	            </div>
	          </div>

	          <div style="display: flex; column-gap: 20px;">
	            <strong>9</strong>
	            <div>
	              <div style="display: flex;">
	                <div style="width: 250px;">
	                  <strong>a</strong>
	                  <span style="margin-left: 10px">a broken ankle</span>
	                </div>
	                <div>
	                  <strong>b</strong>
	                  <span style="margin-left: 10px">a sprained ankle (bad)</span>
	                </div>
	              </div>
	              <input />
	            </div>
	          </div>
	        </div>
				`,
				answer: [
					'The Earth is much smaller than the sun.',
					'Gold is heavier than silver.',
					'Rock-climbing is more dangerous than hiking.',
					'Cycling is much easier than skiing.',
					'The USA is far bigger than the UK.',
					'Porsches are more expensive than Skodas.',
					'Ice storms are far rarer than thunderstorms. ',
					'A broken ankle is worse than a sprained ankle.',
				],
			},
		],
	},
	4: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P114-E4',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '4',
				title:
					"Write sentences based on the information. Use (not) as … as and the adjective in brackets.<leftarrow name='4.3'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 24,
			textAlign: 'left',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 580,
		exerciseKey: 'img/FriendsPlus/Page114/E4/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              <div>Tom and Karen both weigh 72 kg. (heavy)</div>
              <div style="color: rgb(4 84 121); font-style: oblique;">Tom is as heavy as Karen.</div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              <div>The pen costs £12.95 and the book costs £8.50.(expensive)</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div>Darren is 154 cm tall and Lucy is 165 cm tall. (tall)</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              <div>London is 150 km away and Leeds is 180 km away. (far)</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <div>The skiing holiday costs £500 and the beach holiday costs the same. (cheap)</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <div>
              <div>About a hundred students choose to study Spanish each year and only fifty choose French. (popular) </div>
              <input />
            </div>
          </div>
				`,
				answer: [
					"The book isn't as expensive as the pen.",
					"Darren isn't as tall as Lucy.",
					"London isn't as far as Leeds. / London isn't as far away as Leeds.",
					'The skiing holiday is as cheap as the beach holiday.',
					"French isn't as popular as Spanish.",
				],
			},
		],
	},
	5: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P114-E5',
		audio: '',
		video: '',
		component: T6v2,
		titleQuestion: [
			{
				num: '',
				title:
					"<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>4D (Part 1) </span>Superlative adjectives</strong> <br /> <strong>5</strong> Write the superlative form of the adjectives<leftarrow name='4.4'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 24,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 290,
		exerciseKey: 'img/FriendsPlus/Page114/E5/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
        <div style="display: flex; column-gap: 40px">
          <div>
            <div>
              <strong>1</strong>
              <span style="margin-left: 20px">big <input /></span>
            </div>
            <div>
              <strong>2</strong>
              <span style="margin-left: 20px">scary <input /></span>
            </div>
            <div>
              <strong>3</strong>
              <span style="margin-left: 20px">bad <input /></span>
            </div>
          </div>
          <div>
            <div>
              <strong>4</strong>
              <span style="margin-left: 20px">far <input /></span>
            </div>
            <div>
              <strong>5</strong>
              <span style="margin-left: 20px">good <input /></span>
            </div>
            <div>
              <strong>6</strong>
              <span style="margin-left: 20px">intelligent <input /></span>
            </div>
          </div>
        </div>
				`,
				answer: [
					'the biggest',
					'the scariest',
					'the worst',
					'the furthest / the farthest',
					'the best',
					'the most intelligent',
				],
			},
		],
	},
	6: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P114-E6',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '6',
				title:
					"Complete the sentences with superlative adjectives. Add in or of before the final noun.<leftarrow name='4.4, 4.5'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 24,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 230,
		exerciseKey: 'img/FriendsPlus/Page114/E6/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div>
            <strong>1</strong>
            <span style="margin-left: 20px">Joe is <input /> (intelligent) boy <input width="50px" > the school</span>
          </div>
          <div>
            <strong>2</strong>
            <span style="margin-left: 20px">Friday is <input /> (good) day <input width="50px" > the week</span>
          </div>
          <div>
            <strong>4</strong>
            <span style="margin-left: 20px">This is <input /> (bad) restaurant <input width="50px" > London</span>
          </div>
          <div>
            <strong>5</strong>
            <span style="margin-left: 20px">Lake Superior is <input /> (big) <input width="50px" > the five Great Lakes.</span>
          </div>
          <div>
            <strong>6</strong>
            <span style="margin-left: 20px">21 December is <input /> (short) day <input width="50px" > the year.</span>
          </div>
				`,
				answer: [
					'the most intelligent',
					'in',
					'the best',
					'of',
					'the worst',
					'in',
					'the biggest',
					'of',
					'the shortest',
					'of',
				],
			},
		],
	},
	7: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P114-E7',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '7',
				title:
					"Write the questions. Use the superlative form of the adjective, and add in or of where necessary.<leftarrow name='4.4, 4.5'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 24,
			textAlign: 'left',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 530,
		exerciseKey: 'img/FriendsPlus/Page114/E7/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              <div>Who / intelligent / person / the class / ?</div>
              <div style="color:rgb(4 84 121); font-style: oblique;">Who’s the most intelligent person in the class?</div>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              <div>What / good / day / the week / ?</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <div>What / bad / day / the week / ?</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              <div>What / beautiful / city / your country / ?</div>
              <input />
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <div>
              <div>Who / good-looking / actor on TV / ?</div>
              <input />
            </div>
          </div>
				`,
				answer: [
					'What is the best day of the week?',
					'What is the worst day of the week?',
					'What is the most beautiful city in your country?',
					'Who is the best-looking actor on TV?',
				],
			},
		],
	},
	8: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P114-E8',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: '8',
				title:
					"<strong style='color:rgb(0 147 69);'>SPEAKING</strong> Work in pairs. Ask and answer the questions in <leftarrow name='4.4, 4.5'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 24,
			textAlign: 'left',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 500,
		exerciseKey: 'img/FriendsPlus/Page114/E1/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ ``,
				answer: [],
			},
		],
	},
	9: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P114-E9',
		audio: '',
		video: '',
		component: T6,
		checkDuplicated: true,
		titleQuestion: [
			{
				num: '',
				title:
					"<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>4D (Part 2)</span> <i>too</i> and <i>enough</i> </strong> <br /> <strong>9</strong> Write sentences with words from the table below. Use <i>too</i> and the infinitive. <leftarrow name='4.6, 4.8'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 24,
			textAlign: 'left',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 420,
		exerciseKey: 'img/FriendsPlus/Page114/E9/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
          <div>
            <table style="border-collapse: collapse; width: 100%; border: 2px solid rgb(246 140 31);margin-top: 20px">
              <tbody>
                <tr >
                  <td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px"><strong>James</strong></td>
                  <td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px">cold</td>
                  <td style="padding-left: 20px">reach that shelf</td>
                </tr>
                <tr>
                  <td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px">You</td>
                  <td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px;"><strong>young</strong></td>
                  <td style="padding-left: 20px">swim in the sea</td>
                </tr>
                <tr>
                  <td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px">This pizza</td>
                  <td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px;">cloudy</td>
                  <td style="padding-left: 20px; width: 300px">lift</td>
                </tr>
                <tr>
                  <td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px">The sky’s</td>
                  <td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px;">short</td>
                  <td style="padding-left: 20px; width: 300px">eat</td>
                </tr>
                <tr>
                  <td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px">It’s</td>
                  <td style="width: 200px; border-right: 2px solid rgb(246 140 31);padding-left: 20px">heavy</td>
                  <td style="padding-left: 20px; width: 300px"><strong>take his driving test</strong></td>
                </tr>
                <tr>
                  <td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px">This suitcase</td>
                  <td style="width: 200px; border-right: 2px solid rgb(246 140 31);padding-left: 20px">hot</td>
                  <td style="padding-left: 20px; width: 300px">see the stars</td>
                </tr>
              </tbody>
            </table>
          </div>
					<div style="margin-top: 30px;">
						<div style="display: flex; column-gap: 20px">
							<strong>1</strong>
							<div style="color:rgb(4 84 121); font-style: oblique;">James is too young to take his driving test.</div>
						</div>
						<div style="display: flex; column-gap: 20px">
							<strong>2</strong>
							<div>#</div>
						</div>
						<div style="display: flex; column-gap: 20px">
							<strong>3</strong>
							<div>#</div>
						</div>
						<div style="display: flex; column-gap: 20px">
							<strong>4</strong>
							<div>#</div>
						</div>
						<div style="display: flex; column-gap: 20px">
							<strong>5</strong>
							<div>#</div>
						</div>
						<div style="display: flex; column-gap: 20px">
							<strong>6</strong>
							<div>#</div>
						</div>
					</div>
          `,
				answer: [
					"You are too short to reach that shelf. / This pizza is too hot to eat. / The sky's too cloudy to see the stars. / It's too cold to swim in the sea. / This suitcase is too heavy to lift.",
					"You are too short to reach that shelf. / This pizza is too hot to eat. / The sky's too cloudy to see the stars. / It's too cold to swim in the sea. / This suitcase is too heavy to lift.",
					"You are too short to reach that shelf. / This pizza is too hot to eat. / The sky's too cloudy to see the stars. / It's too cold to swim in the sea. / This suitcase is too heavy to lift.",
					"You are too short to reach that shelf. / This pizza is too hot to eat. / The sky's too cloudy to see the stars. / It's too cold to swim in the sea. / This suitcase is too heavy to lift.",
					"You are too short to reach that shelf. / This pizza is too hot to eat. / The sky's too cloudy to see the stars. / It's too cold to swim in the sea. / This suitcase is too heavy to lift.",
				],
				// answer: [
				// 	'You are too short to reach that shelf.',
				// 	'This pizza is too hot to eat.',
				// 	"The sky's too cloudy to see the stars.",
				// 	"It's too cold to swim in the sea.",
				// 	'This suitcase is too heavy to lift.',
				// ],
			},
		],
	},
	10: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P114-E10',
		audio: '',
		video: '',
		component: T6,
		checkDuplicated: true,
		titleQuestion: [
			{
				num: '10',
				title:
					"Rewrite the sentences in exercise 9 with the adjectives below and <i>enough</i>.<leftarrow name='4.7, 4.8'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 24,
			textAlign: 'left',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 480,
		exerciseKey: 'img/FriendsPlus/Page114/E10/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
            <div style="color: rgb(246 140 31); display: flex; column-gap: 20px">
              <span>clear</span>
              <span>cool</span>
              <span>light</span>
              <span style="text-decoration: line-through">old</span>
              <span>tall</span>
              <span>warm</span>
            </div>
						<div>
							<table style="border-collapse: collapse; width: 100%; border: 2px solid rgb(246 140 31);margin-top: 20px">
								<tbody>
									<tr >
										<td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px"><strong>James</td>
										<td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px; color: rgb(246 140 31);">clear</td>
										<td style="padding-left: 20px">reach that shelf</td>
									</tr>
									<tr>
										<td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px">You</td>
										<td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px; color: rgb(246 140 31);">cool</td>
										<td style="padding-left: 20px">swim in the sea</td>
									</tr>
									<tr>
										<td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px">This pizza</td>
										<td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px; color: rgb(246 140 31);">light</td>
										<td style="padding-left: 20px; width: 300px">lift</td>
									</tr>
									<tr>
										<td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px">The sky’s</td>
										<td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px; color: rgb(246 140 31);"><strong>old</strong></td>
										<td style="padding-left: 20px; width: 300px">eat</td>
									</tr>
									<tr>
										<td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px">It’s</td>
										<td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px; color: rgb(246 140 31);">tall</td>
										<td style="padding-left: 20px; width: 300px"><strong>take his driving test</strong></td>
									</tr>
									<tr>
										<td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px">This suitcase</td>
										<td style="width: 200px; border-right: 2px solid rgb(246 140 31); padding-left: 20px; color: rgb(246 140 31);">warm</td>
										<td style="padding-left: 20px; width: 300px">see the stars</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div style="margin-top: 30px;">
							<div style="display: flex; column-gap: 20px">
								<strong>1</strong>
								<div>
									<span style="color:rgb(4 84 121); font-style: oblique;">James isn’t old enough to take his driving test.</span>
								</div>
							</div>
							<div style="display: flex; column-gap: 20px">
								<strong>2</strong>
								<div>#</div>
							</div>
							<div style="display: flex; column-gap: 20px">
								<strong>3</strong>
								<div>#</div>
							</div>
							<div style="display: flex; column-gap: 20px">
								<strong>4</strong>
								<div>#</div>
							</div>
							<div style="display: flex; column-gap: 20px">
								<strong>5</strong>
								<div>#</div>
							</div>
							<div style="display: flex; column-gap: 20px">
								<strong>6</strong>
								<div>#</div>
							</div>
						</div>
          `,
				answer: [
					"You aren't tall enough to reach that shelf. / This pizza isn't cool enough to eat. / The sky isn't clear enough to see the stars. / It isn't warm enough to swim in the sea. / This suitcase isn't light enough to lift.",
					"You aren't tall enough to reach that shelf. / This pizza isn't cool enough to eat. / The sky isn't clear enough to see the stars. / It isn't warm enough to swim in the sea. / This suitcase isn't light enough to lift.",
					"You aren't tall enough to reach that shelf. / This pizza isn't cool enough to eat. / The sky isn't clear enough to see the stars. / It isn't warm enough to swim in the sea. / This suitcase isn't light enough to lift.",
					"You aren't tall enough to reach that shelf. / This pizza isn't cool enough to eat. / The sky isn't clear enough to see the stars. / It isn't warm enough to swim in the sea. / This suitcase isn't light enough to lift.",
					"You aren't tall enough to reach that shelf. / This pizza isn't cool enough to eat. / The sky isn't clear enough to see the stars. / It isn't warm enough to swim in the sea. / This suitcase isn't light enough to lift.",
				],
				// answer: [
				// 	"You aren't tall enough to reach that shelf.",
				// 	"This pizza isn't cool enough to eat.",
				// 	"The sky isn't clear enough to see the stars.",
				// 	"It isn't warm enough to swim in the sea.",
				// 	"This suitcase isn't light enough to lift.",
				// ],
			},
		],
	},
	11: {
		unit: 'Grammar Builder and  Reference',
		id: 'SB10-GBAR-P114-E1',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '',
				title:
					"<strong style='font-size: 800'><span style='color:rgb(246 140 31); font-weight: 800; margin-right: 10px'>4H</span> Zero conditional</strong> <br /> <strong>11</strong> Complete the sentences with the correct form of the verbs in brackets. <leftarrow name='4.9'></leftarrow>",
				color: 'black',
			},
		],
		stylesTextInput: {
			fontSize: 24,
			textAlign: 'center',
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		inputSize: 150,
		exerciseKey: 'img/FriendsPlus/Page114/E11/Key/answerKey.png',
		questionImage: [],
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
					<div style="margin-top: 20px; display: flex; flex-direction: column; gap: 15px;">
						<div style="display: flex; column-gap: 20px">
							<strong>1</strong>
							<div>If we <input /> (use) public transport instead of cars, we <input /> (reduce) our carbon footprint.</div>
						</div>
						<div style="display: flex; column-gap: 20px">
							<strong>2</strong>
							<div>If we <input /> (cut down) rainforests, we <input /> (destroy) many animals’ natural habitat.</div>
						</div>
						<div style="display: flex; column-gap: 20px">
							<strong>3</strong>
							<div>We <input /> (can) combat climate change if we <input /> (conserve) energy.</div>
						</div>
						<div style="display: flex; column-gap: 20px">
							<strong>4</strong>
							<div>You <input /> (save) electricity if you <input /> (not leave) the lights on.</div>
						</div>
					</div>
          `,
				answer: ['use', 'reduce', 'cut down', 'destroy', 'can', 'conserve', 'save', "don't leave / do not leave"],
			},
		],
	},
}

export default json
