import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  3: {
    unit: 'Unit 3',
    id: 'SB10-U3-P41-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page41/E3/Key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `
				Read the <span style='color:rgb(75, 73, 153);'> Reading Strategy</span>  and questions 1–5 in exercise 4 below. Decide which question is about the whole text. Then find the relevant sentences in the text for the other questions.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 50,
    stylesTextInput: [],
    maxLength: 2,
    textAlign: 'center',

    isHiddenCheck: true,
    selectStyle: {
      color: '#00ADFE',
      width: 60,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: ['1', '2', '3', '4', '5'],
    questions: [
      {
        title: `
			  <div style='background-color: rgb(209, 203, 232); margin-top: 20px; margin-left:200px; width:700px; border-radius: 15px; border: 5px dotted rgb(75, 73, 153)'>
          <div style='padding: 15px 25px 25px 25px;' >
            <div ><b style='color:rgb(75, 73, 153) ;'> Reading Strategy</b></div>
            <div>
               When you do a multiple-choice task, decide whether each question is about the whole text or a specific part. If it is about a specific part, find the relevant sentences and underline them.
            </div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div> Newspaper headlines about video games</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div> You learn to think about things in three dimensions when you</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div> Research shows that role-playing games</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div> The report also suggests that video gamers</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div> According to the report, the effect of video games on children</div>
        </div>

      
        <b><div style='display: flex;'>Question <select id='8'></select> is about the whole text.</div></b>
        <div><b> The relevant sentences (lines 1-34): </b></div>
        <div style='display: flex; justify-content: space-around;'>
            <div style='display: flex;'><b> <select id='9'></select>  </b> lines # - #</div>
            <div style='display: flex;'><b> <select id='10'></select>  </b> lines # - #</div>
        </div> 
        <div style='display: flex; justify-content: space-around;'>
            <div style='display: flex;'><b> <select id='11'></select>  </b> lines # - #</div>
            <div style='display: flex;'><b> <select id='12'></select>  </b> lines # - #</div>
        </div>   
			  <div ><img src='img/FriendsPlus/Page41/E3/1.jpg' alt='' style='margin-left: 100px; width:900px;'/></div>
			  `,
        answer: ['1', '6', '10', '13', '14', '19', '20', '31', '5', '1', '2', '3', '4'],
      },
    ],
  },
  4: {
    unit: 'Unit 3',
    id: 'SB10-U3-P41-E4',
    audio: '',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '4',
        title: `
          Circle the correct option (a–d).
        `,
        color: '#860052',
      },
    ],
    exerciseKey: '/img/FriendsPlus/Page41/E4/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          margin: '-2px',
        },
        limitSelect: 1,
        listWords: [
          'a<br> b<br> c <br> d',
          'a<br> b<br> c <br> d',
          'a<br> b<br> c <br> d',
          'a<br> b<br> c <br> d',
          'a<br> b<br> c <br> d',
        ],
        answers: ['0-8', '1-0', '2-4', '3-2', '4-4'],
        initialValue: [],
      },
      Layout: `
        <div>
            <div><b>1</b> Newspaper headlines about video games</div>
            <div style="margin-left: 15px; display: flex;">
              <div><b><input type="Circle" id="0" /></b> </div>
              <div style="margin-left: 10px;">
                only talk about violent games.<br />
                always present the same view. <br />
                do not tell the truth.<br />
                usually present a negative view.
              </div>
            </div>
        </div>
        <div>
        <div style="margin-top:20px">
          <div><b>2</b> You learn to think about things in three dimensions when you</div>
          <div style="margin-left: 15px; display: flex">
            <div >
              <b><input type="Circle" id="1" /></b>
            </div>
            <div style="margin-left: 10px">
              play combat games. <br />
              play any kind of video game.<br />
              study engineering or maths.<br />
              do scientific studies.
            </div>
          </div>
        </div>
        <div style="margin-top:20px">
          <div><b>3</b> Research shows that role-playing games</div>
          <div style="margin-left: 15px; display: flex">
            <div >
              <b><input type="Circle" id="2" /></b>
            </div>
            <div style="margin-left: 10px">
              are the only games that make children more creative.<br />
              do not provide as many benefits as violent games.<br />
              help children to do well at school.<br />
              help children to learn how to use computers.
            </div>
          </div>
        </div>
        <div style="margin-top:20px">
        <div><b>4</b> The report also suggests that video gamers</div>
        <div style="margin-left: 15px; display: flex">
          <div >
            <b><input type="Circle" id="3" /></b>
          </div>
          <div style="margin-left: 10px">
            spend a lot of time on their own.<br />
            learn some useful skills by playing with others.<br />
            usually fail in other areas of their lives.<br />
            spend a lot of time feeling anxious.
          </div>
        </div>
      </div>
      <div style="margin-top:20px">
        <div><b>5</b> According to the report, the effect of video games on children</div>
        <div style="margin-left: 15px; display: flex">
          <div >
            <b><input type="Circle" id="4" /></b>
          </div>
          <div style="margin-left: 10px">
            is mostly good.<br />
            is mostly bad.<br />
            is not known.<br />
            is not very important.
          </div>
        </div>
      </div>
      `,
    },
  },

  5: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P41-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page41/E5/key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `
				<span style='color:rgb(124, 8, 79);'>VOCABULARY</span> Complete these verb + noun collocations <br/>from the text using the words below.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    inputSize: 160,
    stylesTextInput: [],
    maxLength: 20,
    textAlign: 'center',

    // checkDuplicated: true,
    hintBox: [
      {
        src: ['cause', 'express', 'get', 'have', 'have', 'make', 'take part in'],
        // arrow: true,
        width: 700,
        borderColor: '#4b4999',
      },
    ],
    questions: [
      {
        title: `
				<div style='margin: 20px 100px 10px 0;'><hintBox id='0' ></hintBox></div>
        <b style='color:rgb(75, 73, 153);'>Collocations: verb + noun</b>
        <div style='border-left: 3px solid rgb(75, 73, 153)'> 
          <div style='margin: 10px;'>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 1 </b></div>
              <div> # a positive / negative effect</div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 2 </b></div>
              <div> # an opinion</div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 3 </b></div>
              <div> # concern</div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 4 </b></div>
              <div> # a benefit</div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 5 </b></div>
              <div> #  (good) grades at school</div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 6 </b></div>
              <div> # online games</div>
            </div>
            <div style='display: flex;'>
              <div style='margin-right: 10px;'><b> 7 </b></div>
              <div> # a decision</div>
            </div>
          </div>
        </div>

			  `,
        answer: ['have', 'express', 'cause', 'have', 'get', 'take part in', 'make'],
      },
    ],
  },
  6: {
    unit: 'Unit 3',
    id: 'SB10-U3-P41-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page41/E6/key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `
				Answer the questions using information from the text.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 600,
    stylesTextInput: [],
    maxLength: 80,
    textAlign: 'left',
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b> </div>
          <div>
            <div> What do many people assume about the effects of video games on young people?</div>
            <div>#</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>
           <div> What kinds of skills can combat games improve?</div>
            <div>#</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>
            <div> What can simple games like <i>Angry Birds</i> prevent?</div>
            <div>#</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>
           <div> What kind of game helps children to get better grades at school, according to a 2013 report?</div>
            <div>#</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div>
            <div> What kind of game teaches children how to work together?</div>
            <div>#</div>
          </div>
        </div>
			  <div ><img src='img/FriendsPlus/Page41/E6/1.jpg' alt='' style='margin-left: 0px; width:900px;'/></div>

			  `,
        answer: [
          'They think they are bad.',
          'mental skills, such as thinking about objects in 3D',
          'They can prevent people from feeling anxious.',
          'role-playing games / RPGs',
          'huge online games like Farmville',
        ],
      },
    ],
  },
  7: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB10-U3-P38-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page41/E7/key/answerKey.png',
    titleQuestion: [
      {
        num: '7',
        title: `
				<b style='color:rgb(0,147,70);'>SPEAKING</b> Work in groups. Complete the questions with <i>How much</i> or <i>How many</i>. Then ask and answer.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    textAlign: 'center',
    inputSize: 160,
    recorder: true,
    hintBox: [
      {
        src: ['How many di²erent types of screen do you use regularly ? '],
        arrow: true,
        position: 1,
        width: 700,
        borderColor: '#4b4999',
      },
      {
        src: ['I use …'],
        arrow: true,
        position: 2,
        width: 200,
        borderColor: '#4b4999',
      },
    ],
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div> # different types of screen do you use regularly? (phone, computer, tablet, TV, etc.)</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div> # time do you spend watching TV or DVDs each day?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div> # different video games do you play?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div> # different people do you play video games with?</div>
        </div>
				<div style='margin: 20px 100px 10px 100px;'><hintBox id='0' ></hintBox></div>
				<div style='margin: 20px 0 50px 650px;'><hintBox id='1' ></hintBox></div>

			  `,
        answer: ['How many', 'How much', 'How many', 'How many'],
      },
    ],
  },
};

export default json;
