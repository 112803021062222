import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Science',
		id: 'FG10-S-P98-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page98/E1/Key/answerKey.png',
		component: T6,
		inputSize: 280,
		maxLength: 23,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 400, fontSize: 20 },
		titleQuestion: [
			{
				num: '1',
				title: `
          Look at the photo. 
          What things do you think a wearable gadget can do?
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; align-items: start;">
						<div style="position: relative;">
							<div style="display: flex; justify-content: center; align-items: center;">
								<img src="img/FriendsPlus/Page98/E1/1.jpg" style="max-width: 100%;" />
							</div>

							<div style="width: 475px; position: absolute; top: 320px; left: 11px; font-size: 15px;">
								<div>
									Over the past 20 years, <sup>1</sup><u>the way in which we take, keep and share photos has been transformed</u>. 
									Most people use smartphones and digital cameras to take photos, 
									and billions of photos have been uploaded to social networking sites. 
									So <sup>2</sup><u>have traditional celluloid film cameras been replaced by these gadgets</u>? 
									Not yet, but it won’t be long.
								</div>
								<div style="margin-top: 10px;">
									However, things are going to change again. Wearable gadgets that have recently been developed allow you to 
									take a photo simply by blinking. <sup>3</sup><u>Messages can also be sent</u> and information can be accessed 
									with these wearable gadgets. <sup>4</sup><u>The technology hasn’t been perfected</u>, 
									so <sup>5</sup><u>these devices won’t be sold in shops for a while</u>.
								</div>
								<div style="margin-top: 10px;">
									<sup>6</sup><u>Will cameras and mobiles be replaced by wearable gadgets</u>? 
									<sup>7</sup><u>They can’t be replaced yet</u> but <sup>8</sup><u>they may be taken over</u> sooner than we think. 
									What’s certain is that <sup>9</sup><u>our lives will be changed</u> by these devices in ways we haven’t even thought of.
								</div>
							</div>
						</div>

						<div style="margin-top: 15px; margin-left: 20px; font-size: 20px; border-radius: 10px; border: 1px solid rgb(197, 230, 235); padding: 10px;">
							<textarea id="0" rows="3"></textarea>
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
	2: {
		unit: 'Science',
		id: 'FG10-S-P98-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page98/E2/Key/answerKey.png',
		component: T6,
		inputSize: 150,
		maxLength: 12,
		textareaStyle: { width: 400, fontSize: 20 },
		titleQuestion: [
			{
				num: '2',
				title: `
          Read the text and check your ideas from exercise 1.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; align-items: start;">
						<div style="position: relative;">
							<div style="display: flex; justify-content: center; align-items: center;">
								<img src="img/FriendsPlus/Page98/E1/1.jpg" style="max-width: 100%;" />
							</div>

							<div style="width: 475px; position: absolute; top: 320px; left: 11px; font-size: 15px;">
								<div>
									Over the past 20 years, <sup>1</sup><u>the way in which we take, keep and share photos has been transformed</u>. 
									Most people use smartphones and digital cameras to take photos, 
									and billions of photos have been uploaded to social networking sites. 
									So <sup>2</sup><u>have traditional celluloid film cameras been replaced by these gadgets</u>? 
									Not yet, but it won’t be long.
								</div>
								<div style="margin-top: 10px;">
									However, things are going to change again. Wearable gadgets that have recently been developed allow you to 
									take a photo simply by blinking. <sup>3</sup><u>Messages can also be sent</u> and information can be accessed 
									with these wearable gadgets. <sup>4</sup><u>The technology hasn’t been perfected</u>, 
									so <sup>5</sup><u>these devices won’t be sold in shops for a while</u>.
								</div>
								<div style="margin-top: 10px;">
									<sup>6</sup><u>Will cameras and mobiles be replaced by wearable gadgets</u>? 
									<sup>7</sup><u>They can’t be replaced yet</u> but <sup>8</sup><u>they may be taken over</u> sooner than we think. 
									What’s certain is that <sup>9</sup><u>our lives will be changed</u> by these devices in ways we haven’t even thought of.
								</div>
							</div>
						</div>

						<div style="margin-top: 15px; margin-left: 20px; font-size: 20px; border-radius: 10px; border: 1px solid rgb(197, 230, 235); padding: 10px;">
							<i>Answer:</i> <br/>
							<textarea id="0" rows="3"></textarea>
						</div>
					</div>
				`,
				answer: [
					'The gadget allows people to take a photo simply by blinking. You can also send messages and surf the internet.',
				],
			},
		],
	},
	3: {
		unit: 'Science',
		id: 'FG10-S-P98-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page98/E3/Key/answerKey.png',
		component: T6,
		inputSize: 30,
		maxLength: 1,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
					Match the underlined passive forms in the text (1–9) with the tenses below (a–h).
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; align-items: start;">
						<div style="position: relative;">
							<div style="display: flex; justify-content: center; align-items: center;">
								<img src="img/FriendsPlus/Page98/E1/1.jpg" style="max-width: 100%;" />
							</div>
							<div style="width: 475px; position: absolute; top: 320px; left: 11px; font-size: 15px;">
								<div>
									Over the past 20 years, <sup>1</sup><u>the way in which we take, keep and share photos has been transformed</u>. 
									Most people use smartphones and digital cameras to take photos, 
									and billions of photos have been uploaded to social networking sites. 
									So <sup>2</sup><u>have traditional celluloid film cameras been replaced by these gadgets</u>? 
									Not yet, but it won’t be long.
								</div>
								<div style="margin-top: 10px;">
									However, things are going to change again. Wearable gadgets that have recently been developed allow you to 
									take a photo simply by blinking. <sup>3</sup><u>Messages can also be sent</u> and information can be accessed 
									with these wearable gadgets. <sup>4</sup><u>The technology hasn’t been perfected</u>, 
									so <sup>5</sup><u>these devices won’t be sold in shops for a while</u>.
								</div>
								<div style="margin-top: 10px;">
									<sup>6</sup><u>Will cameras and mobiles be replaced by wearable gadgets</u>? 
									<sup>7</sup><u>They can’t be replaced yet</u> but <sup>8</sup><u>they may be taken over</u> sooner than we think. 
									What’s certain is that <sup>9</sup><u>our lives will be changed</u> by these devices in ways we haven’t even thought of.
								</div>
							</div>
						</div>

						<div style="margin-left: 40px;">
							<div style="padding-left: 10px; margin-top: 25px; font-size: 20px; border-radius: 10px; border: 1px solid rgb(197, 230, 235); padding: 10px;">
								<i>Answer:</i><br/>
								<div>
									<strong style="padding-right: 3px;">1</strong>#
									<strong style="padding-right: 3px; padding-left: 15px;">2</strong>#
									<strong style="padding-right: 3px; padding-left: 15px;">3</strong>#
									<strong style="padding-right: 3px; padding-left: 15px;">4</strong>#
								</div>
								<div style="margin-top: 10px;">
									<strong style="padding-right: 3px;">5</strong>#
									<strong style="padding-right: 3px; padding-left: 15px;">6</strong>#
									<strong style="padding-right: 3px; padding-left: 15px;">7</strong>#
									<strong style="padding-right: 3px; padding-left: 15px;">8</strong>#
									<strong style="padding-right: 3px; padding-left: 15px;">9</strong>#
								</div>
							</div>
							
							<div style="padding-left: 10px; margin-top: 20px; font-size: 20px; border-radius: 10px; border: 1px solid rgb(197, 230, 235); padding: 10px;">
								<div style="display: flex;">
									<strong style="padding-right: 10px;">a</strong>
									<span>present perfect affirmative</span>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">b</strong>
									<span>present perfect negative</span>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">c</strong>
									<span>present perfect interrogative</span>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">d</strong>
									<span><i>will</i> future affirmative</span>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">e</strong>
									<span><i>will</i> future interrogative</span>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 15px;">f</strong>
									<span><i>will</i> future negative</span>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">g</strong>
									<span>modal verb <i>can</i> and <i>may</i> affirmative</span>				
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">h</strong>
									<span>modal verb <i>can</i> negative</span>				
								</div>
							</div>
						</div>
					</div>
				`,
				answer: ['a', 'c', 'g', 'b', 'f', 'e', 'h', 'g', 'd'],
			},
		],
	},
	4: {
		unit: 'Science',
		id: 'FG10-S-P98-E4',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page98/E4/Key/answerKey.png',
		component: T6,
		inputSize: 200,
		maxLength: 18,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '4',
				title: `
					Complete the predictions with the affirmative future passive form of the verbs below.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 10px; color: rgb(246, 141, 31);">
							<span>build</span>
							<span style="margin-left: 43px;">control</span>
							<span style="margin-left: 51px;">speak</span>
							<span style="margin-left: 52px;">connect</span>
					</div>

					<div style="margin-top: 20px;">
						<div>One hundred years from now, ...</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>the weather # by humans.</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>we <input id='1' width='60px' /> all # to computers so that we can think faster.</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span>only two languages # in the world (English and Chinese).</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">4</strong>
							<span>hotels # on the moon.</span>				
						</div>
					</div>
				`,
				answer: ['will be controlled', 'will', 'be connected', 'will be spoken', 'will be built'],
			},
		],
	},
	5: {
		unit: 'Science',
		id: 'FG10-S-P98-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page98/E5/Key/answerKey.png',
		component: T6,
		inputSize: 200,
		maxLength: 19,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in pairs. 
					Say if you agree or disagree with the statements in exercise 4. 
					Use the phrases below to help you.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div>
						<div style="margin-top: 10px; color: rgb(246, 141, 31);">
								<span>I don’t agree that …</span>
								<span style="margin-left: 43px;">I agree that …</span>
						</div>
						<div style="margin-top: 10px; color: rgb(246, 141, 31);">
								<span>I’m not sure that …</span>
								<span style="margin-left: 43px;">I wouldn’t say that …</span>
						</div>
					</div>

					<div style="margin-top: 20px; padding: 20px; border-radius: 10px; border: 1px solid rgb(0, 147, 69);">
						<div>One hundred years from now, ...</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>the weather__________ by humans.</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>we__________ all__________ to computers so that we can think faster.</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span>only two languages__________ in the world (English and Chinese).</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">4</strong>
							<span>hotels__________ on the moon.</span>				
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
	6: {
		unit: 'Science',
		id: 'FG10-S-P98-E6',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page98/E6/Key/answerKey.png',
		component: T6,
		inputSize: 240,
		maxLength: 18,
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 23,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '6',
				title: `
					Complete the sentences with the verbs below. 
					Use the present perfect passive.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 10px; color: rgb(246, 141, 31);">
							<span>sell</span>
							<span style="margin-left: 43px;">share</span>
							<span style="margin-left: 51px; text-decoration: line-through;">take</span>
							<span style="margin-left: 52px;">upload</span>
							<span style="margin-left: 52px;">watch</span>
					</div>

					<div style="margin-top: 20px;">
						<div>In the past hour, ...</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>100 million photos <span style="color: rgb(10, 111, 143);">have been taken.</span></span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>208,000 mobile phones # .</span>
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span>1.7 million photos # to Instagram.</span>
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">4</strong>
							<span>350 million photos # with friends on Facebook.</span>

						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">5</strong>
							<span>8.3 million hours of video # on YouTube.</span>
						</div>
					</div>
				`,
				answer: ['have been sold', 'have been uploaded', 'have been shared', 'have been watched'],
			},
		],
	},
	7: {
		unit: 'Science',
		id: 'FG10-S-P98-E7',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page98/E7/Key/answerKey.png',
		component: T6,
		inputSize: 650,
		maxLength: 56,
		titleQuestion: [
			{
				num: '7',
				title: `
					<span style="color: rgb(0, 147, 69)">SPEAKING</span> 
         Work in pairs. Ask and answer questions about your experiences. 
				 Use the present perfect passive with ever for questions and simple past passive for extra information.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>
									photograph / at school? <br/>
									<span style="color: rgb(10, 111, 143);">Have you ever been photographed at school?</span>
							</span>				
						</div>
						<div style="margin-top: 15px; display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>punish / for something you didn’t do?<br/> #</span>				
						</div>
						<div style="margin-top: 15px; display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span>hurt / while doing sport?<br/> #</span>				
						</div>
						<div style="margin-top: 15px; display: flex;">
							<strong style="padding-right: 10px;">4</strong>
							<span>criticise / by a good friend?<br/> #</span>				
						</div>
					</div>
				`,
				answer: [
					"Have you ever been punished for something you didn't do?",
					'Have you ever been hurt while doing sport?',
					'Have you ever been criticised by a good friend?',
				],
			},
		],
	},
	8: {
		unit: 'Science',
		id: 'FG10-S-P98-E8',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page98/E8/Key/answerKey.png',
		component: T6,
		inputSize: 200,
		maxLength: 17,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 800 },
		titleQuestion: [
			{
				num: '8',
				title: `
					<span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in groups. Invent a wearable gadget of your own. 
					Describe its functions using passive sentences with <i>can</i> and <i>may</i>.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<textarea id="0" rows="10"></textarea>
				`,
				answer: [],
			},
		],
	},
};

export default json;
