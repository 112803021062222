import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P85-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page85/E1/key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: `
				<span style='color:rgb(2, 146, 97);'>SPEAKING</span> Work in pairs. Describe the photo. Where are the people? What are they doing? What do you think they are saying?
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    textareaStyle: { width: 600 },
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div><textarea id="0" rows="7"></textarea></div>
          <div><img src= 'img/FriendsPlus/Page85/E1/1.jpg' style="width: 500px;"/></div>
        </div>
			  `,
        answer: [
          'They’re on a plane. The passengers are sitting in their seats and taking meals from the flight attendant.She is offering them their meals.They are saying what kind of meal they want and thanking the flight attendant.The people at the front of the photo are talking about what they can see on the screen.',
        ],
      },
    ],
  },

  2: {
    unit: 'Unit 7',
    id: 'SB10-U7-P85-E2',
    audio: 'Audios/Friends Global Grade 10 SB_CD3_05.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page85/E2/key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: `
				<headphone1 name='3.05' src=''></headphone1> Read and listen to the dialogue. 
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    textareaStyle: { width: 800 },
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='width:80px;'><b> Tom </b></div>
          <div> Did you go to Spain in July?</div>
        </div>
        <div style='display: flex;'>
          <div style='width:80px;'><b> Lydia </b></div>
          <div> No, we went to Portugal. And we went in August.</div>
        </div>
        <div style='display: flex;'>
          <div style='width:80px;'><b> Tom </b></div>
          <div> Where did you fly to?</div>
        </div>
        <div style='display: flex;'>
          <div style='width:80px;'><b> Lydia </b></div>
          <div> We drove there, actually.</div>
        </div>

        <div style='margin-top: 20px;'>
          <div><b> When, where and how did Lydia go on holiday in the summer?</b></div>
          <div> <textarea id='0' rows='1'></textarea></div>
        </div>
        
			  `,
        answer: ['Lydia went to Portugal in August. She went by car.'],
      },
    ],
  },

  3: {
    unit: 'Unit 7',
    id: 'SB10-U7-P85-E3',
    audio: 'Audios/Friends Global Grade 10 SB_CD3_05.mp3',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page85/E3/key/answerKey.png',
    titleQuestion: [
      {
        num: '3',
        title: `
				<headphone1 name='3.05' src=''></headphone1> <span style='color:rgb(34, 132, 200);'> PRONUNCIATION</span> Practise the examples in the <span style='color:rgb(193, 26, 101);'>Listening Strategy</span>. Underline the stressed words in the dialogue in exercise 2. Listen again and check.
			  `,
        color: '#92278f',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, textDecorationLine: 'underline' },
        limitSelect: 1,
        listWords: [
          'Did you go to Spain in July',
          'No, we went to Portugal',
          'And we went in August',
          'Where did you fly to', //0
          'We drove there, actually', //0
        ],
        answers: ['1-8', '2-8', '0-8', '3-6', '4-2'],
        initialValue: [],
      },
      Layout: `
			  <div style='background-color: rgb(230, 196, 203); margin-top: 20px; margin-left:100px; width:700px; border-radius: 15px; border: 5px dotted rgb(193, 26, 101)'>
          <div style='padding: 15px 25px 25px 25px;' >
            <div ><b style='color:rgb(193, 26, 101) ;'>Listening Strategy</b></div>
            <div> Notice the way that speakers emphasise certain words to stress an alternative or to correct what someone has said:</div>
            <div><i style='color:rgb(124, 162, 211)'>‘‘Would you like a <u>double</u> room or a <u>single</u> room?’’</i></div>
            <div><i style='color:rgb(124, 162, 211)'>‘‘A <u>double</u> room, please.’’</i></div>
            <div><i style='color:rgb(124, 162, 211)'>‘‘Does the plane arrive at <u>nine</u>?’’ ‘‘No, it arrives at <u>five</u>.’’</i></div>
            <div><i style='color:rgb(124, 162, 211)'>‘‘Dave’s in <u>Paris</u>.’’ ‘‘No, he isn’t. He’s in <u>Rome</u>.’’</i></div>
          </div>
        </div>

        <div style='display: flex;'>
          <div style='width:80px;'><b> Tom </b></div>
          <div> <input id='0' type='Circle' />?</div>
        </div>
        <div style='display: flex;'>
          <div style='width:80px;'><b> Lydia </b></div>
          <div>  <input id='1' type='Circle' />. <input id='2' type='Circle' />.</div>
        </div>
        <div style='display: flex;'>
          <div style='width:80px;'><b> Tom </b></div>
          <div>  <input id='3' type='Circle' />?</div>
        </div>
        <div style='display: flex;'>
          <div style='width:80px;'><b> Lydia </b></div>
          <div>  <input id='4' type='Circle' />.</div>
        </div>
      `,
    },
  },

  4: {
    unit: 'Unit 7',
    id: 'SB10-U7-P85-E4',
    audio: 'Audios/Friends Global Grade 10 SB_CD3_06.mp3',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page85/E4/key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `
				<headphone1 name='3.06' src=''></headphone1> Listen to the sentences. Pay attention to the words that are stressed. Circle the sentence in each pair (a or b) which the speaker is replying to.
			  `,
        color: '#92278f',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: '#000',
          fontWeight: 700,
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: ['a <br> b', 'a <br> b', 'a <br> b', 'a <br> b'],
        answers: ['0-4', '1-0', '2-0', '3-0'],
        initialValue: [],
      },
      Layout: `
        <div style='display: flex;'>
          <div style='width: 30px;'><b> 1 </b></div>
          <div>
            <div> No, he flew to <i>Italy</i> last summer.</div>
            <div style='display: flex; gap:10px;'>
              <div><b style='border: 2px solid rgb(124, 162, 211); border-radius: 50%; padding: 0 5px;'> a </b> <br/>
                  <b> b </b>
              </div>
              <div>
                <div> Did Sam fly to France last summer? </div>
                <div> Did Sam fly to Italy last spring? </div>
              </div>
            </div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='width: 30px;'><b> 2 </b></div>
          <div>
            <div> I’d like a black coffee, please.</div>
            <div style='display: flex; gap:10px;'>
              <div><b> <input id='0' type='Circle' /> </b></div>
              <div>
                <div> Would you like a drink? </div>
                <div> Would you like white coffee or black coffee? </div>
              </div>
            </div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='width: 30px;'><b> 3 </b></div>
          <div>
            <div> No, we’re meeting at three fifty.</div>
            <div style='display: flex; gap:10px;'>
              <div><b> <input id='1' type='Circle' /> </b></div>
              <div>
                <div> Are we meeting at three fifteen? </div>
                <div> Are we meeting at two fifty? </div>
              </div>
            </div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='width: 30px;'><b> 4 </b></div>
          <div>
            <div> I haven’t got a credit card.</div>
            <div style='display: flex; gap:10px;'>
              <div><b> <input id='2' type='Circle' /> </b></div>
              <div>
                <div> You can buy the tickets by credit card. </div>
                <div> Why didn’t you buy the tickets online? </div>
              </div>
            </div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='width: 30px;'><b> 5 </b></div>
          <div>
            <div> I’ll have a ham sandwich, please.</div>
            <div style='display: flex; gap:10px;'>
              <div><b> <input id='3' type='Circle' /> </b></div>
              <div>
                <div> What kind of sandwich would you like? </div>
                <div> Would you like something to eat? </div>
              </div>
            </div>
          </div>
        </div>

      `,
    },
  },

  5: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P85-E5',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '5',
        title: '  Look at the photos. Which place would you prefer to go for a holiday? Why?',
        color: '#92278f',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `    
        <div>
          <img src='/img/FriendsPlus/Page85/E5/1.jpg' alt='' style='border-radius: 50%; width: 400px;'/>
          <img src='/img/FriendsPlus/Page85/E5/2.jpg' alt='' style='border: 5px solid rgb(255, 255, 255);border-radius: 50%;width: 450px; margin-left: -70px;'/>
        </div>
        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: 'Unit 7',
    id: 'SB10-U7-P85-E6',
    audio: 'Audios/Friends Global Grade 10 SB_CD3_07.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page85/E6/key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `
				<headphone1 name='3.07' src=''></headphone1> Listen to a true story about a holiday that went wrong. 
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    textareaStyle: { width: 800 },
    questions: [
      {
        title: `
        <div style='margin-top: 20px;'>
          <div><b>What was the misunderstanding between Georgina and the travel agent?</b></div>
          <div> <textarea id='0' rows='2'></textarea></div>
        </div>
        
			  `,
        answer: [
          'Georgina wanted to go to Granada in Spain, but the travel agent thought she said Grenada in the Caribbean.',
        ],
      },
    ],
  },
  7: {
    unit: 'Unit 7',
    id: 'SB10-U7-P85-E7',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page85/E7/Key/answerKey.png',
    titleQuestion: [
      {
        num: '7',
        title: `
				<headphone1 name='3.07' src=''></headphone1> Listen again. Are the sentences true or false? Write T or F.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    selectStyle: {
      color: '#00ADFE',
      width: 60,
      textAlign: 'center',
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: ['T', 'F'],
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 &emsp;</b></div>
          <div> The woman next to Georgina was the first to mention Spain.</div>
          <b> <select id='0'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 &emsp;</b></div>
          <div> Georgina booked the holiday by phone.</div>
          <b> <select id='1'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 &emsp;</b></div>
          <div> She discovered her mistake just after the plane took off. </div>
          <b> <select id='2'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 &emsp;</b></div>
          <div> The flight attendant was kind and helpful. </div>
          <b> <select id='3'></select>  </b>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 &emsp;</b></div>
          <div> The travel agent has given her a free holiday in Australia. </div>
          <b> <select id='4'></select>  </b>
        </div>
			  `,
        answer: ['F', 'T', 'F', 'T', 'F'],
      },
    ],
  },
  8: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P85-E8',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page85/E8/key/answerKey.png',
    titleQuestion: [
      {
        num: '8',
        title: `<span style='color:rgb(0,147,70);'>SPEAKING</span>  Work in pairs. Take the role of Georgia Hepworth and a friend of hers. Ask and answer about her holiday.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 500,
    stylesTextInput: [],
    maxLength: 50,
    textAlign: 'left',
    questions: [
      {
        title: `
          <div><b> Student A: &emsp;</b> Prepare questions using the prompts below.</div>
          <div><b> Student B: &emsp;</b> Prepare the answers.</div>


        <div style='display: flex;'>
          <div><b> 1 &emsp;</b></div>
          <div>
            <div> where / buy your ticket?</div>
            <div>#</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div><b> 2 &emsp;</b></div>
          <div>
            <div> where / want to go?</div>
            <div>#</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div><b> 3 &emsp;</b></div>
          <div>
            <div> where / want to go?</div>
            <div>#</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div><b> 4 &emsp;</b></div>
          <div>
            <div> what / happen / on the plane?</div>
            <div>#</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div><b> 5 &emsp;</b></div>
          <div>
            <div> you / finally go to Granada?</div>
            <div>#</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div><b> 6 &emsp;</b></div>
          <div>
            <div> you / get any compensation?</div>
            <div>#</div>
          </div>
        </div>
			  `,
        answer: [
          'Where did you buy your ticket?',
          'Where did you want to go? ',
          'When did you realise your mistake?',
          'What happened on the plane?',
          'Did you finally go to Granada?',
          'Did you get any compensation?',
        ],
      },
    ],
  },
};

export default json;
