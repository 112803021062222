import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  1: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P33-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page33/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: 'READING',
        title: 'Read the text. Circle the correct option (a–d).',
        color: '#05709f',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 3px', borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          'a.|&emsp;he&ensp;didn’t&ensp;know&ensp;what&ensp;to&ensp;do&ensp;with&ensp;his&ensp;life. <br> b.|&emsp;he&ensp;didn’t&ensp;like&ensp;being&ensp;a&ensp;student. <br> c.|&emsp;he&ensp;wanted&ensp;to&ensp;become&ensp;fit. <br> d.|&emsp;he&ensp;thought&ensp;it&ensp;would&ensp;be&ensp;good&ensp;for&ensp;his&ensp;career&ensp;plans', //0
          'a.|&emsp;he&ensp;didn’t&ensp;prepare&ensp;well.<br> b.|&emsp;his&ensp;map&ensp;was&ensp;incorrect.<br> c.|&emsp;the&ensp;weather&ensp;suddenly&ensp;changed. <br> d.|&emsp;he&ensp;wasn’t&ensp;well.', //0
          'a.|&emsp;he&ensp;called&ensp;them&ensp;from&ensp;his&ensp;mobile.<br> b.|&emsp;they&ensp;saw&ensp;that&ensp;all&ensp;his&ensp;money&ensp;was&ensp;gone.<br> c.|&emsp;his&ensp;family&ensp;rang&ensp;to&ensp;speak&ensp;to&ensp;him.<br> d.|&emsp;some&ensp;of&ensp;his&ensp;things&ensp;were&ensp;missing. ', //0
          'a.|&emsp;called&ensp;Sam’s&ensp;family.<br> b.|&emsp;started&ensp;to&ensp;look&ensp;for&ensp;Sam&ensp;by&ensp;helicopters.<br> c.|&emsp;spent&ensp;the&ensp;night&ensp;in&ensp;the&ensp;outback&ensp;to&ensp;look&ensp;for&ensp;Sam.<br> d.|&emsp;set&ensp;out&ensp;to&ensp;find&ensp;Sam&ensp;before&ensp;Sam’s&ensp;employers&ensp;phoned.', //0
        ],
        answers: ['0-18', '1-0', '2-12', '3-4'],
        // initialValue: ['3-2', '4-8', '4-10', '0-8', '1-6', '2-8', '3-6', '3-16', '5-6'],
        initialValue: [],
      },
      Layout: `
        
      <div style=" display: flex; flex-direction: row;  ">
          <img style='margin:0px 5px 0px -40px; height:20cm' src='img/FriendsPlus/Page33/E1/1.jpg' /> 
        <div>
            <b>1</b>&ensp;Sam went backpacking because 
            <div style='margin-left:10px'>
                <input id='0' type='Circle' /> 
            </div>
            <b>2</b>&ensp;Sam got lost because 
            <div style='margin-left:10px'>
                <input id='1' type='Circle' /> 
            </div>
            <b>3</b>&ensp;Sam’s employers realised Sam was lost when 
            <div style='margin-left:10px'>
                <input id='2' type='Circle' /> 
            </div>
            <b>4</b>&ensp;The rescue services
            <div style='margin-left:10px'>
                <input id='3' type='Circle' /> 
            </div>
          
      </div>
      `,
    },
  },
  2: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P33-E2',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_30.mp3',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page33/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: 'LISTENING',
        title:
          '<headphone1 name="1.30"></headphone1> You will hear three texts twice. Choose the correct option (a–d). ',
        color: '#05709f',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { fontWeight: 'bold', border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: '3px solid', borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          'a.|&emsp;making &ensp;life&ensp;decisions <br> b.|&emsp;studying&ensp;for&ensp;tests <br> c.|&emsp;improving&ensp;your&ensp;work. <br> d.|&emsp;planning&ensp;your&ensp;time', //0
          'a.|&emsp;She’s&ensp;leaving&ensp;her&ensp;family<br> b.|&emsp;She’s&ensp;starting&ensp;work.<br> c.|&emsp;She’s&ensp;leaving&ensp;her&ensp;hometown.  <br> d.|&emsp;She’s&ensp;going&ensp;on&ensp;holiday. ', //0
          'a.|&emsp;a&ensp;news&ensp;story.<br> b.|&emsp;a&ensp;holiday.<br> c.|&emsp;a&ensp;charity.<br> d.|&emsp;a&ensp;new&ensp;lottery.  ', //0
        ],
        answers: ['0-8', '1-8', '2-0'],
        // initialValue: ['3-2', '4-8', '4-10', '0-8', '1-6', '2-8', '3-6', '3-16', '5-6'],
        initialValue: [],
      },
      Layout: `
            <div style='margin-left:200px'>
        
                <b>1</b>&ensp;What is the speaker giving advice about? 
                <div style='margin-left:26px'>
                    <input id='0' type='Circle' /> 
                </div>
                <b>2</b>&ensp;What is true about Amy?  
                <div style='margin-left:26px'>
                    <input id='1' type='Circle' /> 
                </div>
                <b>3</b>&ensp;The presenter is asking for opinions about  
                <div style='margin-left:26px'>
                    <input id='2' type='Circle' /> 
                </div>
            </div>
      `,
    },
  },
  3: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P33-E3',
    video: '',
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: 'SPEAKING',
        title: '',
        color: '#05709f',
      },
    ],
    questionImage: [
      // Row

      [
        // Column2
        { url: 'img/FriendsPlus/Page33/E3/1.jpg' },
      ],
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 2',
    id: 'SB10-U2-P33-E4',
    audio: '',
    video: '',
    component: T6,

    inputSize: 170,
    textareaStyle: { width: 700 },
    // inputSize: 50,
    // isHiddenCheck: true,
    maxLength: 13,
    titleImage: '',
    textAlign: 'center',
    // exerciseKey: 'img/FriendsPlus/Page33/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: 'WRITING',
        title: 'Write a paragraph to end the story. Use the following prompts.',
        color: '#05709f',
        prefix: { icon: [''], text: '' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=' line-height:45px'>
          Sam / use / colorful shorts / make / SOS emergency
          signal.<br>
          Friday morning / Sam / hear / helicopter / flying over.<br>
          He / shout / wave / arm / but / they / not see him.<br>
          Afternoon / another helicopter / fly over / SOS signal.<br>
          Wind / helicopter’s engine / blow up / Sam’s shorts /
          pilot suddenly spot / them.<br>
          The pilot / search / area again / find / Sam / five
          kilometres / cattle farm.<br>
          <textarea id='0' rows='7'></textarea>
        </div>
          `,
        answer: [''],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 4',
    id: 'FG10-U4-P33-E5',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#445B9F" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page33/E5/1.jpg' },
      ],
    ],
  },
};

export default json;
