import DrawColor from '../../components/ExcerciseTypes/DrawColor';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Ambition',
		id: 'FG10-A-P63-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page63/E1/Key/answerKey.png',
		component: T6,
		inputSize: 530,
		maxLength: 49,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '1',
				title: `
          <span style="color: rgb(0, 147, 69)">SPEAKING</span> 
          Work in pairs. Think of two advantages of going to university 
          and two advantages of going straight into a job after leaving school. 
          Use the phrases below to help you.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 20px; margin-left: 20px; display: flex; color: rgb(146, 39, 144);  padding: 10px; border-radius: 10px; border: 1px solid rgb(146, 39, 144);">
            <div>
              be independent<br/>
              feel pressure<br/>
              get qualifications<br/>
              have a good social life<br/>
            </div>
            <div style="margin-left: 20px;">
              be unemployed<br/>
              gain opportunities<br/>
              get stressed<br/>
              have a good time<br/>
            </div>
            <div style="margin-left: 20px;">
              earn money<br/>
              get into debt<br/>
              learn a lot<br/>
            </div>
          </div>
        `,
				answer: ['He is applying for the job of CEO of Nisa Retail.'],
			},
		],
	},
	2: {
		unit: 'Ambition',
		id: 'FG10-A-P63-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page63/E2/Key/answerKey.png',
		component: T6,
		inputSize: 90,
		maxLength: 6,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '2',
				title: `
					Read the two texts. Which one is more positive about going to univeristy?
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
				  <div style="position: relative;">
				    <div style="display: flex; align-items: start;">
				      <img src="img/FriendsPlus/Page63/E2/1.jpg" style="max-width: 100%;" />
				      <img src="img/FriendsPlus/Page63/E2/2.jpg" style="max-width: 100%;" />
				    </div>

				    <div style="position: absolute; top: 17px; left: 15px; font-size: 15px; font-weight: bold; background-color: rgb(234,62,167); color: white; border-radius: 50%; width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">A</div>
				    <div style="position: absolute; top: 8px; left: 501px; font-size: 15px; font-weight: bold; background-color: rgb(234,62,167); color: white; border-radius: 50%; width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">B</div>

				    <div style="position: absolute; top: 412px; left: 26px;"><i>Answer:</i> # is more positive.</div>
				  </div>
				`,
				// title: `
				//   <div style="position: relative; width: 900px;">
				//     <div>
				//       <img src="img/FriendsPlus/Page63/E3/1.jpg" style="max-width: 100%;" />
				//     </div>

				//     <div style="position: absolute; top: 30px; left: 401px; font-size: 15px; font-weight: bold; background-color: rgb(234,62,167); color: white; border-radius: 50%; width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">A</div>
				//     <div style="position: absolute; top: 361px; left: 386px; font-size: 15px; font-weight: bold; background-color: rgb(234,62,167); color: white; border-radius: 50%; width: 40px; height: 40px; display: flex; justify-content: center; align-items: center;">B</div>

				//     <div style="position: absolute; top: 26px; left: 479px;"><i>Answer:</i> # is more positive.</div>
				//   </div>
				// `,
				answer: ['B / Text B'],
			},
		],
	},
	3: {
		unit: 'Ambition',
		id: 'FG10-A-P63-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page63/E3/Key/answerKey.png',
		component: T6,
		inputSize: 170,
		maxLength: 16,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		checkDuplicated: true,
		titleQuestion: [
			{
				num: '3',
				title: `
					Read the <span style="color: rgb(146, 39, 144);">Learn this!</span> box. 
					Then read the texts in exercise 2 again and 
					find words with five of the prefixes in the table.
        `,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; align-items: start;">
						<div style="flex: 1">
							<div style="padding: 10px 20px; border-radius: 10px; border: 2px solid rgb(146, 39, 144); background-image: radial-gradient(rgb(250 244 244), rgb(248 231 248); font-size: 20px;">
								<div>
									<span style="color: rgb(146, 39, 144); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LEARN THIS!</span>
									<strong>Prefixes</song>
								</div>

								<div style="margin-top: 5px;">
									Prefixes change the meaning of nouns, adjectives and verbs.
									Sometimes a hyphen is used with a prefix and
									sometimes it is not (e.g. <i>semi-final, semicolon</i>).
									You may need to check in a dictionary.
								</div>

								<div style="margin-top: 10px; display: flex;">
								
									<div style="flex: 1; background-color: white; border-left: 1px solid rgb(146, 39, 144);">
										<div style="padding-left: 15px; background-color: rgb(146, 39, 144); color: white";>Prefix</div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>co-</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>ex-</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>micro-</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>multi-</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>over-</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>post-</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>re-</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>semi-</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>under-</i></div>
									</div>

									<div style="flex: 2; background-color: white; border-left: 1px solid rgb(146, 39, 144);">
										<div style="padding-left: 15px; border-left: 1px solid white; background-color: rgb(146, 39, 144); color: white";>Meaning</div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">with</div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">former</div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">extremely small</div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">many</div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">too much</div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">after</div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">again</div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">half</div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">too little</div>
									</div>

									<div style="flex: 2; background-color: white; border-left: 1px solid rgb(146, 39, 144); border-right: 1px solid rgb(146, 39, 144);">
										<div style="padding-left: 15px; border-left: 1px solid white; background-color: rgb(146, 39, 144); color: white";>Example</div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>co-operate</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>ex-wife</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>microchip</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>multicoloured</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>overcooked</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>post-war</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>rewrite</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>semicircle</i></div>
										<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>undercooked</i></div>
									</div>
								</div>
							</div>

							<div style="margin-top: 10px;">
								<i>Answer:</i><br/> # # # # # # #
							</div>
						</div>

						<div style="margin-left: 30px; flex: 1;">
								<img src="img/FriendsPlus/Page63/E3/1.jpg" style="max-width: 100%;" />
						</div>
					</div>
				`,
				answer: [
					'co-creator / ex-student / overestimate / postgraduate / semiprofessional / undervalue / oversleep',
					'co-creator / ex-student / overestimate / postgraduate / semiprofessional / undervalue / oversleep',
					'co-creator / ex-student / overestimate / postgraduate / semiprofessional / undervalue / oversleep',
					'co-creator / ex-student / overestimate / postgraduate / semiprofessional / undervalue / oversleep',
					'co-creator / ex-student / overestimate / postgraduate / semiprofessional / undervalue / oversleep',
					'co-creator / ex-student / overestimate / postgraduate / semiprofessional / undervalue / oversleep',
					'co-creator / ex-student / overestimate / postgraduate / semiprofessional / undervalue / oversleep',
				],
			},
		],
	},
	4: {
		unit: 'Ambition',
		id: 'FG10-A-P63-E4',
		audio: 'Audios/Friends Global Grade 10 SB_CD2_19.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page63/E4/Key/answerKey.png',
		component: DrawColor,
		titleQuestion: [
			{
				num: '4',
				title: `
					<audioimage name="2.19"></audioimage>
					<span style="color: rgb(5, 107, 174)">PRONUNCIATION</span> 
					Listen to the examples in the table and mark the stress. 
					Practise saying the words.
			`,
				color: '#860052',
			},
		],
		question: {
			Write: {
				underlineStyle: {},
				inputStyle: {
					width: 100,
					textAlign: 'center',
					fontSize: 20,
					color: 'black',
					maxLength: 5,
				},
				answers: [],
				initialValue: [],
			},
			Circle: {
				listStyle: {
					normal: { marginInline: -2 },
					// circle: { padding: 0.75, border: 'solid 2px', borderRadius: '50%', borderColor: '#4285F4' },
					// square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
					// choose: { fontWeight: 900 },
					underline: { marginInline: -2, borderBottom: '2px solid #000' },
				},
				// limitSelect: 1,
				listWords: [
					'co- o perate', //0
					'ex- wife', //1
					'mi cro chip', //2
					'mul ti co lou red', //3
					'over cooked', //4
					'post- war', //5
					're write', //6
					'se mi cir cle', //7
					'un der cooked', //8
				],
				answers: {
					'8-4': 'underline',
					'0-2': 'underline',
					'1-0': 'underline',
					'2-0': 'underline',
					'3-4': 'underline',
					'4-2': 'underline',
					'5-2': 'underline',
					'6-2': 'underline',
					'7-0': 'underline',
				},
				initialValue: {},
			},
			Layout: `
				<div style="display: flex; align-items: start;  font-size: 20px;">
					<div style="width: 550px;">
						<div style="padding: 10px 20px; border-radius: 10px; border: 2px solid rgb(146, 39, 144); background-image: radial-gradient(rgb(250 244 244), rgb(248 231 248);">
							<div>
								<span style="color: rgb(146, 39, 144); font-weight: bold; text-transform: uppercase; margin-right: 10px;">LEARN THIS!</span>
								<strong>Prefixes</song>
							</div>

							<div style="margin-top: 5px;">
								Prefixes change the meaning of nouns, adjectives and verbs.
								Sometimes a hyphen is used with a prefix and
								sometimes it is not (e.g. <i>semi-final, semicolon</i>).
								You may need to check in a dictionary.
							</div>

							<div style="margin-top: 10px; display: flex;">
							
								<div style="flex: 1; background-color: white; border-left: 1px solid rgb(146, 39, 144);">
									<div style="padding-left: 15px; background-color: rgb(146, 39, 144); color: white";>Prefix</div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>co-</i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>ex-</i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>micro-</i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>multi-</i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>over-</i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>post-</i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>re-</i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>semi-</i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i>under-</i></div>
								</div>

								<div style="flex: 2; background-color: white; border-left: 1px solid rgb(146, 39, 144);">
									<div style="padding-left: 15px; border-left: 1px solid white; background-color: rgb(146, 39, 144); color: white";>Meaning</div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">with</div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">former</div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">extremely small</div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">many</div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">too much</div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">after</div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">again</div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">half</div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);">too little</div>
								</div>

								<div style="flex: 2; background-color: white; border-left: 1px solid rgb(146, 39, 144); border-right: 1px solid rgb(146, 39, 144);">
									<div style="padding-left: 15px; border-left: 1px solid white; background-color: rgb(146, 39, 144); color: white";>Example</div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i><input id='0' type='Circle' /></i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i><input id='1' type='Circle' /></i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i><input id='2' type='Circle' /></i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i><input id='3' type='Circle' /></i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i><input id='4' type='Circle' /></i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i><input id='5' type='Circle' /></i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i><input id='6' type='Circle' /></i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i><input id='7' type='Circle' /></i></div>
									<div style="padding-left: 15px; border-bottom: 1px solid rgb(146, 39, 144);"><i><input id='8' type='Circle' /></i></div>
								</div>
							</div>
						</div>
					</div>

					<div style="margin-left: 20px; width: 450px; padding: 10px; border-radius: 10px; border: 4px dotted rgb(146, 39, 144); background-color: rgb(239, 228, 240); box-shadow: 3px 3px 5px rgba(0,0,0,0.4)">
						<span style="font-weight: bold; color: rgb(146, 39, 144);">Study strategy</span>
						<div>
							To help remember new vocabulary:
							<ul>
								<li>visualise its meaning</li>
								<li>restate or explain it in your own words</li>
								<li>create a non-linguistic representation of it (a picture or symbolic representation)</li>
								<li>classify it and write synonyms, antonyms, etc.</li>
								<li>share and compare new words with a partner.</li>
							</ul>
						</div>
					</div>
				</div>
	`,
		},
	},
	5: {
		unit: 'Ambition',
		id: 'FG10-A-P63-E5',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page63/E5/Key/answerKey.png',
		component: T6,
		inputSize: 420,
		maxLength: 37,
		titleQuestion: [
			{
				num: '5',
				title: `
					<span style="color: rgb(146, 39, 144);">USE OF ENGLISH</span> 
					Rewrite the sentences, replacing the underlined words with 
					a word starting with the prefix in brackets.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px; margin-bottom: 16px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>
								I work as a <u>pilot</u> <u>with</u> Elaine (co-). <br/> 
								<i>I am Elaine's co-pilot.</i>
							</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>Mick <u>slept for too long</u> and missed his bus. (over-) <br/> #</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span>Please <u>write</u> the sentence again. (re-) <br/> #</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">4</strong>
							<span>We live in a society <u>with lots of people of different cultures.</u> (multi-) <br/> #</span>				
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">5</strong>
							<span>Sam <u>doesn't get much money</u> for the job he does. (under-) <br/> #</span>				
						</div>
					</div>
        `,
				answer: [
					'Mick overslept and missed his bus.',
					'Please rewrite the sentence.',
					'We live in a multicultural society.',
					'Sam is underpaid for the job he does.',
				],
			},
		],
	},
	6: {
		unit: 'Ambition',
		id: 'FG10-A-P63-E6',
		audio: '',
		video: '',
		exerciseKey: '',
		component: T6,
		inputSize: 800,
		maxLength: 209,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 20,
		},
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '6',
				title: `
					<span style="color: rgb(0, 147, 69)">SPEAKING</span> 
					Work in pairs. Ask and answer the questions. 
					Give extra information where you can.
				`,
				color: '#860052',
			},
		],
		questions: [
			{
				title: `
					<div style="padding-left: 10px; margin-bottom: 16px;">
					<div style="display: flex;">
						<strong style="padding-right: 10px;">1</strong>
						<span>Which jobs do you think are overpaid? (Why?)</span>				
					</div>
					<div style="display: flex;">
						<strong style="padding-right: 10px;">2</strong>
						<span>Are you good at multi-tasking? (Give an example.)</span>				
					</div>
				</div>
		    `,
				answer: [],
			},
		],
	},
};

export default json;
