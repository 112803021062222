import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
  2: {
    unit: 'Weather',
    id: 'FG10-W-P47-E2',
    exerciseKey: '/img/FriendsPlus/Page47/E2/Key/answerKey.png',
    audio: '',
    video: '',
    component: T6,
    maxLength: 15,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      width: 200,
    },

    titleQuestion: [
      {
        num: '2',
        title: `
        <div >
          <span style="color: rgb(146, 39, 143)">VOCABULARY </span>
          <span>
          Complete the table with the highlighted words from the quiz in exercise . What do you notice about the way the
          adjectives are formed?
          </span>
        </div>
        
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div style="width: 650px; margin-left: 100px " >
          <div style="background-color: rgb(146, 39, 143); margin-bottom:-10px;">
            <p style="color: white; margin-left: 10px">Weather</p>
          </div>
        <table style="border-collapse: collapse;width:100%;">
          <thead style="background-color: rgb(146, 39, 143); color: white;">
            <tr style="background-color: rgb(146, 39, 143);color: white; ">
              <th style="border: 1px solid rgb(255,255,255); padding:8px">Noun</th>
              <th style="border: 1px solid rgb(255,255,255); padding:8px">Adjective</th>
              <th style="border: 1px solid rgb(255,255,255); padding:8px">Verb</th>
              <th style="border: 1px solid rgb(255,255,255); padding:8px">Related words and phrases</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="border: 1px solid rgb(146, 39, 143);padding:8px;">
                <span style=""><sup>1</sup></span>
                <div style="margin-top:-20px">
                  <input type="text" style="border: none; border-bottom: 1px solid;" width="80px"; outline: none" />
                </div>
              </td>
              <td style="border: 1px solid rgb(146, 39, 143);padding:8px;">cloudy</td>
              <td style="border: 1px solid rgb(146, 39, 143);padding:8px;background-color: rgb(211, 211, 211); width:150px"></td>
              <td style="border: 1px solid rgb(146, 39, 143);padding:8px;">
              <p  style="margin: 0px">rain clouds</p>
              <sup>2</sup>
                <div style="margin-top:-25px">
                  <input type="text" style="border: none; border-bottom: 1px solid;outline: none" width="160px" />
                </div>
              </td>
            </tr>

            <tr>
              <td style="border: 1px solid rgb(146, 39, 143);padding:8px">fog</td>
              <td style="border: 1px solid rgb(146, 39, 143);padding:8px">
                <sup>3</sup>
                <div style="margin-top: -25px">
                  <input type="text" style="border: none; border-bottom: 1px solid; outline: none" width="80px" />
                </div>
              </td>
              <td
                style="
                  border: 1px solid rgb(146, 39, 143);
                  padding: 8px;
                  background-color: rgb(211, 211, 211);
                  width: 150px;
                "
              ></td>
              <td
                style="
                  border: 1px solid rgb(146, 39, 143);
                  padding: 8px;
                  background-color: rgb(211, 211, 211);
                  width: 150px;
                "
              ></td>
            </tr>
            <tr>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">frost</td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">frost</td>
              <td
                style="
                  border: 1px solid rgb(146, 39, 143);
                  padding: 8px;
                  background-color: rgb(211, 211, 211);
                  width: 150px;
                "
              ></td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">frostbite</td>
            </tr>
            <tr>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">
                <sup>4</sup>
                <div style="margin-top: -25px">
                  <input type="text" style="border: none; border-bottom: 1px solid;outline: none" width="60px"/>
                </div>
              </td>
              <td
                style="
                  border: 1px solid rgb(146, 39, 143);
                  padding: 8px;
                  background-color: rgb(211, 211, 211);
                  width: 150px;
                "
              ></td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">hail</td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">hail storm</td>
            </tr>
            <tr>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">ice</td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">icy</td>
              <td
                style="
                  border: 1px solid rgb(146, 39, 143);
                  padding: 8px;
                  background-color: rgb(211, 211, 211);
                  width: 150px;
                "
              ></td>
              <td
                style="
                  border: 1px solid rgb(146, 39, 143);
                  padding: 8px;
                  background-color: rgb(211, 211, 211);
                  width: 150px;
                "
              ></td>
            </tr>
            <tr>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">
                <sup>5</sup>
                <div style="margin-top: -25px">
                  <input type="text" style="border: none; border-bottom: 1px solid; outline: none" width="120px" />
                </div>
              </td>
              <td
                style="
                  border: 1px solid rgb(146, 39, 143);
                  padding: 8px;
                  background-color: rgb(211, 211, 211);
                  width: 150px;
                "
              ></td>
              <td
                style="
                  border: 1px solid rgb(146, 39, 143);
                  padding: 8px;
                  background-color: rgb(211, 211, 211);
                  width: 150px;
                "
              ></td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">flash of lightning</td>
            </tr>
            <tr>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">
                <sup>6</sup>
                <div style="margin-top: -25px">
                  <input type="text" style="border: none; border-bottom: 1px solid;outline: none" width="60px"/>
                </div>
              </td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">
                <sup>7</sup>
                <div style="margin-top: -25px">
                  <input type="text" style="border: none; border-bottom: 1px solid;outline: none"width="80px" />
                </div>
              </td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">rain</td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">
                <sup>8</sup>
                <div style="margin-top: -25px">
                  <input type="text" style="border: none; border-bottom: 1px solid; outline: none" width="120px"/>
                </div>
              </td>
            </tr>
            <tr>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">shower</td>

              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">showery</td>
              <td
              style="
                border: 1px solid rgb(146, 39, 143);
                padding: 8px;
                background-color: rgb(211, 211, 211);
                width: 150px;
              "
            ></td>

              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">rain shower</td>
            </tr>
            <tr>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">snow</td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">snowy</td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">snow</td>

              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">
                <sup>9</sup>
                <div style="margin-top: -25px">
                  <input type="text" style="border: none; border-bottom: 1px solid; outline: none"width="130px" />
                </div>
              </td>
            </tr>
            <tr>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">
              <sup>10</sup>
              <div style="margin-top: -25px">
                <input type="text" style="border: none; border-bottom: 1px solid; outline: none" width="80px"/>
              </div>
            </td>
            <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">stormy</td>
            <td
              style="
                border: 1px solid rgb(146, 39, 143);
                padding: 8px;
                background-color: rgb(211, 211, 211);
                width: 150px;
              "
            ></td>

            <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">thunderstorm</td>
            </tr>
            <tr>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">
                <sup>11</sup>
                <div style="margin-top: -25px">
                  <input type="text" style="border: none; border-bottom: 1px solid; outline: none"width="60px" />
                </div>
              </td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">sunny</td>

              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">(the sun shines)</td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">
                <sup>12</sup>
                <div style="margin-top: -25px">
                  <input type="text" style="border: none; border-bottom: 1px solid; outline: none" width="120px"/>
                </div>
              </td>
            </tr>
            <tr>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">
                <sup>13</sup>
                <div style="margin-top: -25px">
                  <input type="text" style="border: none; border-bottom: 1px solid; outline: none" width="100px"/>
                </div>
              </td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">thundery</td>

              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">to thunder</td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">clap / crash of thunder</td>
            </tr>
            <tr>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">
                <sup>14</sup>
                <div style="margin-top: -25px">
                  <input type="text" style="border: none; border-bottom: 1px solid; outline: none"width="80px" />
                </div>
              </td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">
                <sup>15</sup>
                <div style="margin-top: -25px">
                  <input type="text" style="border: none; border-bottom: 1px solid; outline: none" width="80px" />
                </div>
              </td>
              <td style="border: 1px solid rgb(146, 39, 143); padding: 8px">(the wind) blows</td>
              <td
                style="
                  border: 1px solid rgb(146, 39, 143);
                  padding: 8px;
                  background-color: rgb(211, 211, 211);
                  width: 150px;
                "
              ></td>
            </tr>
          </tbody>
        </table>
    </div>

        `,
        answer: [
          'cloud',
          'storm clouds',
          'foggy',
          'hail',
          'lightning',
          'rain',
          'rainy',
          'raindrop',
          'snowflake',
          'storm',
          'sun',
          'sunshine',
          'thunder',
          'wind',
          'windy',
        ],
      },
    ],
  },
  3: {
    unit: 'Weather',
    id: 'FG10-W-P47-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: `
        <span style="color: rgb(45,206,137) ;">SPEAKING </span>
            In pairs, ask and answer the questions below.Use words and phrases from exercise 2.
          `,
        color: '#92278f',
      },
    ],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <div>
            <div style="margin-top: 10px; margin-bottom: 10px;">
              <span><b>1. </b></span>
              <span>What is the weather like in your city / province in  May / August / December?</span>
            </div>
            <div>
              <span><b>2. </b></span>
              <span>What is your favourite weather? Why?</span>
            </div>
          </div>
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: 'Weather',
    id: 'FG10-W-P47-E4',
    exerciseKey: 'img/FriendsPlus/Page47/E4/Key/answerKey.png',
    audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    inputSize: 50,
    maxLength: 1,
    titleQuestion: [
      {
        num: '4',
        title: `
        <div  style="margin-bottom: 20px">
          <audioimage name="2.02"></audioimage>
          <span> Listen to three weather reports. Match
          them with the photos (A-C).<span>
        </div>  
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
          <div>
              <div style="display: flex; gap: 50px;">
               <span style="margin-right: 60px"><strong>1</strong>#</span>
               <span style="margin-right: 60px"><strong>2</strong>#</span>
               <span><strong>3</strong>#</span>
              </div>
              
          </div>
          <img src='/img/FriendsPlus/Page47/E4/E3.jpg' alt='' style='width: 900px;' />
        `,
        answer: ['C', 'A', 'B'],
      },
    ],
  },
  5: {
    unit: 'Weather',
    id: 'FG10-W-P47-E5',
    exerciseKey: 'img/FriendsPlus/Page47/E5/Key/answerKey.png',
    audio: 'Audios/Friends Global Grade 10 SB_CD2_02.mp3',
    video: '',
    component: T6,
    inputSize: 200,
    maxLength: 20,
    textAlign: 'center',
    titleQuestion: [
      {
        num: '5',
        title: `
          <audioimage name="2.02"></audioimage>
          <span style="margin-left: 10px">Read the <span style="color: rgb(154, 61, 135)">Recycle!</span> box. 
          Then listen again and complete these sentences from the weather reports.<span>
          
        `,
        color: '#860052',
      },
    ],

    questions: [
      {
        title: `
        <div>
          <img src="/img/FriendsPlus/Page47/E5/E5.png" alt="" style="width: 600px;"/>
        </div>
          <div>
            <div> 
              <strong> Report 1 </strong> 
              <div style="margin-top: 10px">
                <strong style="margin-right: 5px;">1.</strong>It’s –10°C and there’s # everywhere.
              </div>
              <div>
                <p></p>
                <strong style="margin-right: 5px;">2.</strong>We’re expecting # this afternoon.
              </div>
            </div>
            <div style="margin-top: 10px"> 
              <strong> Report 2 </strong> 
              <div>
                <p></p>
                <strong style="margin-right: 5px;">3.</strong>There is # on the ground.
              </div>
              <div style="line-height: 50px;">
                <p></p>
                <strong style="margin-right: 5px;">4.</strong>There isn’t # but the wind is blowing the snow into my face.
              </div>
            </div>
            <div style="margin-top: 10px"> 
              <strong> Report 3 </strong> 
              <div>
                <p></p>
                <strong style="margin-right: 5px;">5.</strong>There were # and it was windy too.
              </div>
              <div>
                <p></p>
                <strong style="margin-right: 5px;">6.</strong>Now there’s # and the wind is extremely strong.
              </div>
            </div>
          </div>
                `,
        answer: ['a lot of ice', 'a little snow', 'some snow', 'any fog', ' a few showers', 'a lot of rain'],
      },
    ],
  },
  6: {
    unit: 'Weather',
    id: 'FG10-W-P47-E6',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '6',
        title: `
        <span style="color: rgb(45, 206, 137)">SPEAKING </span>
            Work in pairs. Make a weather report for yesterday, today, and tomorrow. Present it to the class.
          
          `,

        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [],
  },
};

export default json;
