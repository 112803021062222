import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P84-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page84/E1/key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: ` Read the postcard. 
			  `,
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 700 },
    titleImage: '',
    questionImage: [],
    questions: [
      {
        title: `
				  <div ><img src='img/FriendsPlus/Page84/E1/2.jpg' alt='' style="margin-left: 0px; width: 550px;"><img src='img/FriendsPlus/Page84/E1/1.jpg' alt='' style="margin-left: 5px; width: 450px;"></img></div>
					<div style='display: flex;'><b > Can you identify the country?</b></div>
					<div><textarea id="0" rows="1"></textarea></div>
			  `,
        answer: ['The city is Hue, Viet Nam.'],
      },
    ],
  },

  2: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P84-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page84/E2/key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: ` Read the <span style='color:rgb(244, 143, 32);'>LEARN THIS!</span> box. Can you find any examples of rules a–d in the postcard?
			  `,
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 1000 },
    titleImage: '',
    questionImage: [],
    questions: [
      {
        title: `
				  <div style='display:flex;'>
					  <div style=" width: 750px; background-image: url('img/FriendsPlus/Page84/E2/1.jpg');  background-repeat: no-repeat;
  background-size: 750px 500px;">
              <div style='padding:25px 15px 25px 55px;'>
                    <div><b style='color:rgb(244, 143, 32);'>LEARN THIS!</b><b> Present perfect</b></div>
                    <div>We use the present perfect:</div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> a </b></div>
                      <div> to give news, when we do not say exactly when the event happened. <i style='color:rgb(124, 162, 211)'>Guess what? I’ve won a competition!</i></div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> b </b></div>
                      <div> to talk about events during a period of time (e.g. a holiday) that is still continuing. <i style='color:rgb(124, 162, 211)'> I’m in Paris. I’ve visited a museum but I haven’t seen the Eiffel Tower.</i></div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> c </b></div>
                      <div> to ask how long a situation has existed.<i style='color:rgb(124, 162, 211)'> How long have you been in Spain?</i></div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> d </b></div>
                      <div> with for or since to say how long a situation has existed. We use for with a period of time and since to say when it started.<i style='color:rgb(124, 162, 211)'> We’ve been in Spain for a week /since Tuesday.</i></div>
                    </div>
										
                </div>
            </div>
            <img src='img/FriendsPlus/Page84/E2/2.jpg' alt='' style="margin-left: 5px; width: 550px;"></img>
          </div>
					<div style='display: flex;'><b style='margin-top: 5px;'> a </b> <textarea id="0" rows="1"></textarea></div>
					<div style='display: flex;'><b style='margin-top: 5px;'> b </b> <textarea id="1" rows="3"></textarea></div>
					<div style='display: flex;'><b style='margin-top: 5px;'> c </b> <textarea id="2" rows="1"></textarea></div>
					<div style='display: flex;'><b style='margin-top: 5px;'> d </b> <textarea id="3" rows="1"></textarea></div>

			  `,
        answer: [
          "He's passed his driving test!",
          "The weather has been wonderful, and we've been to a lot of tourist attractions.I've visited the Tomb of Khai Dinh and I've even gone boating on the Perfume River. I've bought you a souvenir.Have you missed me ? ",
          'No example in the text.',
          "We've been in Hue for three days.",
        ],
      },
    ],
  },

  3: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P84-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '3',
        title: ` Imagine you are on holiday. Invent answers to these questions and make notes.
			  `,
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 1000 },
    titleImage: '',
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          
          <div style='display: flex;'>
            <div style='margin-right: 10px;'><b> a </b> How long have you been away? (Use for or since.)</div>
          </div>
          <div style='display: flex;'>
            <div style='margin-right: 10px;'><b> b </b> Which countries have you visited? (Three countries.)</div>
          </div>
          <div style='display: flex;'>
            <div style='margin-right: 10px;'><b> c </b> What have you done? (Choose two activities.)</div>
          </div>
			  `,
        answer: [],
      },
    ],
  },

  4: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P84-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '4',
        title: `
				<span style='color:rgb(0,147,70);'>SPEAKING</span> Work in groups. Ask and answer your questions in exercise 3.
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
          
            <div style='margin-right: 10px;'><b> a </b> How long have you been away? (Use for or since.)</div>
            <div style='margin-right: 10px;'><b> b </b> Which countries have you visited? (Three countries.)</div>
            <div style='margin-right: 10px;'><b> c </b> What have you done? (Choose two activities.)</div>
			  `,
        answer: [],
      },
    ],
  },
  5: {
    unit: 'Unit 7',
    id: 'SB10-U7-P84-E5',
    audio: 'Audios/Friends Global Grade 10 SB_CD3_04.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page84/E5/key/answerKey.png',
    titleQuestion: [
      {
        num: '5',
        title: `
				<headphone1 name='3.04' src=''></headphone1> Read and listen to the dialogue.  
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    textareaStyle: { width: 1000 },
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='margin-right: 30px;'><b> Beth </b></div>
          <div> Have you ever been to Bulgaria?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 30px;'><b> Fred </b></div>
          <div> Yes, I went there last year with my family. Have you been there?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 30px;'><b> Beth </b></div>
          <div> No, I haven’t. What cities did you visit?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 30px;'><b> Fred </b></div>
          <div> We didn’t visit any cities. We went skiing.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 30px;'><b> Beth </b></div>
          <div> Sounds great. I’ve never been skiing, but I’d love to go. Was it good?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 30px;'><b> Fred </b></div>
          <div> Not really. I fell and broke my leg on the first day, so I spent the rest of the holiday in hospital!</div>
        </div>
        <div>
          <div><b> When did Fred go to Bulgaria? </b></div>
          <div><textarea id='0' rows='1'></textarea></div>
          <div><b>What happened to him while he was there?</b></div>
          <div><textarea id='1' rows='2'></textarea></div>
        </div>


			  `,
        answer: [
          'He went last year.',
          'On the first day he fell while he was skiing and broke his leg, so he spent the rest of the holiday in hospital.',
        ],
      },
    ],
  },
  6: {
    unit: 'Unit 7',
    id: 'SB10-U7-P84-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page84/E6/key/key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `
				Find examples of the present perfect and the past simple in the dialogue in exercise 5.  
			  `,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    inputSize: 50,
    stylesTextInput: [],
    maxLength: 2,
    textAlign: 'center',
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='margin-right: 30px;'><b> Beth </b></div>
          <div><b><sup>1</sup></b>Have you ever been to Bulgaria?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 30px;'><b> Fred </b></div>
          <div><b><sup>2</sup></b>Yes, I went there last year with my family. <b><sup>3</sup></b>Have you been there?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 30px;'><b> Beth </b></div>
          <div> <b><sup>4</sup></b>No, I haven’t. <b><sup>5</sup></b>What cities did you visit?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 30px;'><b> Fred </b></div>
          <div> <b><sup>6</sup></b>We didn’t visit any cities. <b><sup>7</sup></b>We went skiing.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 30px;'><b> Beth </b></div>
          <div><b><sup>8</sup></b>Sounds great. <b><sup>9</sup></b>I’ve never been skiing, but I’d love to go. <b><sup>10</sup></b>Was it good?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 30px;'><b> Fred </b></div>
          <div> <b><sup>11</sup></b>Not really. <b><sup>12</sup></b>I fell and broke my leg on the first day, so I spent the rest of the holiday in hospital!</div>
        </div>

        <div style='display: flex;'>
          <div style='margin-right: 30px;'><b> Present perfect: </b></div>
          <div> #, #, #, #</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 30px;'><b> Past simple: </b></div>
          <div> #, #, #, #, #, #</div>
        </div>


			  `,
        answer: ['1', '3', '4', '9', '2', '5', '6', '7', '10', '12'],
      },
    ],
  },
  7: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P84-E7',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page84/E7/key/answerKey.png',
    titleQuestion: [
      {
        num: '7',
        title: ` Read the <span style='color:rgb(244, 143, 32);'>LEARN THIS!</span> box. Why are some verbs in the dialogue in exercise 5 in the past simple and others in the present perfect? Use rules a–c to explain.
			  `,
        color: '#92278f',
      },
    ],
    textareaStyle: { width: 900 },
    titleImage: '',
    questionImage: [],
    questions: [
      {
        title: `
					  <div style=" width: 750px; background-image: url('img/FriendsPlus/Page84/E2/1.jpg');  background-repeat: no-repeat; background-size: 750px 530px;">
              <div style='padding:25px 15px 25px 55px;'>
                    <div><b style='color:rgb(244, 143, 32);'>LEARN THIS!</b><b> Past simple and present perfect contrast</b></div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> a </b></div>
                      <div>
                        <div>We use the present perfect to talk about an experience at any time in the past.</div>
                        <div><i style='color:rgb(124, 162, 211)'>I’ve read ‘The Hobbit’ three times.</i></div>
                        <div><i style='color:rgb(124, 162, 211)'>Have you ever travelled by helicopter?</i></div>
                      </div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> b </b></div>
                      <div>
                        <div>We use the past simple to talk about a specific occasion.</div>
                        <div><i style='color:rgb(124, 162, 211)'>I watched a good film on TV last night.</i></div>
                        <div><i style='color:rgb(124, 162, 211)'>Did you go to Newcastle last weekend?</i></div>
                      </div>
                    </div>
                    <div style='display: flex;'>
                      <div style='margin-right: 10px;'><b> c </b></div>
                      <div>
                        <div>We often use the present perfect to ask and answer questions about an experience, and then use the past simple to give specific information about it.</div>
                        <div><i style='color:rgb(124, 162, 211)'>‘‘Have you ever been to the USA?’’ </i></div>
                        <div><i style='color:rgb(124, 162, 211)'>‘‘Yes, I have. I went to New York last winter.’’</i></div>
                      </div>
                    </div>
                </div>
            </div>
					  <div style='display: flex;'><b style='margin-top: 5px;'> <i>Answer:</i> </b> <textarea id="0" rows="2"></textarea></div>
            <img src='img/FriendsPlus/Page84/E7/3.jpg' alt='' style="margin-left: 5px; width: 650px;"></img>

			  `,
        answer: [
          'The present perfect is used to ask and answer questions, and the past simple is used to give specific information.',
        ],
      },
    ],
  },
  8: {
    // Exercise num
    unit: 'Unit 7',
    id: 'SB10-U7-P84-E8',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '/img/FriendsPlus/Page84/E8/key/answerKey.png',
    inputSize: 650,
    maxLength: 81,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: '8',
        title: `
				<span style='color:rgb(0,147,70);'>SPEAKING</span> Work in pairs. Ask and answer questions about your experiences. You can use the prompts.
			  `,
        color: '#92278f',
      },
    ],
    questions: [
      {
        title: `
        	<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div>
            <div> travel / on a plane?</div>
            <div><i style='color:rgb(124, 162, 211)'>Have you ever travelled on a plane?</i></div>
          </div>
        </div>
        	<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>
            <div> lose / anything while travelling?</div>
            <div>#</div>
          </div>
        </div>
        	<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>
            <div> buy / anything online?</div>
            <div>#</div>
          </div>
        </div>
        	<div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>
            <div> eat / food from another country?</div>
            <div>#</div>
          </div>
        </div>

			  `,
        answer: [
          'Have you ever lost anything while travelling?',
          'Have you ever bought anything online?',
          'Have you ever eaten food from another country?',
        ],
      },
    ],
  },
};

export default json;
