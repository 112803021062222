import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Introduction',
    id: 'FG10-I-P6-E1',
    audio: '',
    video: '',
    component: UI,
    titleQuestion: [
      {
        num: '1',
        title: `<span style='color:rgb(0,147,70);'>SPEAKING</span> Describe the photo. Where are the people? What are they doing?`,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: 'img/FriendsPlus/Page6/E1/1.jpg' }]],
  },
  2: {
    unit: 'Introduction',
    id: 'FG10-I-P6-E2',
    audio: 'Audios/Friends Global Grade 10 SB_CD1_02.mp3',
    video: '',
    component: T6,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '2',
        title: `<audioimage name="1.02"></audioimage>Read and listen to the dialogue. Find the names of the people in the photo.`,
        color: '#92278f',
      },
    ],
    titleImage: '',
    questionImage: [],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page6/E1/1.jpg' />
          <div style='display: flex;'><div style='flex: 1;'><b>Ryan</b></div><div style='flex: 9;'>Hi, Izzy. Can I sit here?</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Izzy</b></div><div style='flex: 9;'>Yes, of course. This is Becky. She’s new.</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Ryan</b></div><div style='flex: 9;'>Hi, Becky. I’m Ryan, Izzy’s brother.</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Becky</b></div><div style='flex: 9;'>Hi. Nice to meet you!</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Ryan</b></div><div style='flex: 9;'>Where are you from, Becky?</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Becky</b></div><div style='flex: 9;'>I’m from London. I moved here two weeks ago.</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Ryan</b></div><div style='flex: 9;'>I love London. I’ve got friends there. I sometimes visit them and we go skateboarding.</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Izzy</b></div><div style='flex: 9;'>Do you like skateboarding, Becky?</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Becky</b></div><div style='flex: 9;'>Not really. But I like ice skating.</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Izzy</b></div><div style='flex: 9;'>Me too! Let’s go ice skating after school.</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Becky</b></div><div style='flex: 9;'>Great idea!</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Ryan</b></div><div style='flex: 9;'>I’m not very keen on ice skating. What do you think of bowling?</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Becky</b></div><div style='flex: 9;'>Bowling? I hate it.</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Ryan</b></div><div style='flex: 9;'>Oh. Actually, I don’t mind ice skating …</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Izzy</b></div><div style='flex: 9;'>There’s the bell. I’ve got maths, then history.</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Becky</b></div><div style='flex: 9;'>I’ve got PE now. I love PE! See you after school, Izzy!</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Izzy</b></div><div style='flex: 9;'>Bye, Becky.</div></div>
          <div style='display: flex;'><div style='flex: 1;'><b>Ryan</b></div><div style='flex: 9;'>Yeah … Bye …</div></div>
        `,
        answer: [],
      },
    ],
  },
  3: {
    unit: 'Introduction',
    id: 'FG10-I-P6-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page6/E3/Key/answerKey.png',
    inputSize: 40,
    maxLength: 1,
    titleImage: '',
    titleQuestion: [
      {
        num: '3',
        title: `Are the sentences true or false? Write T or F.`,
        color: '#92278f',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'><div style='margin-right: 5px;'><b>1</b></div><div style='flex: 1;'>This is the first time Izzy and Ryan have met. #</div></div>
          <div style='display: flex;'><div style='margin-right: 5px;'><b>2</b></div><div style='flex: 1;'>This is the first time Becky and Ryan have met. #</div></div>
          <div style='display: flex;'><div style='margin-right: 5px;'><b>3</b></div><div style='flex: 1;'>Ryan sometimes goes skateboarding in London. #</div></div>
          <div style='display: flex;'><div style='margin-right: 5px;'><b>4</b></div><div style='flex: 1;'>Becky does not like skateboarding or bowling. #</div></div>
          <div style='display: flex;'><div style='margin-right: 5px;'><b>5</b></div><div style='flex: 1;'>Ryan and Becky agree to go ice skating after school. #</div></div>
          <div style='display: flex;'><div style='margin-right: 5px;'><b>6</b></div><div style='flex: 1;'>Izzy and Becky have got PE next. #</div></div>
        `,
        answer: ['F', 'T', 'T', 'T', 'F', 'F'],
      },
    ],
  },
  4: {
    unit: 'Introduction',
    id: 'FG10-I-P6-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page6/E4/Key/answerKey.png',
    titleQuestion: [
      {
        num: '4',
        title: `<span style='color: #92278f;'>VOCABULARY</span> Add three words from the dialogue in exercise 2 to each list.`,
        color: '#92278f',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='width: 600px;'>
            <div style='color: rgb(172,97,170); display: flex;'>
              <div style='flex: 1;'>Sports and hobbies</div>
              <div style='flex: 1;'>School subjects</div>
            </div>
            <div style='color: rgb(172,97,170); display: flex;'>
              <div style='flex: 1;'>board games</div>
              <div style='flex: 1;'>drama</div>
            </div>
            <div style='color: rgb(172,97,170); display: flex;'>
              <div style='flex: 1;'>drawing</div>
              <div style='flex: 1;'>IT (information technology)</div>
            </div>
            <div style='display: flex;'>
              <div style='flex: 1;'>#</div>
              <div style='flex: 1;'>#</div>
            </div>
            <div style='display: flex;'>
              <div style='flex: 1;'>#</div>
              <div style='flex: 1;'>#</div>
            </div>
            <div style='display: flex;'>
              <div style='flex: 1;'>#</div>
              <div style='flex: 1;'>#</div>
            </div>
          </div>
        `,
        answer: [
          'skateboarding',
          'maths',
          'ice skating',
          'history',
          'bowling',
          'PE',
        ],
      },
    ],
  },
  5: {
    unit: 'Introduction',
    id: 'FG10-I-P6-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '5',
        title: `Work in pairs. How many more words can you add to the lists in exercise 4 in three minutes?`,
        color: '#92278f',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='width: 600px;'>
            <div style='color: rgb(172,97,170); display: flex;'>
              <div style='flex: 1;'>Sports and hobbies</div>
              <div style='flex: 1;'>School subjects</div>
            </div>
            <div style='color: rgb(172,97,170); display: flex;'>
              <div style='flex: 1;'>board games</div>
              <div style='flex: 1;'>drama</div>
            </div>
            <div style='color: rgb(172,97,170); display: flex;'>
              <div style='flex: 1;'>drawing</div>
              <div style='flex: 1;'>IT (information technology)</div>
            </div>
            <img src='img/FriendsPlus/Page6/E5/1.jpg' />
          </div>
        `,
        answer: [
          'skateboarding',
          'maths',
          'ice skating',
          'history',
          'bowling',
          'PE',
        ],
      },
    ],
  },
  6: {
    unit: 'Introduction',
    id: 'FG10-I-P6-E6',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page6/E6/Key/answerKey.png',
    titleQuestion: [
      {
        num: '6',
        title: `<span style='color: #92278f;'>KEY PHRASES</span> Label the phrases below A (<i>like</i>), B (<i>OK</i>) or C (<i>don't like</i>). <br/>Which phrases are in the dialogue in exercise 2?`,
        color: '#92278f',
      },
    ],
    selectStyle: {
      // color: '#00ADFE',
      width: 60,
      textAlign: 'center',
    },
    selectOptionValues: ['A', 'B', 'C'],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='color: rgb(146,39,143);'><b>Likes and dislikes</b></div>
          <div style='width: 900px;'>
            <div style='display: flex;'>
              <div style='display: flex; flex: 1;'><select id='5'></select> I can't stand …</div>
              <div style='display: flex; flex: 1;'><select id='6'></select> I love …</div>
              <div style='display: flex; flex: 1;'><select id='7'></select> … isn't bad.</div>
            </div>
            <div style='display: flex;'>
              <div style='display: flex; flex: 1;'><select id='8'></select> I don't mind …</div>
              <div style='display: flex; flex: 1;'><select id='9'></select> I (quite) like …</div>
              <div style='display: flex; flex: 1;'><select id='10'></select> … is great.</div>
            </div>
            <div style='display: flex;'>
              <div style='display: flex; flex: 1;'><select id='11'></select> I hate …</div>
              <div style='display: flex; flex: 1;'><select id='12'></select> I'm really keen on …</div>
              <div style='display: flex; flex: 1;'><select id='13'></select> … is terrible.</div>
            </div>
          </div>
          <div>Which phrases are in the dialogue in exercise 2?</div>
          <div><input id='0'></input></div>
          <div><input id='1'></input></div>
          <div><input id='2'></input></div>
          <div><input id='3'></input></div>
          <div><input id='4'></input></div>
        `,
        answer: [
          'I love',
          'I hate',
          "I don't mind",
          'I like',
          "I'm not really keen on",
          'C',
          'A',
          'B',
          'B',
          'B',
          'A',
          'C',
          'A',
          'C',
        ],
      },
    ],
  },
  7: {
    unit: 'Introduction',
    id: 'FG10-I-P6-E7',
    audio: '',
    video: '',
    component: UI,
    exerciseKey: '',
    titleQuestion: [
      {
        num: '7',
        title: `<span style='color: rgb(0,147,70);'>SPEAKING</span> Work in pairs. Give opinions of school subjects. Use the phrases in exercise 6.`,
        color: '#92278f',
      },
    ],
    recorder: true,
    questionImage: [[{ url: 'img/FriendsPlus/Page6/E7/1.jpg' }]],
  },
};

export default json;
